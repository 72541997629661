import React from "react";

import { collectionPctStr } from "../../helpers";

import CollectionEditorModal from "./CollectionEditorModal";
import CollectionProgressBar from "./CollectionProgressBar";
import Icon from "../Icon";
import MarkedAsKnownIcon from "../MarkedAsKnownIcon";
import MasteredIcon from "../MasteredIcon";
import PlayingIcon from "../PlayingIcon";

export default class CollectionTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionEditorModalVisible: false,
      deleted: false
    };
  }

  renderCollectionEditorModal() {
    if(!this.state.collectionEditorModalVisible) {
      return null;
    }

    return (
      <CollectionEditorModal
        collection={this.props.collection}
        isPro={this.props.isPro}
        onHidden={() => this.setState({ collectionEditorModalVisible: false })}
        onDelete={this.props.onCollectionDelete}
        onUpdate={this.props.onCollectionUpdate}
      />
    );
  }

  onDeleteSharedCollectionClick() {
    if(!confirm('Are you sure? This will delete all your progress for this collection. THIS ACTION CANNOT BE UNDONE.')) {
      return false;
    }
    
    this.setState({ deleted: true });

    $.ajax({
      method: 'delete',
      url: this.props.collection.userCollectionUrl
    })
      .done(() => {
        this.props.onCollectionDelete(this.props.collection);
      })
      .fail(() => {
        this.setState({ deleted: false });
        alert("Oh no! There was an error deleting the collection. Sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  renderEditableCollectionControl() {
    const { collection, editableCollection } = this.props;

    if(!editableCollection) {
      if(collection.user) {
        return (
          <div><small>Created by <a href={"/players/" + collection.user.username}>{collection.user.username}</a></small> <button className="btn btn-link btn-xs delete-shared" onClick={() => this.onDeleteSharedCollectionClick()}><Icon name="trash" /></button></div>
        );
      }

      return null;
    }
    
    return (
      <div>
        <small><Icon name={collection.public ? "users" : "lock"} type="fa" /> {collection.public ? "Shared" : "Private"}</small>
        <button onClick={() => this.setState({ collectionEditorModalVisible: true })} className="btn btn-xs btn-link edit-collection">Edit</button>
        {this.renderCollectionEditorModal()}
      </div>
    );
  }

  renderPlayBtn() {
    const { collection, isPro, isSignedIn, play } = this.props;

    if((collection.proOnly || !collection.type) && !isPro) {
      return (
        <span>
          <a className="btn btn-success joystix btn-sm btn-block disabled" href="/pro">
            Play <span className="glyphicon glyphicon-chevron-right"></span>
          </a>
          <span style={{ display: 'block' }} className="joystix text-center">Pro!</span>
        </span>
      );
    }

    return (
      <>
        <button className="btn btn-success joystix btn-sm btn-block" disabled={!collection.numSentences || !isSignedIn} onClick={() => play(collection)}>
          Play <span className="glyphicon glyphicon-chevron-right"></span>
        </button>
        {!isSignedIn && <span style={{ display: 'block' }} className="joystix text-center">Sign up!</span>}
      </>
    );
  }

  renderProgress() {
    const { add, progressPerChunk, useProgressChunks } = this.props;
    const c = this.props.collection;

    if(!c.numSentences && add) {
      return (
        <div style={{ marginTop: 5, textAlign: 'right' }}>
          Add sentences to get started! <span className="glyphicon glyphicon-arrow-right"></span>
        </div>
      );
    }

    return (
      <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1 }}>
            <strong>{c.numSentences.toLocaleString()}</strong> Sentences
          </div>
          <div style={{ flex: 1 }}>
            <span data-toggle="tooltip" title="Playing"><PlayingIcon /></span> <strong className="num-playing">{c.numPlaying.toLocaleString()}</strong> <small>({collectionPctStr(c, 'playing')})</small>
          </div>
          <div style={{ flex: 1 }}>
            <span data-toggle="tooltip" title="Mastered"><MasteredIcon /></span> <strong className="num-mastered">{c.numMastered.toLocaleString()}</strong> <small>({collectionPctStr(c, 'mastered')})</small>
          </div>
          {!!c.numMarkedAsKnown && (
            <div style={{ flex: 1 }}>
              <span data-toggle="tooltip" title="Marked as Known"><MarkedAsKnownIcon /></span> <strong className="num-marked-as-known">{c.numMarkedAsKnown.toLocaleString()}</strong>
            </div>
          )}
        </div>
        <CollectionProgressBar
          collection={c}
          perChunk={progressPerChunk}
          style={Object.assign({ marginBottom: 0, marginTop: 4 }, useProgressChunks ? null : { height: 10 })}
          useChunks={useProgressChunks}
        />
        {!!c.numPlaying && <div style={{ marginTop: 4 }}><small>Played today: {c.numPlayedToday} / New: {c.numNewToday} / Review: {c.numPlayedToday - c.numNewToday}</small></div>}
      </>
    );
  }

  renderDescriptionControl() {
    const { collection } = this.props;

    if(!collection.description) {
      return null;
    }

    return (
      <button className="btn btn-link" data-placement="right" data-content={collection.description} data-html={!!collection.type ? "true" : "false"} data-title={collection.name} data-toggle="popover" data-trigger="focus" style={{ color: "#000", textDecoration: 'none' }}>
        <span className="glyphicon glyphicon-question-sign"></span>
      </button>
    );
  }

  render() {
    if(this.state.deleted) {
      return null;
    }

    const { add, controls, isSignedIn, manage } = this.props;
    const c = this.props.collection;
    return (
      <tr className="collection collection-table-row">
        <td>
          <div className="flex-collection">
            <div className="collection-name-container">
              <div>
                <strong style={{ fontSize: '2rem' }}>{c.name}{this.renderDescriptionControl()}</strong>
                {this.renderEditableCollectionControl()}
              </div>
              {controls || c.subname}
            </div>
            <div className="collection-progress-container">
              {this.renderProgress()}
            </div>
            <div className="collection-controls-container">
              {!!add && (
                <div style={{ paddingRight: 10 }}>
                  <button className="btn btn-default btn-sm add-sentences" data-toggle="tooltip" data-title={'Add sentences'} onClick={() => add(c)}>
                    <Icon name="plus" />
                  </button>
                </div>
              )}
              <div style={{ paddingRight: 10 }}>
                <button className="btn btn-default btn-sm manage-collection" data-toggle="tooltip" data-title={'Manage ' + c.name} onClick={() => manage(c)} disabled={!isSignedIn}>
                  <span className="glyphicon glyphicon-list"></span>
                </button>
              </div>
              <div className="collection-play-btn-container">{this.renderPlayBtn()}</div>
            </div>
          </div>
        </td>

        {/*
        <td>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "30%" }}>
                <div>
                  <strong style={{ fontSize: '2rem' }}>{c.name}{this.renderDescriptionControl()}</strong>
                  {this.renderEditableCollectionControl()}
                </div>
                {controls || c.subname}
              </td>
              <td className="hidden-xs">
                {this.renderProgress()}
              </td>
              {!!add && (
                <td>
                  <button className="btn btn-default btn-sm add-sentences" data-toggle="tooltip" data-title={'Add sentences'} onClick={() => add(c)}>
                    <Icon name="plus" />
                  </button>
                </td>
              )}
              <td className="hidden-xs">
                <button className="btn btn-default btn-sm manage-collection" data-toggle="tooltip" data-title={'Manage ' + c.name} onClick={() => manage(c)} disabled={!isSignedIn}>
                  <span className="glyphicon glyphicon-list"></span>
                </button>
              </td>
              <td className="hidden-xs">
                {this.renderPlayBtn()}
              </td>
            </tr>
            <tr className="visible-xs">
              <td colSpan="5">
                {this.renderProgress()}
              </td>
            </tr>
            <tr className="visible-xs">
              <td colSpan="5">
                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                  {this.renderPlayBtn()}
                  <button className="btn btn-default btn-sm manage-collection" onClick={() => manage(c)} disabled={!isSignedIn} style={{ marginLeft: 10 }}>
                    <span className="glyphicon glyphicon-list"></span>
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </td>
        */}
      </tr>
    );
  }
}
