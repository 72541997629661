import React from "react";

import Modal from "./Modal";

const controlNoteStyles = {
  display: 'block',
  marginTop: 4,
  textAlign: 'center'
};

export default class PlayOptionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 10,
      mode: 'multiple_choice',
      skill: 'vocabulary',
    };
  }

  componentDidMount() {
    try {
      const { playOptions } = this.props;

      if(playOptions) {
        let count = playOptions.count || this.state.count;
        let skill = playOptions.skill || this.state.skill;
        let mode = playOptions.mode;

        if(mode !== "text_input" && mode !== "multiple_choice") {
          mode = this.state.mode;
        }

        if(!this.props.isPro) {
          count = 10;
          skill = 'vocabulary';
        }
        else if(skill === 'listening' && !this.props.isListeningSkillAvailable) {
          skill = 'vocabluary';
        }
        else if(skill === 'speaking' && !this.isSpeakingSkillAvailable()) {
          skill = 'vocabluary';
        }

        this.setState({
          count,
          mode,
          skill
        });
      }
    } catch(e) {
      // do nothing
    }
  }

  isSpeakingSkillAvailable() {
    const localSpeechRecognitionAvailable = window.clozemaster.speechRecognition.isSupported(this.props.targetLanguageIso)
        && window.clozemaster.speechRecognition.isAvailable();

    return this.props.isSpeakingSkillAvailable || localSpeechRecognitionAvailable;
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.count !== this.state.count || prevState.mode !== this.state.mode || prevState.skill !== this.state.skill) {
      this.savePlayOptions();
    }
  }

  savePlayOptions() {
    try {
      window.localStorage.setItem('playOptions', JSON.stringify({
        count: this.state.count,
        mode: this.state.mode,
        skill: this.state.skill
      }));
    } catch(e) {
      // do nothing
    }
  }

  renderScope() {
    const { scope } = this.props;
    let content = '';
    if(scope === 'ready_for_review') {
      content = 'Ready for Review';
    }
    else if(scope === 'favorited') {
      content = 'Favorites';
    }
    else if(scope === 'frequency_collections') {
      content = 'Most Common Words';
    }
    else {
      return null;
    }
    return <small className="joystix" style={{ display: 'block' }}>{content}</small>;
  }

  radioButton({ disabled = false, key, label, name, value }) {
    return (
      <div className="radio" key={key}>
        <label>
          <input type="radio" disabled={disabled} name={name} value={value} checked={this.state[name] === value} onChange={(e) => this.setState({ [name]: e.target.value })} />
          {label}
        </label>
      </div>
    );
  }

  getSkillOptions() {
    const { isListeningSkillAvailable, isSignedIn, targetLanguageIso } = this.props;

    const options = [
      this.radioButton({ disabled: !isSignedIn, key: "vocabulary", label: "Vocabulary", name: "skill", value: "vocabulary" })
    ];

    if(isListeningSkillAvailable) { // TODO! check if listening available
      options.push(
        this.radioButton({ disabled: !isSignedIn, key: "listening", label: "Listening", name: "skill", value: "listening" })
      );
    }

    // TODO! improve speaking support at some point - checking iso not correct
    if(this.isSpeakingSkillAvailable()) {
      options.push(
        this.radioButton({ disabled: !isSignedIn, key: "speaking", label: "Speaking", name: "skill", value: "speaking" })
      );
    }

    return options;
  }

  renderSkillProPromo() {
    const { skill } = this.state;
    const { isPro, listeningTrialExpired, speakingTrialExpired } = this.props;

    if(!isPro && skill === 'listening') {
      return (
        <small style={controlNoteStyles}>
          {listeningTrialExpired && <span className="text-danger">You've already played your one free round for today. </span>}
          {!listeningTrialExpired && 'Play one round of listening for free per day. '}
          <strong><a href="/pro">Get Clozemaster Pro</a></strong> for unlimited listening practice!
        </small>
      );
    }

    if(!isPro && skill === 'speaking') {
      return (
        <small style={controlNoteStyles}>
          {speakingTrialExpired && <span className="text-danger">You've already played your one free round for today. </span>}
          {!speakingTrialExpired && 'Play one round of speaking for free per day. '}
          <strong><a href="/pro">Get Clozemaster Pro</a></strong> for unlimited speaking practice!
        </small>
      );
    }
  }

  isSkillValid() {
    const { isPro, listeningTrialExpired, speakingTrialExpired } = this.props;
    const { skill } = this.state;

    if(skill === 'listening' && !isPro && listeningTrialExpired) {
      return false;
    }
    if(skill === 'speaking' && !isPro && speakingTrialExpired) {
      return false;
    }
    return true;
  }

  isSubmittable() {
    return this.isSkillValid();
  }

  renderSkill() {
    const { skill } = this.state;
    const options = this.getSkillOptions();

    if(options.length === 1) {
      return <input type="hidden" name="skill" value={skill} />;
    }

    return (
      <div className={"form-group" + (this.isSkillValid() ? '' : ' has-error')}>
        <strong>Skill:</strong>
        <div style={{ marginLeft: 15 }}>
          {options}
        </div>
        {this.renderSkillProPromo()}
      </div>
    );
  }

  renderSentencesPerRound() {
    const { count } = this.state;
    const { isPro } = this.props;

    return (
      <div className="form-group">
        <label htmlFor="count">Sentences per round:</label>
        <select className="form-control" name="count" value={count} onChange={(e) => this.setState({ count: e.target.value })}>
          {[5, 10, 20, 30, 40, 50, 100, 'Infinite'].map((n) => <option key={n} disabled={!isPro && n !== 10} value={n.toString().toLowerCase()}>{n}</option>)}
        </select>
        {!isPro && <small style={controlNoteStyles}><strong><a href="/pro">Get Clozemaster Pro</a></strong> to change the number of sentences per round!</small>}
      </div>
    );
  }

  renderMode() {
    const { translateTranscribeAvailable } = this.props;
    const { mode, skill } = this.state;

    const content = (
      <>
        {this.radioButton({ label: "Multiple Choice (Easy)", name: "mode", value: "multiple_choice" })}
        {this.radioButton({ label: "Text Input (Difficult)", name: "mode", value: "text_input" })}
        {skill === "listening" && this.radioButton({ label: "Transcribe (Hard)", name: "mode", value: "full_text_input" })}
      </>
    );


    return (
      <div className="form-group">
        <strong>Mode:</strong>
        <div style={{ marginLeft: 15 }}>
          {content}
          <small style={{ display: 'block', marginTop: -5 }}>Score 2x the points with Text Input{skill === "listening" && " and Transcribe"}!</small>
        </div>
      </div>
    );
  }

  render() {
    const { onHidden, collection, scope, skill } = this.props;
    const { mode } = this.state;

    return (
      <Modal onHidden={onHidden} size="small" title="Play">
        <p className="text-center">
          <span className="joystix">{collection.name}</span>
          {this.renderScope()}
        </p>
        <form method="get" action={collection.playWebUrl}>
          <input type="hidden" name="scope" value={scope || ''} />
          {this.renderSkill()}
          {this.renderSentencesPerRound()}
          {this.renderMode()}
          <button disabled={!this.isSubmittable()} className="btn btn-block joystix btn-success">
            Go <span className="glyphicon glyphicon-chevron-right"></span>
          </button>
        </form>
      </Modal>
    );
  }
}
