import React from "react";

import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";
import ReviewSettings from "./V3ReviewSettings";

export default class CollectionReviewSettingsModal extends React.Component {
  renderContent() {
    const { isPro, url } = this.props;
    return (
      <ReviewSettings
        isPro={this.props.isPro}
        onUpdate={() => this.reviewSettingsModal.hide()}
        url={this.props.url}
      />
    );
  }

  render() {
    const { collectionName, onHidden } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn text="Cancel" />}
        onHidden={onHidden}
        ref={(el) => this.reviewSettingsModal = el}
        title={`${collectionName} Review Settings`}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
