import React from "react";

import TextareaAutosize from 'react-textarea-autosize';

import Radio from "../Radio";

export default class CollectionEditor extends React.Component {
  constructor(props) {
    super(props);

    const collection = props.collection || {};

    this.state = {
      clozemasterApproved: collection.clozemasterApproved || false,
      description: collection.description || '',
      errors: null,
      id: collection.id || null,
      isPublic: collection.public || false,
      loading: false,
      name: collection.name || '',
      playOrder: collection.playOrder || '',
      useClozesAsMultipleChoiceOptions: collection.useClozesAsMultipleChoiceOptions || false
    };
  }

  onSubmit(e) {
    e.preventDefault();
    
    this.submit();
  }

  submit() {
    this.setState({ loading: true });

    const { description, id, isPublic, name, playOrder, useClozesAsMultipleChoiceOptions } = this.state;
    const { collection, collectionsUrl } = this.props;

    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        collection: {
          description,
          name,
          play_order: playOrder,
          public: isPublic,
          use_clozes_as_multiple_choice_options: useClozesAsMultipleChoiceOptions
        }
      }),
      method: id ? 'patch' : 'post',
      url: id ? collection.url : collectionsUrl
    })
      .done((data) => {
        if(data.errors) {
          this.setState({
            errors: data.errors,
            loading: false
          }, () => $('.modal').animate({ scrollTop: 0 }));
          return false;
        }

        const { collection } = data;
        this.setState({
          id: collection.id,
          loading: false,
          name: collection.name
        }, () => {
          this.props[id ? 'onUpdate' : 'onCreate'](collection);
        });
      })
      .fail(() => {
        this.setState({ loading: false });
        alert('Oh no! There was an error saving the collection. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  render() {
    const {
      clozemasterApproved,
      description,
      errors,
      isPublic,
      loading,
      name,
      playOrder,
      useClozesAsMultipleChoiceOptions,
    } = this.state;

    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        {!loading && errors && <div className="alert alert-danger" role="alert">{errors}</div>}
        <div className="form-group">
          <label htmlFor="collection[name]">Name</label>
          <input disabled={loading} autoFocus={true} name="collection[name]" className="form-control" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
        </div>
        <div className="form-group">
          <label htmlFor="collection[description]">Description (optional)</label>
          <TextareaAutosize
            className="form-control"
            name="collection[description]"
            onChange={(e) => this.setState({ description: e.target.value })}
            value={description}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <strong>Play in order:</strong>
          <div style={{ marginLeft: 15 }}>
            <Radio
              checked={!playOrder}
              label="Random"
              name="collection[play_order]"
              onChange={(e) => this.setState({ playOrder: e.target.value })}
              value=""
            />
            <Radio
              checked={playOrder === "id"}
              label="Added / created at"
              name="collection[play_order]"
              onChange={(e) => this.setState({ playOrder: e.target.value })}
              value="id"
            />
            <Radio
              checked={playOrder === "difficulty"}
              label="Difficulty"
              name="collection[play_order]"
              onChange={(e) => this.setState({ playOrder: e.target.value })}
              value="difficulty"
            />
          </div>
        </div>
        <div style={{ marginBottom: 10 }}>
          <strong>Multiple choice options:</strong>
          <div style={{ marginLeft: 15 }}>
            <Radio
              checked={!useClozesAsMultipleChoiceOptions}
              label="Random words"
              name="collection[use_clozes_as_multiple_choice_options]"
              onChange={(e) => this.setState({ useClozesAsMultipleChoiceOptions: !e.target.checked })}
              value="false"
            />
            <Radio
              checked={useClozesAsMultipleChoiceOptions}
              label="Cloze words"
              name="collection[use_clozes_as_multiple_choice_options]"
              onChange={(e) => this.setState({ useClozesAsMultipleChoiceOptions: e.target.checked })}
              value="true"
            />
          </div>
        </div>
        <div style={{ marginBottom: 10 }}>
          <strong>Visibility:</strong>
          <div style={{ marginLeft: 15 }}>
            <Radio
              checked={!isPublic}
              label="Private"
              name="collection[public]"
              onChange={(e) => this.setState({ isPublic: !e.target.checked })}
              value="false"
            />
            <Radio
              checked={isPublic}
              label="Shared"
              name="collection[public]"
              onChange={(e) => this.setState({ isPublic: e.target.checked })}
              value="true"
            />
          </div>
          {isPublic && <p className="alert alert-danger">Other users will be able to see and play this collection. Please only share collections with content that you own or that is from the public domain.</p>}
        </div>
        {/*<div className="checkbox">
          <label>
            <input name="collection[clozemaster_approved]" onChange={(e) => this.setState({ clozemasterApproved: e.target.checked })} type="checkbox" value={clozemasterApproved} /> Clozemaster Approved (admin)
          </label>
        </div>*/}
        <button disabled={loading} className="btn btn-success joystix btn-block">Save</button>
      </form>
    );
  }
}
