import React from "react";

import Icon from "./Icon";

export default function SelectionTokenText({ tokenText }) {
  if(!tokenText) {
    return null;
  }

  const [exampleSentencesVisible, setExampleSentencesVisible] = React.useState(false);

  const { chatgptExplanation, dictionaryLookup, exampleSentences } = tokenText;

  return (
    <div>
      {!!dictionaryLookup && <p>{dictionaryLookup} <small>(From Microsoft)</small></p>}
      {!!chatgptExplanation && <p>{chatgptExplanation} <small>(From ChatGPT)</small></p>}
      {!!exampleSentences && !!exampleSentences.length && (
        <div>
          <p>
            <button className="btn btn-xs btn-default" onClick={() => setExampleSentencesVisible(!exampleSentencesVisible)}><Icon name={`collapse-${exampleSentencesVisible ? "up" : "down"}`} /> Example sentences</button>
          </p>
          {exampleSentencesVisible && (
            <ul className="list-unstyled">
              {exampleSentences.map((e, index) => (
                <li key={index}>{e[0]}<br /><small>{e[1]}</small></li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
