import React from "react";

import { installGoogleAds } from "../../helpers";

export default class GooglePanelsAd extends React.Component {
  componentDidMount() {
    installGoogleAds();
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  // <!-- Dashboard Right Sidebar Ad -->
  render() {
    return (
      <ins className="adsbygoogle google-right-sidebar-ad"
        data-ad-client="ca-pub-7228983401564876"
        data-ad-slot="6855909541"
        // data-ad-format="auto"
        data-full-width-responsive="false"></ins>
    );
  }
}
