import React from "react";

import AutoScrollablePre from "../AutoScrollablePre";
import Loading from "../Loading";
import TextareaAutosize from 'react-textarea-autosize';
import Pagination from '../Pagination';

export default class BulkSentenceCreationEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      output: null,
      page: 1,
      perPage: 50,
      saving: false,
      sentences: props.sentences,
      total: props.sentences.length,
      updating: false
    };
  }

  componentWillUnmount() {
    clearTimeout(this.handleSaveResultDataTimeout);
  }

  saveResult() {
    this.setState({ saving: true });
    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        creates_only: true,
        updates: this.state.sentences.map((r) => ({ ...r, id: null }))
      }),
      method: 'post',
      url: this.props.collection.bulkCollectionClozeSentencesUpsertsUrl,
    })
      .done((data) => {
        this.handleSaveResultData(data);
      })
      .fail(() => {
        alert("Oh no! There was an error uploading the sentences - sorry about that. Please try again and let us know if you see this message again.");
        this.setState({ saving: false });
      });
  }

  handleSaveResultData(data) {
    console.log(data);

    this.setState({ output: data.output });

    if(data.errors) {
      alert("Oh no! There was an error - " + data.errors + ". Sorry about that! Please try again and let us know if you see this message again.");
      this.setState({ saving: false });
      return false;
    }

    if(data.status === "complete") {
      this.props.onSentencesAdded();
      this.setState({ saving: false });
      return false;
    }

    this.handleSaveResultDataTimeout = setTimeout(() => {
      $.ajax({
        url: data.bulkCollectionClozeSentencesUpsertUrl
      })
        .done((data) => {
          this.handleSaveResultData(data);
        })
        .fail(() => {
          alert("Oh no! There was an error saving the sentences - sorry about that. Please try again and let us know if you see this message again.");
          this.setState({ saving: false });
        });
    }, 3000);
  }

  onTextMouseUp(e, r) {
    if(this.state.saving) {
      return false;
    }

    const selection = window.getSelection();
    const str = selection.toString();
    if(str && r.text.replace(/\{\{|\}\}/g, '').match(str)) {
      this.setState({
        sentences: this.state.sentences.map((_r) => {
          if(r.id === _r.id) {
            return { ...r, text: r.text.replace(/\{\{|\}\}/g, '').replace(str, '{{' + str + '}}') };
          }
          return _r;
        })
      });
    }
  }

  renderPaging() {
    const { page, perPage, total, updating } = this.state;
    return (
      <Pagination
        onNextClick={() => this.setState({ page: this.state.page + 1 })}
        onPrevClick={() => this.setState({ page: this.state.page - 1 })}
        page={page}
        perPage={perPage}
        total={total}
        updating={updating}
      />
    );
  }

  recalculateSentences() {
    this.setState({ total: this.state.sentences.length });
  }

  sentenceTextHasError(text) {
    return !text.match(/\{\{.+\}\}/);
  }

  renderOutput() {
    const { output, saving } = this.state;

    const pre = (
      <AutoScrollablePre style={{ height: 200, marginTop: 10 }}>
        {output.map((o, i) => `${i + 1}. ${o}`).join("\n")}
      </AutoScrollablePre>
    );

    if(saving) {
      return (
        <Loading>
          {pre}
        </Loading>
      );
    }
    
    return pre;
  }

  render() {
    const { onlyShowErrors, output, sentenceIdsWithAnError, sentences } = this.state;
    const lastPage = Math.ceil(this.state.total / this.state.perPage);
    const lastItemIndex = this.state.page * this.state.perPage;
    const firstItemIndex = lastItemIndex - this.state.perPage;

    if(!!output && output.length) {
      return this.renderOutput();
    }

    const sentencesWithAnError = sentences.filter((s) => this.sentenceTextHasError(s.text));
    const errorCount = sentencesWithAnError.length;

    return (
      <div>
        <div className="checkbox">
          <label>
            <input
              checked={onlyShowErrors}
              disabled={!onlyShowErrors && !errorCount}
              onChange={() => {
                this.setState({
                  onlyShowErrors: !onlyShowErrors,
                  page: 1,
                  sentenceIdsWithAnError: new Set(sentencesWithAnError.map((s) => s.id))
                });
              }}
              type="checkbox"
            /> Only show sentences with an issue ({errorCount} sentences)
          </label>
        </div>
        <table className="table sentences">
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Text</th>
              <th>Translation</th>
              <th>Pronunciation</th>
              <th>Note</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(onlyShowErrors ? sentences.filter((s) => sentenceIdsWithAnError.has(s.id)) : sentences).slice(firstItemIndex, lastItemIndex).map((r, i) => (
              <tr className={this.sentenceTextHasError(r.text) ? "danger" : ""} key={r.id}>
                <td className={this.sentenceTextHasError(r.text) ? 'has-error' : ''}>
                  <TextareaAutosize
                    className="form-control"
                    disabled={this.state.saving}
                    name="text"
                    onChange={(e) => this.setState({ sentences: sentences.map((_r) => _r.id === r.id ? { ...r, text: e.target.value } : _r)})}
                    value={r.text}
                  />
                  <small onMouseUp={(e) => this.onTextMouseUp(e, r)} style={{ display: 'block', marginLeft: 13, marginTop: 5 }}>{r.text.split('{{')[0]}<strong><u>{(r.text.split('{{')[1] || '').split('}}')[0]}</u></strong>{r.text.split('}}')[1]}</small>
                </td>
                <td>
                  <TextareaAutosize
                    className="form-control"
                    disabled={this.state.saving}
                    name="translation"
                    onChange={(e) => this.setState({ sentences: sentences.map((_r) => _r.id === r.id ? { ...r, translation: e.target.value } : _r)})}
                    value={r.translation || ''}
                  />
                </td>
                <td>
                  <TextareaAutosize
                    className="form-control"
                    disabled={this.state.saving}
                    name="pronunciation"
                    onChange={(e) => this.setState({ sentences: sentences.map((_r) => _r.id === r.id ? { ...r, pronunciation: e.target.value } : _r)})}
                    value={r.pronunciation || ''}
                  />
                </td>
                <td>
                  <TextareaAutosize
                    className="form-control"
                    disabled={this.state.saving}
                    name="notes"
                    onChange={(e) => this.setState({ sentences: sentences.map((_r) => _r.id === r.id ? { ...r, notes: e.target.value } : _r)})}
                    value={r.notes || ''}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-danger delete-sentence"
                    disabled={this.state.saving}
                    onClick={() => {
                      if(confirm('Are you sure you want to delete this sentence?')) {
                        this.setState({ sentences: sentences.filter((_r) => _r.id !== r.id) }, () => this.recalculateSentences());
                      }
                    }}
                  >
                    <span className="glyphicon glyphicon-trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pull-left">
          <button className="btn btn-default" disabled={onlyShowErrors || this.state.saving} onClick={() => this.setState({ sentences: this.state.sentences.concat([{ id: Math.random(), text: '' }]), page: lastPage }, () => this.recalculateSentences())}>
            <span className="glyphicon glyphicon-plus" /> Add Row
          </button>
        </div>
        <div className="text-right">
          <button className="btn btn-lg btn-success joystix" disabled={this.state.saving || !this.state.sentences.every((r) => r.text.match(/\{\{.+\}\}/))} onClick={() => this.saveResult()}>Save</button>
        </div>
        {this.renderPaging()}
      </div>
    );
  }
}
