import React from "react";

import { getLocalStorageBoolean, setLocalStorageBoolean } from "../../helpers";

import CollectionTableRow from "./CollectionTableRow";
import Icon from "../Icon";

export default class CollectionGroupingsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupingsVisible: getLocalStorageBoolean('dashboardCollectionGroupingsVisible', false)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.groupingsVisible !== prevState.groupingsVisible) {
      setLocalStorageBoolean('dashboardCollectionGroupingsVisible', this.state.groupingsVisible);
    }
  }

  renderGroupings() {
    if(!this.state.groupingsVisible) {
      return null;
    }

    const {
      collectionGroupings,
      collections,
      isPro,
      isSignedIn,
      manage,
      play,
      progressPerChunk,
      useProgressChunks
    } = this.props;

    const groupingCollectionsHash = collections.reduce((h, c) => {
      if(c.collectionGroupingId) {
        h[c.collectionGroupingId] = (h[c.collectionGroupingId] || []).concat([c]);
      }
      return h;
    }, {});

    return collectionGroupings.map((cg) => (
      <div key={cg.id}>
        <h3>{cg.name} <small>{cg.description}</small></h3>
        <table className="table table-striped text-center">
          <tbody>
            {groupingCollectionsHash[cg.id].sort((a, b) => (a.presentationOrder || 0) - (b.presentationOrder || 0)).map((c) => (
              <CollectionTableRow
                collection={c}
                isPro={isPro}
                isSignedIn={isSignedIn}
                key={c.id}
                manage={manage}
                play={play}
                progressPerChunk={progressPerChunk}
                useProgressChunks={useProgressChunks}
              />
            ))}
          </tbody>
        </table>
      </div>
    ));
  }

  render() {
    return (
      <div className="panel panel-default collection-groupings">
        <div className="panel-body">
          <div className="pull-right" style={{ marginTop: 10 }}>
            <i className="fa fa-star fa-4x pro-gold" aria-hidden="true" style={{ marginLeft: 4 }}></i>
          </div>
          <h2>
            Pro Groupings
            <button className="btn btn-xs btn-link" data-content="Looking for an extra challenge? This where we put out experimental collections for extra practice on special topics. Currently in beta and exclusively for Pro subscribers!" data-title="Pro Groupings" data-toggle="popover" data-trigger="focus" style={{ fontSize: '65%', marginLeft: 8, textDecoration: 'none' }}>
              <span className="glyphicon glyphicon-question-sign"></span>
            </button>
            <span style={{ display: 'block', marginTop: 5 }}>
              <button className="btn btn-default toggle" onClick={() => this.setState({ groupingsVisible: !this.state.groupingsVisible })}>
                <Icon name={'chevron-' + (this.state.groupingsVisible ? 'up' : 'down')} />
              </button>
              <small style={{ marginLeft: 8 }}>Custom sentence groupings for focused learning.</small>
            </span>
          </h2>
          {this.renderGroupings()}
        </div>
      </div>
    );
  }
}
