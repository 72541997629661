import React from "react";

export default class Word extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      text: props.text
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.loading && !this.props.loading && this.state.editing) {
      this.setState({ editing: false });
    }
  }

  render() {
    const { editing, text } = this.state;
    const { destroy, loading, update } = this.props;

    if(editing) {
      return (
        <ul className="list-inline">
          <li><input className="form-control" disabled={loading} onChange={(e) => this.setState({ text: e.target.value })} value={text} /></li>
          <li><button className="btn btn-xs btn-default" disabled={loading} onClick={() => update({ text })}>Save</button></li>
          <li><button className="btn btn-xs btn-default" disabled={loading} onClick={() => this.setState({ editing: false, text: this.props.text })}>Cancel</button></li>
        </ul>
      );
    }

    return (
      <ul className="list-inline">
        <li>{text}</li>
        <li><button className="btn btn-xs btn-link" disabled={loading} onClick={() => this.setState({ editing: true })}>Edit</button></li>
        <li><button className="btn btn-xs btn-link" disabled={loading} onClick={() => confirm("Are you sure? This cannot be undone") && destroy()}>Delete</button></li>
      </ul>
    );
  }
}
