import React from "react";

import moment from 'moment';

import FlagSprite from "../FlagSprite";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class LeaderboardModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group: 'everyone',
      loading: true
    };
  }

  componentDidMount() {
    this.loadLeaderboards();
    $(this.content).tooltip({ selector: '[data-toggle="tooltip"]' });
  }

  loadLeaderboards() {
    this.setState({ loading: true });

    $.ajax({
      url: this.props.leaderboardsUrl
    })
      .done((data) => {
        const { everyone, following } = data.leaderboards;
        console.log(data);
        this.setState({
          everyone,
          following,
          loading: false,
        });
      })
      .fail(() => {
        alert('Oh no! There was an error loading the leaderboards. Please try again, and please let us know if you see this message again.');
      });
  }

  renderLeaderboardTable(entries) {
    if(!entries || !entries.length) {
      return <div className="text-center"><em>No one yet - be the first!</em></div>;
    }

    return (
      <table className="table table-striped" style={{ marginBottom: 10 }}>
        <tbody>
          {entries.map((e) => (
            <tr key={e.rank}>
              <td>{e.rank}.</td>
              <td style={{ width: '100%' }}>
                <a href={'/players/' + encodeURI(e.username)} className="joystix">{e.username}</a>
                <sup data-toggle="tooltip" title={'Level ' + e.level}>{e.level}</sup>
                {/*{e.pro && <span className="glyphicon glyphicon-star pro-gold" data-toggle="tooltip" title="Pro!" style={{ marginLeft: 4 }}></span>}*/}
                {('' + e.rank) === '1' && <span style={{ marginLeft: 4 }}>&#x1F3C6;</span>}
              </td>
              <td>{e.score.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderLeaderboards() {
    const { group } = this.state;
    const { weekly, monthly, allTime } = this.state[group];

    return (
      <div className="text-center">
        <p className="text-right">
          <span className="btn-group btn-group-sm" role="group" aria-label="Leaderboard users">
            <button className={'btn btn-default ' + (group === 'everyone' ? 'active' : '')} onClick={() => this.setState({ group: 'everyone' })}>Everyone</button>
            <button className={'btn btn-default ' + (group === 'everyone' ? '' : 'active')} onClick={() => this.setState({ group: 'following' })}>Following</button>
          </span>
        </p>
        <div className="current-week">
          <h4>
            Weekly
            <br />
            <small>Week of {moment().utc().startOf('isoWeek').format('MMM D')}-{moment().utc().endOf('isoWeek').format('MMM D')}, UTC</small>
          </h4>
          {this.renderLeaderboardTable(weekly)}
          {/*<div className="text-right"><a href="#">Full leaderboard</a></div>*/}
        </div>
        <hr />
        <div className="current-month">
          <h4>
            Monthly
            <br />
            <small>Month of {moment().utc().startOf('month').format('MMM D')}-{moment().utc().endOf('month').format('MMM D')}, UTC</small>
          </h4>
          {this.renderLeaderboardTable(monthly)}
          {/*<div className="text-right"><a href="#">Full leaderboard</a></div>*/}
        </div>
        <hr />
        <div className="all-time">
          <h4>All Time</h4>
          {this.renderLeaderboardTable(allTime)}
          {/*<div className="text-right"><a href="#">Full leaderboard</a></div>*/}
        </div>
      </div>
    );
  }

  renderSocial() {
    const { group } = this.state;

    if(group === 'everyone') {
      return null;
    }

    // TODO!
    return null;

    return (
      <div>Language learning doesn't have to be competitive - but it's more fun when it is. Challenge a friend! Or search for them on Clozemaster via their username.</div>
    );
  }

  renderContent() {
    if(this.state.loading) {
      return <p className="text-center">Loading...</p>;
    }

    return (
      <>
        {this.renderLeaderboards()}
        {this.renderSocial()}
      </>
    );
  }

  render() {
    const { baseLanguageFlagIso, onHidden, targetLanguageFlagIso } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={onHidden}
        ref={(el) => this.modal = el}
        title={(
          <><FlagSprite size={24} flagIso={targetLanguageFlagIso} /> / <FlagSprite size={24} flagIso={baseLanguageFlagIso} /> Leaderboard</>
        )}
      >
        <div ref={(el) => this.content = el}>{this.renderContent()}</div>
      </Modal>
    );
  }
}
