import React from "react";
import ReactDOM from "react-dom";

import Loading from "./Loading";

export default function LoadingOverlay(props) {
  return ReactDOM.createPortal(
    <div id="loading-overlay" className={props.className}>
      <Loading />
    </div>,
    document.querySelector('body')
  );
}
