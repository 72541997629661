import React from "react";

import { shuffleArray } from "../helpers";

import ClozeSentence from "./ClozeSentence";
import Icon from "./Icon";
import Loading from "./Loading";

export default class PublicRadio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      playing: false,
      sentences: []
    };
  }

  componentDidMount() {
    this.isSystemTtsAvailable();
    this.loadSentences();
  }

  isSystemTtsAvailable() {
    const { baseLanguageIso, baseLanguageCode } = this.props;
    return window.clozemaster.systemTtsAvailable(baseLanguageIso, baseLanguageCode);
  }


  loadSentences() {
    $.ajax({
      data: {
        count: 100,
        radio: true
      },
      url: this.props.url
    })
      .done((data) => {
        console.log(data);
        this.setState({
          loading: false,
          sentences: data.collectionClozeSentences,
        });
      })
      .fail(() => {
        alert("Oh no! There was an error loading more sentences. Sorry about that. Please refresh the page and let us know if you see this message again.");
      });
  }

  async playAudio() {
    clearTimeout(this.playAudioTimeout);

    if(!this.state.sentences.length) {
      return null;
    }
    if(!this.state.loading && this.state.sentences.length <= 5) {
      this.loadSentences();
    }

    const currentSentence = this.state.sentences[0];
    const pattern = ['text', 'pause', 'translation', 'pause', 'text', 'pause', 'text', 'pause', 'text', 'pause'];

    console.log(currentSentence);

    let canceled = false;
    this.playAudioTimeout = setTimeout(() => {
      canceled = true;
      this.next();
    }, 3 * 60 * 1000); // 3m per play

    try {
      for(let i = 0, n = pattern.length; i < n; i++) {
        console.log(pattern[i]);
        if(canceled) {
          break;
        }
        else if(pattern[i] === 'text') {
          await this.playRemoteAudio(currentSentence.ttsAudioUrl);
        }
        else if(pattern[i] === 'translation') {
          await this.playSystemTts(currentSentence.translation);
        }
        else {
          await this.sleep(2);
        }
      }
    }
    catch(e) {
      console.log('playAudio error', e);
      // alert("Oh no! There was an error playing the audio. Sorry about that. Please refresh the page and let us know if you see this message again.");
    }

    if(!canceled) {
      this.next();
    }
  }

  next() {
    this.setState({ sentences: this.state.sentences.slice(1) }, () => this.playAudio());
  }

  playRemoteAudio(url) {
    return new Promise((resolve, reject) => {
      const audio = new Audio(url);
      audio.addEventListener('playing', () => {
        clearTimeout(this.playRemoteAudioTimeout);
        this.playRemoteAudioTimeout = setTimeout(reject, 10000); // 10s to end
      });
      audio.addEventListener('ended', () => {
        clearTimeout(this.playRemoteAudioTimeout); 
        resolve();
      });
      audio.addEventListener('error', (e) => {
        clearTimeout(this.playRemoteAudioTimeout);
        reject(e);
      });
      this.playRemoteAudioTimeout = setTimeout(reject, 5000); // 5s to start
      audio.play().catch(reject);
    });
  }

  playSystemTts(s) {
    return new Promise((resolve, reject) => {
      const { baseLanguageIso, baseLanguageCode } = this.props;
      this.playSystemTtsTimeout = setTimeout(reject, 5000); // 5s to start
      const voices = window.clozemaster.getSystemTtsVoices(baseLanguageIso, baseLanguageCode);
      shuffleArray(voices);
      console.log(voices);
      const voice = voices.find((v) => v.name.match(/Google/)) || // prefer google voices
        voices[0];
      console.log(voice);
      const u = new SpeechSynthesisUtterance(s);
      u.voice = voice;
      u.lang = voice.lang;
      u.onstart = () => {
        clearTimeout(this.playSystemTtsTimeout);
        this.playSystemTtsTimeout = setTimeout(reject, 10000); // 10s to end
      };
      u.onend = () => {
        clearTimeout(this.playSystemTtsTimeout);
        resolve();
      };
      u.onerror = (e) => {
        clearTimeout(this.playSystemTtsTimeout);
        reject(e);
      };
      window.speechSynthesis.cancel();
      window.speechSynthesis.speak(u);
    });
  }

  getCurrentSentencePlainText() {
    return this.state.sentences[0].text.replace(/{{|}}/g, '');
  }

  sleep(n) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, n * 1000);
    });
  }

  renderContent() {
    if(this.state.loading && !this.state.sentences.length) {
      return <Loading />;
    }

    if(!this.state.playing) {
      return (
        <button className="btn btn-lg btn-default" onClick={() => this.setState({ playing: true }, () => this.playAudio())} style={{ marginTop: 10 }}>
          <Icon name="play" />
        </button>
      );
    }

    const currentSentence = this.state.sentences[0];

    return (
      <>
        <div style={{ fontFamily: 'joystix' }}>Clozemaster Radio</div>
        <div>
          <span style={{ fontSize: '2em' }}><ClozeSentence text={currentSentence.text} /></span>
          <div>{currentSentence.translation}</div>
        </div>
        <div>
          <div>
            <a href={this.props.googlePlayUrl} target="_blank"><img src={this.props.googlePlayBadgeUrl} height={120} /></a>
            <a href={this.props.appStoreUrl} target="_blank"><img src={this.props.appStoreBadgeUrl} height={80} /></a>
          </div>
          <div style={{ fontFamily: 'joystix' }}>Get Clozemaster. Get fluent faster.</div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div style={{ alignItems: 'center', background: '#000', bottom: 0, color: '#fff', display: 'flex', flexDirection: 'column', fontSize: '3em', justifyContent: this.state.playing ? 'space-between' : 'center', left: 0, padding: 20, position: 'fixed', right: 0, textAlign: 'center', top: 0 }}>
        {this.renderContent()}
      </div>
    );
  }
}
