import React from "react";

import moment from 'moment';

import { secondsToHHMMSSStr } from "../helpers";

import Loading from "./Loading";

export default class Rankings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group: props.group || "everyone",
      metric: props.metric || "num_points",
      period: props.period || "weekly",
      loading: true,
      results: []
    };
  }

  componentDidMount() {
    this.submitRankingRequest();
    $(this.content).tooltip({ selector: '[data-toggle="tooltip"]' });
  }

  componentWillUnmount() {
    clearTimeout(this.getRankingResultsTimeout);
  }

  submitRankingRequest() {
    const { group, metric, period } = this.state;

    this.setState({ loading: true });

    $.ajax({
      data: {
        ranking: {
          count: 50,
          count_around_user: 3,
          // from_date: this.getPeriodFromDate(),
          group,
          metric,
          // to_date: this.getPeriodToDate()
          period
        }
      },
      method: "post",
      url: this.props.rankingsUrl
    })
      .done((data) => {
        console.log(data);
        const { url } = data.ranking;
        this.getRankingResults(url);
      })
      .fail(() => {
        this.handleError();
      });
  }

  getRankingResults(url) {
    $.ajax({
      url
    })
      .done((data) => {
        console.log(data);
        const { results, status } = data.ranking;
        if(status === "complete") {
          this.setState({
            loading: false,
            results
          });
        }
        else if(status === "error") {
          this.handleError();
        }
        else if(status === "working" || status === "pending") {
          this.getRankingResultsTimeout = setTimeout(() => this.getRankingResults(url), 1000);
        }
        else {
          this.handleError();
        }
      })
      .fail(() => {
        this.handleError();
      });
  }

  handleError() {
    alert('Oh no! There was an error loading the leaderboards. Please try again, and please let us know if you see this message again.');
    this.setState({ loading: false, results: [] });
  }

  getPeriodFromDate() {
    const format = "YYYY-MM-DD";
    const { period } = this.state;

    if(period === "weekly") {
      return moment().startOf("isoWeek").format(format);
    }
    if(period === "monthly") {
      return moment().startOf("month").format(format);
    }
    if(period === "yearly") {
      return moment().startOf("year").format(format);
    }
    return null;
  }

  getPeriodToDate() {
    const format = "YYYY-MM-DD";
    const { period } = this.state;

    if(period === "weekly") {
      return moment().endOf("isoWeek").format(format);
    }
    if(period === "monthly") {
      return moment().endOf("month").format(format);
    }
    if(period === "yearly") {
      return moment().endOf("year").format(format);
    }
    return null;
  }

  renderRankingTable() {
    const { metric, results } = this.state;

    if(!results || !results.length) {
      return <div className="text-center"><em>No one yet - be the first!</em></div>;
    }

    return (
      <table className="table table-striped" style={{ marginBottom: 10 }}>
        <tbody>
          {results.map((r) => (
            <tr className="result" key={r.rank}>
              <td>{r.rank}.</td>
              <td style={{ width: '100%' }}>
                <a href={r.userProfileUrl} className="joystix">{r.username}</a>
                <sup data-toggle="tooltip" title={'Level ' + r.level}>{r.level}</sup>
                {/*{r.pro && <span className="glyphicon glyphicon-star pro-gold" data-toggle="tooltip" title="Pro!" style={{ marginLeft: 4 }}></span>}*/}
                {('' + r.rank) === '1' && <span style={{ marginLeft: 4 }}>&#x1F3C6;</span>}
              </td>
              <td>{metric === "time_played" ? secondsToHHMMSSStr(r.sum) : r.sum.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderResults() {
    if(this.state.loading) {
      return <Loading />;
    }

    return (
      <div className="text-center">
        {this.renderResultsHeader()}
        {this.renderRankingTable()}
        {this.renderMetricNotes()}
      </div>
    );
  }

  renderMetricNotes() {
    if(this.state.metric === "time_played") {
      return (
        <p><small>*Max 15 seconds per sentence played.</small></p>
      );
    }
  }

  renderResultsHeader() {
    const { period } = this.state;

    let header = null
    let subheader = null;

    if(period === "weekly") {
      // header = "Weekly";
      subheader = <>Week of {moment().startOf('isoWeek').format('MMM D')}-{moment().endOf('isoWeek').format('MMM D')}</>;
    }
    else if(period === "monthly") {
      // header = "Monthly";
      subheader = <>Month of {moment().startOf('month').format('MMM D')}-{moment().endOf('month').format('MMM D')}</>;
    }
    else if(period === "yearly") {
      // header = "Yearly";
      subheader = <>Year of {moment().startOf('year').format('MMM D')}-{moment().endOf('year').format('MMM D YYYY')}</>;
    }
    else {
      header = "All-Time"
    }

    // {header}
    // {subheader && <><br /><small>{subheader}</small></>}

    return (
      <h4>
        {header || subheader}
      </h4>
    );
  }

  renderSocial() {
    const { group } = this.state;

    if(group === 'everyone') {
      return null;
    }

    // TODO!
    return null;

    return (
      <div>Language learning doesn't have to be competitive - but it's more fun when it is. Challenge a friend! Or search for them on Clozemaster via their username.</div>
    );
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.submitRankingRequest();
  }

  renderForm() {
    const { group, loading, metric, period } = this.state; 

    return (
      <form onSubmit={(e) => this.onFormSubmit(e)}>
        <div className="form-group">
          <select className="form-control" disabled={loading} name="group" onChange={(e) => this.setState({ group: e.target.value }, () => this.submitRankingRequest())} value={group}>
            <option value="everyone">Everyone</option>
            <option value="following">Following</option>
          </select>
        </div>
        {/*
        <span className="btn-group btn-group-sm" role="group" aria-label="Leaderboard users">
          <button className={'btn btn-default ' + (group === 'everyone' ? 'active' : '')} onClick={() => this.setState({ group: 'everyone' })}>Everyone</button>
          <button className={'btn btn-default ' + (group === 'everyone' ? '' : 'active')} onClick={() => this.setState({ group: 'following' })}>Following</button>
        </span>
        */}
        <div className="form-group">
          <select className="form-control" disabled={loading} name="period" onChange={(e) => this.setState({ period: e.target.value }, () => this.submitRankingRequest())} value={period}>
            <option value="weekly">This Week</option>
            <option value="monthly">This Month</option>
            <option value="yearly">This Year</option>
            <option value="all_time">All-Time</option>
          </select>
        </div>
        <div className="form-group">
          <select className="form-control" disabled={loading} name="metric" onChange={(e) => this.setState({ metric: e.target.value }, () => this.submitRankingRequest())} value={metric}>
            <option value="num_listening">Listening</option>
            <option value="num_mastered">Mastered</option>
            <option value="num_multiple_choice">Multiple Choice</option>
            <option value="num_new">New</option>
            <option value="num_points">Points</option>
            <option value="num_review">Review</option>
            <option value="num_speaking">Speaking</option>
            <option value="num_text_input">Text Input</option>
            <option value="time_played">Time</option>
            <option value="num_played">Total Played</option>
          </select>
        </div>
      </form>
    );
  }

  renderContent() {
    return (
      <>
        {this.renderForm()}
        {this.renderResults()}
        {this.renderSocial()}
      </>
    );
  }

  render() {
    return (
      <div id="rankings" ref={(el) => this.content = el}>{this.renderContent()}</div>
    );
  }
}


