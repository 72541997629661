import React from "react";

import FadeOut from "../FadeOut";

export default class Points extends React.Component {
  render() {
    const { points } = this.props;

    if(!points) {
      return null;
    }

    return (
      <FadeOut delay={3000}>
        <div className="score current joystix" style={{ display: 'block' }}>
          <div className="content">
            <span className="plus">+</span><span className="value">{points}</span>
          </div>
        </div>
      </FadeOut>
    );
  }
}
