import React from "react";

import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";
import { secondsToHHMMSSStr } from "../../helpers";

export default class MoreStatsModal extends React.Component {
  constructor(props) {
    super(props);

    this.clozeMasteryColors = [
      '224,58,48', // red
      '150,61,151', // purple
      '245,130,31', // orange
      '253,184,39', // yellow
      '97,187,70', // green
    ];

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
  }

  initGraphs() {
    this.initClozeMasteryGraph();
    this.initReviewForecastGraph();
    this.initStatsPerDayGraph();
  }

  initReviewForecastGraph() {
    const { reviewForecast } = this.state;
    const ctx = this.reviewForecastCanvas.getContext('2d');
    const data = {
      labels: reviewForecast.map((r) => r.date),
      datasets: [{
        label: "Reviews",
        fillColor: "rgba(51, 122, 183, 0.4)",
        strokeColor: "rgba(51, 122, 183, 1)",
        highlightFill: "rgba(51, 122, 183, 0.8)",
        highlightStroke: "rgba(51, 122, 183, 1)",
        data: reviewForecast.map((r) => parseInt(r.count) || 0)
      }]
    };
    const chart = new Chart(ctx).Bar(data, { tooltipTemplate: "<%= datasetLabel %>: <%= value %>" });
  }

  initClozeMasteryGraph() {
    const { clozeMastery } = this.state;
    const data = [];
    for(let i = 0, n = 4; i <= n; i++) {
      data.push({
        color: 'rgba(' + this.clozeMasteryColors[i] + ',0.6)',
        highlight: 'rgba(' + this.clozeMasteryColors[i] + ',1)',
        label: i * 25 + '% Mastered',
        value: clozeMastery[i] || 0,
      });
    }
    const ctx = this.clozeMasteryCanvas.getContext('2d');
    const chart = new Chart(ctx).Doughnut(data, { responsive: true });
  }

  initStatsPerDayGraph() {
    const { statsPerDay } = this.state;
    const ctx = this.statsPerDayCanvas.getContext('2d');

    const datasets = {};
    statsPerDay.forEach((s) => {
      Object.keys(s).forEach((k) => {
        let key = '';
        if(k.match(/^num/)) {
          key = k.match(/[A-Z][a-z]+/g).join(' ');
        }
        if(k === 'timePlayed') {
          key = 'Time Played';
        }
        if(key) {
          datasets[key] = datasets[key] || [];
          datasets[key].push(s[k] || 0);
        }
      });
    });

    const colors = [
      '72,195,117',
      '96,5,173',
      '168,120,73',
      '217,168,173',
      '232,141,206',
      '149,144,38',
      '32,70,58',
      '78,162,144',
      '242,71,208',
      '117,161,205'
    ];

    const data = {
      labels: statsPerDay.map((s) => s.date),
      datasets: Object.keys(datasets).map((k, i) => {
        const rgb = colors[i % colors.length];
        return {
          label: k,
          fillColor: "rgba(" + rgb + ",0.2)",
          strokeColor: "rgba(" + rgb + ",1)",
          pointColor: "rgba(" + rgb + ",1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(" + rgb + ",1)",
          data: datasets[k]
        };
      })
    };

    console.log(data);

    const chart = new Chart(ctx).Line(data, { multiTooltipTemplate: "<%= datasetLabel %>: <%= value %>", });
  }

  loadStats() {
    if(!this.props.isPro) {
      return false;
    }

    this.setState({ loading: true });

    $.ajax({
      url: this.props.moreStatsUrl
    })
      .done((data) => {
        console.log(data);

        const {
          clozeMastery,
          reviewForecast,
          statsPerDay,
          totals
        } = data;

        this.setState({
          clozeMastery,
          loading: false,
          reviewForecast,
          statsPerDay,
          totals
        }, () => this.initGraphs());
      })
      .fail(() => {
        alert("Oh no! There was an error loading more stats. Please refresh the page and try again, and let us know if you see this message again.");
      });
  }

  renderReviewForecast() {
    return (
      <div>
        <h3 className="joystix" style={{ marginTop: 0 }}>Review Forecast</h3>
        <canvas ref={(el) => this.reviewForecastCanvas = el} style={{ width: '100%', height: 300 }} />
      </div>
    );
  }

  renderStatsPerDay() {
    return (
      <div>
        <h3 className="joystix">Stats Per Day</h3>
        <canvas ref={(el) => this.statsPerDayCanvas = el} style={{ width: '100%', height: 300 }} />
      </div>
    );
  }

  renderTotal(attr, value) {
    return <li style={{ padding: '0 15px 15px 10px' }}><span className="joystix">{attr}:</span> {value}</li>;
  }

  renderTotals() {
    const { totals } = this.state;
    const timePlayed = parseInt(totals.timePlayed) || 0;
    return (
      <div>
        <h3 className="joystix">Totals</h3>
        <ul className="list-inline text-center totals">
          {this.renderTotal('Played', totals.numPlayed)}
          {this.renderTotal('Mastered', totals.numMastered)}
          {this.renderTotal('Correct', totals.numCorrect)}
          {this.renderTotal('Incorrect', (parseInt(totals.numPlayed) || 0) - (parseInt(totals.numCorrect) || 0))}
          {this.renderTotal('Multiple Choice', totals.numMultipleChoice)}
          {this.renderTotal('Text Input', (parseInt(totals.numPlayed) || 0) - (parseInt(totals.numMultipleChoice) || 0))}
          {this.renderTotal('Listening', totals.numListening)}
          {this.renderTotal('Speaking', totals.numSpeaking)}
          {this.renderTotal('Used Hint', totals.numUsedHint)}
          {this.renderTotal('Time Played', secondsToHHMMSSStr(timePlayed))}
        </ul>
      </div>
    );
  }

  renderClozeMastery() {
    const { clozeMastery } = this.state;

    return (
      <div>
        <h3 className="joystix">Cloze Mastery</h3>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <canvas ref={(el) => this.clozeMasteryCanvas = el} style={{ width: '100%', height: 250 }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <table className="table table-striped text-center">
              <thead>
                <tr>
                  <th className="joystix text-center">% Mastered</th>
                  <th className="joystix text-center">Count</th>
                </tr>
              </thead>
              <tbody>
                {[0, 1, 2, 3, 4].map((l) => (
                  <tr key={l}>
                    <td className="joystix">{l * 25}%</td>
                    <td><strong style={{ color: 'rgb(' + this.clozeMasteryColors[l] + ')' }}>{clozeMastery[l] || 0}</strong></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  renderProPromo() {
    return (
      <ModalProPromo
        can="see even more stats - review forecast, cloze mastery, progress for the past 90-days, and more."
      />
    );
  }

  renderContent() {
    if(!this.props.isPro) {
      return this.renderProPromo();
    }

    if(this.state.loading) {
      return 'Loading...';
    }

    return (
      <div>
        {this.renderReviewForecast()}
        <hr />
        {this.renderTotals()}
        <hr />
        {this.renderClozeMastery()}
        <hr />
        {this.renderStatsPerDay()}
      </div>
    );
  }

  render() {
    return (
      <Modal
        className="more-stats-modal"
        footer={<ModalFooterCloseBtn disabled={this.state.loading} />}
        onHidden={this.props.onHidden}
        onShown={() => this.loadStats()}
        size={this.props.isPro ? 'large' : ''}
        title="More Stats"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
