import React from "react";

import Loading from "../Loading";

export default class CrosswordViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      sentences: props.sentences
    };
  }

  componentDidMount() {
    this.loadPreview();
  }

  loadPreview() {
    $.ajax({
      url: this.props.crosswordsUrl,
      method: "post",
      data: { sentences: JSON.stringify(this.state.sentences) }
    })
      .done((data) => {
        this.setState({ loading: false, crossword: data.crossword });
      })
      .fail(() => {
        alert("Something went wrong, please try again or contact an admin.");
      });
  }

  emailPdf() {
    $.ajax({
      url: this.props.crosswordsUrl,
      method: "post",
      data: { email_pdf: true, sentences: JSON.stringify(this.state.sentences) }
    })
      .done((data) => {
        alert("PDF will be sent to your email shortly!");
      })
      .fail(() => {
        alert("Something went wrong, please try again or contact an admin.");
      });
  }

  renderCrossword() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.crossword.html }}></div>;
  }

  renderDownloadButtons() {
    return (
      <div>
        <button className="btn btn-success" onClick={() => this.emailPdf()}>Send PDF to email</button>
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div>
        {this.renderCrossword()}
        {this.renderDownloadButtons()}
      </div>
    );
  }
}
