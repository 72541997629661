import React from "react";

export default class TextToSpeechSettings extends React.Component {
  renderToggle() {
    return (
      <div className="subgroup text-to-speech-option">
        <div className="header joystix">
          Auto-Play:
        </div>
        <div className="btn-group" role="group" aria-label="Text-to-speech auto-play">
          {this.props.renderBtnGroupBtn('textToSpeech', 'on', this.props.textToSpeech, 'On')}
          {this.props.renderBtnGroupBtn('textToSpeech', 'off', this.props.textToSpeech, 'Off')}
        </div>
      </div>
    );
  }

  renderLoopingToggle() {
    return (
      <div className="subgroup text-to-speech-option">
        <div className="header">
          <div className="joystix">Loop:</div>
          <small>(sentence audio will play on repeat for chorusing)</small>
        </div>
        <div className="btn-group" role="group" aria-label="Text-to-speech looping">
          {this.props.renderBtnGroupBtn('textToSpeechLooping', 'on', this.props.textToSpeechLooping, 'On')}
          {this.props.renderBtnGroupBtn('textToSpeechLooping', 'off', this.props.textToSpeechLooping, 'Off')}
        </div>
      </div>
    );
  }

  renderSystemVoices() {
    if(this.props.clozemasterTtsAvailable && this.props.clozemasterTextToSpeech === 'on') {
      return null;
    }

    if(!this.props.systemTtsAvailable) {
      return null;
    }

    return (
      <div className="subgroup text-to-speech-option">
        <div className="header joystix">
          System Voice:
        </div>
        <select className="form-control" name="system_voice" value={this.props.textToSpeechVoice} onChange={(e) => this.props.onVoiceSelected(e.target.value)}>
          <option value="random">Random</option>
          {this.props.systemTtsVoices.map((voice) => (
            <option value={voice.name} key={voice.voiceURI || voice.name}>{voice.lang} {voice.id} {voice.name}</option>
          ))}
        </select>
      </div>
    );
  }

  renderControls() {
    if(this.props.clozemasterTtsAvailable || this.props.systemTtsAvailable) {
      return (
        <>
          {this.renderToggle()}
          {this.renderLoopingToggle()}
          {this.renderClozemasterTtsToggle()}
          {this.renderSystemVoices()}
          {this.renderSpeedControl()}
        </>
      );
    }

    return (
      <div className="no-voices">
        <em>There are currently no voices available for {this.props.targetLanguageName} on your browser/device. Text-to-speech works best on Chrome and Safari for desktop.</em>
      </div>
    );
  };

  renderClozemasterTtsToggle() {
    if(!this.props.clozemasterTtsAvailable || !this.props.systemTtsAvailable) {
      return null;
    }

    return (
      <div className="subgroup use-clozemaster-voice">
        <div className="header joystix">
          Use Clozemaster Voice:
        </div>
        <div className="btn-group" role="group" aria-label="Use Clozemaster text-to-speech">
          {this.props.renderBtnGroupBtn('clozemasterTextToSpeech', 'on', this.props.clozemasterTextToSpeech, 'On')}
          {this.props.renderBtnGroupBtn('clozemasterTextToSpeech', 'off', this.props.clozemasterTextToSpeech, 'Off')}
        </div>
      </div>
    );
  }

  renderSpeedControl() {
    const speed = parseFloat(this.props.textToSpeechSpeed);

    return (
      <div className="subgroup text-to-speech-option" style={{ display: 'block' }}>
        <div className="header joystix">
          Speed:
        </div>
        <div className="text-to-speech-speed">
          <div className="btn-group" data-toggle="buttons" aria-label="Text-to-speech speed">
            {[0.5, 0.75, 1, 1.25, 1.5, 2].map((s) => (
              this.props.renderBtnGroupBtn('textToSpeechSpeed', s, speed, s + 'x')
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="group text-to-speech">
        <div className="header joystix">
          Text-to-Speech
        </div>
        {this.renderControls()}
      </div>
    );
  }
};
