import React from "react";

export default function ProgressBar(props) {
  return (
    <div className="progress">
      <div
        className={"progress-bar progress-bar-" + (props.context || "success")}
        role="progressbar"
        aria-valuenow={props.percent}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: props.percent + "%" }}
      >
        <span className="sr-only">{props.percent}% Complete</span>
        {props.children}
      </div>
    </div>
  );
}
