import React from "react";

import moment from 'moment';

import CollectionSelector from "../CollectionSelector";

export default class BlukAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collection: null,
      readyForReview: true,
      upsertUrl: null
    };
  }

  add() {
    $.ajax({
      contentType: 'application/json',
      method: 'post',
      data: JSON.stringify({
        updates: this.props.clozeSentences
          .filter((cs) => this.props.selected.has(cs.id))
          .map((cs) => ({
            alternative_answers: cs.alternative_answers,
            cloze_sentence_id: cs.id,
            hint: cs.hint,
            multiple_choice_options: cs.multiple_choice_options,
            next_review: this.state.readyForReview ? moment().format("YYYY-MM-DD") : null,
            notes: cs.notes,
            pronunciation: cs.pronunciation,
            tatoeba_id: cs.tatoeba_id,
            text: cs.text,
            translation: cs.translation,
          }))
      }),
      url: this.state.upsertUrl
    })
      .done(() => {
        this.props.onAdd();
      })
      .fail(() => {
        alert("Oh no! There was an error adding the sentences, sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  cancel() {
    this.props.onCancel();
  }

  renderControls() {
    if(!this.state.upsertUrl) {
      return null;
    }

    const selectedCount = this.props.selected.size;

    return (
      <div>
        <div className="checkbox">
          <label>
            <input type="checkbox" name="ready_for_review" checked={this.state.readyForReview} onChange={(e) => this.setState({ readyForReview: e.target.checked })} /> Set to Ready for Review
          </label>
        </div>
        <div>
          <button className="btn btn-success" onClick={() => this.add()} style={{ marginRight: 10 }}>Add {selectedCount} Selected Sentences</button>
          <button className="btn btn-link" onClick={() => this.cancel()}>Cancel</button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <CollectionSelector
          collectionsUrl={this.props.collectionsUrl}
          initialSelectedCollectionId={this.props.initialSelectedCollectionId}
          onSelect={(collection) => this.setState({
            collection,
            upsertUrl: collection.collectionClozeSentencesUpsertUrl
          })}
        />
        {this.renderControls()}
        <hr />
      </div>
    );
  }
}
