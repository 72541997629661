import React from "react";

export default class ChatBot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: "",
      messages: []
    };
  }

  componentDidMount() {
  }

  onSubmit(e) {
    e.preventDefault();

    const { input } = this.state;

    if(!input) {
      return false;
    }

    this.setState({
      input: "",
      messages: this.state.messages.concat([input])
    });

    $.ajax({
      data: { input },
      method: "post",
      url: this.props.url
    })
      .done((data) => {
        console.log(data);
        this.setState({
          messages: this.state.messages.concat([data.chatBotResponse])
        });
      })
      .fail(() => {
        alert("Failed!");
      });
  }

  renderMessage(m) {
    if(typeof m === 'string' || m instanceof String) {
      return <><strong>Me:</strong> {m}</>;
    }

    return <><strong>🤖:</strong> {m.target_language_response} <small><em>[{m.en_response}]</em></small></>;
  }

  render() {
    return (
      <div>
        <ul>
          {this.state.messages.map((m, i) => (
            <li key={i}><p>{this.renderMessage(m)}</p></li>
          ))}
        </ul>
        <form onSubmit={this.onSubmit.bind(this)}>
          <div className="form-group">
            <input className="form-control" onChange={(e) => this.setState({ input: e.target.value.substring(0, 280) })} type="text" value={this.state.input} />
            <small>Max 280 characters</small>
          </div>
          <button className="btn btn-success joystix">Submit</button>
        </form>
      </div>
    );
  }
}
