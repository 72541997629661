import React from "react";

import Icon from "../Icon";
import SentenceEditor from "./SentenceEditor";

const containerStyle = {
  borderBottom: "1px solid #ccc",
  display: "flex",
  flexDirection: "row",
  marginBottom: 15,
  paddingBottom: 15
};

export default class SentenceSearchResult extends React.Component {
  constructor(props) {
    super(props);

    const { text, translation } = props.sentence;

    this.state = {
      text,
      translation
    };
  }

  render() {
    const { text, translation } = this.state;
    const { onToggle, selectedMap } = this.props;

    return (
      <div style={containerStyle}>
        <div>
          <button
            className={"btn btn-" + (selectedMap.has(text) ? "success active" : "default") + " btn-lg toggle"}
            onClick={() => onToggle({ text, translation })}
          >
            <Icon name="ok" />
          </button>
        </div>
        <div style={{ flex: 1, paddingLeft: 20 }}>
          <SentenceEditor
            disabled={selectedMap.has(text)}
            onTextChange={(text) => this.setState({ text })}
            onTranslationChange={(translation) => this.setState({ translation })}
            text={text}
            translation={translation}
          />
        </div>
      </div>
    );
  }
}
