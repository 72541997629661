import React from "react";

export default class MultipleChoiceOptions extends React.Component {
  constructor(props) { 
    super(props);

    this.state = {
      options: []
    };
  }

  componentDidMount() {
    this.setState({ options: this.getOptions() });
  }

  componentDidUpdate(prevProps) {
    if(this.props.sentenceIndex !== prevProps.sentenceIndex) {
      this.setState({ options: this.getOptions() });
    }
  }

  getOptions() {
    const { actualAnswer, currentSentence, options } = this.props;

    // shuffle possible answers
    let answers = options.slice(0).sort(function() { return 0.5 - Math.random(); });
    // ensuring answers are lower case so we can remove actual answer when lowercase
    // was running into bug where actual answer was upcase (start of sentence), but all answer lowercase causing actual answer to not be removed
    for(let i = 0, n = answers.length; i < n; i++) {
      answers[i] = answers[i].toLowerCase();
    }
    // remove actual answer
    answers = answers.filter((a) => a !== actualAnswer.toLowerCase());
    if(answers < 4) {
      answers = answers.concat([actualAnswer]).sort();
    }
    else {
      // get subset
      answers = answers.slice(0, 3);
      // inject actual answer randomly
      answers.splice(Math.floor(Math.random() * 4), 0, actualAnswer);
    }
    // if first letter of cloze capitalized, capitalize all the potential answers
    if(actualAnswer.toUpperCase()[0] === actualAnswer[0]) {
      answers = answers.map(function(answer) {
        return answer.charAt(0).toUpperCase() + answer.slice(1);
      });
    }

    return answers;
  }

  getAnsweredOptionClassNames(o) {
    const { answered, answerSubmitted, correct, actualAnswer } = this.props;

    if(!answered) {
      return '';
    }


    if(o === actualAnswer) {
      return 'correct';
    }

    if(o === answerSubmitted && !correct) {
      return 'wrong incorrect';
    }

    return 'wrong';
  }

  clickOptionAtIndex(i) {
    const { options } = this.state;
    this.props.onOptionClick(options[i]);
  }

  renderButtonColumn({ o, i }) {
    return (
      <div key={o + i} className={ 'col-xs-12 col-sm-4' + (i % 2 === 0 ? ' col-sm-offset-2' : '')}>
        <button
          className={'btn btn-block btn-lg btn-default option ' + this.getAnsweredOptionClassNames(o)}
          onClick={() => this.props.onOptionClick(o)}
          style={this.props.targetLanguageCode.match(/cmn|yue/) && { fontWeight: 'normal' }}
        >
          {!this.props.answered && <small className="number">{i + 1}. </small>}<span className="answer">{o}</span>
        </button>
      </div>
    );
  }

  renderClickableOptions() {
    const { answered, onClickableOptionClick } = this.props;
    const { options } = this.state;

    if(!answered) {
      return null;
    }

    return (
      <div className="text-center">
        <small style={{ marginRight: 4 }}>Learn more:</small>
        {options.map((o) => (
          <button
            className="btn btn-sm btn-link"
            key={o}
            onClick={() => onClickableOptionClick(o)}
            style={{ margin: "0 4px" }}
          >
            {o}
          </button>
        ))}
      </div>
    );
  }

  render() {
    const options = this.state.options;

    return (
      <div className="multiple-choice row" style={{ display: 'block' }}>
        {options.map((o, i) => {
          if((i % 2) === 1) {
            return null;
          }

          return (
            <div className="row" key={o + i}>
              {this.renderButtonColumn({ o, i })}
              {!!options[i + 1] && this.renderButtonColumn({ o: options[i + 1], i: i + 1 })}
            </div>
          );
        })}
        <div className="clearfix"></div>
        {this.renderClickableOptions()}
      </div>
    );
  }
}
