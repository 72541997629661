import React from "react";

import CollectionsModalTable from "./CollectionsModalTable";
import Icon from "../Icon";
import Modal from "../Modal";

export default class ReviewStatPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    };
  }

  onReviewModalBtnClick() {
    const collections = this.props.collections.filter((c) => c.numReadyForReview);
    if(collections.length > 1) {
      this.setState({ modalVisible: true })
    }
    else {
      this.props.onPlay(collections[0]);
    }
  }

  // renderControls() {
  //   const { numReadyForReview } = this.props;

  //   return (
  //     <>
  //       <button onClick={() => this.onReviewModalBtnClick()} className="btn btn-primary btn-block joystix review default" disabled={!numReadyForReview}>
  //         Review
  //         <Icon name="chevron-right" />
  //       </button>
  //       <button className="btn btn-xs btn-link" data-toggle="popover" data-trigger="focus" data-html="true" title="Reviews" data-placement="auto right" data-content={"Clozemaster uses a <a href=\"https://en.wikipedia.org/wiki/Spaced_repetition\" target=\"_blank\">spaced repetition system</a> to help you remember what you learn. Intervals are set to 1 day (25% Mastered), 10 days (50% Mastered), 30 days (75% Mastered), and 180 days (100% Mastered) by default. <a href=\"/pro\">Clozemaster Pro</a> users can customize the intervals. You can also manually master or reset sentences while playing."}>
  //         How reviews work
  //       </button>
  //     </>
  //   );
  // }

  onReviewClick(collection) {
    this.modal.hide({ onHidden: () => this.props.onPlay(collection) });
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    return (
      <Modal
        onHidden={() => this.setState({ modalVisible: false })}
        ref={(el) => this.modal = el}
        title="Ready for Review"
      >
        <button
          className="btn btn-lg btn-default btn-block joystix"
          onClick={() => this.onReviewClick({ name: 'All', playWebUrl: this.props.playLanguagePairingUrl })}
        >
            Review All <Icon name="chevron-right" />
        </button>
        <CollectionsModalTable
          attr="numReadyForReview"
          btnClassName="btn-default"
          btnIcon={<Icon name="chevron-right" />}
          btnText="Review"
          collections={this.props.collections.filter((c) => c.numReadyForReview)}
          onBtnClick={(collection) => this.onReviewClick(collection)}
        />
      </Modal>
    );
  }

  render() {
    const { numReadyForReview, todayNumReview } = this.props;

    return (
      <div className="panel panel-default">
        <div className="panel-body" style={{ height: 128 }}>
          <h3 className="joystix" style={{ fontSize: 16, margin: 0 }}><span className="hidden-xs hidden-md">Ready for </span>Review</h3>
          <div className="joystix" style={{ fontSize: "2em" }}>
            {todayNumReview > 0 && numReadyForReview === 0 && <>🙌 </>}
            {numReadyForReview}
            {todayNumReview > 0 && numReadyForReview === 0 && <> 🙌</>}
          </div>
          <button className="btn btn-default btn-block joystix" disabled={!numReadyForReview} onClick={() => this.onReviewModalBtnClick()}>Review</button>
          {this.renderModal()}
        </div>
      </div>
    );
  }
}
