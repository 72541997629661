import React from "react";

import FlagSprite from "./FlagSprite";

export default class LanguagePairings extends React.Component {
  constructor(props) {
    super(props);

    this.setBaseLanguages();

    this.state = {
      baseLanguageName: !!this.baseLanguages.find((l) => l.name === 'English') ? 'English' : this.baseLanguages[0].name,
      orderBy: 'popularity',
      query: '',
    };
  }

  setBaseLanguages() {
    const hash = {};
    this.baseLanguages = [];

    this.props.languagePairings.forEach((languagePairing) => {
      if(hash[languagePairing.baseLanguageName]) {
        return null;
      }

      const {
        baseLanguageEnglishName,
        baseLanguageFlagIso,
        baseLanguageIso,
        baseLanguageName
      } = languagePairing;

      hash[baseLanguageName] = true;
      this.baseLanguages.push({
        englishName: baseLanguageEnglishName,
        flagIso: baseLanguageFlagIso,
        iso: baseLanguageIso,
        name: baseLanguageName
      });
    });

    this.baseLanguages = this.baseLanguages.sort((a, b) => a.englishName.localeCompare(b.englishName));
  }

  renderBaseLanguageSelect() {
    return (
      <select name="base_language" value={this.state.baseLanguageName} onChange={(e) => this.setState({ baseLanguageName: e.target.value })}>
        {this.baseLanguages.map((baseLanguage) => <option key={baseLanguage.name} value={baseLanguage.name}>{baseLanguage.name} ({baseLanguage.englishName})</option>)}
      </select>
    );
  }

  renderLanguagePairings() {
    const { baseLanguageName, query } = this.state;
    const regexp = new RegExp(query, 'i');
    const matches = this.props.languagePairings.filter((languagePairing) => {
      return (!query || languagePairing.targetLanguageName.match(regexp) || languagePairing.targetLanguageEnglishName.match(regexp))
        && languagePairing.baseLanguageName === baseLanguageName;
    });

    if(!matches.length) {
      return (
        <div className="lead text-center">
          Don't see the language you're learning? <a href={this.props.contactPath}>Let us know</a>.
        </div>
      );
    }

    if(this.state.orderBy === 'popularity') {
      matches.sort((l1, l2) => l1.popularityRank - l2.popularityRank);
    }

    return (
      <ul className="list-unstyled languages text-center">
        {matches.map((languagePairing) => (
          <li key={languagePairing.path} className="language text-center">
            <a className="btn btn-success" href={languagePairing.path}>
              <FlagSprite flagIso={languagePairing.targetLanguageFlagIso} />
              <h3 className="name">{languagePairing.targetLanguageName}</h3>
              <small className="joystix english">{languagePairing.targetLanguageEnglishName}</small>
            </a>
          </li>
        ))}
      </ul>
    );
    //           %ul.list-unstyled.languages
    //             - challenge_language_hash = @challenges.inject({}) { |h, c| h[c.target_language] ||= []; h[c.target_language] << c.slug; h }
    //             - challenge_language_hash.to_a.sort_by { |x| x.first.english_name }.each do |language, challenges|
    //               %li.language.text-center{ data: { slug: language.slug, code: language.code, name: language.name, english_name: language.english_name, challenges: challenges.join('|') } }
    //                 %a.btn.btn-success{ href: user_signed_in? ? challenge_path(*challenges[0].split('-')) : play_path(challenges[0], skill: 'vocabulary', mode: 'multiple-choice') } 
    //                   %div= language_flag_sprite_tag(language)
    //                   %h3.name= language.name
    //                   %small.joystix.english= language.english_name
    //           .lead.text-center
    //             Don't see the language you're learning? #{link_to 'Let us know', contact_path}.
    //       */}
  }

  render() {
    const { orderBy } = this.state;

    return (
      <div>
        <div className="heading text-left">
          <div className="pull-right">
            <label>I speak: {this.renderBaseLanguageSelect()}</label>
          </div>
          <h1>Languages</h1>
        </div>
        <div className="clearfix"></div>
        <section>
          <h2 className="text-center">I'm learning...</h2>
          <div className="form-group" style={{ marginBottom: 5 }}>
            <input className="form-control text-center" name="target_language" autoFocus={true} value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} />
          </div>
          <p>
            <small>Order by:</small>
            <button className={"btn btn-xs btn-link" + (orderBy === 'popularity' ? ' active' : '')} onClick={() => this.setState({ orderBy: 'popularity' })} style={{ fontWeight: orderBy === 'popularity' ? 'bold' : null, marginLeft: 5 }}>Popular</button>
            <button className={"btn btn-xs btn-link" + (orderBy === 'alphabetical' ? ' active' : '')} onClick={() => this.setState({ orderBy: 'alphabetical' })} style={{ fontWeight: orderBy === 'popularity' ? null : 'bold', marginLeft: 5 }}>Alphabetical</button>
          </p>
          <div className="well">
            {this.renderLanguagePairings()}
          </div>
        </section>
      </div>
    );
  }
}
