import React from "react";

import FlagSprite from "../FlagSprite";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

import { progressChunksExplainerText } from "../../helpers";

export default class LanguagePairingSettingsModalV2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: $('body').hasClass('dark-mode') ? 'dark' : 'light',
    };
  }

  setDarkMode(theme) {
    const { gameSettingsUrl } = this.props;

    $.ajax({
      url: gameSettingsUrl,
      data: {
        settings: { theme }
      },
      method: 'post'
    })
      .fail(() => {
        // TODO!
      });

    this.setState({ theme }, () => {
      $('body').toggleClass('dark-mode', this.state.theme === 'dark');
    });
  }

  renderBtnGroup({ active, onClick, values }) {
    return (
      <div className="btn-group" data-toggle="buttons">
        {values.map((v) => (
          <button
            className={"btn " + (active === v.value ? "btn-success active" : "btn-default")}
            key={v.value}
            type="button"
            value={v.value} onClick={onClick}
          >
            {v.label}
          </button>
        ))}
      </div>
    );
  }

  render() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      onDeleteLanguagePairingClick,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    const {
      theme
    } = this.state;

    return (
      <Modal
        footer={(
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <button className="btn btn-danger" onClick={onDeleteLanguagePairingClick}><Icon name="trash" /> Delete</button>
            <ModalFooterCloseBtn />
          </div>
        )}
        id="manage-language-pairing-modal"
        ref={(el) => this.modal = el}
        show={false}
        size="small"
        title={<span><FlagSprite flagIso={targetLanguageFlagIso} size={24} /> / <FlagSprite flagIso={baseLanguageFlagIso} size={24} /> Settings</span>}
      >
        <div className="text-center">
          <div className="dark-mode">
            <div className="joystix" style={{ marginBottom: 2 }}>Dark Mode</div>
            {this.renderBtnGroup({
              active: theme,
              onClick: (e) => this.setDarkMode(e.target.value),
              values: [
                { label: "On", value: "dark" },
                { label: "Off", value: "light" }
              ]
            })}
          </div>
        </div>
      </Modal>
    );
  }
}
