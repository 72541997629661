import React from "react";

import Icon from "../Icon";
import MasteredIcon from "../MasteredIcon";
import PlayingIcon from "../PlayingIcon";

import { progressChunksExplainerText } from "../../helpers";

export default function ChunkProgressBar(props) {
  const { 
    includePopoverHelperBtn,
    perChunk,
    num,
    pct,
    style,
    total,
    type
  } = props;

  const isPlaying = (type === "playing"); // else mastered

  const chunkStart = Math.floor(num / perChunk) * perChunk;
  const chunkProgress = num - chunkStart;
  const chunkGoal = Math.min(chunkStart + perChunk, total);
  const chunkPct = chunkProgress / (chunkGoal - chunkStart) * 100;
  const hasSomeProgress = chunkPct > 30;

  return (
    <div className="progress" style={{ height: 28, marginBottom: 0, position: "relative", ...style }}>
      <div
        className={"progress-bar progress-bar-" + (isPlaying ? "warning" : "success")}
        data-toggle="tooltip"
        style={{ fontSize: 16, lineHeight: "28px", width: chunkPct + "%" }}
        title={(isPlaying ? "Playing" : "Mastered") + " " + num.toLocaleString() + " sentences (" + pct + ")"}
      >
        <small style={{ paddingLeft: hasSomeProgress ? 0 : 10}}>{!hasSomeProgress && <span>{isPlaying ? <PlayingIcon /> : <MasteredIcon />} </span>}{num} / {chunkGoal} {!!includePopoverHelperBtn && <button className="btn btn-xs btn-link" data-container="#dashboard" data-placement="auto top" data-title="Progress Chunks" data-toggle="popover" data-trigger="focus" data-content={progressChunksExplainerText(perChunk)} style={{ color: "#000" }}><Icon name="question-sign" /></button>}</small>
      </div>
    </div>
  );
};
