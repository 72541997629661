import React from "react";

import { v4 as uuidv4 } from "uuid";

import Loading from "../Loading";
import PlaySystemTtsButton from "../PlaySystemTtsButton";

export default class Tokens extends React.Component {
  constructor(props) {
    super(props);

    this.id = `tokens-${uuidv4()}`;

    this.state = {
      loading: true
    };
  }
  
  componentDidMount() {
    this.loadTokens();
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, tokens } = this.state;
    if(prevState.loading && !loading && tokens && tokens.length) {
      $(`#${this.id} [data-toggle=popover]`).popover();
    }
  }

  componentWillUnmount() {
    $(`#${this.id} [data-toggle=popover]`).popover("destroy");
  }

  loadTokens() {
    const { tokenizeableId, tokenizeableType, url } = this.props;

    this.setState({ loading: true });

    $.ajax({
      data: {
        tokenizeable_id: tokenizeableId,
        tokenizeable_type: tokenizeableType
      },
      url
    })
      .done((data) => {
        console.log(data);
        const { tokens } = data;

        this.setState({
          loading: false,
          tokens
        });
      })
      .fail(() => {
        alert("Oh no! There was an error loading the tokens. Sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  renderMorphologicalFeatures(t) {
    if(!t.morphologicalFeatures || !t.morphologicalFeatures.length) {
      return null;
    }

    const { onClick } = this.props;

    return (
      <div>
        <a
          tabIndex="0"
          className="btn btn-default btn-xs"
          data-html="true"
          data-content={t.morphologicalFeatures.map((f) => `<strong>${f.keyLabel.charAt(0).toUpperCase() + f.keyLabel.slice(1)}:</strong> ${f.valueLabel}`).join("<br />")}
          // data-content={`
          //   <table class="table table-striped table-bordered" style="margin-bottom: 0">
          //     ${t.morphologicalFeatures.map((f) => `<tr><td>${f.keyLabel}</td><td>${f.valueLabel}</td></tr>`).join("")}
          //   </table>
          // `}
          data-placement="bottom"
          data-toggle="popover"
          data-trigger="focus"
          role="button"
          style={{ marginTop: 10 }}
        >
          More
        </a>
      </div>
    );
  }

  renderLemma(t) {
    if(!t.lemma || !t.lemma.text || t.text === t.lemma.text) {
      return null;
    }

    const { onClick, targetLanguageCode, targetLanguageIso } = this.props;

    const text = !!onClick ? (
      <button
        className="btn btn-link"
        onClick={() => onClick({ cloze: t.text, text: t.lemma.text })}
      >
        {t.lemma.text}
      </button>
    ) : <span style={{ display: "inline-block", padding: "6px 12px" }}>{t.lemma.text}</span>;

    return (
      <div>
        {text}
        <div>
          <PlaySystemTtsButton targetLanguageCode={targetLanguageCode} targetLanguageIso={targetLanguageIso} text={t.lemma.text} />
        </div>
        {!!t.lemma.pronunciation && t.lemma.pronunciation !== t.lemma.text && <div style={{ marginTop: 10 }}>{t.lemma.pronunciation}</div>}
      </div>
    );
  }

  renderTokenText(t) {
    const { onClick } = this.props;

    if(!onClick) {
      return (
        <strong style={{ display: "inline-block", padding: "10px 16px", fontSize: 18, lineHeight: 1.33333 }}>
          {t.text}
        </strong>
      );
    }

    return (
      <button
        className="btn btn-lg btn-link"
        disabled={t.posTag && t.posTag.key === "PUNCT"}
        onClick={() => onClick({ text: t.text })}
        style={{ fontWeight: "bold" }}
      >
        {t.text}
      </button>
    );
  }

  render() {
    const { loading, tokens } = this.state;
    const { onClick, targetLanguageCode, targetLanguageIso } = this.props;

    if(loading) {
      return <Loading />;
    }

    if(!tokens || !tokens.length) {
      return "No tokens found!";
    }

    return (
      <div className="tokens" id={this.id} style={{ marginBottom: 20 }}>
        {tokens.map((t) => (
          <div key={t.id} className="token" style={{ display: "inline-block", margin: "0 2px", verticalAlign: "top" }}>
            <div>
              {this.renderTokenText(t)}
              {(!t.posTag || t.posTag.key !== "PUNCT") && (
                <div>
                  <PlaySystemTtsButton style={{ marginBottom: 10 }} targetLanguageCode={targetLanguageCode} targetLanguageIso={targetLanguageIso} text={t.text} />
                </div>
              )}
              {!!t.pronunciation && t.pronunciation !== t.text && <div style={{ marginBottom: 10 }}>{t.pronunciation}</div>}
            </div>
            <div>{!!t.posTag && t.posTag.key}</div>
            {this.renderLemma(t)}
            {this.renderMorphologicalFeatures(t)}
          </div>
        ))}
      </div>
    );
  }
}

