import React from "react";

export default function Pagination(props) {
  const { onNextClick, onPrevClick, page, perPage, total, updating } = props;
  return (
    <div className="row">
      <div className="col-xs-4">{page > 1 && <button disabled={updating} onClick={() => onPrevClick()} className="btn btn-link">Prev</button>}</div>
      <div className="col-xs-4 text-center" style={{ paddingTop: 8 }}>
        Total: {total.toLocaleString()} Page: {page} 
      </div>
      <div className="col-xs-4 text-right">{(page * perPage) < total && <button disabled={updating} onClick={() => onNextClick()} className="btn btn-link">Next</button>}</div>
    </div>
  );
}
