import React from "react";

import { v4 as uuidv4 } from 'uuid';
import TextareaAutosize from 'react-textarea-autosize';

import ClozeSentenceSearch from "./ClozeSentenceSearch";
import Loading from "../Loading";

export default class SentencesEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'editor',
      saving: false,
      sentences: props.sentences
    };
  }

  saveSentences() {
    this.setState({ saving: true });

    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        collection: { sentences: this.state.sentences }
      }),
      method: 'patch',
      url: this.props.collectionUrl,
    })
      .done((data) => {
        if(data.errors) {
          alert(data.errors.join(" "));
          this.setState({ saving: false });
          return false;
        }

        if(this.props.onSave) {
          this.props.onSave(this.state.sentences);
        }
      })
      .fail(() => {
        alert("Oh no! There was an error saving the sentences - sorry about that. Please try again and let us know if you see this message again.");
        this.setState({ saving: false });
      });
  }

  onTextMouseUp(e, r, index) {
    if(this.state.saving) {
      return false;
    }

    const selection = window.getSelection();
    const str = selection.toString();
    if(str && r.text.replace(/\{\{|\}\}/g, '').match(str)) {
      this.setState({
        sentences: this.state.sentences.map((r, i) => {
          if(i === index) {
            return { ...r, text: r.text.replace(/\{\{|\}\}/g, '').replace(str, '{{' + str + '}}') };
          }
          return r;
        })
      });
    }
  }

  getNewId() {
    return uuidv4();
  }

  renderSentences() {
    const { sentences } = this.state;

    return (
      <div>
        <table className="table sentences">
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Text</th>
              <th>Translation</th>
              <th>Multiple choice options<br />(comma-separated list, optional)</th>
              <th>Notes (optional)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sentences.map((r, i) => (
              <tr key={r.id}>
                <td className={!r.text.match(/\{\{.+\}\}/) || r.text.match(/\{\{\s|\s\}\}/) ? 'has-error bg-danger' : ''}>
                  <TextareaAutosize
                    className="form-control"
                    disabled={this.state.saving}
                    name="text"
                    onChange={(e) => this.setState({ sentences: sentences.map((r2, i2) => i === i2 ? { ...r, text: e.target.value } : r2)})}
                    value={r.text}
                  />
                  <small onMouseUp={(e) => this.onTextMouseUp(e, r, i)} style={{ display: 'block', marginLeft: 13, marginTop: 5 }}>{r.text.split('{{')[0]}<strong><u>{(r.text.split('{{')[1] || '').split('}}')[0]}</u></strong>{r.text.split('}}')[1]}</small>
                </td>
                <td>
                  <TextareaAutosize
                    className="form-control"
                    disabled={this.state.saving}
                    name="translation"
                    onChange={(e) => this.setState({ sentences: sentences.map((r2, i2) => i === i2 ? { ...r, translation: e.target.value } : r2)})}
                    value={r.translation || ''}
                  />
                </td>
                <td>
                  <TextareaAutosize
                    className="form-control"
                    disabled={this.state.saving}
                    name="multiple_choice_options"
                    onChange={(e) => this.setState({ sentences: sentences.map((r2, i2) => i === i2 ? { ...r, multiple_choice_options: e.target.value } : r2)})}
                    value={r.multiple_choice_options || ''}
                  />
                </td>
                <td>
                  <TextareaAutosize
                    className="form-control"
                    disabled={this.state.saving}
                    name="notes"
                    onChange={(e) => this.setState({ sentences: sentences.map((r2, i2) => i === i2 ? { ...r, notes: e.target.value } : r2)})}
                    value={r.notes || ''}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-danger delete-sentence"
                    disabled={this.state.saving}
                    onClick={() => {
                      if(confirm('Are you sure you want to delete this sentence?')) {
                        this.setState({ sentences: sentences.filter((r2, i2) => i2 !== i) });
                      }
                    }}
                  >
                    <span className="glyphicon glyphicon-trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pull-left">
          <ul className="list-inline">
            <li>
              <button className="btn btn-default" disabled={this.state.saving} onClick={() => this.setState({ sentences: this.state.sentences.concat([{ id: this.getNewId(), text: '' }]) })}>
                <span className="glyphicon glyphicon-plus" /> Add Row
              </button>
            </li>
            <li>
              <button className="btn btn-default" disabled={this.state.saving} onClick={() => this.setState({ mode: 'search' })}>
                <span className="glyphicon glyphicon-search" /> Add via Search
              </button>
            </li>
          </ul>
        </div>
        <div className="text-right">
          <button className="btn btn-lg btn-success joystix" disabled={this.state.saving || !this.state.sentences.every((r) => r.text.match(/\{\{.+\}\}/))} onClick={() => this.saveSentences()}>Save</button>
            <button className="btn btn-link" onClick={() => this.props.onCancel()}>Cancel</button>
        </div>
      </div>
    );
  }

  renderSearch() {
    return (
      <div>
        <button className="btn btn-default" onClick={() => this.setState({ mode: 'editor' })} style={{ marginBottom: 10 }}>Back</button>
        <ClozeSentenceSearch
          add={(clozeSentence) => this.setState({ sentences: [{ id: this.getNewId(), text: clozeSentence.text, translation: clozeSentence.translation, tts: clozeSentence.tts }].concat(this.state.sentences) })}
          remove={(clozeSentence) => this.setState({ sentences: this.state.sentences.filter((s) => s.text !== clozeSentence.text) })}
          sentencesMap={new Map(this.state.sentences.map((s) => [s.text, true]))}
          url={this.props.clozeSentencesUrl}
        />
        <button className="btn btn-default" onClick={() => this.setState({ mode: 'editor' })} style={{ marginBottom: 10 }}>Back</button>
      </div>
    );
  }

  render() {
    if(this.state.mode === 'search') {
      return this.renderSearch();
    }

    return this.renderSentences();
  }
}

