import React from "react";

import SpeechSynthesisBtn from "./SpeechSynthesisBtn";

class ConjugationGame extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      answered: false,
      correct: false,
      correctSoFar: false,
      currentAnswer: "",
      currentIndex: null,
      history: [],
      historyVisible: false,
      loading: true
    };
  }

  componentDidMount() {
    // sample n selected verbs
    // load them
    // play
    // when kkkk

    this.loadVerbs();
  }

  init() {
    this.focusInput();

    $(window).on("keydown", (e) => {
      const { answered } = this.state;

      if(e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        if(answered) {
          this.next();
        }
        else {
          this.setState({
            answered: true
          });
        }
      }
    });
  }

  loadVerbs() {
    const { verbs, verbsUrl } = this.props;
    console.log(verbs.map((v) => v.id));
    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({ ids: verbs.map((v) => v.id) }),
      method: "post",
      url: verbsUrl
    })
      .done((data) => {
        this.setCandidates(data.inflectionEntries);
        this.setState({
          currentIndex: this.getRandomIndex(),
          loading: false
        }, () => this.init());
      })
      .fail(() => {
      });
  }

  setCandidates(entries) {
    const { checked } = this.props;
    this.candidates = [];
    entries.forEach((e) => {
      e.data.tables.forEach((t, tableIndex) => {
        t.columns.forEach((col, colIndex) => {
          col.forEach((row, rowIndex) => {
            if(colIndex === 0 || rowIndex === 0) {
              return false;
            }
            if(!checked[tableIndex][colIndex][rowIndex]) {
              return false;
            }
            this.candidates.push([
              e.term,
              e.translation,
              t.heading,
              t.columns[colIndex][0],
              t.columns[0][rowIndex],
              row
            ]);
          });
        });
      });
    });
  }

  focusInput() {
    this.inputRef && this.inputRef.current && this.inputRef.current.focus();
  }

  getRandomIndex() {
    return Math.floor(Math.random() * this.candidates.length);
  }

  next() {
    const { correct, currentIndex, history } = this.state;
    this.setState({
      answered: false,
      correct: false,
      correctSoFar: false,
      currentAnswer: "",
      currentIndex: this.getRandomIndex(),
      history: history.concat([this.candidates[currentIndex].concat([correct])])
    }, () => this.focusInput());
  }

  renderInput() {
    const { answered, correct, correctSoFar, currentAnswer, currentIndex } = this.state;
    const current = this.candidates[currentIndex];

    return (
      <input
        autoFocus={true}
        className="form-control input-lg"
        disabled={answered}
        onChange={(e) => this.setState({
          answered: e.target.value === current[5],
          correct: e.target.value === current[5],
          correctSoFar: current[5].toLowerCase().indexOf(e.target.value.toLowerCase()) === 0,
          currentAnswer: e.target.value
        })}
        onKeyDown={(e) => {
          console.log(e);
          if(e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
            if(answered) {
              this.next();
            }
            else {
              this.setState({
                answered: true
              });
            }
          }
        }}
        ref={this.inputRef}
        style={{
          backgroundColor: answered ? (correct ? "#dff0d8" : "#f2dede") : null,
          color: answered || !currentAnswer ? null : (correctSoFar ? "#3fb618" : "#e00"),
          fontWeight: "bold"
        }}
        type="text"
        value={currentAnswer}
      />
    );
  }

  hint() {
    const { correctSoFar, currentAnswer, currentIndex } = this.state;
    const current = this.candidates[currentIndex];

    let newCurrentAnswer = "";
    for(let i = 0; i < current[5].length; i++) {
      newCurrentAnswer += current[5][i];
      if((currentAnswer[i] || "") !== current[5][i]) {
        break;
      }
    }

    this.setState({
      answered: newCurrentAnswer === current[5],
      correct: newCurrentAnswer === current[5],
      correctSoFar: true,
      currentAnswer: newCurrentAnswer
    }, () => this.focusInput());
  }

  // X right or wrong icon next to Next button
  // X fix speech synthesis btn language code
  // X tts for infinitive
  // X tts for answer
  // X scroll up on play
  // X fix enter on body to go to next
  // - conjugation table toggle after answering (or link)
  // - link to translation entry
  // - history lists words played
  // - settings lists verbs
  // - cache list of verbs and table parts of speech
  // - add endpoint for getting next entry - don't want to render 500 entries on page load
  // - clozemaster promotion
  // - check mobile
  // - add to sitemap
  // - update route
  // next iteration
  // - show example sentences after answering with add to collection option
  // -- or even just search sentences button for now

  render() {
    const { answered, correct, correctSoFar, currentAnswer, currentIndex, history, historyVisible, loading } = this.state;
    const { languageCode, languageIso, languagePairingSlug, onSettingsClick } = this.props;

    if(loading) {
      return <p>Loading...</p>;
    }

    const current = this.candidates[currentIndex];

    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <div>
            <span style={{ fontSize: "2.5em", fontWeight: "bold" }}>{current[0]}</span>
            <SpeechSynthesisBtn
              className="btn btn-default"
              languageCode={languageCode} 
              languageIso={languageIso}
              style={{ marginLeft: 10, marginTop: -10 }}
              text={current[0]}
            />
            <a href={`/translate/${languagePairingSlug}/${current[0]}`} style={{ marginLeft: 10 }} target="_blank">Translation Entry <span className="glyphicon glyphicon-new-window" /></a>
            <a href={`/conjugate/${languagePairingSlug}/${current[0]}`} style={{ marginLeft: 10 }} target="_blank">Conjugation Tables <span className="glyphicon glyphicon-new-window" /></a>
            <a href={`/examples/${languagePairingSlug}/${current[0]}`} style={{ marginLeft: 10 }} target="_blank">Example Sentences <span className="glyphicon glyphicon-new-window" /></a>
          </div>
          <div>{current[1]}</div>
        </div>
        <div style={{ fontSize: "1.5em", marginBottom: 20 }}>{current[2]} - {current[3]}</div>
        <div style={{ alignItems: "center", display: "flex", flexDirection: "row", fontSize: "1.5em", marginBottom: 20 }}>
          <div style={{ paddingRight: 10, whiteSpace: "nowrap" }}>{current[4]}</div>
          {answered && !correct && <div style={{ fontWeight: "bold", paddingRight: 10 }}>{current[5]}</div>}
          {answered && !correct && (
            <SpeechSynthesisBtn
              className="btn btn-default"
              languageCode={languageCode} 
              languageIso={languageIso}
              style={{ marginRight: 10 }} 
              text={current[5]}
            />
          )}
          <div style={!answered || !correct ? { flex: 1 } : null}>
            {this.renderInput()}
          </div>
          {answered && (
            <div style={{ flex: 1 }}>
              {correct && (
                <SpeechSynthesisBtn
                  className="btn btn-default"
                  languageCode={languageCode} 
                  languageIso={languageIso}
                  style={{ marginLeft: 10 }}
                  text={current[5]}
                />
              )}
            </div>
          )}
        </div>
        <p>
          {!answered && <button className="btn btn-lg btn-default joystix" onClick={() => this.setState({ answered: true })}>Show Answer</button>}
          {!answered && <button className="btn btn-lg btn-default joystix" onClick={() => this.hint()} style={{ marginLeft: 10 }}>Hint</button>}
          {answered && <button className="btn btn-lg btn-success joystix" onClick={() => this.next()} style={{ marginRight: 10 }}>Next</button>}
          {answered && <span style={{ fontSize: "2em", verticalAlign: "middle" }}>{correct ? "🎉" : "❌"}</span>}
        </p>
        <p>
          <button className="btn btn-default btn-sm joystix" onClick={onSettingsClick}><span className="glyphicon glyphicon-arrow-left"></span> Settings</button>
          <button className="btn btn-default btn-sm joystix" onClick={() => this.setState({ historyVisible: !historyVisible })} style={{ marginLeft: 10 }}><span className="glyphicon glyphicon-time"></span> History</button>
        </p>
        {historyVisible && (
          <ol>
            {history.map((h, i) => (
              <li key={i}>
                <div><strong>{h[0]}</strong> {h[2]} {h[3]} - {h[4]} <strong>{h[5]}</strong> {h[6] ? "🎉" : "❌"}</div>
                <div><small>{h[1]}</small></div>
              </li>
            ))}
          </ol>
        )}
      </div>
    );
  }
}

export default class ConjugationPractice extends React.Component {
  constructor(props) {
    super(props);

    console.log(props);

    const checked = [];
    // table-col-row
    props.demoEntry.tables.forEach((table, tableIndex) => {
      const checkedColsArr = [];
      table.columns.forEach((col, colIndex) => {
        const checkedRowsArr = [];
        col.forEach((row, rowIndex) => {
          checkedRowsArr.push(colIndex > 0 && rowIndex > 0 && row ? true : null);
        });
        checkedColsArr.push(checkedRowsArr);
      });
      checked.push(checkedColsArr);
    });

    const verbsMap = new Map(props.verbs.map((v) => [v.id, true]));

    this.state = {
      checked,
      verbsMap,
      view: "settings"
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.view !== this.state.view) {
      $("html, body").animate({ scrollTop: 0 });
    }
  }

  renderHeading({ tableIndex }) {
    const { demoEntry } = this.props;
    const { checked } = this.state;

    let anyChecked = false;
    checked[tableIndex].forEach((col, colIndex) => {
      col.forEach((row, rowIndex) => {
        if(colIndex === 0 || rowIndex === 0) {
          return false;
        }
        anyChecked = anyChecked || checked[tableIndex][colIndex][rowIndex];
      });
    });

    return (
      <h2 style={{ fontSize: 20 }}>
        <label>
          <input
            checked={anyChecked}
            onChange={(e) => {
              const newChecked = JSON.parse(JSON.stringify(checked));
              newChecked[tableIndex].forEach((col, colIndex) => {
                col.forEach((row, rowIndex) => {
                  if(colIndex > 0 && rowIndex > 0) {
                    newChecked[tableIndex][colIndex][rowIndex] = anyChecked ? false : true;
                  }
                });
              });
              this.setState({
                checked: newChecked
              });
            }}
            type="checkbox"
          /> {demoEntry.tables[tableIndex].heading}
        </label>
      </h2>
    );
  }

  renderColControl({ colIndex, rowIndex, tableIndex }) {
    const { demoEntry } = this.props;
    const { checked } = this.state;

    let anyChecked = null;
    checked[tableIndex].forEach((col, _colIndex) => {
      col.forEach((row, _rowIndex) => {
        if(_colIndex === 0 || _rowIndex === 0) {
          return false;
        }
        if(_rowIndex === rowIndex && demoEntry.tables[tableIndex].columns[_colIndex][_rowIndex]) {
          anyChecked = !!anyChecked || checked[tableIndex][_colIndex][_rowIndex];
        }
      });
    });

    return (
      <label>
        {anyChecked !== null && (
          <input
            checked={anyChecked}
            onChange={(e) => {
              const newChecked = JSON.parse(JSON.stringify(checked));
              newChecked[tableIndex].forEach((col, _colIndex) => {
                col.forEach((row, _rowIndex) => {
                  if(_colIndex === 0 || _rowIndex === 0) {
                    return false;
                  }
                  if(_rowIndex === rowIndex) {
                    newChecked[tableIndex][_colIndex][_rowIndex] = anyChecked ? false : true;
                  }
                });
              });
              this.setState({
                checked: newChecked
              });
            }}
            type="checkbox"
          />
        )} {demoEntry.tables[tableIndex].columns[colIndex][rowIndex]}
      </label>
    );
  }

  renderRowControl({ colIndex, rowIndex, tableIndex }) {
    const { demoEntry } = this.props;
    const { checked } = this.state;

    let anyChecked = null;
    checked[tableIndex].forEach((col, _colIndex) => {
      col.forEach((row, _rowIndex) => {
        if(_colIndex === 0 || _rowIndex === 0) {
          return false;
        }
        if(_colIndex === colIndex && demoEntry.tables[tableIndex].columns[_colIndex][_rowIndex]) {
          anyChecked = !!anyChecked || checked[tableIndex][_colIndex][_rowIndex];
        }
      });
    });

    return (
      <label>
        {anyChecked !== null && (
          <input
            checked={anyChecked}
            onChange={(e) => {
              const newChecked = JSON.parse(JSON.stringify(checked));
              newChecked[tableIndex].forEach((col, _colIndex) => {
                col.forEach((row, _rowIndex) => {
                  if(_colIndex === 0 || _rowIndex === 0) {
                    return false;
                  }
                  if(_colIndex === colIndex) {
                    newChecked[tableIndex][_colIndex][_rowIndex] = anyChecked ? false : true;
                  }
                });
              });
              this.setState({
                checked: newChecked
              });
            }}
            type="checkbox"
          />
        )} {demoEntry.tables[tableIndex].columns[colIndex][rowIndex]}
      </label>
    );
  }

  renderCell({ colIndex, rowIndex, tableIndex }) {
    const { demoEntry } = this.props;
    const { checked } = this.state;

    if(rowIndex === 0 && colIndex === 0) {
      return <>&nbsp;</>;
    }
    else if(colIndex === 0) {
      return this.renderColControl({ colIndex, rowIndex, tableIndex });
    }
    else if(rowIndex === 0) {
      return this.renderRowControl({ colIndex, rowIndex, tableIndex });
    }

    const content = demoEntry.tables[tableIndex].columns[colIndex][rowIndex];

    return (
      <label style={{ fontWeight: "normal" }}>
        {!!content && (
          <input
            type="checkbox"
            checked={checked[tableIndex][colIndex][rowIndex]}
            onChange={(e) => {
              const newChecked = JSON.parse(JSON.stringify(checked));
              newChecked[tableIndex][colIndex][rowIndex] = e.target.checked;
              this.setState({ checked: newChecked });
            }}
          />
        )} {content}
      </label>
    );
  }

  renderSettings() {
    const { demoEntry, demoEntryTerm, verbs } = this.props;
    const { checked, verbsMap } = this.state;

    const anyChecked = checked.find((table, tableIndex) => (
      checked[tableIndex].find((col, colIndex) => (
        col.find((row, rowIndex) => {
          if(colIndex === 0 || rowIndex === 0) {
            return false;
          }
          return checked[tableIndex][colIndex][rowIndex];
        })
      ))
    ));
    const verbsSelected = Array.from(verbsMap.values()).filter((v) => v).length;

    return (
      <div>
        <hr />
        <p><strong>Example:</strong> {demoEntryTerm}</p>
        {demoEntry.tables.map((t, tableIndex) => (
          <div style={{ marginBottom: 40 }}>
            {this.renderHeading({ tableIndex })}
            <div className="entry-table">
              {t.columns.map((col, colIndex) => (
                <div className="entry-table-col">
                  {col.map((row, rowIndex) => (
                    <div className="entry-table-row" style={colIndex === 0 ? { borderLeft: "none" } : null}>
                      {this.renderCell({ colIndex, rowIndex, tableIndex })}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
        <hr />
        <div>
          <h2 style={{ display: "inline-block" }}>Verbs</h2>
          <label style={{ marginLeft: 10 }}>
            <input
              checked={!Array.from(verbsMap.values()).filter((v) => !v).length}
              onChange={(e) => {
                Array.from(verbsMap.keys()).forEach((k) => verbsMap.set(k, e.target.checked));
                this.setState({ verbsMap: new Map(verbsMap) });
              }}
              type="checkbox"
            /> All
          </label>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {verbs.map((v) => (
            <div style={{ paddingBottom: 10, paddingRight: 10 }}>
              <label style={{ fontWeight: "normal" }}>
                <input
                  checked={verbsMap.get(v.id)}
                  onChange={(e) => {
                    verbsMap.set(v.id, e.target.checked);
                    this.setState({ verbsMap: new Map(verbsMap) });
                  }}
                  type="checkbox"
                /> {v.term}
              </label>
            </div>
          ))}
        </div>
        <hr />
        <p>
          <button
            className="btn btn-success joystix btn-block btn-lg"
            disabled={!verbsSelected || !anyChecked}
            onClick={() => this.setState({ view: "game" })}
          >
            Play
          </button>
        </p>
        {!anyChecked && <p className="text-danger">Please select one or more parts of speech!</p>}
        {!verbsSelected && <p className="text-danger">Please select one or more verbs!</p>}
      </div>
    );
  }

  render() {
    const { checked, verbsMap, view } = this.state;
    const { languageCode, languageIso, languagePairingSlug, verbs, verbsUrl } = this.props;

    if(view === "settings") {
      return this.renderSettings();
    }

    return (
      <ConjugationGame
        checked={checked}
        languageCode={languageCode}
        languageIso={languageIso}
        languagePairingSlug={languagePairingSlug}
        onSettingsClick={() => this.setState({ view: "settings" })}
        verbs={verbs.filter((v) => verbsMap.get(v.id))}
        verbsUrl={verbsUrl}
      />
    );
  }
}
