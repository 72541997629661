import React from "react";

import 'url-search-params-polyfill';

import Checkbox from "../Checkbox";
import Radio from "../Radio";

export default class LinkBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "multiple_choice",
      results: true,
    };
  }

  onTextInputClick() {
    this.textInput.select();
    try {
      if(document.execCommand('copy')) {
        clearTimeout(this.copyTimeout);
        this.setState({ copied: true }, () => (this.copyTimeout = setTimeout(() => this.setState({ copied: false }), 3000)));
      }
    } catch(e) {
    }
  }

  getUrl() {
    const searchParams = new URLSearchParams();
    searchParams.append("mode", this.state.mode);
    searchParams.append("results", this.state.results);
    return this.props.url + "?" + searchParams.toString();
  }

  render() {
    return (
      <div>
        <p>You can share the link below with your students. They'll be able to play through all the sentences in the collection in a single round without having to sign in.</p>
        <div className="form-group">
          <strong>Mode:</strong>
          <div style={{ marginLeft: 15 }}>
            <Radio
              checked={this.state.mode === "multiple_choice"}
              label="Multiple Choice"
              name="mode"
              onChange={(e) => this.setState({ mode: e.target.value })}
              value="multiple_choice"
            />
            <Radio
              checked={this.state.mode === "text_input"}
              label="Text Input"
              name="mode"
              onChange={(e) => this.setState({ mode: e.target.value })}
              value="text_input"
            />
          </div>
        </div>
        <div className="form-group">
          <Checkbox
            checked={this.state.results}
            label="Allow players to submit their results at the end of the round"
            name="results"
            onChange={(e) => this.setState({ results: e.target.checked })}
          />
        </div>
        <input
          className="form-control"
          onClick={() => this.onTextInputClick()}
          readOnly
          ref={(el) => this.textInput = el}
          type="text"
          value={this.getUrl()}
        />
        {this.state.copied && <div>Copied!</div>}
      </div>
    );
  }
}
