import React from "react";

import CollectionsModalTable from "./CollectionsModalTable";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";

export default class FavoritesModal extends React.Component {
  hide() {
    this.modal.hide(...arguments);
  }

  renderContent() {
    if(!this.props.isPro) {
      return (
        <ModalProPromo
          can="favorite sentences and then practice just their favorites or download their favorites (to print out, for import into Anki, etc.)."
          secondary="Take control of your learning. Get fluent faster."
        />
      );
    }

    const { action, collections, onHidden, onDownload, onPlay, onView } = this.props;
    const withFavorites = collections.filter((c) => c.numFavorited);

    return (
      <>
        {action === 'play' && (
          <button
            className="btn btn-lg btn-success btn-block joystix"
            onClick={() => onPlay({ name: 'All', playWebUrl: this.props.playLanguagePairingUrl })}
          >
            Play All <Icon name="chevron-right" />
          </button>
        )}
        <CollectionsModalTable
          attr="numFavorited"
          btnClassName={action === 'play' ? 'btn-success' : (action === 'download' ? 'btn-primary' : 'btn-default')}
          btnIcon={<Icon name={action === 'play' ? 'chevron-right' : (action === 'download' ? 'cloud-download' : '')} />}
          btnText={action === 'play' ? 'Play' : (action === 'download' ? 'Download' : 'View')}
          collections={withFavorites}
          onBtnClick={(collection) => action === 'play' ? onPlay(collection) : (action === 'view' ? onView(collection) : onDownload(collection))}
        />
      </>
    );
  }

  render() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={this.props.onHidden}
        ref={(e) => this.modal = e}
        title="Favorites"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
