import React from "react";

export default class Loading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dots: 0
    };
  }

  componentDidMount() {
    this.dotsInterval = setInterval(() => {
      this.setState({ dots: this.state.dots + 1 });
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.dotsInterval);
  }

  renderDots() {
    let dots = '';
    for(let i = 0; i < (this.state.dots % 4); i++) {
      dots += '.';
    }
    return dots;
  }

  render() {
    return (
      <div className="loading" style={{ fontSize: '3rem', textAlign: 'center', marginTop: 40, marginBottom: 40 }}>
        <i className="fa fa-cog fa-spin fa-3x fa-fw"></i>
        <div className="joystix">Loading{this.renderDots()}</div>
        {this.props.children}
      </div>
    );
  }
}
