import React from "react";

import Icon from "./Icon";
import Radio from "./Radio";

import { validateEmail } from "../helpers";

export default class ExplanationRequest extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      format: null,
      open: false,
      otherFormat: "",
      question: "",
      sending: false,
      sent: false
    };
  }

  toggleOpen() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  onFieldChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit() {
    const { format, otherFormat, question } = this.state;
    const { collectionClozeSentenceId, collectionId, languagePairingId, url } = this.props;

    if (question.length < 20) {
      alert("The question should be at least 20 characters please!");
      return;
    }

    if (!format) {
      alert("Please select a format!");
      return;
    }

    if(format === "other" && !otherFormat) {
      alert("Please enter a format!");
      return;
    }

    this.setState({ sending: true });

    $.ajax({
      data: {
        explanation_request: {
          collection_id: collectionId,
          collection_cloze_sentence_id: collectionClozeSentenceId,
          format,
          language_pairing_id: languagePairingId,
          other_format: otherFormat,
          question
        }
      },
      method: "post",
      url: "/api/v1/explanation_requests"
    })
      .done((data) => {
        this.setState({ sent: true });
      })
      .fail(() => {
        alert("Oh no! There was an error sending the request. Sorry about that. Please try again and let us know if you see this message again.");
        this.setState({ sent: false });
      })
      .always(() => {
        this.setState({ sending: false });
      });
  }

  onKeyDown(e) {
    e.stopPropagation();
  }

  render() {
    const { format, open, otherFormat, question, sending, sent } = this.state;
    return (
      <div className="feedback-popup wide">
        <span className="title" aria-label="Open explanation request" onClick={() => this.toggleOpen()}>
          Explanation Request
          {!open && <Icon name="question-sign" style={{ marginLeft: 10 }} />}
          {open && <button type="button" className="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>}
        </span>
        {open &&
          <div className="text-wrap">
            {!sent &&
              <p className="text">
                Question about this sentence? Request an explanation from a native speaker. We're testing interest in this feature. Your request will help determine whether we actually build it.
              </p>
            }
            {sent &&
              <p className="success-text alert alert-success">Your request has been sent! We'll let you know if we end up building this feature and an explanation is added.</p>
            }
            {!sent &&
              <div>
                <label htmlFor="question">Question:</label>
                <textarea disabled={sending} value={question} className="form-control" name="question" onChange={(e) => this.onFieldChange(e)} onKeyDown={(e) => this.onKeyDown(e)}></textarea>
                <label>Preferred explanation format:</label>
                <Radio checked={format === "video"} disabled={sending} label="Video" name="format" onChange={(e) => this.onFieldChange(e)} value="video" style={{ marginTop: 0 }} />
                <Radio checked={format === "text"} disabled={sending} label="Text" name="format" onChange={(e) => this.onFieldChange(e)} value="text" />
                <Radio checked={format === "other"} disabled={sending} label="Other" name="format" onChange={(e) => this.onFieldChange(e)} value="other" />
                {format === "other" && (
                  <>
                    <label>Other format:</label>
                    <input className="form-control" disabled={sending} name="otherFormat" onChange={(e) => this.onFieldChange(e)} value={otherFormat} onKeyDown={(e) => this.onKeyDown(e)} />
                  </>
                )}
                <button className="button btn-block action-feedback-popup-send" disabled={sending} onClick={() => this.onSubmit()}>Send request</button>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

