import React from "react";

export default class TeachingCollectionResultsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      submitted: false,
      submitting: false
    };
  }

  onTeachingCollectionResultSubmit(e) {
    e.preventDefault();

    if(!this.state.name) {
      return false;
    }

    this.setState({ submitting: true });

    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({ 
        teaching_collection_result: { name: this.state.name, stats: this.props.stats }
      }),
      method: 'post',
      url: this.props.teachingCollectionResultsUrl
    })
      .done((data) => {
        this.setState({ submitted: true });
      })
      .fail(() => {
        alert("Oh no! There was an error sending your results. Sorry about that. Please try again and let us know if you see this message again.");
        this.setState({ submitting: false });
      });

    return false;
  }

  render() {
    return (
      <form onSubmit={(e) => this.onTeachingCollectionResultSubmit(e)}>
        <p>This is a Teaching Collection. Enter your name below if you want to let the collection creator know how you did!</p>
        {this.state.submitted && <p className="alert alert-success text-center"><strong>&#x1F389; Sent! &#x1F389;</strong></p>}
        {!this.state.submitted && (
          <div>
            <div className="form-group">
              <label htmlFor="teaching_collection_result[name]">Name</label>
              <input className="form-control" disabled={this.state.submitting || this.state.submitted} name="teaching_collection_result[name]" onChange={(e) => this.setState({ name: e.target.value.substring(0, 255) })} type="text" value={this.state.name} />
            </div>
            <button className="btn btn-success btn-block joystix" disabled={this.state.submitting || this.state.submitted}>Submit</button>
          </div>
        )}
      </form>
    );
  }
}
