import React from "react";

import { collectionPctStr } from "../../helpers";

import CollectionProgressBar from "./CollectionProgressBar";
import Icon from "../Icon";
import MasteredIcon from "../MasteredIcon";
import Panel from "../Panel";
import PlayingIcon from "../PlayingIcon";

export default class ClozeListeningCollectionComponent extends React.Component {
  render() {
    const { collection, isSignedIn, progressPerChunk, useProgressChunks } = this.props;

    return (
      <Panel className="cloze-listening-collection">
        <div className="pull-right hidden-xs">
          <button disabled={!isSignedIn} onClick={() => this.props.play()} className="btn btn-success btn-lg btn-block joystix">Play <span className="glyphicon glyphicon-chevron-right"></span></button>
          {!isSignedIn && <div className="joystix text-center">Sign up!</div>}
        </div>
        <h2>
          Cloze-Listening Collection
          <button
            className="btn btn-xs btn-link"
            data-content={`
              Cloze-Listening is the ultimate challenge and the best way to improve your listening skills at the intermediate level and above.
              <br /><br />
              There are different accents, background noise, and the audio quality is variable - if you can understand and master these sentences, you'll have no problem in the real world.
              <br /><br />
              Sentences and audio are from Mozilla's <a href="https://commonvoice.mozilla.org/en" target="_blank">Common Voice</a> project (contribute if you can!). Translations are machine generated.
            `}
            data-html="true"
            data-title="Cloze-Listening"
            data-toggle="popover"
            data-trigger="focus"
            style={{ fontSize: '65%', marginLeft: 8 }}
          >
            <span className="glyphicon glyphicon-question-sign"></span>
          </button>
          <button data-toggle="tooltip" data-title="Manage Collection" disabled={!isSignedIn} onClick={() => this.props.manage()} className="btn btn-default btn-sm manage-collection" style={{ marginLeft: 8 }}><Icon name="list" /></button>
          <small style={{ display: 'block', marginTop: 8 }}>Play {collection.numSentences.toLocaleString()} sentences with native speaker audio.</small>
        </h2>
        <CollectionProgressBar
          collection={collection}
          perChunk={progressPerChunk}
          style={{ marginBottom: 10 }}
          useChunks={useProgressChunks}
        />
        <div>
          <span style={{ fontSize: '1.75rem' }}>
            <strong><PlayingIcon /> Playing {collection.numPlaying.toLocaleString()} <span className="hidden-xs">sentences </span>({collectionPctStr(collection, 'playing')})</strong>
          </span>
          <span style={{ marginLeft: 8, fontSize: '1.75rem' }}>
            <strong><MasteredIcon /> Mastered {collection.numMastered.toLocaleString()} <span className="hidden-xs">sentences </span>({collectionPctStr(collection, 'mastered')})</strong>
          </span>
          <button disabled={!isSignedIn} onClick={() => this.props.play()} className="btn btn-success btn-lg btn-block joystix visible-xs" style={{ marginTop: 10 }}>Play <span className="glyphicon glyphicon-chevron-right"></span></button>
          {!isSignedIn && <div className="joystix text-center visible-xs" style={{ marginTop: 4 }}>Sign up!</div>}
        </div>
      </Panel>
    );
  }
}

