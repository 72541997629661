import React from 'react';
import { validateEmail } from '../helpers';

export default class FeedbackWidget extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      sent: false,
      email: '',
      message: ''
    };
  }

  toggleOpen() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  onSubmit() {
    const { email, message } = this.state;

    if (message.length < 20) {
      alert('The message should be at least 20 characters please!');
      return;
    }

    if (!validateEmail(email)) {
      alert('Hm the email seems to be invalid.')
      return;
    }

    this.setState({ sending: true });

    $.ajax({
      data: { email, message },
      method: 'post',
      url: this.props.sendFeedbackUrl
    })
      .done((data) => {
        this.setState({ sent: true });
      })
      .fail(() => {
        alert('Oh no! There was an error sending the feedback. Sorry about that. Please try again and let us know if you see this message again.');
        this.setState({ sent: false });
      })
      .always(() => {
        this.setState({ sending: false });
      });
  }

  render() {
    const { open, sending, sent } = this.state;
    return (
      <div className="feedback-popup">
        <span className="title" aria-label="Open feedback widget" onClick={() => this.toggleOpen()}>
          Feedback? Need help?
          {open && <button type="button" className="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>}
        </span>
        {open &&
          <div className="text-wrap">
            {!sent &&
              <div className="text">
                If you have feedback, found a bug, need a feature, or need help, write it here. Also <a href="/faq" target="_blanc">check out our FAQ</a> for answers to most questions.
              </div>
            }
            {sent &&
              <div className="success-text">Thanks for the message! Any and all feedback is always very much appreciated.</div>
            }
            {!sent &&
              <div>
                <input disabled={sending} value={this.state.email} type="email" placeholder="Your email" className="email" onChange={e => this.onEmailChange(e)} />
                <textarea disabled={sending} value={this.state.message} placeholder="Your message" className="message" onChange={e => this.onMessageChange(e)}></textarea>
                <button className="button action-feedback-popup-send" disabled={sending} onClick={() => this.onSubmit()}>Send feedback</button>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}
