import React from "react";

export default class AutoScrollablePre extends React.Component {
  constructor(props) {
    super(props);

    this.preRef = React.createRef();

    this.state = {
      autoScroll: true
    };
  }

  componentDidMount() {
    this.autoScroll();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.children !== this.props.children && this.state.autoScroll) {
      this.autoScroll();
    }
  }

  autoScroll() {
    const pre = this.preRef.current;
    const $pre = $(pre);
    $pre.scrollTop(pre.scrollHeight || 10000);
  }

  render() {
    const { children, style } = this.props;
    const { autoScroll } = this.state;

    return (
      <>
        <pre ref={this.preRef} style={Object.assign({ height: 300, marginBottom: 10, overflow: "scroll", textAlign: "left" }, style)}>
          {children}
        </pre>
        <label style={{ display: "block", fontSize: 14, textAlign: "left" }}><input type="checkbox" checked={autoScroll} onChange={(e) => this.setState({ autoScroll: e.target.checked })} /> Auto-scroll to bottom</label>
      </>
    );
  }
}

