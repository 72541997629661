import React from "react";
import ReactDOM from "react-dom";

import Checkbox from "./Checkbox";
import Icon from "./Icon";
import ResourceLinks from "./ResourceLinks";
import SelectionToken from "./SelectionToken";
import SelectionTokenText from "./SelectionTokenText";
import Slideout from "./Slideout";

export default class SelectionSlideout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conjugationEntryUrl: null,
      loadingTranslations: true,
      translationEntryUrl: null,
      translations: null,
      ttsAvailable: false,
      wiktionaryIframeVisible: localStorage.getItem("wiktionaryIframeVisible") !== "false"
    };
  }

  componentDidMount() {
    this.loadTranslations();
    this.initTts();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.wiktionaryIframeVisible !== prevState.wiktionaryIframeVisible) {
      localStorage.setItem("wiktionaryIframeVisible", this.state.wiktionaryIframeVisible ? "true" : "false");
    }
  }

  initTts() {
    // run check to load voices
    this.ttsTimeoutCount = 0;
    this.checkForTts();
  }

  checkForTts() {
    const ttsAvailable = this.isSystemTtsAvailable();
    this.setState({ ttsAvailable });
    this.ttsTimeoutCount += 1;
    if(!ttsAvailable && this.ttsTimeoutCount < 5) {
      this.ttsTimeout = setTimeout(() => this.checkForTts(), 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.ttsTimeout);
  }

  isSystemTtsAvailable() {
    const { targetLanguageIso, targetLanguageCode } = this.props;
    return window.clozemaster.systemTtsAvailable(targetLanguageIso, targetLanguageCode);
  }

  getSystemTtsVoices() {
    const { targetLanguageIso, targetLanguageCode } = this.props;
    return window.clozemaster.getSystemTtsVoices(targetLanguageIso, targetLanguageCode);
  }

  playTts() {
    const { selection } = this.props;
    const voices = this.getSystemTtsVoices();
    const voice = voices[0]; // voices[Math.floor(Math.random()*voices.length)];
    const u = new SpeechSynthesisUtterance(selection);
    u.voice = voice;
    u.lang = voice.lang;
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(u);
  }

  loadTranslations() {
    this.setState({ loadingTranslations: true });

    const {
      baseLanguageIso,
      selection,
      targetLanguageIso,
      tokenizeableId,
      tokenizeableType,
      userSelectionTranslationsUrl
    } = this.props;

    $.ajax({
      data: {
        from: targetLanguageIso,
        to: baseLanguageIso,
        tokenizeable_id: tokenizeableId,
        tokenizeable_type: tokenizeableType,
        translate: selection
      },
      method: 'post',
      url: userSelectionTranslationsUrl
    })
      .done((data) => {
        console.log(data);
        this.setState({
          loadingTranslations: false,
          resourceLinks: data.resourceLinks,
          token: data.token,
          tokenText: data.tokenText,
          translations: data.translations && data.translations.map((t) => t.translatedText).join('; ')
        });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        if(jqXHR.status === 401) {
          this.setState({
            loadingTranslations: false,
            translations: false
          });
        }
        else {
          alert('Oh no! There was an error loading translations. Sorry about that. Please let us know if you see this error message again.');
        }
      })
      .always(() => {
        this.loadTranslationEntry();
      });
  }

  loadTranslationEntry() {
    const { searchTranslationEntriesUrl, selection } = this.props;

    if(!searchTranslationEntriesUrl) {
      return false;
    }

    $.ajax({
      data: { term: selection },
      url: searchTranslationEntriesUrl
    })
      .done((data) => {
        console.log(data);
        const { translationEntry } = data;
        const { conjugationEntry } = translationEntry;
        this.setState({
          conjugationEntryUrl: conjugationEntry && conjugationEntry.webUrl,
          translationEntryUrl: translationEntry.webUrl
        });
      })
      .fail(() => {
        // do nothing
      });
  }

  renderLinks() {
    const { loadingTranslations, resourceLinks, resourceLinksUrl } = this.state;

    if(loadingTranslations) {
      return null;
    }

    if(resourceLinks) {
      const { openResourceLinksEditor, selection, targetLanguageCode } = this.props;
      return (
        <ResourceLinks
          onEditClick={openResourceLinksEditor}
          query={selection}
          resourceLinks={resourceLinks}
          targetLanguageCode={targetLanguageCode}
        />
      );
    }

    const { baseLanguageIso, baseLanguageCode, targetLanguageCode, targetLanguageEnglishName, targetLanguageIso } = this.props;
    const { conjugationEntryUrl, translationEntryUrl } = this.state;
    const content = this.props.selection;
    const links = [
      { name: 'Google Translate', url: window.clozemaster.getGoogleTranslateUrl(targetLanguageIso, baseLanguageIso, content) },
      { name: 'Google Images', url: "https://www.google.com/images?q=" + encodeURI(content) },
      { name: 'Forvo', url: 'https://forvo.com/search/' + encodeURI(content) + '/' },
      { name: 'Wiktionary', url: this.getWiktionaryUrl() },
      { name: 'Tatoeba', url: 'https://tatoeba.org/eng/sentences/search?from=' + targetLanguageCode + '&to=' + baseLanguageCode + '&query=' + encodeURI(content) },
    ];

    if(targetLanguageIso === 'es') {
      links.push({ name: 'SpanishDict', url: 'https://www.spanishdict.com/translate/' + encodeURI(content) });
    }

    if(targetLanguageIso === 'he') {
      links.push({ name: 'Morfix', url: 'https://www.morfix.co.il/' + encodeURI(content) });
      links.push({ name: 'Pealim', url: 'https://www.pealim.com/search/?q=' + encodeURI(content) });
    }

    if(targetLanguageIso === 'it') {
      links.push({ name: 'Treccani', url: 'https://www.treccani.it/vocabolario/ricerca/' + encodeURI(content) + '/' });
    }

    if(targetLanguageIso === 'ja') {
      links.push({ name: 'Jisho', url: 'https://jisho.org/search/' + encodeURI(content) + '/' });
    }

    if(targetLanguageIso === 'de') {
      links.push({ name: 'dict.cc', url: 'https://www.dict.cc/?s=' + encodeURI(content) });
    }

    if(targetLanguageIso === 'jbo') {
      links.push({ name: 'la sutysisku', url: 'https://la-lojban.github.io/sutysisku/jb/#seskari=cnano&sisku=' + encodeURI(content) });
    }

    if(targetLanguageIso === 'tr') {
      links.push({ name: 'Tureng', url: 'https://tureng.com/en/turkish-english/' + encodeURI(content) });
    }

    if(translationEntryUrl) {
      links.push({ name: "Translation Entry", url: translationEntryUrl });
    }

    if(conjugationEntryUrl) {
      links.push({ name: "Conjugation Entry", url: conjugationEntryUrl });
    }

    return (
      <ul className="links list-inline">
        {links.sort((a, b) => a.name > b.name ? 1 : -1).map((link, i) => (
          <li className="link" key={link.url} style={{ paddingLeft: 0 }}>
            <a className="btn btn-link btn-xs" href={link.url} target="_blank">{link.name} <span className="glyphicon glyphicon-new-window"></span></a>
          </li>
        ))}
      </ul>
    );
  }

  getWiktionaryUrl({ mobile = false } = {}) {
    const { baseLanguageIso, selection, targetLanguageEnglishName } = this.props;
    const query = encodeURI(targetLanguageEnglishName === "German" ? selection : selection.toLowerCase());
    return 'https://' + baseLanguageIso + (mobile ? '.m' : '') + '.wiktionary.org/wiki/' + query + '#' + targetLanguageEnglishName;
  }

  getWiktionaryIframeSrc() {
    return this.getWiktionaryUrl({ mobile: true });
  }

  renderTranslations() {
    if(this.state.loadingTranslations) {
      return <p className="translations">Loading...</p>;
    }

    if(this.state.translations === false) {
      return (
        <p>
          Get automatic Google translations for any word or selected text with <a className="joystix" href="/pro">Clozemaster Pro</a>.
        </p>
      );
    }

    return (
      <p className="translations">
        <span dangerouslySetInnerHTML={{ __html: this.state.translations }} />
        <span className="attribution" style={{ display: "block" }}>
          <a href="https://translate.google.com/" target="_blank"><img src="/assets/google-translate-attribution/png/color-short-db1357358c54ba873f60dae0b7fab45f96d57c1ed7e3205853bc64be7941e279.png" alt="Color short" /></a>
        </span>
      </p>
    );
  }

  renderControlBtn(text, icon, onClick) {
    return (
      <button className="btn btn-success btn-xs btn-block joystix" onClick={onClick} style={{ marginTop: 10 }}>
        <span className={'glyphicon glyphicon-' + icon}></span> {text}
      </button>
    );
  }

  renderControls() {
    const {
      isSearchCollectionAvailable,
      onAddToCollectionClick,
      onSearchCollectionClick,
      onSearchSentencesClick
    } = this.props;

    return (
      <div className="controls" style={{ marginBottom: 10 }}>
        {!!onAddToCollectionClick && this.renderControlBtn('Add to Collection', 'plus', () => onAddToCollectionClick())}
        {!!isSearchCollectionAvailable && this.renderControlBtn('Collection Sentences', 'search', () => onSearchCollectionClick())}
        {this.renderControlBtn((!!isSearchCollectionAvailable ? 'All ' : '') + 'Sentences', 'search', () => onSearchSentencesClick())}
      </div>
    );
  }

  // renderWiktionaryEntry() {
  //   const { wiktionaryEntry } = this.state;

  //   if(!wiktionaryEntry) {
  //     return null;
  //   }

  //   return (
  //     <div className="text-left wiktionary-entry">
  //       <div className="definitions">
  //         {wiktionaryEntry.map((e) => (
  //           <div>
  //             {e.definitions.map((d) => (
  //               <p>
  //                 <strong>{d.text[0]}</strong> <em>{d.partOfSpeech}</em>
  //                 <ul>
  //                   {d.text.slice(1).map((t) => <li>{t}</li>)}
  //                 </ul>
  //               </p>
  //             ))}
  //           </div>
  //         ))}
  //       </div>
  //       <div className="text-right">
  //         <small>
  //           From <a href={this.getWiktionaryUrl()} target="_blank">Wiktionary <span className="glyphicon glyphicon-new-window"></span></a>
  //         </small>
  //       </div>
  //     </div>
  //   );
  // }

  renderAudioControl() {
    const { tokenText, ttsAvailable } = this.state;

    let handler = null;

    if(tokenText && tokenText.ttsUrl) {
      handler = () => {
        const audio = new Audio(tokenText.ttsUrl);
        audio.play();
      };
    }
    else if(ttsAvailable) {
      handler = () => this.playTts();
    }
    else {
      return null;
    }

    return (
      <button className="btn btn-default btn-xs" onClick={handler} style={{ marginLeft: 5 }}><Icon name="volume-up" /></button>
    );
  }

  renderToken() {
    const { token } = this.state;

    return (
      <SelectionToken
        token={token}
      />
    );
  }

  renderTokenText() {
    const { tokenText } = this.state;

    return (
      <SelectionTokenText tokenText={tokenText} />
    );
  }

  render() {
    const { onHidden } = this.props;
    const { wiktionaryIframeVisible } = this.state;
    return (
      <Slideout className="selection-slideout" header={false} onHidden={onHidden} ref={(el) => this.slideout = el}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
          <div className="selection"><strong style={{ fontSize: "2em", verticalAlign: "middle" }}>{this.props.selection}</strong>{this.renderAudioControl()}</div>
          <button type="button" className="close" aria-label="Close" onClick={() => this.slideout && this.slideout.hide()}><span aria-hidden="true">&times;</span></button>
        </div>
        {this.renderLinks()}
        {this.renderTranslations()}
        {this.renderToken()}
        {this.renderTokenText()}
        <div style={{ alignItems: "flex-end", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <button className="btn btn-xs btn-default" onClick={() => this.setState({ wiktionaryIframeVisible: !wiktionaryIframeVisible })}><Icon name={`collapse-${wiktionaryIframeVisible ? "up" : "down"}`} /> Wiktionary</button>
        </div>
        {wiktionaryIframeVisible && <iframe src={this.getWiktionaryIframeSrc()} style={{ border: "1px solid #000", flex: 1, marginTop: 10, minHeight: 300, width: "100%" }} />}
        {this.renderControls()}
        {this.props.switchToPopover && (
          <div style={{ marginTop: 10 }}>
            <button className="btn btn-link btn-xs" onClick={this.props.switchToPopover}><Icon name="arrow-left" /> Use Popover</button>
          </div>
        )}
      </Slideout>
    );
  }
}
