import React from "react";

export default class TokensEditorToken extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({ loading: false }, this.getTokenStateFromProps());

    this.handleChange = (e) => {
      const { target } = e;
      const { name, selectedOptions, value } = target;

      if(name === "morphologicalFeatureIds") {
        const values = Array.from(selectedOptions, (option) => parseInt(option.value));
        this.setState({
          [name]: values
        });
      }
      else {
        this.setState({
          [name]: ("" + value).match(/^\d+$/) ? parseInt(value) : value
        });
      }
    };
  }

  getTokenStateFromProps() {
    const {
      index,
      lemma,
      morphologicalFeatures,
      posTag,
      pronunciation,
      text
    } = this.props.token;

    return {
      index,
      lemmaPronunciation: (lemma || {}).pronunciation || "",
      lemmaText: (lemma || {}).text || "",
      morphologicalFeatureIds: (morphologicalFeatures || []).map((f) => f.id),
      posTagId: (posTag || {}).id || "",
      pronunciation: pronunciation || "",
      text: text || "",
    };
  }

  onSubmit(e) {
    e.preventDefault();

    const { onSave, token, tokensUrl } = this.props;
    const {
      index,
      lemmaPronunciation,
      lemmaText,
      morphologicalFeatureIds,
      posTagId,
      pronunciation,
      text
    } = this.state;

    this.setState({ loading: true });

    const exists = !!token.url;

    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        lemma: {
          pronunciation: lemmaPronunciation,
          text: lemmaText
        },
        token: {
          index,
          morphological_feature_ids: morphologicalFeatureIds,
          pos_tag_id: posTagId,
          pronunciation,
          text
        }
      }),
      method: exists ? "put" : "post",
      url: exists ? token.url : tokensUrl
    })
      .done((data) => {
        if(data.errors) {
          alert(data.errors);
        }
        else {
          onSave({ token: data.token, uuid: token.uuid });
        }
        this.setState({ loading: false });
      })
      .fail(() => {
        alert("Error saving token! Please tell someone.");
      });
  }

  resetToken(e) {
    e.preventDefault();
    if(confirm("Are you sure? This will reset the fields to the last saved values.")) {
      this.setState(
        Object.assign(this.state, this.getTokenStateFromProps())
      );
    }
    return false;
  }

  deleteToken(e) {
    e.preventDefault();

    if(!confirm("Are you sure? This action cannot be undone.")) {
      return false;
    }

    this.setState({ loading: true });

    const { onDelete, token } = this.props;
    console.log(token);

    if(!token.url) {
      onDelete();
    }

    $.ajax({
      method: "delete",
      url: token.url
    })
      .done(() => {
        onDelete();
      })
      .fail(() => {
        alert("Error deleting token! Please tell someone.");
      });

    return false;
  }

  render() {
    const {
      index,
      lemmaPronunciation,
      lemmaText,
      loading,
      morphologicalFeatureIds,
      posTagId,
      pronunciation,
      text
    } = this.state;

    const {
      morphologicalFeatures,
      posTags,
      token
    } = this.props;

    const handleChange = this.handleChange;

    return (
      <form className="token" onSubmit={(e) => this.onSubmit(e)} style={{ display: "inline-block", paddingRight: 10, verticalAlign: "top", whiteSpace: "normal", width: 250 }}>
        <div className="form-group">
          <label htmlFor="index">Index</label>
          <input className="form-control" disabled={loading} name="index" onChange={handleChange} type="number" value={index} />
        </div>
        <div className="form-group">
          <label htmlFor="text">Text</label>
          <input className="form-control" disabled={loading} name="text" onChange={handleChange} type="text" value={text} />
        </div>
        <div className="form-group">
          <label htmlFor="pronunciation">Pronunciation</label>
          <input className="form-control" disabled={loading} name="pronunciation" onChange={handleChange} type="text" value={pronunciation} />
        </div>
        <div className="form-group">
          <label htmlFor="posTagId">Part-of-Speech</label>
          <select className="form-control" disabled={loading} name="posTagId" onChange={handleChange} value={posTagId}>
            <option value=""></option>
            {posTags.map((t) => (<option key={t.id} value={t.id}>{t.key} ({t.label})</option>))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="morphologicalFeatureIds">Morphological Features</label>
          <p style={{ minHeight: 20, overflowY: "scroll", whiteSpace: "nowrap" }}>{morphologicalFeatures.filter((f) => morphologicalFeatureIds.indexOf(f.id) >= 0).map((f) => <span key={f.id}><span className="label label-default">{f.key} - {f.value}</span> </span>)}</p>
          <select className="form-control" disabled={loading} multiple={true} name="morphologicalFeatureIds" onChange={handleChange} style={{ height: 250, overflow: "auto" }} value={morphologicalFeatureIds}>
            {morphologicalFeatures.map((f) => (<option key={f.id} value={f.id}>{f.key} ({f.keyLabel}) - {f.value} ({f.valueLabel})</option>))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="lemmaText">Lemma Text</label>
          <input className="form-control" disabled={loading} name="lemmaText" onChange={handleChange} type="text" value={lemmaText} />
        </div>
        <div className="form-group">
          <label htmlFor="lemmaPronunciation">Lemma Pronunciation</label>
          <input className="form-control" disabled={loading} name="lemmaPronunciation" onChange={handleChange} type="text" value={lemmaPronunciation} />
        </div>
        <p>
          <button className="btn btn-success btn-block joystix" disabled={loading}>Save</button>
          <a className="btn btn-link btn-xs" disabled={loading} href="#" onClick={(e) => this.resetToken(e)} role="button">Reset</a>
          <small> / </small>
          <a className="btn btn-link btn-xs" disabled={loading} href="#" onClick={(e) => this.deleteToken(e)} role="button">Delete</a>
        </p>
        <div><strong><small>JSON (for debugging, updates after save, fine to ignore)</small></strong></div>
        <pre style={{ height: 300, overflow: "auto", width: "100%" }}>{JSON.stringify(token, null, 2)}</pre>
      </form>
    );
  }
}
