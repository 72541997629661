import React from "react";

import FlagSprite from "../FlagSprite";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class DeleteLanguagePairingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  deleteLanguagePairing() {
    const { languagePairingUrl, onDeleteLanguagePairing } = this.props;
      
    onDeleteLanguagePairing();

    this.modal.hide();

    $.ajax({
      method: 'delete',
      url: languagePairingUrl
    })
      .done(() => {
        window.location.href = '/dashboard';
      })
      .fail(() => {
        alert('Oh no! There was an error. Sorry about that. Please refresh the page and try again.');
      });
  }

  onDeleteClick() {
    if(confirm('Are you sure? THIS WILL PERMANENTLY DELETE *ALL* YOUR PROGRESS FOR LEARNING ' + this.props.targetLanguageName + ' FROM ' + this.props.baseLanguageName + '.')) {
      this.deleteLanguagePairing();
    }
  }

  render() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        id="delete-language-pairing-modal"
        ref={(el) => this.modal = el}
        show={false}
        size="small"
        title={<span><FlagSprite flagIso={targetLanguageFlagIso} size={24} /> / <FlagSprite flagIso={baseLanguageFlagIso} size={24} /></span>}
      >
        <div className="alert alert-danger" style={{ marginBottom: 0 }}>
          <h4 style={{ marginTop: 0 }}>Delete {targetLanguageName}</h4>
          <p style={{ marginBottom: 10 }}><strong>Warning!</strong> This will delete <strong><u>all</u></strong> your progress for learning {targetLanguageName} from {baseLanguageName}. <strong><u>This action is permanent and cannot be undone.</u></strong></p>
          <button className="btn btn-danger btn-block" onClick={() => this.onDeleteClick()}>Delete {targetLanguageName}</button>
        </div>
      </Modal>
    );
  }
}

