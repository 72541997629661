import React from "react";
import ReactDOM from "react-dom";

import moment from 'moment';

import { collectionPctStr } from "../helpers";

import Ad from "./Ad";
import AddFileToCollectionModal from "./Dashboard/AddFileToCollectionModal";
import AddToCollectionModal from "./Dashboard/AddToCollectionModal";
import AddSentenceToCollectionModal from "./AddSentenceToCollectionModal";
import AddClozeFromWordsToCollectionModal from "./Dashboard/AddClozeFromWordsToCollectionModal";
import AddTextToCollectionModal from "./Dashboard/AddTextToCollectionModal";
import ChallengeFriendsPanel from "./Dashboard/ChallengeFriendsPanel";
import ClozeListeningCollectionComponent from "./Dashboard/ClozeListeningCollectionComponent";
import ClozeListeningPanel from "./Dashboard/ClozeListeningPanel";
import ClozeSentenceSearchModal from "./ClozeSentenceSearchModal";
import CollectionGroupingsPanel from "./Dashboard/CollectionGroupingsPanel";
import CollectionsModalTable from "./Dashboard/CollectionsModalTable";
import CollectionPanel from "./Dashboard/CollectionPanel";
import CollectionProgressBar from "./Dashboard/CollectionProgressBar";
import Core1kCollectionComponent from "./Dashboard/Core1kCollectionComponent";
import DailyGoalPanel from "./DailyGoalPanel";
import DailyReminderPanel from "./DailyReminderPanel";
import DeleteLanguagePairingModal from "./Dashboard/DeleteLanguagePairingModal";
import DownloadFavoritesModal from "./Dashboard/DownloadFavoritesModal";
import FadeIn from "./FadeIn";
import FavoritesModal from "./Dashboard/FavoritesModal";
import FastTrackComponent from "./Dashboard/FastTrackComponent";
import FastTrackPanel from "./Dashboard/FastTrackPanel";
import FastTrackV2Panel from "./Dashboard/FastTrackV2Panel";
import FlagSprite from "./FlagSprite";
import FollowClozemasterPanel from "./Dashboard/FollowClozemasterPanel";
import FrequencyCollectionsComponent from "./Dashboard/FrequencyCollectionsComponent";
import FullHistoryBtn from "./Dashboard/FullHistoryBtn";
import GoogleAdPanel from "./Dashboard/GoogleAdPanel";
import GooglePanelsAd from "./Dashboard/GooglePanelsAd";
import GoogleRightSidebarAd from "./Dashboard/GoogleRightSidebarAd";
import GrammarCollectionsComponent from "./Dashboard/GrammarCollectionsComponent";
import Icon from "./Icon";
import LanguagePairingSettingsModal from "./Dashboard/LanguagePairingSettingsModal";
import LeaderboardStatPanel from "./Dashboard/LeaderboardStatPanel";
import Loading from "./Loading";
import LoadingOverlay from "./LoadingOverlay";
import ManageCollectionModal from "./ManageCollectionModal";
import MasteredIcon from "./MasteredIcon";
import Modal from "./Modal";
import ModalFooterCloseBtn from "./ModalFooterCloseBtn";
import MoreStatsModal from "./Dashboard/MoreStatsModal";
import MyCollectionsPanel from "./Dashboard/MyCollectionsPanel";
import Onboarding from "./Onboarding";
import PlayingIcon from "./PlayingIcon";
import PlayOptionsModal from "./PlayOptionsModal";
import RandomCollectionComponent from "./Dashboard/RandomCollectionComponent";
import ResetProgressModal from "./Dashboard/ResetProgressModal";
import ReviewSettingsModal from "./Dashboard/ReviewSettingsModal";
import ReviewStatPanel from "./Dashboard/ReviewStatPanel";

function getCurrentDateStr() {
  const date = new Date();

  // Format day/month/year to two digits
  const formattedDate = ('0' + date.getDate()).slice(-2);
  const formattedMonth = ('0' + (date.getMonth() + 1)).slice(-2);
  const formattedYear = date.getFullYear().toString().substr(2,2);

  return [formattedYear, formattedMonth, formattedDate].join('-');
}

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      aboutToPlayCollection: null,
      aboutToPlayScope: null,
      addSentenceToCollectionVisible: false,
      favoritesModalVisible: false,
      managingCollection: null,
      managingCollectionScope: null,
      moreStatsVisible: false,
      reviewModalVisible: false,
      reviewSettingsModalVisible: false
    };
  }

  getCollectionsStats(collections) {
    let numPlaying = 0;
    let numMastered = 0;
    let numReadyForReview = 0;
    let numFavorited = 0;

    for(let i = 0, n = collections.length; i < n; i++) {
      numPlaying += collections[i].numPlaying;
      numMastered += collections[i].numMastered;
      numReadyForReview += collections[i].numReadyForReview;
      numFavorited += collections[i].numFavorited;
    }

    return {
      numFavorited,
      numMastered,
      numPlaying,
      numReadyForReview
    };
  }

  componentDidMount() {
    const $dashboard = $('#dashboard');

    $dashboard.popover({
      selector: '[data-toggle="popover"]'
    });
    $dashboard.on('show.bs.popover', '[data-toggle="popover"]', function(e) {
      $('[data-toggle="popover"]').not(this).popover('destroy');
    });
    $dashboard.on('inserted.bs.popover', '[data-toggle="popover"]', function(e) {
      $('.popover .popover-title').append(
        '<button type="button" class="close" data-dismiss="popover" aria-label="Close" style="margin-top: -4px"><span aria-hidden="true">&times;</span></button>'
      );
    });
    $dashboard.on('click', '.popover .close', () => {
      $('[data-toggle="popover"]').popover('destroy');
    });

    $('#dashboard').tooltip({ selector: '[data-toggle="tooltip"]' });

    // run check to load voices
    window.clozemaster.systemTtsAvailable(this.props.targetLanguageIso, this.props.targetLanguageCode)

    this.loadLanguagePairing();
  }

  loadLanguagePairing() {
    this.setState({ loading: true });
    const {
      includeFastTrackV2Collections,
      languagePairingUrl
    } = this.props;

    $.ajax({
      data: { include_fast_track_v2_collections: includeFastTrackV2Collections },
      url: languagePairingUrl
    }).done((data) => {
      const { collectionGroupings, collections, languagePairing, user } = data;
      console.log(data);

      const {
        clozeFromWordsUrl,
        clozeListening,
        clozeSentencesUrl,
        collectionFileImportUrl,
        collectionsUrl,
        currentLevelPoints,
        currentStreakDays,
        currentStreakIncludesToday,
        currentWeekLeaderboardRank,
        dailyGoalHoursLeftToday,
        dailyGoalPointsPerDay,
        dailyGoalStreak,
        dailyGoalUrl,
        dailyReminderEmail,
        dailyReminderEmailUrl,
        dailyStatsWebUrl,
        gameSettingsUrl,
        leaderboardsUrl,
        level,
        listeningTrialExpired,
        moreStatsUrl,
        nextLevelPoints,
        nextReviewByLevel,
        numPointsToday,
        past7DaysNewReview,
        playClozeListeningWebUrl,
        playOptions,
        playWebUrl,
        prevWeekLeaderboardRank,
        progressPerChunk,
        quickClozeUrl,
        rankingsUrl,
        resetProgressUrl,
        reviewSettingsUrl,
        score,
        sharedCollectionsUrl,
        speakingTrialExpired,
        useProgressChunks
      } = languagePairing;

      const { isSignedIn } = this.props;
      // this should only ever happen once after signing up
      if(isSignedIn && !user.hasRunPlayTutorial && !!window.localStorage.getItem("hasRunPlayTutorial")) {
        this.saveHasRunPlayTutorial();
      }

      this.setState(Object.assign(this.getCollectionsStats(collections), {
        clozeFromWordsUrl,
        clozeListening,
        clozeSentencesUrl,
        collectionFileImportUrl,
        collectionGroupings,
        collections,
        collectionsUrl,
        currentLevelPoints,
        currentStreakDays,
        currentStreakIncludesToday,
        currentWeekLeaderboardRank,
        dailyGoalHoursLeftToday,
        dailyGoalPointsPerDay,
        dailyGoalStreak,
        dailyGoalUrl,
        dailyReminderEmail,
        dailyReminderEmailUrl,
        dailyStatsUrl: dailyStatsWebUrl,
        gameSettingsUrl,
        leaderboardsUrl,
        level,
        listeningTrialExpired,
        loading: false,
        moreStatsUrl,
        nextLevelPoints,
        nextReviewByLevel,
        numPointsToday,
        past7DaysNewReview,
        playClozeListeningUrl: playClozeListeningWebUrl,
        playLanguagePairingWebUrl: playWebUrl,
        playOptions,
        prevWeekLeaderboardRank,
        progressPerChunk,
        quickClozeUrl,
        rankingsUrl,
        resetProgressUrl,
        reviewSettingsUrl,
        score,
        sharedCollectionsUrl,
        speakingTrialExpired,
        useProgressChunks,
        user
      }), () => this.initPast7DaysChart());
    });
  }

  initPast7DaysChart() {
    const { past7DaysNewReview } = this.state;
    const dates = [];
    const newCounts = [];
    const reviewCounts = [];

    past7DaysNewReview.forEach((d) => {
      dates.push(moment(d.date, "YYYY-MM-DD").format('ddd MMM D'));
      newCounts.push(d.numNew);
      reviewCounts.push(d.numReview);
    });

    const ctx = this.past7DaysCanvas.getContext('2d');
    const data = {
      labels: dates,
      datasets: [
        {
          label: "New",
          fillColor: "rgba(20,220,20,0.2)",
          strokeColor: "rgba(20,220,20,1)",
          pointColor: "rgba(20,220,20,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(20,220,20,1)",
          data: newCounts
        },
        {
          label: "Reviewed",
          fillColor: "rgba(151,187,205,0.5)",
          strokeColor: "rgba(151,187,205,1)",
          pointColor: "rgba(151,187,205,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(151,187,205,1)",
          data: reviewCounts
        }
      ]
    };
    const chart = new Chart(ctx).Line(data, { multiTooltipTemplate: "<%= datasetLabel %>: <%= value %>", });
  }

  getPast7DaysNewReviewAvg(attr) {
    const avg = this.state.past7DaysNewReview.reduce((n, d) => n += d[attr], 0) / 7.0;
    return Math.round(avg * 10) / 10;
  }

  saveHasRunPlayTutorial() {
    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        user: { has_run_play_tutorial: true }
      }),
      method: "put",
      url: "/api/v1/users"
    });
  }

  renderStats() {
    const state = this.state;
    const { isSignedIn } = this.props;

    return (
      <div className="stats-panels-container">
        <div className="stats-panels-table">
          <div className="stats-panels">
            <div className="stat-panel score">
              <div className="title">Score</div>
              <div className="value">{state.score.toLocaleString()}</div>
              <div>
                <small>
                  <PlayingIcon style={{ marginRight: 3 }} />
                  Playing: <strong>{state.numPlaying.toLocaleString()}</strong>
                </small>
              </div>
              <div>
                <small>
                  <MasteredIcon style={{ marginRight: 3 }} />
                  Mastered: <strong>{state.numMastered.toLocaleString()}</strong>
                </small>
              </div>
            </div>
            <div className="stat-panel">
              <div className="title">
                Level <span className="glyphicon glyphicon-question-sign" data-toggle="tooltip" title="Score more points to level up"></span>
              </div>
              <div className="value">{state.level}</div>
              <div className="level-progress">
                <div className="progress" style={{ height: '10px', marginTop: '0px', marginBottom: '4px' }}>
                  <div className="progress-bar progress-bar-success progress-bar-striped" style={{ width: (((state.score - state.currentLevelPoints) / (state.nextLevelPoints - state.currentLevelPoints)) * 100) + '%' }}></div>
                </div>
              </div>
              <small style={{ display: 'block', lineHeight: 1.1 }}>
                {(state.nextLevelPoints - state.score).toLocaleString()} more points to level {state.level + 1}
              </small>
            </div>
            <div className={'stat-panel streak' + (state.currentStreakDays === 0 ? ' zero' : '') }>
              <div className="title">
                Streak <small style={{ fontWeight: "normal" }}><a href="/settings#timezone">{this.props.timeZone}</a></small>
              </div>
              <div className="value">
                {state.currentStreakDays} Day{state.currentStreakDays === 1 ? '' : 's'}
                {state.currentStreakIncludesToday ? <span className="glyphicon glyphicon-ok played-today" title="Streak includes today" data-toggle="tooltip"></span> : ''}
              </div>
              <div className="averages">
                <div className="title">
                  7-day Av<span className="hidden-sm hidden-md">era</span>g<span className="hidden-sm hidden-md">e</span>
                  <span className="glyphicon glyphicon-question-sign" style={{ marginLeft: 4 }} data-toggle="tooltip" title="Average number of sentences played per day for the last 7 days"></span>
                </div>
                <span className="average average-new" data-toggle="tooltip" title="New sentences">
                  <small className="glyphicon glyphicon-plus"></small>
                  <span className="count" style={{ marginLeft: 4 }}>{this.getPast7DaysNewReviewAvg('numNew')}</span>
                </span>
                <span className="average average-review" data-toggle="tooltip" title="Review sentences">
                  <small className="glyphicon glyphicon-refresh"></small>
                  <span className="count" style={{ marginLeft: 4 }}>{this.getPast7DaysNewReviewAvg('numReview')}</span>
                </span>
              </div>
            </div>
            <ReviewStatPanel
              collections={state.collections}
              numReadyForReview={state.numReadyForReview}
              onPlay={(collection) => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: 'ready_for_review' })}
              onReviewSettingsBtnClick={this.onReviewSettingsBtnClick.bind(this)}
              playLanguagePairingUrl={state.playLanguagePairingWebUrl}
            />
            <LeaderboardStatPanel
              baseLanguageFlagIso={this.props.baseLanguageFlagIso}
              currentWeekLeaderboardRank={state.currentWeekLeaderboardRank}
              isSignedIn={isSignedIn}
              leaderboardsUrl={state.leaderboardsUrl}
              prevWeekLeaderboardRank={state.prevDayRanking}
              ranking={state.ranking}
              rankingsUrl={state.rankingsUrl}
              targetLanguageFlagIso={this.props.targetLanguageFlagIso}
              useRankingsForLeaderboard={this.props.useRankingsForLeaderboard}
            />
            {/*
            <div className="stat-panel review">
              <div className="title">Ready for Review</div>
              <div className="value">{state.numReadyForReview.toLocaleString()}</div>
              <button className="btn btn-primary btn-block joystix review default" disabled={!state.numReadyForReview}>
                Review
                <Icon name="chevron-right" />
              </button>
              <small>
                <a href="javascript:void(0);" data-toggle="popover" data-trigger="focus" data-html="true" title="Reviews" data-placement="auto right" data-content={ "Clozemaster uses a <a href=\"https://en.wikipedia.org/wiki/Spaced_repetition\" target=\"_blank\">spaced repetition system</a> to help you remember what you learn. Intervals are set to 1 day (25% Mastered), 10 days (50% Mastered), 30 days (75% Mastered), and 180 days (100% Mastered) by default. <a href=\"/pro\">Clozemaster Pro</a> users can customize the intervals. You can also manually master or reset sentences while playing." }>
                  How reviews work
                </a>
              </small>
            </div>
            <div className="stat-panel leaderboard">
              <div className="title">Leaderboard</div>
              <div className="value">
                {state.ranking ? '' : 'N/A'}
                {state.ranking && state.prevDayRanking ?
                  <span className={'glyphicon trend glyphicon-arrow-' + (state.ranking > state.prevDayRanking ? 'up uptrend' : 'down downtrend')} data-toggle="tooltip" title={'Ranked ' + state.prevDayRanking + ' yesterday UTC'}></span>
                  : null
                }
              </div>
              <small>This week</small>
              <button className="btn btn-xs btn-default btn-block" data-toggle="modal" data-target=".challenge-leaderboard.modal">View</button>
            </div>
            */}
            <div className="stat-panel favorites">
              <div className="title">Favorites</div>
              <div className="value">{state.numFavorited.toLocaleString()}</div>
              <button onClick={() => this.openFavoritesModal('play')} className="btn btn-success btn-xs joystix btn-block play" disabled={!state.numFavorited} style={{ fontSize: '11px', padding: 0 }}>
                Play <span className="glyphicon glyphicon-chevron-right"></span>
              </button>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', paddingRight: '3px' }}>
                      <button onClick={() => this.openFavoritesModal('view')} disabled={!state.numFavorited} className="btn btn-default btn-xs btn-block view" style={{ padding: 0, marginTop: '4px' }}>View</button>
                    </td>
                    <td style={{ width: '50%', paddingLeft: '3px' }}>
                      <span data-toggle="tooltip" data-placement="bottom" title="Download">
                        <button onClick={() => this.openFavoritesModal('download')} disabled={!state.numFavorited} className="btn btn-primary btn-xs btn-block download" style={{ padding: 0, marginTop: '4px' }}>
                          <span className="glyphicon glyphicon-cloud-download download"></span>
                        </button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  openFavoritesModal(action) {
    this.setState({ favoritesModalVisible: action });
  }

  renderReviewModal() {
    const { reviewModalVisible, collections } = this.state;
    // TODO! aboutToPlayScope = 'ready_for_review'
  }

  renderDownloadFavoritesModal() {
    const { downloadFavoritesCollection, user } = this.state;

    if(!downloadFavoritesCollection) {
      return null;
    }

    return (
      <DownloadFavoritesModal
        collection={downloadFavoritesCollection}
        isPro={user.isPro}
        onHidden={() => this.setState({ downloadFavoritesCollection: null })}
      />
    );
  }

  renderFavoritesModal() {
    const { collections, favoritesModalVisible, playLanguagePairingWebUrl, user } = this.state;

    if(!favoritesModalVisible) {
      return null;
    }

    return (
      <FavoritesModal
        action={favoritesModalVisible}
        collections={collections}
        isPro={user.isPro}
        onDownload={(collection) => this.favoritesModal.hide({
          onHidden: () => this.setState({ downloadFavoritesCollection: collection })
        })}
        onHidden={() => this.setState({ favoritesModalVisible: false })}
        onPlay={(collection) => this.favoritesModal.hide({
          onHidden: () => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: 'favorited' })
        })}
        onView={(collection) => this.favoritesModal.hide({
          onHidden: () => this.setState({ managingCollection: collection, managingCollectionScope: 'favorited' })
        })}
        playLanguagePairingUrl={playLanguagePairingWebUrl}
        ref={(e) => this.favoritesModal = e}
      />
    );
  }

  renderAnonPlayPanel() {
    if(this.props.isSignedIn) {
      return null;
    }

    if(this.props.simpleAnonPlayPanel) {
      return (
        <div className="alert alert-success anon-play text-center" style={{ marginBottom: 10 }}>
          <h2>Get in the game, track your progress, and more!</h2>
          <a href="/sign-up" className="btn btn-success joystix btn-lg" style={{ minWidth: 280 }}>Sign up</a>
        </div>
      );
    }

    return (
      <p className="alert alert-success anon-play">
        <h2>
          <a href="/sign-up" className="btn btn-success joystix">Sign up</a> or <a href="/login" className="btn btn-success joystix">Login</a> to get in the game, track your progress, and more! Or try out Clozemaster by playing random sentences in anonymous mode.
          <button className="btn btn-success btn-lg btn-block joystix" onClick={() => this.setState({ aboutToPlayCollection: { name: "Random", playWebUrl: this.state.playLanguagePairingWebUrl } })} style={{ marginTop: 10 }}>Play <Icon name="chevron-right" /></button>
        </h2>
      </p>
    );
  }

  renderCore1kCollection() {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const core1kCollection = collections.find((c) => c.type === "Core1kCollection");

    if(!core1kCollection) {
      return null;
    }

    return (
      <CollectionPanel
        className="core1k-collection"
        collection={core1kCollection}
        fallbackDescription="Play a collection of the most useful sentences and vocabulary you need to know."
        isSignedIn={isSignedIn}
        manage={() => this.setState({ managingCollection: core1kCollection })}
        play={() => this.setState({ aboutToPlayCollection: core1kCollection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderFastTrackPanel({ legacy = false } = {}) {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const fastTrackCollection = collections.find((c) => c.type === 'FastTrackCollection');

    if(!fastTrackCollection) {
      return null;
    }

    return (
      <FastTrackPanel
        className="fast-track"
        collection={fastTrackCollection}
        fallbackDescription={`Play ${fastTrackCollection.numSentences.toLocaleString()} sentences with ${fastTrackCollection.numSentences.toLocaleString()} unique cloze-words in order of difficulty.`}
        helpText="Fast and efficient language learning. Each word in each sentence is matched against a frequency list (a list of words ordered by how often they're likely to occur). The most difficult (least common) word is then selected as the missing word. The Fast Track has one sentence for each missing word, and the sentences are played in order of difficulty, so you're always making progress."
        isSignedIn={isSignedIn}
        manage={() => this.setState({ managingCollection: fastTrackCollection })}
        name={legacy ? "Legacy Fast Track" : fastTrackCollection.name}
        panelId={legacy ? "legacy-fast-track" : null}
        play={() => this.setState({ aboutToPlayCollection: fastTrackCollection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderLegacyFastTrack() {
    const { collections } = this.state;
    const fastTrackV2Collections = collections.filter((c) => c.type === "FastTrackV2Collection");
    if(fastTrackV2Collections.length) {
      return this.renderFastTrackPanel({ legacy: true });
    }
  }

  renderMainPanel() {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const core1kCollection = collections.find((c) => c.type === "Core1kCollection");
    const fastTrackV2Collections = collections.filter((c) => c.type === "FastTrackV2Collection");
    const fastTrackCollection = collections.find((c) => c.type === 'FastTrackCollection');
    const randomCollection = collections.find((c) => c.type === 'RandomCollection');

    if(fastTrackV2Collections.length) {
      // render these
      // then core1k if present
      return (
        <>
          <FastTrackV2Panel
            collections={fastTrackV2Collections}
            hasFastTrackV1Progress={!!fastTrackCollection && fastTrackCollection.numPlaying > 0}
            isSignedIn={isSignedIn}
            manage={(collection) => this.setState({ managingCollection: collection })}
            play={(collection, scope) => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: scope })}
            progressPerChunk={progressPerChunk}
            useProgressChunks={useProgressChunks}
          />
          {this.renderCore1kCollection()}
        </>
      );
    }

    if(core1kCollection || fastTrackCollection) {
      // <FastTrackComponent
      //   fastTrackCollection={fastTrackCollection}
      //   isSignedIn={isSignedIn}
      //   manage={(collection) => this.setState({ managingCollection: collection })}
      //   play={(collection) => this.setState({ aboutToPlayCollection: collection })}
      //   progressPerChunk={progressPerChunk}
      //   useProgressChunks={useProgressChunks}
      // />
      return (
        <>
          {this.renderFastTrackPanel()}
          {this.renderCore1kCollection()}
        </>
      );
    }

    return (
      <CollectionPanel
        className="random-collection"
        collection={randomCollection}
        fallbackDescription={`Play ${randomCollection.numSentences.toLocaleString()} sentences in random order.`}
        isSignedIn={isSignedIn}
        manage={() => this.setState({ managingCollection: randomCollection })}
        play={() => this.setState({ aboutToPlayCollection: randomCollection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );

    // return (
    //   <RandomCollectionComponent
    //     collection={randomCollection}
    //     isSignedIn={isSignedIn}
    //     manage={() => this.setState({ managingCollection: randomCollection })}
    //     play={() => this.setState({ aboutToPlayCollection: randomCollection })}
    //     progressPerChunk={progressPerChunk}
    //     useProgressChunks={useProgressChunks}
    //   />
    // );
  }

  renderFrequencyCollectionsPanel() {
    const { collections, playLanguagePairingWebUrl, progressPerChunk, useProgressChunks, user } = this.state;
    const frequencyCollections = collections.filter((c) => c.type === 'FrequencyCollection');
    if(!frequencyCollections.length) {
      return null;
    }
    return (
      <FrequencyCollectionsComponent
        collections={frequencyCollections}
        isSignedIn={this.props.isSignedIn}
        manage={(collection) => this.setState({ managingCollection: collection })}
        play={(collection, scope) => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: scope })}
        playLanguagePairingWebUrl={playLanguagePairingWebUrl}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderGrammarCollectionsPanel() {
    const { collections, progressPerChunk, useProgressChunks, user } = this.state;
    const grammarCollections = collections.filter((c) => c.type === 'GrammarCollection');
    if(!grammarCollections.length) {
      return null;
    }
    return (
      <GrammarCollectionsComponent
        collections={grammarCollections}
        isPro={user.isPro}
        isSignedIn={this.props.isSignedIn}
        manage={(collection) => this.setState({ managingCollection: collection })}
        play={(collection) => this.setState({ aboutToPlayCollection: collection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderMyCollectionsPanel() {
    const { collections, sharedCollectionsUrl, progressPerChunk, useProgressChunks } = this.state;
    const myCollections = collections.filter((c) => !c.type);
    return (
      <MyCollectionsPanel
        add={(collection) => this.setState({ addingToCollection: collection, addToCollectionVisible: true })}
        collections={myCollections}
        collectionsUrl={this.state.collectionsUrl}
        isPro={this.state.user.isPro}
        isSignedIn={this.props.isSignedIn}
        manage={(collection) => this.setState({ managingCollection: collection })}
        onCollectionCreate={(collection) => {
          const collections = JSON.parse(JSON.stringify(this.state.collections));
          collections.unshift(collection);
          this.setState({ collections });
        }}
        onCollectionDelete={(collection) => {
          const collections = JSON.parse(JSON.stringify(this.state.collections));
          for(let i = 0, n = collections.length; i < n; i++) {
            if(collections[i].id === collection.id) {
              collections.splice(i, 1);
              break;
            }
          }
          this.setState({ collections });
        }}
        onCollectionUpdate={(collection) => {
          const collections = JSON.parse(JSON.stringify(this.state.collections));
          for(let i = 0, n = collections.length; i < n; i++) {
            if(collections[i].id === collection.id) {
              collections[i] = collection;
              break;
            }
          }
          this.setState({ collections });
        }}
        play={(collection) => this.setState({ aboutToPlayCollection: collection })}
        progressPerChunk={progressPerChunk}
        sharedCollectionsUrl={sharedCollectionsUrl}
        teachingUrl={this.props.teachingUrl}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderCollectionGroupingsPanel() {
    const { collectionGroupings, collections, progressPerChunk, useProgressChunks, user } = this.state;
    if(!collectionGroupings || !collectionGroupings.length) {
      return null;
    }

    return (
      <CollectionGroupingsPanel
        collectionGroupings={collectionGroupings}
        collections={collections}
        isPro={user.isPro}
        isSignedIn={this.props.isSignedIn}
        manage={(collection) => this.setState({ managingCollection: collection })}
        play={(collection) => this.setState({ aboutToPlayCollection: collection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderRandomCollection() {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const core1kCollection = collections.find((c) => c.type === "Core1kCollection");
    const fastTrackCollection = collections.find((c) => c.type === "FastTrackCollection");
    const fastTrackV2Collection = collections.find((c) => c.type === "FastTrackV2Collection");
    const randomCollection = collections.find((c) => c.type === "RandomCollection");
    if((!!core1kCollection || !!fastTrackCollection || !!fastTrackV2Collection) && !!randomCollection) {
      return (
        <CollectionPanel
          className="random-collection"
          collection={randomCollection}
          fallbackDescription={`Play ${randomCollection.numSentences.toLocaleString()} sentences in random order.`}
          isSignedIn={isSignedIn}
          manage={() => this.setState({ managingCollection: randomCollection })}
          play={() => this.setState({ aboutToPlayCollection: randomCollection })}
          progressPerChunk={progressPerChunk}
          useProgressChunks={useProgressChunks}
        />
      );
      // return (
      //   <RandomCollectionComponent
      //     collection={randomCollection}
      //     isSignedIn={isSignedIn}
      //     manage={() => this.setState({ managingCollection: randomCollection })}
      //     play={() => this.setState({ aboutToPlayCollection: randomCollection })}
      //     progressPerChunk={progressPerChunk}
      //     useProgressChunks={useProgressChunks}
      //   />
      // );
    }
  }

  renderNsfwCollection() {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const nsfwCollection = collections.find((c) => c.type === "NsfwCollection");

    if(nsfwCollection) {
      return (
        <CollectionPanel
          className="nsfw-collection"
          collection={nsfwCollection}
          fallbackDescription={"A collection of sentences with swear words, vulgarities, and other NSFW content."}
          isSignedIn={isSignedIn}
          manage={() => this.setState({ managingCollection: nsfwCollection })}
          play={() => this.setState({ aboutToPlayCollection: nsfwCollection })}
          progressPerChunk={progressPerChunk}
          useProgressChunks={useProgressChunks}
        />
      );
    }
  }

  renderPanels() {
    return (
      <div>
        {this.renderAnonPlayPanel()}
        {this.renderMainPanel()}
        {this.renderPanelsAd()}
        {this.renderFrequencyCollectionsPanel()}
        {this.renderMyCollectionsPanel()}
        {this.renderGrammarCollectionsPanel()}
        {this.renderCollectionGroupingsPanel()}
        {this.renderClozeListeningPanel()}
        {this.renderClozeReadingPanel()}
        {this.renderRandomCollection()}
        {this.renderLegacyFastTrack()}
        {this.renderNsfwCollection()}
      </div>
    );
  }

  renderClozeListeningPanel() {
    if(!this.state.playClozeListeningUrl) {
      return null;
    }

    const { clozeListening, collections, playClozeListeningUrl, progressPerChunk, useProgressChunks, user } = this.state;
    const { isSignedIn } = this.props;

    const clozeListeningCollection = collections.find((c) => c.type === "ClozeListeningCollection");

    if(clozeListeningCollection) {
      return (
        <ClozeListeningCollectionComponent
          collection={clozeListeningCollection}
          isSignedIn={isSignedIn}
          manage={() => this.setState({ managingCollection: clozeListeningCollection })}
          play={() => this.setState({ aboutToPlayCollection: clozeListeningCollection })}
          progressPerChunk={progressPerChunk}
          useProgressChunks={useProgressChunks}
        />
      );
    }

    const {
      numPlaying,
      numReadyForReview,
      uniqueListeningTimeToday,
      uniqueListeningTimePast7Days,
      uniqueListeningTimePast30Days,
      uniqueListeningTimeTotal
    } = clozeListening;

    return (
      <ClozeListeningPanel
        isPro={user.isPro}
        numPlaying={numPlaying}
        numReadyForReview={numReadyForReview}
        uniqueListeningTimeToday={uniqueListeningTimeToday}
        uniqueListeningTimePast7Days={uniqueListeningTimePast7Days}
        uniqueListeningTimePast30Days={uniqueListeningTimePast30Days}
        uniqueListeningTimeTotal={uniqueListeningTimeTotal}
        url={playClozeListeningUrl}
      />
    );
  }

  renderClozeReadingPanel() {
    const { clozeReadingPath, isSignedIn } = this.props;

    if(!clozeReadingPath) {
      return null;
    }

    return (
      <div className="panel panel-default cloze-reading">
        <div className="panel-body">
          <div className="pull-right" style={{ marginTop: 10 }}>
            <i className="fa fa-file-text-o fa-4x" aria-hidden="true" style={{ marginLeft: 4 }}></i>
          </div>
          <h2 className="title">
            Cloze-Reading
            <button className="btn btn-xs btn-link" data-content="<p>Take your literacy to the next level! Practice reading native level content on a variety of topics from Wikipedia, or create your own.</p><p>Multiple paragraphs > multiple sentences > one missing word per sentence > select the correct answer > score points.</p><p>Points are saved after playing all sentences in an article and count towards your total score, level, and streak, but don't affect your overall sentences played, mastered, or reviews.</p>" data-html="true" data-title="Cloze-Reading" data-toggle="popover" data-trigger="focus" style={{ fontSize: '65%', marginLeft: 8, textDecoration: 'none' }}>
              <span className="glyphicon glyphicon-question-sign"></span>
            </button>
            <br className="hidden-xs" />
            <small>
              Level up your reading skills with longer texts.
            </small>
            <a className="btn btn-success joystix" disabled={!isSignedIn} href={clozeReadingPath} style={{ marginLeft: 8 }}>Go
              <span className="glyphicon glyphicon-chevron-right"></span>
            </a>
          </h2>
        </div>
      </div>
    );
  }

  renderAddToCollectionModal() {
    const { addingToCollection, addToCollectionVisible, user } = this.state;

    if(!addToCollectionVisible) {
      return null;
    }

    return (
      <AddToCollectionModal
        collection={addingToCollection}
        collectionsUrl={this.state.collectionsUrl}
        isPro={user.isPro}
        onFile={() => this.addToCollectionModal.hide({ onHidden: () => this.showAddFileToCollectionModal(addingToCollection) })}
        onHidden={() => this.setState({ addingToCollection: null, addToCollectionVisible: false })}
        onManual={() => this.addToCollectionModal.hide({ onHidden: () => this.showAddSentenceToCollectionModal(addingToCollection) })}
        onSearch={() => this.addToCollectionModal.hide({ onHidden: () => this.showSentenceSearchModal(addingToCollection) })}
        onPaste={() => this.addToCollectionModal.hide({ onHidden: () => this.showAddTextToCollectionModal(addingToCollection) })}
        onWords={() => this.addToCollectionModal.hide({ onHidden: () => this.showAddClozeFromWordsToCollectionModal(addingToCollection) })}
        ref={(el) => this.addToCollectionModal = el}
      />
    );
  }

  updateMyCollections() {
    this.setState({ updating: true });
    $.ajax({
      data: { filter: 'mine' },
      url: this.state.collectionsUrl
    })
      .done((data) => {
        const updatedCollectionsHash = data.collections.reduce((h, c) => { h[c.id] = c; return h; }, {});
        let collections = this.state.collections.map((collection) => {
          if(updatedCollectionsHash[collection.id]) {
            const updatedCollection = updatedCollectionsHash[collection.id];
            delete updatedCollectionsHash[collection.id];
            return updatedCollection;
          }
          return collection;
        });
        // add any remaining collections
        collections = collections.concat(Object.values(updatedCollectionsHash));

        this.setState(Object.assign(this.getCollectionsStats(collections), {
          collections,
          updating: false
        }));
      })
      .fail(() => {
        alert('Oh no! There was an error updating the dashboard. Sorry about that. Please refresh the page and let us know if you see this message again.');
        this.setState({ updating: false });
      });
  }

  updateCollection(collection) {
    this.setState({ updating: true });
    $.ajax({
      url: collection.url
    })
      .done((data) => {
        let updated = false;
        const collections = this.state.collections.map((collection) => {
          if(collection.id === data.collection.id) {
            updated = true;
            return data.collection;
          }
          return collection;
        });

        // if it's a new collection
        if(!updated) {
          collections.push(data.collection);
        }

        this.setState(Object.assign(this.getCollectionsStats(collections), {
          collections,
          updating: false
        }));
      })
      .fail(() => {
        alert('Oh no! There was an error updating the dashboard. Sorry about that. Please refresh the page and let us know if you see this message again.');
        this.setState({ updating: false });
      });
  }

  renderManageCollectionModal() {
    const { collectionsUrl, managingCollection, managingCollectionScope, user } = this.state;
    const { baseLanguageEnglishName, targetLanguageCode, targetLanguageEnglishName, targetLanguageIso, targetLanguageName } = this.props;

    if(!managingCollection) {
      return null;
    }

    return (
      <ManageCollectionModal
        baseLanguageEnglishName={baseLanguageEnglishName}
        collectionClozeSentencesUrl={managingCollection.collectionClozeSentencesUrl}
        collectionName={managingCollection.name}
        collectionType={managingCollection.type}
        collectionsUrl={collectionsUrl}
        deleteCollectionProgressUrl={managingCollection.deleteProgressUrl}
        isCollectionEditable={managingCollection.isEditable}
        isPro={user.isPro}
        onHidden={({ hasCopiedSentences }) => {
          const { managingCollection } = this.state;

          this.setState({
            managingCollection: null,
            managingCollectionScope: null
          }, () => {
            // reload the dashboard if copied sentences between collections
            // easier to reload everything vs individual collections
            if(hasCopiedSentences) {
              this.loadLanguagePairing();
              return false;
            }
            this.updateCollection(managingCollection);
          });
        }}
        scope={managingCollectionScope}
        targetLanguageCode={targetLanguageCode}
        targetLanguageEnglishName={targetLanguageEnglishName}
        targetLanguageIso={targetLanguageIso}
        targetLanguageName={targetLanguageName}
      />
    );
  }

  showAddSentenceToCollectionModal(addSentenceToCollection) {
    this.setState({ addSentenceToCollectionVisible: true, addSentenceToCollection });
  }

  onMoreStatsBtnClick() {
    this.setState({ moreStatsVisible: true });
  }

  renderProControls() {
    if(this.state.loading) {
      return null;
    }

    const { isSignedIn } = this.props;

    return (
      <ul className="list-inline pro-controls" style={{ marginRight: -5 }}>
        <li><button className="btn btn-default btn-lg add-sentence-to-collection" disabled={!isSignedIn} onClick={() => this.showAddSentenceToCollectionModal()} data-toggle="tooltip" title="Add sentence to collection"><Icon name="plus" /></button></li>
        {/*= link_to current_user.pro_subscriber? ? more_stats_path(@language_pairing.slug) : pro_signup_path, disabled: !current_user.pro_subscriber?, class: 'btn btn-default btn-lg', data: { toggle: 'tooltip' }, title: 'More stats' do*/}
        <li><button className="btn btn-default btn-lg more-stats" disabled={!isSignedIn} onClick={() => this.onMoreStatsBtnClick()} data-toggle="tooltip" title="More stats"><Icon name="stats" /></button></li>
        {/*= link_to current_user.pro_subscriber? ? sentences_path(@language_pairing.slug) : pro_signup_path, disabled: !current_user.pro_subscriber?, class: 'btn btn-default btn-lg', data: { toggle: 'tooltip' }, title: 'Search sentences' do*/}
        <li><button id="search-sentences" disabled={!isSignedIn} onClick={() => this.onSearchBtnClick()} className="btn btn-default btn-lg" data-toggle="tooltip" title="Search sentences"><Icon name="search" /></button></li>
        {/*= link_to current_user.pro_subscriber? ? settings_path(@language_pairing.slug) : pro_signup_path, disabled: !current_user.pro_subscriber?, class: 'btn btn-default btn-lg', data: { toggle: 'tooltip', html: true }, title: '<span style="white-space: nowrap;">Review settings</span>' do*/}
        <li><button onClick={() => this.onReviewSettingsBtnClick()} disabled={!isSignedIn} className="btn btn-default btn-lg review-settings" data-toggle="tooltip" data-html="true" title="<span style='white-space: nowrap;'>Review settings</span>"><Icon name="time" /></button></li>
      </ul>
    );
  }

  onSearchBtnClick() {
    this.showSentenceSearchModal();
  }

  showSentenceSearchModal(addSentenceToCollection) {
    // TODO! if not pro, show pro promo
    this.setState({ addSentenceToCollection, sentenceSearchModalVisible: true });
  }

  onReviewSettingsBtnClick() {
    // TODO! if not pro, show pro promo
    this.setState({ reviewSettingsModalVisible: true });
  }

  renderDailyReminder() {
    return (
      <DailyReminderPanel
        dailyReminderEmail={this.state.dailyReminderEmail}
        isSignedIn={this.props.isSignedIn}
        timeZone={this.props.timeZone}
        updateUrl={this.state.dailyReminderEmailUrl}
      />
    );
  }

  renderDailyGoal() {
    const { isSignedIn, targetLanguageName, timeZone } = this.props;
    const {
      dailyGoalHoursLeftToday,
      dailyGoalPointsPerDay,
      dailyGoalStreak,
      dailyGoalUrl,
      numPointsToday
    } = this.state;

    return (
      <DailyGoalPanel
        hoursLeftToday={dailyGoalHoursLeftToday}
        isSignedIn={isSignedIn}
        numPointsToday={numPointsToday}
        pointsPerDay={dailyGoalPointsPerDay}
        streak={dailyGoalStreak}
        targetLanguageName={targetLanguageName}
        timeZone={timeZone}
        updateUrl={dailyGoalUrl}
      />
    );
  }

  renderProPromo() {
    if(this.state.user.isPro) {
      return null;
    }

    return (
      <div className="panel panel-default" style={{ background: "#464646", color: "#fff" }}>
        <div className="panel-body text-center">
          <h5 style={{ fontSize: 24, marginTop: 0 }}>Get fluent faster.</h5>
          <a href="/pro" className="btn joystix btn-block btn-attention">
            Clozemaster Pro
            <Icon name="chevron-right" className="hidden-md" />
          </a>
        </div>
      </div>
    );
  }

  renderGiftPro() {
    if(this.props.giftsDisabled) {
      return null;
    }

    return (
      <div className="panel panel-default gift-pro clozemaster">
        <div className="panel-heading joystix">
          Gift Pro!
        </div>
        <div className="panel-body text-center">
          <p style={{ fontSize: '1.25em' }}>Send Clozemaster Pro as a gift and get a free month!</p>
          <a className="btn btn-attention joystix btn-block" href="/gift-pro">Learn More
            <span className="glyphicon glyphicon-chevron-right"></span>
          </a>
        </div>
      </div>
    );
  }

  renderLatestBlogPost() {
    return (
      <div className="panel panel-default clozemaster">
        <div className="panel-heading joystix">
          Blog
        </div>
        <div className="panel-body text-center">
          <strong>Latest blog post:</strong>
          <div style={{ fontSize: '1.25em' }}>
            <a target="_blank" href={this.props.latestBlogPostUrl}>{this.props.latestBlogPostTitle}</a>
          </div>
        </div>
      </div>
    );
  }

  renderChallengeFriends() {
    return null;

    // // just skipping the challenge friends if anon for now
    // // should probably add some kind of way to share later
    // // or improve challenge friend so you follow them automatically?
    // if(!this.props.isSignedIn) {
    //   return null;
    // }

    // return (
    //   <ChallengeFriendsPanel
    //     friendInvitesUrl={this.props.friendInvitesUrl}
    //     isSignedIn={this.props.isSignedIn}
    //   />
    // );
  }

  renderFollowClozemaster() {
    return (
      <FollowClozemasterPanel />
    );
  }

  renderPlayOptionsModal() {
    const { aboutToPlayCollection, aboutToPlayScope, collections, listeningTrialExpired, playOptions, speakingTrialExpired, user } = this.state;
    const { isSignedIn, isSpeakingSkillAvailable, targetLanguageCode, targetLanguageIso, translateTranscribeAvailable } = this.props;

    if(!aboutToPlayCollection) {
      return null;
    }

    let isListeningSkillAvailable = false;
    if(aboutToPlayCollection.ttsAvailable) {
      isListeningSkillAvailable = true;
    }
    else if(aboutToPlayCollection.name === 'All') {
      if(aboutToPlayScope === 'ready_for_review') {
        isListeningSkillAvailable = collections.filter((c) => c.numReadyForReview).every((c) => c.ttsAvailable);
      }
      else if(aboutToPlayScope === 'favorited') {
        isListeningSkillAvailable = collections.filter((c) => c.numFavorited).every((c) => c.ttsAvailable);
      }
    }
    // allow pro users to use system tts as well
    isListeningSkillAvailable = isListeningSkillAvailable || 
      (user.isPro && window.clozemaster.systemTtsAvailable(targetLanguageIso, targetLanguageCode));

    return (
      <PlayOptionsModal
        collection={aboutToPlayCollection}
        isPro={user.isPro}
        isListeningSkillAvailable={isListeningSkillAvailable}
        isSignedIn={isSignedIn}
        isSpeakingSkillAvailable={isSpeakingSkillAvailable}
        listeningTrialExpired={listeningTrialExpired}
        onHidden={() => this.setState({ aboutToPlayCollection: null, aboutToPlayScope: null })}
        playOptions={playOptions}
        scope={aboutToPlayScope}
        speakingTrialExpired={speakingTrialExpired}
        targetLanguageIso={targetLanguageIso}
        translateTranscribeAvailable={translateTranscribeAvailable}
      />
    );
  }

  renderPast7DaysChart() {
    return (
      <div className="text-center" style={{ clear: "both", position: "relative" }}>
        <canvas ref={(el) => this.past7DaysCanvas = el} style={{ width: '95%', height: 100, margin: '10px 0' }} />
        {this.renderFullHistoryBtn()}
      </div>
    );
  }

  renderFullHistoryBtn() {
    const { dailyStatsUrl, user } = this.state;
    const { isSignedIn } = this.props;

    return (
      <FullHistoryBtn 
        dailyStatsUrl={dailyStatsUrl}
        disabled={!isSignedIn}
        style={{ position: "absolute", right: 0, top: 10 }}
        user={user}
      />
    );
  }

  renderReviewSettingsModal() {
    if(!this.state.reviewSettingsModalVisible) {
      return null;
    }

    return (
      <ReviewSettingsModal
        isPro={this.state.user.isPro}
        onHidden={() => this.setState({ reviewSettingsModalVisible: false }, () => this.loadLanguagePairing())}
        url={this.state.reviewSettingsUrl}
      />
    );
  }

  renderMoreStatsModal() {
    if(!this.state.moreStatsVisible) {
      return null;
    }

    return (
      <MoreStatsModal
        isPro={this.state.user.isPro}
        moreStatsUrl={this.state.moreStatsUrl}
        onHidden={() => this.setState({ moreStatsVisible: false })}
      />
    );
  }

  showAddClozeFromWordsToCollectionModal(addSentencesToCollection) {
    this.setState({
      addSentencesToCollection,
      addClozeFromWordsToCollectionModalVisible: true,
    });
  }

  showAddTextToCollectionModal(addSentencesToCollection) {
    this.setState({
      addSentencesToCollection,
      addTextToCollectionModalVisible: true,
    });
  }

  showAddFileToCollectionModal(addSentencesToCollection) {
    this.setState({
      addSentencesToCollection,
      addFileToCollectionModalVisible: true,
    });
  }

  renderAddClozeFromWordsToCollectionModal() {
    const { addSentencesToCollection, addClozeFromWordsToCollectionModalVisible, collectionsUrl, clozeFromWordsUrl, user } = this.state;

    if(!addClozeFromWordsToCollectionModalVisible) {
      return null;
    }

    return (
      <AddClozeFromWordsToCollectionModal
        collection={addSentencesToCollection}
        isPro={user.isPro}
        onHidden={() => this.setState({ addSentencesToCollection: null, addClozeFromWordsToCollectionModalVisible: false })}
        onSentencesAdded={() => {
          this.updateCollection(addSentencesToCollection);
        }}
        clozeFromWordsUrl={clozeFromWordsUrl}
        ref={(el) => this.addClozeFromWordsToCollectionModal = el}
      />
    );
  }

  renderAddTextToCollectionModal() {
    const { addSentencesToCollection, addTextToCollectionModalVisible, collectionsUrl, quickClozeUrl, user } = this.state;

    if(!addTextToCollectionModalVisible) {
      return null;
    }

    return (
      <AddTextToCollectionModal
        collection={addSentencesToCollection}
        isPro={user.isPro}
        onHidden={() => this.setState({ addSentencesToCollection: null, addTextToCollectionModalVisible: false })}
        onSentencesAdded={() => {
          this.updateCollection(addSentencesToCollection);
        }}
        quickClozeUrl={quickClozeUrl}
        ref={(el) => this.addTextToCollectionModal = el}
      />
    );
  }

  renderAddFileToCollectionModal() {
    const { addSentencesToCollection, addFileToCollectionModalVisible, collectionsUrl, collectionFileImportUrl, user } = this.state;

    if(!addFileToCollectionModalVisible) {
      return null;
    }

    return (
      <AddFileToCollectionModal
        collection={addSentencesToCollection}
        collectionFileImportUrl={collectionFileImportUrl}
        isPro={user.isPro}
        onHidden={() => this.setState({ addSentencesToCollection: null, addFileToCollectionModalVisible: false })}
        onSentencesAdded={() => {
          this.updateCollection(addSentencesToCollection);
        }}
        ref={(el) => this.addFileToCollectionModal = el}
      />
    );
  }

  renderSentenceSearchModal() {
    if(!this.state.sentenceSearchModalVisible) {
      return null;
    }

    return (
      <ClozeSentenceSearchModal 
        collectionsUrl={this.state.collectionsUrl}
        isPro={this.state.user.isPro}
        initialSelectedCollectionId={this.state.addSentenceToCollection && this.state.addSentenceToCollection.id}
        nextReviewByLevel={this.state.nextReviewByLevel}
        onHidden={() => {
          this.updateMyCollections();
          this.setState({ sentenceSearchModalVisible: false, addSentenceToCollection: null });
        }}
        url={this.state.clozeSentencesUrl}
      />
    );
  }

  renderAddSentenceToCollectionModal() {
    if(!this.state.addSentenceToCollectionVisible) {
      return null;
    }

    return (
      <AddSentenceToCollectionModal
        collectionsUrl={this.state.collectionsUrl}
        initialSelectedCollectionId={this.state.addSentenceToCollection && this.state.addSentenceToCollection.id}
        isPro={this.state.user.isPro}
        nextReviewByLevel={this.state.nextReviewByLevel}
        onHidden={() => this.setState({ addSentenceToCollectionVisible: false, addSentenceToCollection: null })}
        onSentenceAdded={(collectionClozeSentence, collection) => {
          this.updateCollection(collection);
          this.addSentenceToCollectionModal.reset();
          // this.addSentenceToCollectionModal.hide();
        }}
        ref={(el) => this.addSentenceToCollectionModal = el}
      />
    );
  }

  renderModals() {
    return (
      <>
        {this.renderPlayOptionsModal()}
        {this.renderManageCollectionModal()}
        {this.renderAddToCollectionModal()}
        {this.renderAddSentenceToCollectionModal()}
        {this.renderAddTextToCollectionModal()}
        {this.renderAddClozeFromWordsToCollectionModal()}
        {this.renderAddFileToCollectionModal()}
        {this.renderFavoritesModal()}
        {this.renderDownloadFavoritesModal()}
        {this.renderReviewSettingsModal()}
        {this.renderSentenceSearchModal()}
        {this.renderMoreStatsModal()}
        {this.renderLanguagePairingSettingsModal()}
        {this.renderDeleteLanguagePairingModal()}
        {this.renderResetProgressModal()}
      </>
    );
  }

  renderResetProgressModal() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    const { collections, resetProgressUrl } = this.state;

    return (
      <ResetProgressModal
        baseLanguageFlagIso={baseLanguageFlagIso}
        baseLanguageName={baseLanguageName}
        collections={collections.filter((c) => c.playing)}
        resetProgressUrl={resetProgressUrl}
        onResetLanguagePairing={() => this.setState({ updating: true })}
        onResetCollection={() => this.setState({ updating: true })}
        targetLanguageFlagIso={targetLanguageFlagIso}
        targetLanguageName={targetLanguageName}
      />
    );
  }

  renderDeleteLanguagePairingModal() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      languagePairingUrl,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    return (
      <DeleteLanguagePairingModal
        baseLanguageFlagIso={baseLanguageFlagIso}
        baseLanguageName={baseLanguageName}
        languagePairingUrl={languagePairingUrl}
        onDeleteLanguagePairing={() => this.setState({ updating: true })}
        targetLanguageFlagIso={targetLanguageFlagIso}
        targetLanguageName={targetLanguageName}
      />
    );
  }

  renderLanguagePairingSettingsModal() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      languagePairingUrl,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    const {
      gameSettingsUrl,
      progressPerChunk,
      useProgressChunks
    } = this.state;

    return (
      <LanguagePairingSettingsModal
        baseLanguageFlagIso={baseLanguageFlagIso}
        baseLanguageName={baseLanguageName}
        gameSettingsUrl={gameSettingsUrl}
        languagePairingUrl={languagePairingUrl}
        onProgressChunksChange={({ progressPerChunk, useProgressChunks }) => {
          this.setState({
            progressPerChunk,
            useProgressChunks
          });
        }}
        progressPerChunk={progressPerChunk}
        targetLanguageFlagIso={targetLanguageFlagIso}
        targetLanguageName={targetLanguageName}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderControls() {
    const { isSignedIn } = this.props;

    return (
      <div className="text-right">
        <ul className="list-inline">
          <li>
            <button className="btn btn-default" disabled={!isSignedIn} id="manage-language-pairing-btn" data-toggle="modal" data-target="#manage-language-pairing-modal">
              <span className="glyphicon glyphicon-cog"></span>
            </button>
          </li>
          <li>
            <button className="btn btn-danger" disabled={!isSignedIn} id="reset-progress-btn" data-toggle="modal" data-target="#reset-progress-modal">
              <span className="glyphicon glyphicon-erase"></span>
            </button>
          </li>
          <li>
            <button className="btn btn-danger" disabled={!isSignedIn} id="delete-language-pairing-btn" data-toggle="modal" data-target="#delete-language-pairing-modal">
              <span className="glyphicon glyphicon-trash"></span>
            </button>
          </li>
        </ul>
      </div>
    );
  }

  renderOnboarding() {
    if(true) {
      return null;
    }

    return (
      <Onboarding
        elements={[
          { selector: null, text: "Clozemaster takes a simple concept - a fill-in-the-blank exercise, otherwise known as \"cloze\" exercise - and takes it to the extreme to help you get fluent faster." },
          { selector: ".panel.fast-track", text: "The Fast Track is where most users start - play through N sentences in order of difficulty, one sentence per missing word." }
    //       { selector: "", text: "The Most Common Words collections let you get extra practice at any point if the Fast Track becomes too difficult." },
    //       { selector: "", text: "You score points for each correct answer, and level up to score more points. Play each day to keep your streak alive, and check out the leaderboards to compete with other players." },

    //       { selector: "", text: "And you level up as you score more points." },
    //       { selector: "", text: "Sentences come up for review to help you remember them better. The number of sentences ready for review is shown here." },
    //       { selector: "", text: "Stuck on grammar? Grammar Challenges are collections of sentences selected to help practice tricky grammar and parts-of-speech." },
    //       { selector: "", text: "Want to customize your learning? 
        ]}
      />
    );
  }

  renderMainContent() {
    if(this.state.loading) {
      return <Loading />;
    }

    return (
      <FadeIn>
        {this.renderStats()}
        {this.renderPast7DaysChart()}
        {this.renderPanels()}
        {this.renderControls()}
        {this.renderModals()}
        {this.renderOnboarding()}
      </FadeIn>
    );
  }

  renderUpdatingOverlay() {
    if(!this.state.updating) {
      return null;
    }

    return <LoadingOverlay />;
  }

  renderRightColumn() {
    if(this.state.loading) {
      return null;
    }

    return (
      <div className="col-xs-12 col-md-3 right-column">
        {this.renderProPromo()}
        {this.renderDailyReminder()}
        {this.renderDailyGoal()}
        {this.renderGiftPro()}
        {this.renderLatestBlogPost()}
        {this.renderRightColumnAd()}
        {this.renderChallengeFriends()}
        {this.renderFollowClozemaster()}
        {this.renderUpdatingOverlay()}
      </div>
    );
  }

  renderRightColumnAd() {
    const { user } = this.state;
    const { isPro } = user;
    const { googleAdsEnabled } = this.props;

    if(isPro || !googleAdsEnabled) {
      return null;
    }

    return (
      <GoogleAdPanel>
        <GoogleRightSidebarAd />
      </GoogleAdPanel>
    );

    // const { rightColumnAd } = this.props;

    // if(isPro || !rightColumnAd) {
    //   return null;
    // }

    // return (
    //   <Ad
    //     ad={rightColumnAd}
    //     className="right-column-vert"
    //   />
    // );
  }

  renderPanelsAd() {
    const { user } = this.state;
    const { isPro } = user;
    const { googleAdsEnabled } = this.props;

    if(isPro || !googleAdsEnabled) {
      return null;
    }

    return (
      <GoogleAdPanel>
        <GooglePanelsAd />
      </GoogleAdPanel>
    );

    // const { panelsAd } = this.props;

    // if(isPro || !panelsAd) {
    //   return null;
    // }

    // return (
    //   <Ad
    //     ad={panelsAd}
    //     className="panels-vert"
    //   />
    // );
  }

  renderAnonUserPromo() {
    if(this.props.isSignedIn) {
      return null;
    }

    return (
      <div
        className="joystix"
        style={{
          background: '#464646',
          color: '#fff',
          left: 0,
          padding: '10px 15px',
          position: 'fixed',
          right: 0,
          textAlign: 'center',
          top: 0,
          zIndex: 994
        }}
      >
        <div className="hidden-xs" style={{ left: 10, lineHeight: 1, position: 'absolute', top: 2 }}><a href="/" style={{ color: '#fff', fontSize: '3em' }}>M</a></div>
        Get in the game! <a className="btn btn-success" href="/sign-up">Sign up</a> or <a className="btn btn-success" href="/login">Login</a>
      </div>
    );
  }

  render() {
    return (
      <div id="dashboard" className="container-fluid" style={{ maxWidth: 1400 }}>
        <div className="row" style={{ marginTop: 20 }}>
          <div className={'col-xs-12 ' + (this.state.loading ? '' : 'col-md-9')}>
            <div className="pull-right">{this.renderProControls()}</div>
            <h1 className="title" style={{ marginTop: 0 }}>
              <span className="languages">{this.props.targetLanguageName} <small>from {this.props.baseLanguageName}</small></span>
              <span style={{ marginLeft: 10 }}>
                <FlagSprite flagIso={this.props.targetLanguageFlagIso} size={48} />
                <FlagSprite flagIso={this.props.baseLanguageFlagIso} size={48} />
              </span>
              <span className="joystix" style={{ fontSize: 16, display: 'block', marginTop: 4 }}>Dashboard</span>
            </h1>
            {this.renderMainContent()}
          </div>
          {this.renderRightColumn()}
        </div>
        {this.renderAnonUserPromo()}
      </div>
    );
  }
}
