import React from "react";
import ReactDOM from "react-dom";

import Icon from "./Icon";
import FadeIn from "./FadeIn";
import Loading from "./Loading";
import Modal from "./Modal";
import Panel from "./Panel";

const letters = (str) => str.split("").map((l) => <span className="letter">{l}</span>);

class OnboardingCollections extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    const { languagePairingUrl } = this.props;

    $.ajax({
      url: languagePairingUrl
    })
      .done((data) => {
        console.log(data);
        const { collectionGroupings, collections } = data;
        this.setState({
          collectionGroupings,
          collections,
          loading: false
        });
      });
  }

  renderCollection(c, body) {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          {body}
        </div>
        <div>
          {c.proOnly ? (
            <span className="text-center" style={{ display: "inline-block" }}>
              <button className="btn btn-success btn-lg joystix" disabled={true}>Play <Icon name="chevron-right" /></button>
              <br />
              <span className="joystix">Pro!</span>
            </span>
          ) : <a className="btn btn-success btn-lg joystix" href={c.playWebUrl + "?onboarding=true"}>Play <Icon name="chevron-right" /></a>}
        </div>
      </div>
    );
  }

  renderContent() {
    const { collectionGroupings, collections, loading } = this.state;

    if(loading) {
      return <Loading />;
    }

    const fastTrackCollection = collections.find((c) => c.type === "FastTrackCollection");
    const grammarCollections = collections.filter((c) => c.type === "GrammarCollection");
    const mostCommonWordsCollections = collections.filter((c) => c.type === "FrequencyCollection");
    const randomCollection = collections.find((c) => c.type === "RandomCollection");

    return (
      <div>
        {!!fastTrackCollection && (
          <Panel>
            {this.renderCollection(fastTrackCollection, (
              <>
                <h3 style={{ margin: 0 }}>{fastTrackCollection.name}</h3>
                <p>Play a collection of {fastTrackCollection.numSentences.toLocaleString()} sentences with {fastTrackCollection.numSentences.toLocaleString()} unique cloze-words in order of difficulty.</p>
              </>
            ))}
            <div><strong>Not sure where to start? Start with this collection!</strong></div>
          </Panel>
        )}
        {!!mostCommonWordsCollections.length && (
          <Panel>
            <h3 style={{ margin: 0 }}>Most Common Words</h3>
            <p>Play collections of sentences grouped by difficulty.</p>
            <p><strong>Not sure where to start? Try the {mostCommonWordsCollections[0].name} collection and go up from there.</strong></p>
            {mostCommonWordsCollections.map((c) => (
              <div style={{ borderLeft: "3px solid #ccc", marginLeft: 15, padding: "10px 0 10px 15px" }}>
                {this.renderCollection(c, (
                  <>
                    <h4 style={{ margin: 0 }}>{c.name}</h4>
                    <div>{c.numSentences.toLocaleString()} sentences</div>
                  </>
                ))}
                <div></div>
              </div>
            ))}
          </Panel>
        )}
        {!!grammarCollections.length && (
          <Panel>
            <h3 style={{ margin: 0 }}>Grammar Challenges</h3>
            <p>Master difficult grammar in context.</p>
            {grammarCollections.map((c) => (
              <div style={{ borderLeft: "3px solid #ccc", marginLeft: 15, padding: "10px 0 10px 15px" }}>
                {this.renderCollection(c, (
                  <>
                    <h4 style={{ margin: 0 }}>{c.name}</h4>
                    <div>{c.numSentences.toLocaleString()} sentences</div>
                  </>
                ))}
                <div></div>
              </div>
            ))}
          </Panel>
        )}
        {!!randomCollection && (
          <Panel>
            {this.renderCollection(randomCollection, (
              <>
                <h3 style={{ margin: 0 }}>{randomCollection.name}</h3>
                <p>Play a collection of {randomCollection.numSentences.toLocaleString()} randomly selected sentences.</p>
                {!fastTrackCollection && <div><strong>Not sure where to start? Start with this collection!</strong></div>}
              </>
            ))}
          </Panel>
        )}
      </div>
    );
  }

  render() {
    const { onHidden } = this.props;
    return (
      <Modal onHidden={onHidden} title="Collections">
        {this.renderContent()}
      </Modal>
    );
  }
}

class Scene1 extends React.Component {
  componentDidMount() {
    let typeLine1Timeout = null;
    let typeLine2Timeout = null;

    const typeLine2 = (index) => {
      if(!$("#line-2 .letter").get(index)) {
        $("#continue-btn").delay(1000).animate({ opacity: 1 });
      }
      $("#line-2 .letter").eq(index).css("opacity", 1);
      typeLine2Timeout = setTimeout(() => typeLine2(index + 1), 50);
    };

    const typeLine1 = (index) => {
      if(!$("#line-1 .letter").get(index)) {
        $("#line-1 .blank").animate(
          { opacity: 1 },
          {
            complete: () => {
              $("#line-1 .punctuation").css("opacity", 1);
              $("#line-1 .missing")
                .animate({ opacity: 1 })
                .animate({ opacity: 0 })
                .animate({ opacity: 1 })
                .animate({ opacity: 0 })
                .animate({ opacity: 1 })
                .animate({ opacity: 0 })
                .animate({ opacity: 1 }, {
                  complete: () => {
                    typeLine2Timeout = setTimeout(() => typeLine2(0), 1000);
                  }
                })
            }
          }
        );
        return false;
      }
      $("#line-1 .letter").eq(index).css("opacity", 1);
      typeLine1Timeout = setTimeout(() => typeLine1(index + 1), 50);
    };

    typeLine1Timeout = setTimeout(() => typeLine1(0), 500);

    let skipped = false;
    $(window).on("keydown", (e) => {
      // enter or space
      if(!skipped && (e.which === 13 || e.which === 32)) {
        e.preventDefault();
        clearTimeout(typeLine1Timeout);
        clearTimeout(typeLine2Timeout);
        $("#line-1 .letter, #line-1 .punctuation, #line-1 .blank, #line-1 .missing, #line-2 .letter, #continue-btn").stop(true, true).css("opacity", 1);
        skipped = true;
      }
    });
  }

  render() {
    const { onContinueClick } = this.props;

    return (
      <div className="container text-center" style={{ fontFamily: "joystix", fontWeight: "bold", marginTop: 60 }}>
        <div id="line-1" style={{ fontSize: "2em" }}><span className="letter">A</span><span className="letter"> </span><span className="letter">&ldquo;</span><span style={{ color: "#5cb85c" }}>{"cloze".split("").map((l) => <span className="letter">{l}</span>)}</span><span className="letter">&rdquo;</span>{" sentence is a sentence with a word ".split("").map((l) => <span className="letter">{l}</span>)}<span className="blank" style={{ display: "inline-block", position: "relative" }}><span style={{ borderBottom: "3px solid #000" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><small className="missing" style={{ fontSize: 16, position: "absolute", bottom: -30, left: 0, right: 0, opacity: 0 }}>(missing)</small></span><span className="punctuation">.</span></div>
        <div id="line-2" style={{ fontSize: "2em", margin: "60px 0" }}>{"The objective is to fill in the missing word based on context or the given translation.".split("").map((l) => <span className="letter">{l}</span>)}</div>
        <button id="continue-btn" className="btn btn-lg btn-success joystix" onClick={onContinueClick} style={{ opacity: 0 }}>Continue <span className="glyphicon glyphicon-chevron-right" /></button>
      </div>
    );
  }
}

class Scene2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionsModalVisible: false
    };
  }

  componentDidMount() {
    let typeLine1Timeout = null;
    let typeLine2Timeout = null;

    const typeLine2 = (index) => {
      if(!$("#line-2 .letter").get(index)) {
        $("#buttons").delay(1000).animate({ opacity: 1 });
      }
      $("#line-2 .letter").eq(index).css("opacity", 1);
      typeLine2Timeout = setTimeout(() => typeLine2(index + 1), 50);
    };

    const typeLine1 = (index) => {
      if(!$("#line-1 .letter").get(index)) {
        typeLine2Timeout = setTimeout(() => typeLine2(0), 1000);
        return false;
      }
      $("#line-1 .letter").eq(index).css("opacity", 1);
      typeLine1Timeout = setTimeout(() => typeLine1(index + 1), 50);
    };

    typeLine1Timeout = setTimeout(() => typeLine1(0), 500);

    let skipped = false;
    $(window).on("keypress", (e) => {
      // enter or space
      if(!skipped && (e.which === 13 || e.which === 32)) {
        e.preventDefault();
        clearTimeout(typeLine1Timeout);
        clearTimeout(typeLine2Timeout);
        $("#line-1 .letter, #line-2 .letter, #buttons").stop(true, true).css("opacity", 1);
        skipped = true;
      }
    });
  }

  renderOnboardingCollections() {
    const { collectionsModalVisible } = this.state;
    const { languagePairingUrl } = this.props;

    if(!collectionsModalVisible) {
      return null;
    }

    return (
      <OnboardingCollections
        languagePairingUrl={languagePairingUrl}
        onHidden={() => this.setState({ collectionsModalVisible: false })}
      />
    );
  }

  render() {
    const { justPlayUrl } = this.props;
    return (
      <>
        <div id="lines" className="container text-center" style={{ fontFamily: "joystix", fontWeight: "bold", marginTop: 60 }}>
          <div id="line-1" style={{ fontSize: "2em", marginBottom: 60 }}>{letters("With Clozemaster you play through ")}<span className="letter">&ldquo;</span><span style={{ color: "#5cb85c" }}>{letters("collections")}</span><span className="letter">&rdquo;</span>{letters(" of hundreds and even thousands of cloze sentences to rapidly expand your vocabulary.")}</div>
          <div id="line-2" style={{ fontSize: "2em", marginBottom: 60 }}>{letters("Clozemaster Pro users can also create their own collections.")}</div>
          <div id="buttons" style={{ opacity: 0 }}>
            <button className="btn btn-lg btn-default joystix" onClick={() => this.setState({ collectionsModalVisible: true })} style={{ margin: "0 10px 20px 10px" }}>Choose collection</button>
            <a className="btn btn-lg btn-success joystix" href={justPlayUrl} style={{ margin: "0 10px 20px 10px" }}>Just start playing</a>
          </div>
        </div>
        {this.renderOnboardingCollections()}
      </>
    );
  }
}

// class RefinerOnboardingQuestions extends React.Component {
//   constructor(props) {
//     super(props);
// 
//     this.state = {
//       loading: true,
//       showContinueBtn: false
//     };
//   }
// 
//   componentDidMount() {
//     const { allDone, formId } = this.props;
// 
//     if(window._refiner) {
//       let showContinueBtnTimeout = null;
//       window._refiner("onShow", (formId) => {
//         clearTimeout(showContinueBtnTimeout);
//         this.setState({
//           loading: false,
//           showContinueBtn: false  // in case it was shown
//         });
//       });
//       window._refiner("onClose", (formId) => {
//         this.setState({
//           loading: false,
//           showContinueBtn: true
//         });
//       });
//       window._refiner("showForm", formId, true);
//       // in case form fails to load in 1s
//       showContinueBtnTimeout = setTimeout(() => {
//         this.setState({
//           loading: false,
//           showContinueBtn: true
//         });
//       }, 3000);
//     }
//     else {
//       allDone();
//     }
//   }
// 
//   render() {
//     const { allDone } = this.props;
//     const { loading, showContinueBtn } = this.state;
//     return (
//       <div style={{ display: "flex", alignItems: "center", height: 400, justifyContent: "center" }}>
//         {loading && <Loading />}
//         {showContinueBtn && (
//           <FadeIn>
//             <button className="btn btn-lg btn-success joystix" onClick={allDone}>Continue <Icon name="chevron-right" /></button>
//           </FadeIn>
//         )}
//       </div>
//     );
//   }
// }


export default class Onboarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 1
    };
  }

  render() {
    const { index } = this.state;
    const { justPlayUrl, languagePairingUrl } = this.props;

    if(index === 2) {
      return (
        <Scene2
          justPlayUrl={justPlayUrl}
          languagePairingUrl={languagePairingUrl}
        />
      );
    }

    // on round results, check if dailyGoalPromptShown and dailyReminderPromptShown, show if not


    // choose collection shows collections list with brief descriptions and "best for:"
    // play throws them in to multiple choice
    //
    // play goes to FFT or Random
    // multiple choice
    //
    // play 5 sentences
    // then round results
    // then
    // - continue
    // --> daily goal and daily reminder prompt
    // - try text input, listening, transcribe, or speaking (play options)
    // - go back to dashboard to check out stats or choose another collection
    // --> daily goal and daily reminder prompt after next round results
    // 
    // otherwise business as usual
    //
    // could also
    // 1. consolidate dashboard
    // 2. consolidate round results
    // 3. simplify play options
    // 4. update srs controls
    // 5. "known" metric, uccs last_played, and uccs_stats

    // return (
    //   <RefinerOnboardingQuestions
    //     allDone={() => this.setState({ index: 1 })}
    //     formId={refinerOnboardingFormId}
    //   />
    // );

    return (
      <Scene1
        onContinueClick={() => this.setState({ index: 2 })}
      />
    );
  }

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     index: 0
  //   };
  // }

  // componentDidMount() {
  //   this.setFocusInterval = setInterval(() => this.setFocus(), 500);
  // }

  // componentWillUnmount() {
  //   clearInterval(this.setFocusInterval);
  // }

  // getCurrentElement() {
  //   const { elements } = this.props;
  //   const { index } = this.state;
  //   return elements[index];
  // }

  // setFocus() {
  //   const currentElement = this.getCurrentElement();

  //   if(currentElement.selector) {
  //     const $el = $(currentElement.selector);
  //     const { left, top } = $el.offset();
  //     this.setState({
  //       height: $el.height(),
  //       left,
  //       top,
  //       width: $el.width()
  //     }); // , () => $("body").animate({ scrollTop: top - 20 }));
  //   }
  // }

  // render() {
  //   const { elements } = this.props;
  //   const { height, index, left, top, width } = this.state;
  //   const boxShadow = "rgba(33, 33, 33, 0.8) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px";
  //   const currentElement = this.getCurrentElement();

  //   if(index >= elements.length) {
  //     return null;
  //   }

  //   return ReactDOM.createPortal(
  //     <div className="overlay" style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0 }}>
  //       {!!currentElement.selector && (
  //         <div className="focus" style={{
  //           boxShadow,
  //           height,
  //           left,
  //           position: "absolute",
  //           top,
  //           width,
  //           zIndex: 999
  //         }} />
  //       )}
  //       <div style={{ background: "#fff", border: "2px solid #000", bottom: 15, boxShadow: "5px 5px 15px 5px rgba(33, 33, 33, 0.8)", fontFamily: "joystix", padding: "5px 10px", maxWidth: "60%", position: "fixed", right: 15, zIndex: 1000 }}>
  //         <p>{currentElement.text}</p>
  //         <button className="btn btn-success btn-sm joystix" onClick={() => this.setState({ index: index + 1 })}>Next</button> <button className="btn btn-default btn-sm joystix" onClick={() => this.setState({ index: elements.length })}>Skip Onboarding</button>
  //       </div>
  //     </div>,
  //     document.querySelector('body')
  //   );
  // }
}
