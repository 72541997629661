import React from "react";

import BulkAdd from "./ClozeSentenceSearch/BulkAdd";
import Pagination from "./Pagination";
import SearchForm from "./ClozeSentenceSearch/SearchForm";
import SearchResult from "./ClozeSentenceSearch/SearchResult";

export default class ClozeSentenceSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addToCollection: null,
      bulkAddVisible: false,
      clozeSentences: null,
      context: 'text',
      loading: false,
      page: 1,
      perPage: 20,
      query: props.query || '',
      selected: new Map(),
      total: 0,
    };
  }

  componentDidMount() {
    if(!!this.state.query) {
      this.search();
    }

    setTimeout(this.focusQuery.bind(this), 500);
  }

  focusQuery() {
    $("#cloze-sentences-query").focus();
  }

  search() {
    this.setState({ loading: true });
    const {
      context,
      page,
      perPage,
      query
    } = this.state;
    $.ajax({
      data: {
        context,
        page,
        per_page: perPage,
        query,
      },
      method: 'get',
      url: this.props.url
    })
      .done((data) => {
        this.setState({
          clozeSentences: data.clozeSentences,
          loading: false,
          selected: new Map(),
          total: data.total
        }, this.focusQuery.bind(this));
      })
      .fail(() => {
        this.setState({ loading: false });
        alert('Oh no! There was an error searching sentences. Please try again and let us know if you see this error message again.');
      });
  }

  renderBulkAdd() {
    if(!this.state.bulkAddVisible || this.props.addToCollectionDisabled) {
      return null;
    }

    return (
      <BulkAdd
        clozeSentences={this.state.clozeSentences}
        collectionsUrl={this.props.collectionsUrl}
        onAdd={() => this.setState({ selected: new Map(), bulkAddVisible: false })}
        onCancel={() => this.setState({ bulkAddVisible: false })}
        selected={this.state.selected}
      />
    );
  }

  renderBulkControls() {
    const selectedCount = this.state.selected.size;
    const sentencesCount = this.state.clozeSentences.length;

    if(this.state.bulkAddVisible || this.props.addToCollectionDisabled) {
      return null;
    }

    return (
      <div>
        <button
          className="btn btn-success btn-xs"
          disabled={!selectedCount}
          onClick={() => this.setState({ bulkAddVisible: !this.state.bulkAddVisible })}
          style={{ marginRight: 10 }}
        >
          Add {selectedCount} Selected to Collection
        </button>
        <button
          className="btn btn-success btn-xs"
          onClick={() => {
            if(sentencesCount === selectedCount) {
              this.setState({ selected: new Map() });
            }
            else {
              this.setState({ selected: new Map(this.state.clozeSentences.map((cs) => [cs.id, true])) });
            }
          }}
        >
          {selectedCount === sentencesCount ? 'Uns' : 'S'}elect All
        </button>
      </div>
    );
  }

  renderResults() {
    if(this.state.loading) {
      return <div>Loading...</div>;
    }

    if(!this.state.clozeSentences) {
      return null;
    }

    return (
      <div>
        <hr />
        {this.renderBulkControls()}
        {this.renderBulkAdd()}
        {this.state.clozeSentences.map((cs) => (
          <SearchResult
            addToCollectionDisabled={this.props.addToCollectionDisabled}
            collectionsUrl={this.props.collectionsUrl}
            initialSelectedCollectionId={this.props.initialSelectedCollectionId}
            key={cs.text}
            nextReviewByLevel={this.props.nextReviewByLevel}
            onSelect={(selected) => {
              const newSelected = new Map(this.state.selected);
              if(selected) {
                newSelected.set(cs.id, true);
              }
              else {
                newSelected.delete(cs.id);
              }
              this.setState({ selected: newSelected });
            }}
            sentence={cs}
            selected={this.state.selected.has(cs.id)}
          />
        ))}
      </div>
    );
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ page: 1 }, () => this.search());
  }

  renderPagination() {
    if(!this.state.clozeSentences || this.state.loading) {
      return null;
    }

    const { page, perPage, total, updating } = this.state;

    return (
      <Pagination
        onNextClick={() => this.setState({ page: this.state.page + 1 }, () => this.search())}
        onPrevClick={() => this.setState({ page: this.state.page - 1 }, () => this.search())}
        page={page}
        perPage={perPage}
        total={total}
        updating={updating}
      />
    );
  }

  render() {
    const { context, loading, query } = this.state;

    return (
      <div className="cloze-sentence-search">
        <SearchForm
          context={context}
          disabled={loading}
          onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
          onSubmit={(e) => this.onSubmit(e)}
          query={query}
        />
        {this.renderResults()}
        {this.renderPagination()}
      </div>
    );
  }
}
