import React from "react";

import { isRTL } from "../helpers";

import moment from 'moment';

import Toast from "./Toast";

export default class CollectionClozeSentenceEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign(this.getSentenceState(props.collectionClozeSentence), {
      saving: false
    });
  }

  isRTL() {
    if(this.state.text) {
      return isRTL(this.state.text);
    }
    else if(this.props.text) {
      return isRTL(this.props.text);
    }
  }

  isDisabled() {
    return this.state.saving || this.props.disabled;
  }

  reset() {
    this.setState(Object.assign(this.state, this.getSentenceState()), () => this.textInput.focus());
  }

  getSentenceState(sentence) {
    sentence = sentence || {}; // in case new / blank sentence
    const state = {};

    [
      'alternativeAnswers',
      'favorited',
      'hint',
      'ignored',
      'level',
      'nextReview',
      'notes',
      'pronunciation',
      'text',
      'translation',
    ].forEach((attr) => {
      if(attr === 'level') {
        return state[attr] = ('' + sentence[attr]).match(/[0-4]/) ? parseInt(sentence[attr]) : 0;
      }
      if(attr === 'alternativeAnswers') {
        return state[attr] = (sentence[attr] || []).join(",");
      }
      return state[attr] = sentence[attr] || '';
    });

    return state;
  }

  handleChange(e) {
    const update = {};
    update[e.target.name] = e.target.value;
    this.setState(update);
  }

  onLevelBtnClick(e, i) {
    e.preventDefault();

    const { nextReviewByLevel } = this.props;
    const { nextReview } = this.state;

    if(this.state.level === i && !!nextReview) {
      this.setState({
        level: 0,
        nextReview: null
      });
      return true;
    }

    this.setState({
      level: i,
      nextReview: moment().add(nextReviewByLevel[i], 'days').format("YYYY-MM-DD")
    });
  }

  getUpdate() {
    const {
      alternativeAnswers,
      favorited,
      hint,
      ignored,
      level,
      nextReview,
      notes,
      pronunciation,
      text,
      translation,
    } = this.state;

    const {
      clozeSentenceId,
      id,
      tatoebaId
    } = this.props.collectionClozeSentence || {};

    const update = {
      alternative_answers: alternativeAnswers,
      cloze_sentence_id: clozeSentenceId,
      favorited,
      hint,
      id,
      ignored,
      level,
      next_review: nextReview,
      notes,
      pronunciation,
      tatoeba_id: tatoebaId,
      text,
      translation,
    };

    // only for level = 0 so it's added to reviews
    // if new sentence and user didn't select level, then next review will be null
    // if existing sentence at 0, then next review won't have changed
    // if user selects level 0, then next review should be current date as expected
    if(level === 0) {
      update.next_review = nextReview;
    }

    return update;
  }

  onSubmit(e) {
    e.preventDefault();
    const update = this.getUpdate();
    this.setState({ errors: null, saving: true });
    if(this.props.createUrl) {
      this.sendUpdate(update, this.props.createUrl);
    }
    else if(this.props.upsertUrl) {
      this.sendUpsert(update);
    }
    else if(this.props.updateUrl) {
      this.sendUpdate(update, this.props.updateUrl);
    }
    else {
      // do something!
      throw new Error("No url provided!");
    }
  }

  sendUpdate(update, url) {
    $.ajax({
      contentType: 'application/json',
      method: 'post',
      data: JSON.stringify({
        collection_cloze_sentence: update
      }),
      url
    })
      .done((data) => {
        if(data.errors) {
          this.onUpdateErrors(data.errors);
        }
        else {
          this.onUpdateSuccess(data.collectionClozeSentence);
        }
      })
      .fail(() => {
        this.onUpdateFail();
      });
  }

  sendUpsert(update) {
    $.ajax({
      contentType: 'application/json',
      method: 'post',
      data: JSON.stringify({
        updates: [update]
      }),
      url: this.props.upsertUrl,
    })
      .then((data) => {
        if(data.errors) {
          this.onUpdateErrors(data.errors);
          return null;
        }

        return $.ajax({
          method: 'post',
          data: { ids: data.ids },
          url: data.collectionClozeSentencesBatchUrl
        });
      })
      .done((data) => {
        if(data) {
          this.onUpdateSuccess(data.collectionClozeSentences[0]);
        }
      })
      .fail(() => {
        this.onUpdateFail();
      });
  }

  onUpdateSuccess(collectionClozeSentence) {
    // TODO! error handling
    this.setState(
      Object.assign({}, this.getSentenceState(collectionClozeSentence), { savedAt: new Date().getTime(), saving: false }),
      () => {
        // TODO!
        // scroll top
        // show saved notification
        // reset / clear state / form
        // then if container on update closes all good
        this.props.onUpdate && this.props.onUpdate(collectionClozeSentence)
      }
    );
  }

  onUpdateErrors(errors) {
    this.setState({ errors, saving: false }, () => $('html, body, .modal').animate({ scrollTop: 0 }));
  }

  onUpdateFail() {
    alert('Oh no! There was an error saving the sentence. Sorry about that. Please try again and let us know if you see this message again.');
    this.setState({ saving: false });
  }

  renderTextFormGroup() {
    const { saving, text } = this.state;
    const { isTextEditable } = this.props;

    if(!isTextEditable) {
      return null;
    }

    return (
      <div className="form-group">
        <label htmlFor="text">Text</label>
        <input autoFocus disabled={this.isDisabled()} className={`form-control ${this.isRTL() ? "rtl" : ""}`} name="text" ref={(el) => this.textInput = el} value={text} onChange={(e) => this.handleChange(e)} />
        <small>Wrap the cloze-word in <code>{'{{'}</code><code>{'}}'}</code>, for example <code>A {'{{'}missing{'}}'} word.</code>.</small>
      </div>
    );
  }

  renderPercentMastered() {
    const { level, nextReview, saving } = this.state;
    const { isPro, sentenceOnly } = this.props;

    if(!nextReview || sentenceOnly) {
      return null;
    }

    return (
      <div className="form-group">
        <label>Percent mastered</label>
        <div className="btn-group btn-group-justified percent-mastered" role="group" aria-label="Percent mastered">
          {[0, 1, 2, 3, 4].map((i) => (
            <div className="btn-group" role="group" key={i}>
              <button
                disabled={!isPro || this.isDisabled()}
                type="button"
                className={'joystix btn btn-' + (!!nextReview && level === i ? 'success active' : 'default')}
                onClick={(e) => this.onLevelBtnClick(e, i)}
              >
                {i * 25}%
              </button>
            </div>
          ))}
        </div>
        {!isPro && <small>Change percent mastered with <a href="/pro">Clozemaster Pro</a>!</small>}
        {this.renderNextReview()}
      </div>
    );
  }

  renderNextReview() {
    const { level, nextReview } = this.state;
    const {
      alreadyMasteredNextReview,
      answerQualityOptionsVisible,
      collectionClozeSentence,
      isPro,
      nextReviewByLevel
    } = this.props;

    if(answerQualityOptionsVisible && alreadyMasteredNextReview && level === 4) {
      const ef = collectionClozeSentence.easinessFactor || 1.5;
      const ri = collectionClozeSentence.repetitionInterval || nextReviewByLevel[4];
      const srsResponseQuality = [1, 4, 7];
      return (
        <div>
          Next review:
          <ul>
            {['Hard', 'Normal', 'Easy'].map((r, q) => {
              let d = alreadyMasteredNextReview === 'srs' ?
                Math.ceil(ri * Math.max(ef + (0.1-(5-srsResponseQuality[q])*(0.08+(5-srsResponseQuality[q])*0.02)), 1.2)) :
                // 0.5, 1, 2
                (q === 0 ? 0.5 : q) * nextReviewByLevel[4];

              return (
                <li key={r}>{moment().add(d, 'days').format("YYYY-MM-DD")} if {r} is selected</li>
              );
            })}
          </ul>
        </div>
      );
    }

    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div>Next review: {nextReview < moment().format("YYYY-MM-DD") ? "Now!" : nextReview}</div>
        <button
          className="btn btn-xs btn-link"
          disabled={!isPro || this.isDisabled()}
          onClick={(e) => {
            e.preventDefault();
            this.setState({ level: 0, nextReview: null });
          }}
          style={{ marginTop: 2 }}
          type="button"
        >
          Remove from Reviews
        </button>
      </div>
    );
  }

  renderAddToReviewQueue() {
    const { nextReview } = this.state;
    if(this.props.sentenceOnly || !!nextReview) {
      return null;
    }

    return (
      <div className="form-group">
        <button className="btn btn-sm btn-success btn-block joystix" onClick={() => this.setState({ level: 0, nextReview: moment().format("YYYY-MM-DD") })} type="button">
          <span className="glyphicon glyphicon-plus" /> Add to Reviews
        </button>
      </div>
    );
  }

  renderFavoritedIgnored() {
    const { isPro, sentenceOnly } = this.props;
    const { saving } = this.state;

    if(sentenceOnly) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-6 text-center">
          <div className="checkbox">
            <label>
              <input checked={this.state.favorited} disabled={!isPro || this.isDisabled()} name="favorited" onChange={(e) => this.setState({ favorited: e.target.checked })} type="checkbox" /> Favorited
            </label>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 text-center">
          <div className="checkbox">
            <label>
              <input checked={this.state.ignored} disabled={this.isDisabled()} name="ignored" onChange={(e) => this.setState({ ignored: e.target.checked })} type="checkbox" /> Ignored
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderToast() {
    if(!this.state.savedAt) {
      return null;
    }

    return (
      <Toast
        content="Saved! &#x1f389;"
        context="success"
        key={this.state.savedAt}
      />
    );
  }

  onTextMouseUp(e) {
    if(!this.props.isTextEditable) {
      return null;
    }

    const selection = window.getSelection();
    const str = selection.toString();
    if(str && this.state.text.replace(/\{\{|\}\}/g, '').match(str)) {
      this.setState({ text: this.state.text.replace(/\{\{|\}\}/g, '').replace(str, '{{' + str + '}}') });
    }
  }

  render() {
    const {
      alternativeAnswers,
      errors,
      hint,
      level,
      nextReview,
      notes,
      pronunciation,
      saving,
      text,
      translation
    } = this.state;

    const { isPro, isTextEditable } = this.props;

    let parts = text.split('{{');
    const preClozeStr = parts[0];
    parts = (parts[1] || '').split('}}');
    const cloze = parts[0];
    const postClozeStr = parts[1];

    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        {errors && <p className="alert alert-danger">{errors}</p>}
        <p className={this.isRTL() && "rtl"} onMouseUp={(e) => this.onTextMouseUp(e)} style={{ fontSize: '1.5em' }}>{preClozeStr}<b><u>{cloze}</u></b>{postClozeStr}</p>
        {this.renderTextFormGroup()}
        <div className="form-group">
          <label htmlFor="translation">Translation</label>
          <input autoFocus={!isTextEditable} disabled={!isPro || this.isDisabled()} className="form-control" name="translation" value={translation} onChange={(e) => this.handleChange(e)} />
          {!isPro && <small>Edit translations with <a href="/pro">Clozemaster Pro</a>!</small>}
        </div>
        <div className="form-group">
          <label htmlFor="pronunciation">Pronunciation</label>
          <input disabled={!isPro || this.isDisabled()} className="form-control" name="pronunciation" value={pronunciation} onChange={(e) => this.handleChange(e)} />
          {!isPro && <small>Edit pronunciations with <a href="/pro">Clozemaster Pro</a>!</small>}
        </div>
        <div className="form-group">
          <label htmlFor="notes">Notes <em style={{ fontWeight: "normal" }}>(shown after answering)</em></label>
          <input disabled={!isPro || this.isDisabled()} className="form-control" name="notes" value={notes} onChange={(e) => this.handleChange(e)} />
          {!isPro && <small>Save notes with <a href="/pro">Clozemaster Pro</a>!</small>}
        </div>
        {this.renderAddToReviewQueue()}
        {this.renderPercentMastered()}
        {this.renderFavoritedIgnored()}
        {/*
        <div className="form-group">
          <ul className="list-inline">
            <li><button className="btn btn-default"><span className="glyphicon glyphicon-star" /> Favorite</button></li>
            <li><button className="btn btn-danger"><span className="glyphicon glyphicon-ban-circle" /> Ignore</button></li>
          </ul>
        </div>
        */}
        <div className="form-group">
          <label htmlFor="hint">Hint <em style={{ fontWeight: "normal" }}>(shown before answering)</em></label>
          <input disabled={!isPro || this.isDisabled()} className="form-control" name="hint" value={hint} onChange={(e) => this.handleChange(e)} />
          {!isPro && <small>Save hints with <a href="/pro">Clozemaster Pro</a>!</small>}
        </div>
        <div className="form-group">
          <label htmlFor="alternativeAnswers">Alternative answers (comma-separated list)</label>
          <input disabled={this.isDisabled()} className="form-control" name="alternativeAnswers" value={alternativeAnswers} onChange={(e) => this.handleChange(e)} />
        </div>
        <button disabled={this.isDisabled()} className="joystix btn btn-lg btn-success btn-block">Save</button>
        {this.renderToast()}
      </form>
    );
  }
}
