import React from "react";

export default class NewCollectionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      name: ''
    };
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    $.ajax({
      data: {
        collection: {
          name: this.state.name,
        }
      },
      method: 'post',
      url: this.props.createUrl
    })
      .done((data) => {
        this.props.onCreate(data.collection)
      })
      .fail(() => {
        alert('Oh no! There was an error creating the collection. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  render() {
    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        <p>Create a new collection.</p>
        <div className="form-group">
          <label htmlFor="collection[name]">Name</label>
          <input autoFocus type="text" className="form-control" name="collection[name]" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
        </div>
        <div>
          <button className="btn btn-success joystix">Submit</button>
          {this.props.cancelable &&
            <button className="btn btn-link" onClick={(e) => {
              e.preventDefault();
              this.props.onCancel();
            }}>
              Cancel
            </button>
          }
        </div>
      </form>
    );
  }
}

