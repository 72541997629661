import React from "react";

import DailyReminderModal from "../DailyReminderModal";
import Icon from "../Icon";
import Modal from "../Modal";
import Panel from "../Panel";
import Radio from "../Radio";

// TODO!
// - settings button as own component with modal?
// - daily reminder button as own component with modal

const DailyReminderBtn = ({ dailyReminderEmail, dailyReminderEmailUrl }) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [thisDailyReminderEmail, setThisDailyReminderEmail] = React.useState(dailyReminderEmail);

  console.log(dailyReminderEmail, dailyReminderEmailUrl);

  let modal = null;

  return (
    <>
      <button className={`btn btn-default ${thisDailyReminderEmail ? "active" : ""} btn-xs tw-mr-2`} onClick={() => setModalVisible(true)}><Icon name="bell" /></button>
      {modalVisible && (
        <DailyReminderModal
          dailyReminderEmail={thisDailyReminderEmail}
          onHidden={() => setModalVisible(false)}
          onUpdate={(dailyReminderEmail) => {
            setThisDailyReminderEmail(dailyReminderEmail);
            modal.hide();
          }}
          ref={(el) => modal = el}
          updateUrl={dailyReminderEmailUrl}
        />

      )}
    </>
  );
};

const StreakToggleBtn = ({ languagePairingUrl, onChange, showDaysPlayedInsteadOfStreak }) => {
  const [localShowDaysPlayedInsteadOfStreak, setLocalShowDaysPlayedInsteadOfStreak] = React.useState(showDaysPlayedInsteadOfStreak);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const save = () => {
    setSaving(true);
    $.ajax({
      data: {
        user_language_pairing: {
          show_days_played_instead_of_streak: localShowDaysPlayedInsteadOfStreak
        }
      },
      method: "put",
      url: languagePairingUrl
    })
      .done(() => {
        setSaving(false);
        $("#streak-toggle-btn-modal").modal("hide");
        onChange(localShowDaysPlayedInsteadOfStreak);
      })
      .fail(() => {
        alert("Oh no! There was an error saving the change. Sorry about that. Please try again and let us know if you see this message again.");
      });
  };

  return (
    <>
      <button className="btn btn-default btn-xs" onClick={() => setModalVisible(true)}><Icon name="cog" /></button>
      {modalVisible && (
        <Modal
          id="streak-toggle-btn-modal"
          onHidden={() => setModalVisible(false)}
          size="small"
          title="Toggle Streak"
        >
          <p>Don't care for streaks? Switch to show total days played instead.</p>
          <Radio checked={!localShowDaysPlayedInsteadOfStreak} disabled={saving} label="Streak" name="showDaysPlayedInsteadOfStreak" onChange={() => setLocalShowDaysPlayedInsteadOfStreak(false)} />
          <Radio checked={localShowDaysPlayedInsteadOfStreak} disabled={saving} label="Days Played" name="showDaysPlayedInsteadOfStreak" onChange={() => setLocalShowDaysPlayedInsteadOfStreak(true)} />
          <button className="btn btn-success btn-block joystix" disabled={saving} onClick={() => save()}>Save</button>
          {/*
          Toggle streak
          userLanguagePairing.useDaysPlayedInsteadOfStreak = true

          Days Played
          54
          then need to update 1. round results, 2. language pairing drop down, 3. profile
          */}
        </Modal>
      )}
    </>
  );
};

export default function V3StreakPanel({ activeMobilePanels, currentStreakDays, currentStreakIncludesToday, dailyReminderEmail, dailyReminderEmailUrl, daysPlayedCount, languagePairingUrl, onShowDaysPlayedInsteadOfStreakChange, showDaysPlayedInsteadOfStreak, timeZone }) {
  return (
    <Panel className={`tw-mb-0 ${activeMobilePanels === 1 ? "tw-hidden" : ""} md:tw-block`} bodyClassName="tw-p-4">
      <div className="tw-flex tw-flex-row tw-items-start">
        <div className="tw-font-bold tw-flex-1 tw-uppercase">
          {showDaysPlayedInsteadOfStreak ? "Days Played" : "Streak"} <small style={{ fontWeight: "normal" }}><a href="/settings#timezone">({timeZone})</a></small>
        </div>
        <DailyReminderBtn
          dailyReminderEmail={dailyReminderEmail}
          dailyReminderEmailUrl={dailyReminderEmailUrl}
        />
        <StreakToggleBtn
          languagePairingUrl={languagePairingUrl}
          onChange={onShowDaysPlayedInsteadOfStreakChange}
          showDaysPlayedInsteadOfStreak={showDaysPlayedInsteadOfStreak}
        />
      </div>
      {!showDaysPlayedInsteadOfStreak && <div className={`joystix tw-my-2.5 ${currentStreakIncludesToday ? "tw-color-streak-active" : ""}`}><span className="tw-text-4xl">{currentStreakDays.toLocaleString()}</span> Day{currentStreakDays === 1 ? "" : "s"}</div>}
      {showDaysPlayedInsteadOfStreak && <div className={`joystix tw-my-2.5 ${currentStreakIncludesToday ? "tw-color-streak-active" : ""}`}><span className="tw-text-4xl">{daysPlayedCount.toLocaleString()}</span> Day{daysPlayedCount === 1 ? "" : "s"}</div>}
      {currentStreakIncludesToday && <div>Includes today! <Icon style={{ color: "#27bb4e" }} name="ok" /></div>}
      {/*
      <div className="tw-flex tw-flex-row tw-gap-2">
        <Icon name="heart" />
        <Icon name="heart" />
        <Icon name="heart" />
        <Icon name="question-sign" />
      </div>
      */}
    </Panel>
  );
}
