import React from "react";
import ReactDOM from 'react-dom';

import FadeOut from "./FadeOut";

export default class Toast extends React.Component {
  componentDidMount() {
    const { content, context } = this.props;

    clearTimeout(window.toastTimeout);
    $("#toast").remove();

    $("body").append(`
      <div class="alert alert-${context}" id="toast" role="alert" style="bottom: 15px; font-weight: bold; margin-bottom: 0; max-width: 150px; position: fixed; right: 15px; z-index: 1050">
        ${content}
      </div>
    `);

    window.toastTimeout = setTimeout(() => $("#toast").fadeOut(), 3000);
  }

  render() {
    return null;
  }
}
