import React from "react";

import DailyReminderModal from "./DailyReminderModal";
import Icon from "./Icon";

export default class DailyReminderBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dailyReminderEmail: props.dailyReminderEmail,
      modalVisible: false
    };
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    return (
      <DailyReminderModal
        dailyReminderEmail={this.state.dailyReminderEmail}
        onHidden={() => this.setState({ modalVisible: false })}
        onUpdate={(dailyReminderEmail) => {
          this.setState({ dailyReminderEmail }, () => {
            this.modal.hide();
            this.props.onUpdate && this.props.onUpdate(dailyReminderEmail);
          });
        }}
        ref={(el) => this.modal = el}
        updateUrl={this.props.updateUrl}
      />
    );
  }

  render() {
    const { btnClassName, children, data, isSignedIn, style } = this.props;

    return (
      <>
        <button className={btnClassName || "btn btn-default btn-lg"} {...data} disabled={!isSignedIn} onClick={() => this.setState({ modalVisible: true })} style={style} title="Daily reminder">{children || <Icon name="bell" />}</button>
        {this.renderModal()}
      </>
    );
  }
}
