import React from "react";

import Modal from "./Modal";

export default class ModalControl extends React.Component {
  componentDidMount() {
  }

  componentWillUnmount() {
  }

  renderControl() {
    return this.props.control;
  }

  renderModal() {
    return ReactDOM.createPortal(
      <Modal>
        hi
        {this.props.modalContent}
      </Modal>,
      document.querySelector('body')
    );
  }

  render() {
    return (
      <span>
        {this.renderControl()}
        {this.renderModal()}
      </span>
    );
  }
}
