import React from "react";

export default function SelectionToken({ token }) {
  if(!token) {
    return null;
  }

  const { lemma, morphologicalFeatures, posTag, text } = token;

  let parts = [
    posTag && posTag.label,
    lemma && lemma.text !== text && lemma.text
  ];

  const str = parts.filter((p) => !!p).join(" / ");

  if(!str) {
    return null;
  }

  return (
    <p>
      {str}
      {morphologicalFeatures.length ? " / " : ""}
      {morphologicalFeatures.map((m, index) => (
        <span key={index}>
          <abbr title={`${m.key}: ${m.valueLabel.charAt(0).toUpperCase() + m.valueLabel.slice(1)}`}>{m.value.toLowerCase()}</abbr>
          {index === morphologicalFeatures.length - 1 ? "" : " / "}
        </span>
      ))}
    </p>
  );
}
