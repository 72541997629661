import React from "react";

import CollectionClozeSentenceChange from "./CollectionClozeSentenceChange";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class CollectionClozeSentenceChangesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: null,
      loading: true
    };
  }

  componentDidMount() {
    this.loadChanges();
  }

  loadChanges() {
    const { url } = this.props;

    $.ajax({
      url
    })
      .done((data) => {
        this.setState({
          changes: data.collectionClozeSentenceChanges,
          loading: false
        });
      })
      .fail(() => {
        alert("Oh no! There was an error loading the changes. Sorry about that. Please try again and let someone know if you see this message again.");
      });
  }

  renderContent() {
    const { changes, loading } = this.state;

    if(loading) {
      return "Loading...";
    }

    if(!changes || !changes.length) {
      return "No changes!";
    }

    return changes.map((c, i) => (
      <CollectionClozeSentenceChange
        change={c}
        key={i}
      />
    ));
  }

  render() {
    const { onHidden } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={onHidden}
        size="large"
        title="Collection Cloze Sentence Changes"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
