import React from "react";

export default function StatPanel(props) {
  return (
    <div className={'stat-panel ' + props.className}>
      <div className="title">{props.title}</div>
      <div className="value">{props.value}</div>
      {props.children}
    </div>
  );
};
