import React from "react";

import DailyGoalModal from "../DailyGoalModal";
import Icon from "../Icon";
import Panel from "../Panel";

export default function V3DailyGoalPanel({ activeMobilePanels, hoursLeft, numPointsToday, pointsPerDay, updateUrl }) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [thisPointsPerDay, setThisPointsPerDay] = React.useState(pointsPerDay);

  let modal = null;

  return (
    <Panel className={`tw-mb-0 ${activeMobilePanels === 1 ? "tw-hidden" : ""} md:tw-block`} bodyClassName="tw-p-4">
      <div className="tw-flex tw-flex-row">
        <div className="tw-font-bold tw-flex-1 tw-uppercase">Daily Goal</div>
        {thisPointsPerDay && <button className="btn btn-default btn-xs" onClick={() => setModalVisible(true)}><Icon name="cog" /></button>}
      </div>
      {thisPointsPerDay && (
        <>
          <div className="tw-my-2.5">
            <span className="joystix"><span className={`tw-text-4xl ${numPointsToday >= thisPointsPerDay ? "tw-color-success" : ""}`}>{numPointsToday}</span> / {thisPointsPerDay}</span>
          </div>
          <div>Points today</div>
        </>
      )}
      {!thisPointsPerDay && (
        <>
          <div>Learning a language requires practice every day. Stay motivated!</div>
          <button className="btn btn-default joystix btn-xs btn-block tw-mt-2" onClick={() => setModalVisible(true)}>
            Set <span className="tw-hidden sm:tw-inline">a daily </span>goal
          </button>
        </>
      )}
      {modalVisible && (
        <DailyGoalModal
          onHidden={() => setModalVisible(false)}
          onUpdate={({ pointsPerDay }) => { setThisPointsPerDay(pointsPerDay); modal.hide(); }}
          pointsPerDay={thisPointsPerDay}
          ref={(el) => modal = el}
          targetLanguageName={""}
          updateUrl={updateUrl}
        />
      )}
    </Panel>
  );
}
