import React from "react";

import Icon from "../Icon";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";

export default class FullHistoryBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      proPromoModalVisible: false
    };
  }

  renderProPromoModal() {
    if(!this.state.proPromoModalVisible) {
      return null;
    }

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={() => this.setState({ proPromoModalVisible: false })}
        title="Daily Stats"
      >
        <ModalProPromo
          primary={(
            <>View your full history and all your daily stats with <span className="joystix">Clozemaster Pro</span>.</>
          )}
          secondary="Take control of your learning. Get fluent faster."
        />
      </Modal>
    );
  }

  render() {
    const { dailyStatsUrl, disabled, style, user } = this.props;

    if(!dailyStatsUrl) {
      return null;
    }

    const content = (
      <>
        <Icon name="history" type="fa" />
      </>
    );

    const defaultProps = Object.assign({
      className: "btn btn-default btn-lg",
      "data-toggle": "tooltip",
      "data-html": true,
      title: "<span style='white-space: nowrap;'>Full history</span>"
    }, { disabled, style });

    // if pro, link
    if(user && user.isPro) {
      return <a {...defaultProps} href={dailyStatsUrl}>{content}</a>;
    }
    
    // else button to pro promo modal
    return (
      <>
        <button {...defaultProps} onClick={() => this.setState({ proPromoModalVisible: true })}>
          {content}
        </button>
        {this.renderProPromoModal()}
      </>
    );
  }
}
