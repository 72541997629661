import React from "react";

export default function ModalProPromo(props) {
  const primary = !!props.primary ? props.primary : (
    <>
      <span className="joystix">Clozemaster Pro</span> subscribers can {props.can}
    </>
  );
  const secondary = props.secondary || 'Get fluent faster.';

  return (
    <div className="text-center modal-pro-promo" style={{ fontSize: '1.5em' }}>
      <p style={{ marginBottom: 20 }}>{primary}</p>
      <p style={{ marginBottom: 20 }}>{secondary}</p>
      <a href="/pro" className="joystix btn btn-success btn-block btn-lg">Get Pro Today!</a>
    </div>
  );
};

