import React from "react";

import FlagSprite from "../FlagSprite";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import Rankings from "../Rankings";

export default class RankingsModal extends React.Component {
  render() {
    const { baseLanguageFlagIso, onHidden, targetLanguageFlagIso } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={onHidden}
        ref={(el) => this.modal = el}
        title={(
          <><FlagSprite size={24} flagIso={targetLanguageFlagIso} /> / <FlagSprite size={24} flagIso={baseLanguageFlagIso} /> Leaderboard</>
        )}
      >
        <Rankings {...this.props} />
      </Modal>
    );
  }
}

