import React from "react";

import Icon from "../Icon";
import Panel from "../Panel";
import RankingsModal from "./RankingsModal";

import { ordinalSuffix } from "../../helpers";

export default function V3LeaderoardPanel({ activeMobilePanels, baseLanguageFlagIso, currentWeekLeaderboardRank, rankingsUrl, targetLanguageFlagIso }) {
  const [modalVisible, setModalVisible] = React.useState(false);

  return (
    <Panel className={`tw-mb-0 ${activeMobilePanels === 0 ? "tw-hidden" : ""} md:tw-block`} bodyClassName="tw-p-4">
      <div className="tw-flex tw-flex-row tw-justify-between">
        <span className="tw-font-bold tw-uppercase">Leaderboard</span>
        <span data-toggle="tooltip" title="This week in points, UTC">
          <Icon name="question-sign" />
        </span>
      </div>
      <div className="joystix tw-my-2.5"><span className="tw-text-4xl">{currentWeekLeaderboardRank ? currentWeekLeaderboardRank.toLocaleString() : "N/A"}</span>{currentWeekLeaderboardRank && ordinalSuffix(currentWeekLeaderboardRank)}</div>
      <button className="btn btn-default btn-block joystix btn-xs" onClick={() => setModalVisible(true)}>View</button>
      {modalVisible && (
        <RankingsModal
          baseLanguageFlagIso={baseLanguageFlagIso}
          onHidden={() => setModalVisible(false)}
          rankingsUrl={rankingsUrl}
          targetLanguageFlagIso={targetLanguageFlagIso}
        />
      )}
    </Panel>
  );
}
