import React from "react";

import CollectionsModalTable from "./CollectionsModalTable";
import Icon from "../Icon";
import Modal from "../Modal";
import Panel from "../Panel";

export default class V3ReviewPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    };
  }

  onReviewModalBtnClick() {
    const collections = this.props.collections.filter((c) => c.numReadyForReview);
    if(collections.length > 1) {
      this.setState({ modalVisible: true })
    }
    else {
      this.props.onPlay(collections[0]);
    }
  }

  onReviewClick(collection) {
    this.modal.hide({ onHidden: () => this.props.onPlay(collection) });
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    return (
      <Modal
        onHidden={() => this.setState({ modalVisible: false })}
        ref={(el) => this.modal = el}
        title="Ready for Review"
      >
        <button
          className="btn btn-lg btn-success btn-block joystix"
          onClick={() => this.onReviewClick({ name: 'All', playWebUrl: this.props.playLanguagePairingUrl })}
        >
            Review All <Icon name="chevron-right" />
        </button>
        <CollectionsModalTable
          attr="numReadyForReview"
          btnClassName="btn-success"
          btnIcon={<Icon name="chevron-right" />}
          btnText="Review"
          collections={this.props.collections.filter((c) => c.numReadyForReview)}
          onBtnClick={(collection) => this.onReviewClick(collection)}
        />
      </Modal>
    );
  }

  render() {
    const {
      activeMobilePanels,
      onReviewSettingsBtnClick,
      numReadyForReview,
      todayNumReview
    } = this.props;

    return (
      <Panel className={`tw-mb-0 ${activeMobilePanels === 1 ? "tw-hidden" : ""} md:tw-block`} bodyClassName="tw-p-4">
        <div className="tw-flex tw-flex-row tw-items-start">
          <div className="tw-font-bold tw-flex-1 tw-uppercase">Ready for Review</div>
          <button className="btn btn-default btn-xs" onClick={() => onReviewSettingsBtnClick()}><Icon name="cog" /></button>
        </div>
        <div className="joystix tw-text-4xl tw-my-2.5">
          {numReadyForReview.toLocaleString()} {todayNumReview > 0 && numReadyForReview === 0 &&<>🙌</>}
        </div>
        <button
          className={`btn btn-success${numReadyForReview ? "" : "-outline"} joystix btn-block btn-sm`}
          disabled={!numReadyForReview}
          onClick={() => this.onReviewModalBtnClick()}
        >
          Review
        </button>
        {this.renderModal()}
      </Panel>
    );
  }
}
