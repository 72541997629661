import React from "react";

export default class Checkbox extends React.Component {
  render() {
    const { checked, id, label, name, onChange, style, value } = this.props;

    return (
      <div className="checkbox" style={style}>
        <label>
          <input
            checked={!!checked}
            id={id}
            name={name}
            onChange={onChange}
            type="checkbox"
            value={value}
          />
          {label}
        </label>
      </div>
    );
  }
}

