import React from "react";

import ReportErrorModal from "./ReportErrorModal";

export default class ReportErrorBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    };
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    const { collectionClozeSentence, modalBackdrop, url } = this.props;

    return (
      <ReportErrorModal
        backdrop={modalBackdrop}
        collectionClozeSentence={collectionClozeSentence}
        onHidden={() => this.setState({ modalVisible: false })}
        url={url}
      />
    );
  }

  render() {
    const { className, disabled, showText, title } = this.props;

    return (
      <>
        <button className={className || "report-error btn btn-danger"} disabled={disabled} onClick={() => this.setState({ modalVisible: true })}>
          <span className="glyphicon glyphicon-flag"></span>{showText !== false && " Report"}
        </button>
        {this.renderModal()}
      </>
    );
  }
}
