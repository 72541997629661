import React from "react";

import TextareaAutosize from 'react-textarea-autosize';

import FlagSprite from "./FlagSprite";
import Icon from "./Icon";

export default class ActivityLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: props.comments,
      commentText: '',
      likes: props.likes,
      liking: false,
      savingComment: false
    };
  }

  componentDidMount() {
    $(this.container).tooltip({ selector: '.likes .count' });
  }

  renderPinContent() {
    const { isLeveledUp, languagePairing } = this.props;

    if(languagePairing) {
      return (
        <div className="flags">
          <FlagSprite flagIso={languagePairing.targetLanguageFlagIso} size={48} />
          <FlagSprite flagIso={languagePairing.baseLanguageFlagIso} size={24} />
        </div>
      );
    }
    else if(isLeveledUp) {
      return (
        <span className="glyphicon glyphicon-arrow-up user-leveled-up" />
      );
    }
  }

  toggleLike() {
    this.setState({ liking: true });

    const currentUserLike = this.currentUserLike();
    const isLiked = !!currentUserLike;

    $.ajax({
      method: isLiked ? 'delete' : 'post',
      url: isLiked ? currentUserLike.url : this.props.likeUrl
    })
      .done((data) => {
        this.setState({
          likes: isLiked ? this.state.likes.filter((l) => l.id !== currentUserLike.id) : data.activityLogLikes,
          liking: false
        });
      })
      .fail(() => {
        alert('Oh no! There was an error updating. Please try again and let us know if you see this message again.');
        this.setState({ liking: false });
      });
  }

  isLiked() {
    return !!this.currentUserLike();
  }

  currentUserLike() {
    const { likes } = this.state;
    const { currentUser } = this.props;

    return likes.find((l) => l.user.username === currentUser.username);
  }

  renderLikes() {
    const { currentUser, isSignedIn, user } = this.props;
    const { likes, liking } = this.state;

    if(!isSignedIn) {
      return null;
    }

    const tooltipProps = likes.length ? { 'data-toggle': 'tooltip', 'data-html': 'true', title: likes.map((l) => l.user.username).join('<br />') } : {};

    return (
      <div className="likes">
        {currentUser.username !== user.username && (
          <button
            className={'btn ' + (this.isLiked() ? 'btn-success active' : 'btn-default')}
            disabled={liking}
            onClick={() => this.toggleLike()}
            style={{ marginRight: 10 }} 
          >
            G{this.isLiked() ? 'a' : 'i'}ve 🙌
          </button>
        )}
        {(currentUser.username !== user.username || !!likes.length) && (
          <small className="count" {...tooltipProps}>
            {likes.length ? (likes.length + 'x 🙌') : 'Be the first!'}
          </small>
        )}
      </div>
    );
  }

  onCommentSubmit(e) {
    e.preventDefault();

    if(!this.state.commentText || this.state.savingComment) {
      return false;
    }

    this.setState({ savingComment: true });

    $.ajax({
      data: { text: this.state.commentText },
      method: 'post',
      url: this.props.commentUrl
    })
      .done((data) => {
        if(data.errors) {
          alert(data.errors);
          this.setState({ savingComment: false });
          return false;
        }
        this.setState({ commentText: '', comments: data.activityLogComments, savingComment: false });
      })
      .fail(() => {
        alert('Oh no! There was an error saving. Please try again and let us know if you see this message again.');
        this.setState({ savingComment: false });
      });
  }

  deleteComment(c) {
    if(!confirm('Are you sure you want to delete this comment? This action cannot be undone.')) {
      return false;
    }

    const oldComments = this.state.comments.slice(0);

    this.setState({ comments: this.state.comments.filter((comment) => comment.id !== c.id) });

    $.ajax({
      method: 'delete',
      url: c.url
    })
      .fail(() => {
        this.setState({ comments: oldComments });
        alert('Oh no! There was an error deleting the comment. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  renderComments() {
    return null;

    if(!this.props.isSignedIn) {
      return null;
    }

    const { currentUser } = this.props;
    const { comments } = this.state;

    return (
      <div className="comments">
        {comments.map((c) => (
          <div className="comment">
            <div className="header">
              <small>
                <a href={c.user.url}>{c.user.username}</a> {c.createdAtAgo} ago
                {c.user.username === currentUser.username && <> <button className="btn btn-xs btn-link" aria-label="delete" onClick={() => this.deleteComment(c)}><span className="glyphicon glyphicon-trash" /></button></>}
              </small>
            </div>
            <div className="text">{c.text}</div>
          </div>
        ))}
        <form className="form" onSubmit={(e) => this.onCommentSubmit(e)}>
          <div className="display-table">
            <div className="display-table-row">
              <div className="display-table-cell">
                <TextareaAutosize
                  className="form-control new-comment"
                  disabled={this.state.savingComment}
                  name="comment"
                  onChange={(e) => this.setState({ commentText: e.target.value })}
                  placeholder="Write a comment..."
                  value={this.state.commentText}
                />
              </div>
              <div className="display-table-cell">
                <button className="btn btn-default" disabled={this.state.savingComment}>Post</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  onDeleteClick() {
    if(!confirm("Are you sure? This activity log on your journey to fluency will be lost forever!")) {
      return false;
    }

    const { url } = this.props;

    this.setState({ deleted: true });

    $.ajax({
      method: "delete",
      url
    })
      .done(() => {
        // do nothing
      })
      .fail(() => {
        this.setState({ deleted: false });
        alert("Oh no! There was an error deleting the activity log. Sorry about that. Please refresh the page and try again, and let us know if you see this message again.");
      });
  }

  renderDeleteBtn() {
    const { currentUser, isSignedIn, user } = this.props;

    if(isSignedIn && currentUser.username === user.username) {
      return (
        <button className="btn btn-link btn-sm delete" onClick={() => this.onDeleteClick()}>
          <Icon name="trash" />
        </button>
      );
    }
  }

  render() {
    const { createdAtAgo, currentUser, description, user } = this.props;
    const { deleted } = this.state;

    if(deleted) {
      return null;
    }

    return (
      <div className="activity-log" ref={(el) => this.container = el}>
        <div className="pin">{this.renderPinContent()}</div>
        <div className="content">
          <div className="header">
            <span className="username"><a href={user.url}>{user.username}</a></span>
            <small style={{ marginLeft: 10 }}>{createdAtAgo} ago</small>
            {this.renderDeleteBtn()}
          </div>
          <div className="event">
            <strong>{description}</strong>
          </div>
          {this.renderLikes()}
          {this.renderComments()}
        </div>
      </div>
    );
  }
}
