import React from "react";

import CollectionClozeSentenceRow from "./CollectionClozeSentenceRow";
import Icon from "../Icon";
import Pagination from "../Pagination";
import SearchForm from "./ClozeSentenceSearch/SearchForm";

export default class CollectionClozeSentences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionClozeSentences: [],
      context: props.context || "text",
      excludeModerated: false,
      loading: false,
      order: "difficulty_asc",
      page: 1,
      perPage: 100,
      query: props.query || '',
      total: 0,
      withHint: "",
      withNotes: ""
    };
  }

  componentDidMount() {
    this.loadCollectionClozeSentences();

    $("body").tooltip({ selector: "[data-toggle=tooltip]" });
  }

  loadCollectionClozeSentences() {
    this.setState({ loading: true });

    const { url } = this.props;
    const {
      context,
      excludeModerated,
      order,
      page,
      perPage,
      query,
      withHint,
      withNotes
    } = this.state;

    $.ajax({
      data: {
        context,
        exclude_moderated: excludeModerated,
        include_stats: true,
        include_tts: true,
        order,
        page,
        per_page: perPage,
        query,
        with_conditions: {
          hint: withHint,
          notes: withNotes
        }
      },
      url
    })
      .done((data) => {
        console.log(data);
        this.setState({
          collectionClozeSentences: data.collectionClozeSentences,
          loading: false,
          relativeNextPageUrl: data.relativeNextPageUrl,
          total: data.total
        });
      })
      .fail(() => {
        alert("Error loading sentences! Please tell someone.");
        this.setState({ loading: false });
      });
  }

  onSearchSubmit(e) {
    e.preventDefault();
    this.setState({ page: 1 }, () => this.loadCollectionClozeSentences());
    return false;
  }

  renderPagination() {
    const { loading, page, perPage, total } = this.state;

    if(loading) {
      return null;
    }

    return (
      <Pagination
        onNextClick={() => this.setState({ page: page + 1 }, () => this.loadCollectionClozeSentences())}
        onPrevClick={() => this.setState({ page: page - 1 }, () => this.loadCollectionClozeSentences())}
        page={page}
        perPage={perPage}
        total={total}
        updating={loading}
      />
    );
  }

  render() {
    const {
      collectionClozeSentences,
      context,
      excludeModerated,
      loading,
      order,
      query,
      withHint,
      withNotes
    } = this.state;
    const { languagePairing, testEnv } = this.props;

    if(loading) {
      return "Loading...";
    }

    return (
      <div>
        <SearchForm
          context={context}
          disabled={loading}
          excludeModerated={excludeModerated}
          onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
          onSubmit={(e) => this.onSearchSubmit(e)}
          order={order}
          query={query}
          withHint={withHint}
          withNotes={withNotes}
        />
        <br />
        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ whiteSpace: "nowrap" }}>
                Moderated
                <span data-toggle="tooltip" data-trigger="hover" style={{ marginLeft: 4 }} title="Check this option once you've confirmed this is a high quality sentence + translation with good TTS.">
                  <Icon name="question-sign" />
                </span>
              </th>
              <th>Sentence</th>
              {/*<th>Has TTS</th>*/}
              <th style={{ whiteSpace: "nowrap" }}>
                Played
                <span data-toggle="tooltip" data-trigger="hover" style={{ marginLeft: 4 }} title="Played, Incorrect, and Ignored stats are updated once per day.">
                  <Icon name="question-sign" />
                </span>
              </th>
              <th>Incorrect</th>
              <th>Ignored</th>
              <th>Tokens</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              {/*<th></th>*/}
            </tr>
          </thead>
          <tbody>
            {collectionClozeSentences.map((ccs) => (
              <CollectionClozeSentenceRow
                collectionClozeSentence={ccs}
                key={ccs.id}
                languagePairing={languagePairing}
                onUpdate={(attrs) => {
                  this.setState({
                    collectionClozeSentences: collectionClozeSentences.map((existing) => existing.id === ccs.id ? Object.assign(ccs, attrs) : existing)
                  });
                }}
                testEnv={testEnv}
              />
            ))}
          </tbody>
        </table>
        {this.renderPagination()}
      </div>
    );
  }
}
