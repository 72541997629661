import React from "react";

import ClozeSentenceEntry from "../mods/ClozeSentenceEntry";
import CollectionClozeSentenceChangesModal from "../mods/CollectionClozeSentenceChangesModal";
import CollectionClozeSentenceEditor from "../mods/CollectionClozeSentenceEditor";
import CollectionClozeSentenceTts from "../mods/CollectionClozeSentenceTts";
import TokensEditor from "../mods/TokensEditor";

export default class CollectionClozeSentenceRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorVisible: false,
      resourcesEditorVisible: false,
      tokensCount: props.collectionClozeSentence.tokensCount,
      tokensEditorVisible: false,
      ttsEditorVisible: false,
    };
  }

  renderTokensEditor() {
    if(!this.state.tokensEditorVisible) {
      return null;
    }

    const { collectionClozeSentence, testEnv } = this.props;

    return (
      <TokensEditor
        clozeText={collectionClozeSentence.text}
        onHidden={({ tokensCount }) => this.setState({ tokensCount, tokensEditorVisible: false })}
        testEnv={testEnv}
        tokensUrl={collectionClozeSentence.tokensUrl}
      />
    );
  }

  renderTtsModal() {
    if(!this.state.ttsEditorVisible) {
      return false;
    }

    const { collectionClozeSentence } = this.props;

    return (
      <CollectionClozeSentenceTts
        clozeSentenceTtsJobUrl={collectionClozeSentence.clozeSentenceTtsJobUrl}
        clozeSentenceUrl={collectionClozeSentence.clozeSentenceUrl}
        collectionClozeSentenceTtsJobUrl={collectionClozeSentence.collectionClozeSentenceTtsJobUrl}
        collectionClozeSentenceUrl={collectionClozeSentence.url}
        onHidden={() => this.setState({ ttsEditorVisible: false })}
      />
    );
  }

  renderEditorModal() {
    if(!this.state.editorVisible) {
      return false;
    }

    const { collectionClozeSentence, onUpdate } = this.props;

    return (
      <CollectionClozeSentenceEditor
        collectionClozeSentence={collectionClozeSentence}
        collectionClozeSentenceUrl={collectionClozeSentence.url}
        clozeSentenceUrl={collectionClozeSentence.clozeSentenceUrl}
        onHidden={() => this.setState({ editorVisible: false })}
        onUpdate={onUpdate}
      />
    );
  }

  renderChangesModal() {
    const { collectionClozeSentence } = this.props;
    const { changesModalVisible } = this.state;

    if(!changesModalVisible) {
      return null;
    }

    return (
      <CollectionClozeSentenceChangesModal
        onHidden={() => this.setState({ changesModalVisible: false })}
        url={collectionClozeSentence.collectionClozeSentenceChangesUrl}
      />
    );
  }

  renderModals() {
    return (
      <>
        {this.renderChangesModal()}
        {this.renderEditorModal()}
        {this.renderTtsModal()}
        {this.renderTokensEditor()}
      </>
    );
  }

  renderModerated() {
    const { collectionClozeSentence, onUpdate } = this.props;

    if(collectionClozeSentence.moderated) {
      return "✅";
    }

    return (
      <input
        type="checkbox"
        onChange={() => {
          $.ajax({
            contentType: "application/json",
            data: JSON.stringify({
              collection_cloze_sentence: { moderated: true }
            }),
            method: "patch",
            url: collectionClozeSentence.url
          })
            .fail(() => {
              alert("Oh no! There was an error marking that sentence as moderated. Please refresh the page and try again, and let someone know if you see this message again.");
            });

          onUpdate({ moderated: true });
        }}
      />
    );
  }

  renderAudio() {
    const { collectionClozeSentence } = this.props;
    const { tts } = collectionClozeSentence;

    if(!tts || !tts.length) {
      return null;
    }

    // if(tts.length > 1) {
      return (
        <>
          <hr />
          <ul>
            {tts.map((t) => (
              <li key={t.id}>
                <strong>{t.voice_id}</strong>
                <br />
                <audio controls preload="none" src={t.audioUrl} />
              </li>
            ))}
          </ul>
        </>
      );
    // }
    // else {
    //   return (
    //     <audio controls preload="none" src={tts[0].audioUrl} />
    //   );
    // }
  }

  render() {
    const { collectionClozeSentence, languagePairing } = this.props;
    const { jsonVisible, tokensCount } = this.state;
    const { baseLanguageIso, targetLanguageIso } = (languagePairing || {});

    return (
      <tr className="collection-cloze-sentence">
        <td className="text-center">{this.renderModerated()}</td>
        <td>
          <ClozeSentenceEntry
            baseLanguageIso={baseLanguageIso}
            clozeSentence={collectionClozeSentence}
            clozeSentenceStyle={{ fontSize: "1.5em" }}
            includeTranslationLinks={!!baseLanguageIso && !!targetLanguageIso}
            targetLanguageIso={targetLanguageIso}
          />
          <div>{this.renderAudio()}</div>
          <div><small><button className="btn btn-xs btn-link" onClick={() => this.setState({ jsonVisible: !jsonVisible })}>{jsonVisible ? "Less" : "More"}</button></small></div>
          {!!jsonVisible && (
            <pre style={{ maxHeight: 200, maxWidth: 400 }}>{JSON.stringify(collectionClozeSentence, null, 2)}</pre>
          )}
        </td>
        {/*<td>{!!collectionClozeSentence.ttsAudioUrl ? "✅" : "❌"}</td>*/}
        <td>{collectionClozeSentence.numPlayed} 🎮</td>
        <td>{collectionClozeSentence.numIncorrect} ❌</td>
        <td>{collectionClozeSentence.numIgnored} 🚫</td>
        <td>{tokensCount}</td>
        <td>
          <p><button className="btn btn-default btn-block" onClick={() => this.setState({ editorVisible: true })}>Edit</button></p>
          <button className="btn btn-xs btn-default" onClick={() => this.setState({ changesModalVisible: true })}>Changes</button>
        </td>
        <td><button className="btn btn-default" onClick={() => this.setState({ ttsEditorVisible: true })}>TTS</button></td>
        <td><button className="btn btn-default" onClick={() => this.setState({ tokensEditorVisible: true })}>Tokens</button></td>
        <td><button className="btn btn-link btn-xs" onClick={() => alert("Please try editing/changing the sentence but keeping the cloze word the same. If you'd really like to delete it, please let someone know. We'll get this implemented soon!")}>Delete</button></td>
        {/*<td>Resources</td>*/}
        {this.renderModals()}
      </tr>
    );
  }
}
