import React from "react";

import Icon from "../Icon";
import Modal from "../Modal";
import ShortcutsList from "./ShortcutsList";

export default class HelpModal extends React.Component {
  runTutorial() {
    const { runTutorial } = this.props;

    this.modal.hide({
      onHidden: () => {
        $("html, body").animate({ scrollTop: 0 });
        runTutorial();
      }
    });
  }

  renderIconTable() {
    if(!this.props.isPlayingTextInput) {
      return null;
    }

    return (
      <table className="table">
        <tbody>
          <tr>
            <td><span className="glyphicon glyphicon-transfer"></span></td>
            <td>alt+t</td>
            <td>Toggle translation</td>
          </tr>
          <tr>
            <td><Icon name="th-large" /></td>
            <td>alt+p</td>
            <td>Switch sentence to multiple choice - half points!</td>
          </tr>
          <tr>
            <td><Icon name="question-sign" /></td>
            <td>alt+h</td>
            <td>Single letter hint - only 1 per sentence and half the points!</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const { isUsingSrsControls } = this.props;

    return (
      <Modal
        id="help-modal"
        ref={(el) => this.modal = el}
        show={false}
        title="Help"
      >
        <h4 style={{ marginTop: 0 }}>Complete the sentence with the correct missing word!</h4>
        <p><button className="btn btn-default btn-block" onClick={this.runTutorial.bind(this)}>👉 Run Tutorial 👈</button></p>
        <p>Points are determined as follows:</p>
        <p className="text-center">
          <kbd style={{ display: 'inline-block' }}>(4 points for multiple choice, 8 points for text input)<br />&nbsp;x (<span className="joystix">% Mastered</span> divided by 25)</kbd>
        </p>
        <ul>
          <li>Points are cut in half if you use a hint while playing text input or you review a sentence before it's ready for review.</li>
          <li>An incorrect answer gets 0 points.</li>
          <li>Playing favorites doesn't use the same equation - correct answers always get 2 points.</li>
        </ul>
        {this.renderIconTable()}
        <p>
          Clozemaster uses a spaced-repetition system to help maximize your learning efficiency with sentences starting at 0% Mastered. If a sentence is answered correctly it goes to 25% Mastered and is set to review the next day. Correct again? It goes to 50% Mastered and is set to review 10 days in the future. 75% Mastered is 30 days in the future, and 100% Mastered is 180 days in the future. Incorrect at any point resets the sentence to 0% Mastered. Pro users can customize these intervals via the Dashboard.
        </p>
        {isUsingSrsControls && (
          <>
            <p>
              Once a sentence is 100% Mastered, each additional correct answer results in the review interval getting larger using a variation of the <a href="https://www.supermemo.com/en/archives1990-2015/english/ol/sm2" target="_blank">SuperMemo 2 algorithm</a>.
            </p>
            <p>
              If a sentence is 100% Mastered and answered correctly again, you'll see three buttons: <span className="btn btn-xs btn-danger joystix">Hard</span> <span className="btn btn-xs btn-warning joystix">Normal</span> <span className="btn btn-xs btn-success joystix">Easy</span>. These buttons are used to help determine the next review due date for the sentence. Hard will result in the next review being a bit sooner, and Easy will result in the next review being a bit further in the future.
            </p>
          </>
        )}
        <p><strong>Questions? Something else you'd like to see here? <a href="/contact" target="_blank">Let us know</a>.</strong></p>
        <p className="joystix text-center">Shortcut Hotkeys</p>
        <ShortcutsList />
      </Modal>
    );
  }
}
