import React from "react";

export default class CommonVoiceSentenceTr extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      status: props.status
    };
  }

  onStatusChange(e) {
    this.setState({ loading: true });
    $.ajax({
      data: { common_voice_sentence: { status: e.target.value } },
      method: "put",
      url: this.props.url
    })
      .done((data) => {
        this.setState({ status: data.commonVoiceSentence.status });
      })
      .fail(() => {
        alert("Error saving status! Please try again and let someone know if you see this message again.");
      })
      .always(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { audioUrl, difficulty, duration, id, text } = this.props;
    const { loading, status } = this.state;
    const statusClassName = status === "approved" ? "success" : (status === "rejected" ? "danger" : null);

    return (
      <>
        <td className={statusClassName} style={{ maxWidth: 300 }}>{text}</td>
        <td className={statusClassName}><audio controls preload="none" src={audioUrl} /></td>
        <td className={statusClassName}>{duration}s</td>
        <td className={statusClassName}>
          <div className="radio">
            <label>
              <input checked={status === "unknown"} disabled={loading} name={`status-${id}`} onChange={this.onStatusChange.bind(this)} type="radio" value="unknown" /> Unknown
            </label>
          </div>
          <div className="radio">
            <label>
              <input checked={status === "approved"} disabled={loading} name={`status-${id}`} onChange={this.onStatusChange.bind(this)} type="radio" value="approved" /> Approved
            </label>
          </div>
          <div className="radio">
            <label>
              <input checked={status === "rejected"} disabled={loading} name={`status-${id}`} onChange={this.onStatusChange.bind(this)} type="radio" value="rejected" /> Rejected
            </label>
          </div>
        </td>
      </>
    );
  }
}
