import React from "react";

import { pctStr } from "../../helpers";

import Icon from "../Icon";
import Loading from "../Loading";
import CollectionProgressBar from "./CollectionProgressBar";

export default class FastTrackStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      stats: null
    }
  }

  componentDidMount() {
    this.loadStats();
  }

  loadStats() {
    this.setState({ loading: true });

    const { collection } = this.props;

    $.ajax({
      url: collection.statsUrl
    })
      .done((data) => {
        console.log(data);
        this.setState({
          loading: false,
          stats: data.stats
        });
      })
      .fail(() => {
        alert("Oh no! There was an error loading the stats. Sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  render() {
    if(this.state.loading) {
      return <Loading />;
    }

    return (
      <div>
        <p>Fast Track stats and progress broken down by word frequency / most common words.</p>
        <p>The missing word for each sentences on the Fast Track is the least common word on a <a href="https://en.wikipedia.org/wiki/Word_lists_by_frequency" target="_blank">frequency list <Icon name="new-window" /></a> for the language. The Fast Track includes one sentence for each missing word, and the sentences are played in order of difficulty for easiest to hardest, or in other words, from most common words to least common.</p>
        <p>This breakdown shows the number and percent of incorrect answers and aligns with the Most Common Words collections so you can identify where you might want more practice.</p>
        <div>
          {this.state.stats.map((s) => (
            <div className="frequency-collection" style={{ marginBottom: 10 }}>
              <strong style={{ fontSize: '1.5em' }}>{s.name}</strong>
              <CollectionProgressBar
                style={{ height: 10, marginBottom: 5 }}
                collection={{ numPlaying: s.numPlaying, numMastered: s.numMastered, numSentences: s.numSentences }}
              />
              <ul className="list-inline">
                <li>Sentences: {s.numSentences}</li>
                <li>Playing: {s.numPlaying} ({pctStr(s.numPlaying, s.numSentences)})</li>
                <li>Mastered: {s.numMastered} ({pctStr(s.numMastered, s.numSentences)})</li>
                <li>Total Played: {s.totalPlayed}</li>
                <li>Total Incorrect: {s.totalIncorrect} ({pctStr(s.totalIncorrect, s.totalPlayed)})</li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
