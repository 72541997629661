import React from "react";

export default class FadeIn extends React.Component {
  componentDidMount() {
    $(this.el).fadeIn(() => this.props.complete && this.props.complete());
  }

  render() {
    return (
      <div ref={(el) => this.el = el} style={{ display: 'none' }}>
        {this.props.children}
      </div>
    );
  }
}
