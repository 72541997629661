import React from "react";

import Icon from "../Icon";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";

export default class AddToCollectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      via: null
    };
  }

  hide() {
    this.modal.hide(...arguments);
  }

  renderOptions() {
    return (
      <div className="text-center">
        <p>
          <button className="btn btn-success btn-block joystix" onClick={this.props.onManual}>
            Manual Entry <Icon name="chevron-right" />
          </button>
          <small>Add your own custom sentences to the collection via text input.</small>
        </p>
        <p>
          <button className="btn btn-success btn-block joystix" onClick={this.props.onSearch}>
            Search <Icon name="chevron-right" />
          </button>
          <small>Search and select sentences from Clozemaster to copy to the collection.</small>
        </p>
        <p>
          <button className="btn btn-success btn-block joystix" onClick={this.props.onPaste}>
            Paste in Text <Icon name="chevron-right" />
          </button>
          <small>Paste in a text or list of sentences and we'll select a missing word for each.</small>
        </p>
        <p>
          <button className="btn btn-success btn-block joystix" onClick={this.props.onWords}>
            Paste in Words <Icon name="chevron-right" />
          </button>
          <small>Paste in a list of words and we'll find sentences.</small>
        </p>
        <p>
          <button className="btn btn-success btn-block joystix" onClick={this.props.onFile}>
            Upload File <Icon name="chevron-right" />
          </button>
          <small>Upload your own CSV/TSV or SRT{/*CSV/TSV, SRT, or EPUB*/} file.</small>
        </p>
        {/*
        <p>
          <button disabled={true} className="btn btn-success btn-block joystix">
            File Upload <Icon name="chevron-right" />
          </button>
          <small><em><strong>Coming soon!</strong></em> Add your own custom sentences to the collection via file upload.</small>
        </p>
        <p>
          <button className="btn btn-success btn-block joystix" disabled={true}>
            Word/Phrase List <Icon name="chevron-right" />
          </button>
          <small><em><strong>Coming soon!</strong></em> Use Clozemaster sentences auto-selected for each word or phrase in a list you provide.</small>
        </p>
        <p>
          <button className="btn btn-success btn-block joystix" disabled={true}>
            From a Text <Icon name="chevron-right" />
          </button>
          <small><em><strong>Coming soon!</strong></em> Use sentences from any text, or use Clozemaster sentences auto-selected for the words in the text.</small>
        </p>
        */}
      </div>
    );
  }

  renderContent() {
    if(this.props.isPro) {
      return this.renderOptions();
    }

    return this.renderProPromo();
  }

  renderProPromo() {
    return (
      <ModalProPromo
        can="add sentences to their custom cloze-collections by manually entering their own cloze-sentences or search and adding the cloze-sentences available on Clozemaster."
        secondary="Take control of your learning. Get fluent faster."
      />
    );
  }

  render() {
    return (
      <Modal
        footer={(
          <div>
            <ModalFooterCloseBtn />
            <small style={{ display: 'block', float: 'left', textAlign: 'left' }}>Need a different option?<br /><a href="/contact" target="_blank">Let us know</a>.</small>
          </div>
        )}
        onHidden={this.props.onHidden}
        ref={(el) => this.modal = el}
        size={this.props.isPro ? "small" : ''}
        title="Add Sentences"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
