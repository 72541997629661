import React from "react";

export default class FadeOut extends React.Component {
  componentDidMount() {
    const { complete, delay } = this.props;
    $(this.el).delay(delay || 0).fadeOut(() => complete && complete());
  }

  render() {
    return (
      <div ref={(el) => this.el = el}>
        {this.props.children}
      </div>
    );
  }
}
