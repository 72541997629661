import React from "react";

import FlagSprite from "../FlagSprite";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class LeveledUpModal extends React.Component {
  componentDidMount() {
    window.clozemaster.loadTwitterSDK();
    window.clozemaster.loadFacebookSDK();
  }

  getShareMessage({ service = null }) {
    return 'I just leveled up playing ' + (service === "twitter" ? "@clozemaster" : "Clozemaster") + '! Now at level ' + this.props.level + ' learning ' + this.props.targetLanguageEnglishName + ' from ' + this.props.baseLanguageEnglishName + '.';
  }

  getTwitterShareUrl() {
    return 'https://twitter.com/intent/tweet?url=' +
      encodeURIComponent(this.props.shareUrl) +
      '&text=' +
      encodeURIComponent(this.getShareMessage({ service: "twitter" }));
  }

  onFacebookClick() {
    FB.ui({
      method: 'share',
      href: this.props.shareUrl,
      quote: this.getShareMessage()
    }, function(response){});
  }

  onTumblrClick() {
    window.open('http://tumblr.com/widgets/share/tool?posttype=text&tags=' + encodeURIComponent('langblr,studyblr,languages,language learning') + '&title=' + encodeURIComponent('Leveled up playing Clozemaster!') + '&content=' + encodeURIComponent(this.getShareMessage() + ' ' + this.props.shareUrl) + '&canonicalUrl=' + encodeURIComponent(window.location.href), '_blank', 'height=600,width=540');
  }

  render() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        id="levelup-modal"
        ref={(el) => this.modal = el}
        size="small"
        title="Leveled Up!"
      >
        <div className="text-center" ref={(el) => this.content = el}>
          <div className="challenge levelup" style={{ display: 'block' }}>
            <div className="title joystix">
              <FlagSprite flagIso={this.props.targetLanguageFlagIso} /> / <FlagSprite flagIso={this.props.baseLanguageFlagIso} />
              <div>Level:</div>
            </div>
            <div className="value">
              <div className="level">{this.props.level}</div>
              <div className="plus">+1<span className="glyphicon glyphicon-arrow-up"></span></div>
            </div>
          </div>
          <img src={this.props.imagePath} style={{ maxWidth: 250 }} />
          <h3>Tell the world!</h3>
          <button className="btn btn-primary btn-sm facebook" onClick={() => this.onFacebookClick()}>
            <i className="fa fa-facebook" aria-hidden="true"></i> Share
          </button>
          <a className="twitter-share-button" href={this.getTwitterShareUrl()} data-size="large">Tweet</a>
          <button className="btn btn-default btn-sm tumblr" onClick={() => this.onTumblrClick()}>
            <i className="fa fa-tumblr" aria-hidden="true"></i> Post
          </button>
        </div>
      </Modal>
    );
  }
}
