import React from "react";

import { splitTextOnCloze } from "../helpers";

export default class ClozeSentence extends React.Component {
  render() {
    const { preClozeStr, cloze, postClozeStr } = splitTextOnCloze(this.props.text);
    return <span className="cloze-sentence-component">{preClozeStr}<strong><u>{cloze}</u></strong>{postClozeStr}</span>;
  }
}
