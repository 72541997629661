import React from "react";

import Icon from "./Icon";

export default class SpeechSynthesisBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      voices: null
    };
  }

  componentDidMount() {
    if(!window.speechSynthesis) {
      return null;
    }

    this.voicesChangedListener = window.speechSynthesis.addEventListener("voiceschanged", () => {
      this.updateVoices();
    });

    this.updateVoices();
  }

  // TODO! clean up listener on unmount

  updateVoices() {
    const { languageCode, languageIso } = this.props;

    this.setState({
      voices: window.clozemaster.getSystemTtsVoices(languageIso, languageCode)
    });
  }

  play() {
    const { languageCode, languageIso, text } = this.props;
    const { voices } = this.state;

    window.clozemaster.playSystemUtterance(text, voices[0]);
  }

  render() {
    const { voices } = this.state;

    if(!voices || !voices.length) {
      return null;
    }

    const { className, style, text } = this.props;

    return (
      <button className={className} onClick={this.play.bind(this)} style={style} title={"Play audio " + text}>
        <Icon name="volume-up" />
      </button>
    );
  }
}
