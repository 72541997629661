import React from "react";

import AddSentenceToCollection from "./AddSentenceToCollection";
import Modal from "./Modal";
import ModalFooterCloseBtn from "./ModalFooterCloseBtn";
import ModalProPromo from "./ModalProPromo";

export default class AddSentenceToCollectionModal extends React.Component {
  hide() {
    this.modal.hide();
  }

  renderProPromo() {
    return (
      <ModalProPromo
        can="create and play their own custom collections of cloze-sentences."
        secondary="Take control of your learning. Get fluent faster."
      />
    );
  }

  reset() {
    this.addSentenceToCollection.reset();
    $('.modal').animate({ scrollTop: 0 });
  }

  renderContent() {
    if(!this.props.isPro) {
      return this.renderProPromo();
    }

    return (
      <AddSentenceToCollection
        collectionClozeSentence={this.props.collectionClozeSentence}
        collectionsUrl={this.props.collectionsUrl}
        copyable={this.props.copyable}
        initialSelectedCollectionId={this.props.initialSelectedCollectionId}
        nextReviewByLevel={this.props.nextReviewByLevel}
        onSentenceAdded={this.props.onSentenceAdded}
        ref={(el) => this.addSentenceToCollection = el}
      />
    );
  }

  render() {
    const { collectionClozeSentence } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn text="Cancel" />}
        id="add-sentence-to-collection-modal"
        onHidden={this.props.onHidden}
        onShown={() => {
          const $modal = $("#add-sentence-to-collection-modal");

          const $collectionName = $modal.find("[name='collection[name]']");
          if($collectionName.length) {
            return $collectionName.focus();
          }

          $modal.find(!!collectionClozeSentence ? ".copy-save" : "[name=text]").focus();
        }}
        ref={(el) => this.modal = el}
        title="Add Sentence to Collection"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
