import React from "react";

import ClozeSelectable from "../ClozeSelectable";

export default function SentenceEditor(props) {
  const { disabled, onTextChange, onTranslationChange, text, textError, translation, translationError } = props;
  const textWrapperClass = textError ? "form-group has-error" : "form-group";
  const translationWrapperClass = translationError ? "form-group has-error" : "form-group";
  return (
    <div>
      <p style={{ fontSize: "1.25em" }}>
        <ClozeSelectable onChange={(text) => onTextChange(text)} text={text} />
      </p>
      <div className={textWrapperClass}>
        <input
          className="form-control sentence-text"
          disabled={disabled}
          onChange={(e) => onTextChange(e.target.value)}
          placeholder="Cloze sentence"
          type="text"
          value={text}
        />
        {textError && <span className="help-block">{textError}</span>}
      </div>
      <div className={translationWrapperClass}>
        <input
          className="form-control sentence-translation"
          disabled={disabled}
          onChange={(e) => onTranslationChange(e.target.value)}
          placeholder="Translation"
          type="text"
          value={translation}
        />
        {translationError && <span className="help-block">{translationError}</span>}
      </div>
    </div>
  );
}
