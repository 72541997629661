import React from "react";

import Icon from "../Icon";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class ReportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reporting: false,
      reported: false
    };
  }

  report() {
    this.setState({ reporting: true });
    $.ajax({
      method: "post",
      url: this.props.url
    })
      .done(() => {
        this.setState({
          reporting: false,
          reported: true
        });
      })
      .fail(() => {
        alert("Oh no! There was an error reporting the sentence. Sorry about that. Please try again and let us know if you see this message again.");
        this.setState({ reporting: false });
      });
  }

  renderContent() {
    if(this.state.reported) {
      return (
        <>
          <p>Thanks for your help to improve Clozemaster!</p>
          <p><strong>Don't forget to ignore this sentence via the <Icon name="thumbs-down" /> button if you don't want to see it any more.</strong></p>
        </>
      );
    }

    return (
      <>
        <p><strong>Strong accents, poor quality, and background noise are features of Cloze-Listening to help you improve your listening skills and understand what you hear in any context.</strong></p>
        <p>But sometimes a bad recording is just a bad recording. Or perhaps the translation isn't quite right.</p>
        <p>Let us know here. If enough users report a sentence we'll remove it for everyone.</p>
        <p>
          <button
            className="btn btn-block btn-danger joystix"
            disabled={this.state.reporting}
            onClick={() => this.report()}
          >
            Report
          </button>
        </p>
        <p><strong>In the meantime, if you don't want to see this sentence any more, please ignore it via the <Icon name="thumbs-down" /> button.</strong></p>
      </>
    );
  }

  render() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={() => this.props.onHidden()}
        size="small"
        title="Report Sentence"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
