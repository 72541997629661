import React from "react";

export default function SearchForm(props) {
  const { children, context, disabled, query, onChange, onSubmit } = props;

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-xs-8">
          <div className="form-group">
            <label htmlFor="cloze-sentences-query">Query</label>
            <input
              className="form-control"
              disabled={disabled}
              id="cloze-sentences-query"
              name="query"
              onChange={onChange}
              type="text"
              value={query}
            />
          </div>
        </div>
        <div className="col-xs-4">
          <div className="form-group">
            <label htmlFor="cloze-sentence-search-context">Context</label>
            <select
              className="form-control" 
              disabled={disabled} 
              id="cloze-sentence-search-context"
              name="context" 
              onChange={onChange}
              value={context} 
            >
              <option value="cloze">Cloze</option>
              <option value="text">Text</option>
              <option value="translation">Translation</option>
            </select>
          </div>
        </div>
      </div>
      {children}
      <button disabled={disabled} type="submit" className="btn btn-success btn-sm btn-block joystix">Search</button>
    </form>
  );
};
