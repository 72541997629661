import React from "react";

import { v4 as uuidv4 } from "uuid";

import ClozeSentence from "../ClozeSentence";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import TokensEditorToken from "./TokensEditorToken";

export default class TokensEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tokens: []
    };
  }

  componentDidMount() {
    this.loadTokens();
  }

  loadTokens() {
    const { testEnv, tokensUrl } = this.props;

    this.setState({ loading: true });

    $.ajax({
      data: { include: "morphological_features,pos_tags" },
      url: tokensUrl
    })
      .done((data) => {
        console.log("loadTokens", data);
        const {
          tokenizationErrorMessage,
          tokenizationStatus,
          tokens
        } = data;

        if(tokenizationStatus === "tokenization_failed") {
          this.setState({ loading: false });
          alert("Tokenization failed! Please try again.");
          return false;
        }

        if(tokenizationStatus === "tokenization_queued" || tokenizationStatus === "tokenization_working") {
          setTimeout(this.loadTokens.bind(this), testEnv ? 500 : 3000); // try again in 3s
          return false;
        }

        // intracomponent uuid so we can update new tokens when saved
        tokens.forEach((t) => t.uuid = uuidv4());
        this.setState({
          loading: false,
          morphologicalFeatures: data.morphologicalFeatures,
          posTags: data.posTags,
          tokens
        });
      })
      .fail(() => {
        alert("Error loading tokens! Please tell someone.");
      });
  }

  renderContent() {
    const { loading, morphologicalFeatures, posTags, tokens } = this.state;
    const { clozeText, tokensUrl } = this.props;

    if(loading) {
      return "Loading... This may take a few moments if the sentence is being tokenized.";
    }

    return (
      <div id="tokens" style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%" }}>
        <p style={{ fontSize: "2em" }}><ClozeSentence text={clozeText} /></p>
        {tokens.sort((a, b) => a.index - b.index).map((t) => (
          <TokensEditorToken
            key={t.id || t.index}
            morphologicalFeatures={morphologicalFeatures}
            onDelete={() => {
              this.setState({
                tokens: tokens.filter((token) => {
                  return t.uuid !== token.uuid;
                })
              });
            }}
            onSave={({ token, uuid }) => {
              this.setState({
                tokens: tokens.map((t) => {
                  return t.uuid === uuid ? token : t;
                })
              });
            }}
            posTags={posTags}
            token={t}
            tokensUrl={tokensUrl}
          />
        ))}
        <div>Scrollable ➡️</div>
      </div>
    );
  }

  addToken() {
    const { tokens } = this.state;
    this.setState({
      tokens: tokens.concat([{
        index: tokens.length ? (Math.max(...tokens.map((t) => t.index)) + 1) : 1,
        uuid: uuidv4()
      }])
    }, () => $("#tokens").scrollLeft(10000)); // max scroll left
  }

  regenerateTokens() {
    const confirmation = "Are you sure? This will delete all existing tokens and regenerate new ones. This cannot be undone, and this may take a few minutes. Please type confirmed to continue.";
    if(prompt(confirmation) !== "confirmed") {
      return false;
    }

    this.setState({ loading: true });

    const { testEnv, tokensUrl } = this.props;

    $.ajax({
      method: "put",
      url: tokensUrl
    })
      .done(() => {
        setTimeout(this.loadTokens.bind(this), testEnv ? 500 : 3000);
      })
      .fail(() => {
        alert("Error regenerating tokens! Please tell someone.");
      });
  }

  render() {
    const { onHidden } = this.props;
    const { tokens } = this.state;

    return (
      <Modal
        footer={(
          <>
            <button className="btn btn-default" onClick={() => this.regenerateTokens()} style={{ marginRight: 5 }}>Regenerate Tokens</button>
            <button className="btn btn-default" onClick={() => this.addToken()} style={{ marginRight: 5 }}>Add Token</button>
            <ModalFooterCloseBtn />
          </>
        )}
        onHidden={() => onHidden({ tokensCount: tokens.length })}
        size="large"
        title="Tokens"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
