import React from "react";

export default class CollectionClozeSentenceEditableAttribute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      value: props.value || ''
    };
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.update(this.state.value, (success) => {
      success && this.setState({ editing: false });
    });
  }

  renderHelpText() {
    if(this.props.helpText) {
      return <div><small>{this.props.helpText}</small></div>;
    }
  }

  render() {
    const { className, label, name, updating } = this.props;
    const { editing, value } = this.state;

    if(editing) {
      return (
        <form className={className} onSubmit={(e) => this.onSubmit(e)} style={{ marginBottom: 10 }}>
          <label>{label}</label>
          <input disabled={updating} className="form-control" autoFocus={true} name={name} type="text" value={value} onChange={(e) => this.setState({ value: e.target.value })} />
          {this.renderHelpText()}
          <div style={{ marginTop: 8 }}>
            <button disabled={updating} className="btn btn-sm btn-success joystix" onClick={(e) => this.onSubmit(e)}>Save</button>
            <button disabled={updating} className="btn btn-sm btn-link" onClick={(e) => this.setState({ editing: false })}>Cancel</button>
          </div>
        </form>
      );
    }

    return (
      <div className={className}><small><strong>{label}:</strong> {value} <button disabled={updating} className="btn btn-xs btn-link" onClick={() => this.setState({ editing: true })}>Edit</button></small></div>
    );
  }
}

