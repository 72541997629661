import React from "react";

export default class LanguagePairingOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.order || 'alphabetical'
    };
  }

  save() {
    this.setState({ saving: true });

    $.ajax({
      data: { user: { language_pairing_order: this.state.order } },
      method: 'put',
      url: this.props.url
    })
      .done(() => {
        // reload the page for username menu change to take effect
        // TODO! flash success, need to move user update into user controller
        window.location = window.location;
      })
      .fail(() => {
        alert('Oh no! There was an error updating. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  handleChange(e) {
    this.setState({ order: e.target.value });
  }

  renderOption(value, label) {
    return (
      <div className="radio">
        <label>
          <input
            checked={this.state.order === value}
            disabled={this.state.saving}
            name="user[language_pairing_order]"
            onChange={this.handleChange.bind(this)}
            value={value}
            type="radio"
          /> {label}
        </label>
      </div>
    );
  }

  render() {
    return (
      <div className="language-pairing-order">
        <div style={{ display: 'inline-block', textAlign: 'left' }}>
          {this.renderOption('alphabetical', 'Alphabetical')}
          {this.renderOption('streak', 'Streak')}
        </div>
        <button className="btn btn-success btn-block joystix" disabled={this.state.saving} onClick={() => this.save()}>Save</button>
      </div>
    );
  }
}
