import React from "react";

import moment from 'moment';

import Checkbox from "../Checkbox";
import CollectionClozeSentenceEditableAttribute from "./CollectionClozeSentenceEditableAttribute";
import CopySentencePopover from "../CopySentencePopover";
import DiscussionBtn from "../DiscussionBtn";
import Icon from "../Icon";
import MasteredIcon from "../MasteredIcon";
import PlayingIcon from "../PlayingIcon";
import ReportErrorBtn from "../PlayCollection/ReportErrorBtn";
import Tokens from "../PlayCollection/Tokens";

export default class CollectionClozeSentence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteVisible: false,
      editingCloze: false,
      editingTranslation: false,
      editingPronunciation: false,
      tokensVisible: false
    };
  }

  playAudio() {
    if(!$('#manage-collection-audio').length) {
      $('body').append('<audio id="manage-collection-audio" />');
    }

    const audio = $('#manage-collection-audio')[0];
    audio.pause();
    audio.src = this.props.collectionClozeSentence.ttsAudioUrl;
    audio.play();
  }

  renderStats() {
    const ccs = this.props.collectionClozeSentence;
    return (
      <small>
        <ul className="list-inline">
          <li>{Math.round(ccs.level / 4 * 100) + '% Mastered'}</li>
          <li>Next Review: {ccs.nextReview === "2100-01-01" ? "🧠" : (ccs.nextReview <= moment().format('YYYY-MM-DD') ? <span title={ccs.nextReview}>Due!</span> : ccs.nextReview)}</li>
          <li>Correct: {ccs.numPlayed - ccs.numIncorrect}</li>
          <li>Incorrect: {ccs.numIncorrect || 0}</li>
        </ul>
      </small>
    );
  }

  renderTokensBtn() {
    const ccs = this.props.collectionClozeSentence;

    if(!ccs.tokensCount) { 
      return null;
    }

    const { tokensVisible } = this.state;

    return (
      <button className="btn btn-default" onClick={() => this.setState({ tokensVisible: !tokensVisible })}>
        <Icon name={`chevron-${tokensVisible ? "up" : "down"}`} />
      </button>
    );
  }

  renderTokens() {
    if(!this.state.tokensVisible) {
      return null;
    }

    const {
      collectionClozeSentence,
      onTokenClick,
      targetLanguageCode,
      targetLanguageIso
    } = this.props;

    return (
      <div className="scrollbar-always-visible" style={{ overflowY: "scroll", textAlign: "center", whiteSpace: "nowrap", width: "100%" }}>
        <Tokens
          onClick={onTokenClick}
          targetLanguageCode={targetLanguageCode}
          targetLanguageIso={targetLanguageIso}
          tokenizeableId={collectionClozeSentence.id}
          tokenizeableType={"CollectionClozeSentence"}
          url={collectionClozeSentence.tokensUrl}
        />
      </div>
    );
  }

  renderControls() {
    const ccs = this.props.collectionClozeSentence;
    const {
      baseLanguageEnglishName,
      collectionsUrl,
      collectionClozeSentencesErrorUrl,
      isDeleteable,
      isReportable,
      targetLanguageEnglishName,
      targetLanguageName,
      update,
      updating
    } = this.props;

    return (
      <div aria-label="Sentence controls" style={{ textAlign: "right" }}>
        <div style={{ marginBottom: 10 }}>
          <div className="btn-group btn-group-xs" role="group">
            <button className="btn btn-default add-to-review-queue" disabled={updating || !!ccs.nextReview} onClick={() => update({ next_review: moment().format("YYYY-MM-DD") })}><Icon name="plus" /></button>
            <button className="btn btn-default remove-from-review-queue" disabled={updating || !ccs.nextReview} onClick={() => update({ level: 0, next_review: null })}><Icon name="minus" /></button>
            <button disabled={updating} className={'favorite btn btn-default' + (ccs.favorited ? ' active' : '')} onClick={() => update({ favorited: !ccs.favorited })}><Icon name={'star' + (ccs.favorited ? '' : '-empty')} /></button>
            <button className="btn btn-default mark-as-known" disabled={updating || (ccs.level === 4 && ccs.nextReview === "2100-01-01")} onClick={() => update({ level: 4, next_review: "2100-01-01" })}>🧠</button> 
            <button className="btn btn-default master" disabled={updating || ccs.level === 4} onClick={() => update({ level: 4 })}><Icon name="ok" /></button>
            <button className="btn btn-default reset" disabled={updating || !ccs.level} onClick={() => update({ level: 0 })}><Icon name="remove" /></button>
          </div>
        </div>
        <div className="btn-group btn-group-xs" role="group" aria-label="Sentence controls">
          {this.renderTokensBtn()}
          {!!ccs.ttsAudioUrl && <button className="btn btn-default" disabled={updating} onClick={() => this.playAudio()}><Icon name="volume-up" /></button>}
          {!!collectionsUrl && <button disabled={updating} className="btn btn-default copy" onClick={() => this.onCopyBtnClick()}><Icon name="copy" type="fa" /></button>}
          <DiscussionBtn
            baseLanguageEnglishName={baseLanguageEnglishName}
            clozeSentence={ccs}
            disabled={updating}
            isPro={true}
            targetLanguageEnglishName={targetLanguageEnglishName}
            targetLanguageName={targetLanguageName}
          />
          <button disabled={updating} className={'btn btn-default ignore' + (ccs.ignored ? ' active' : '')} onClick={() => update({ ignored: !ccs.ignored })}><Icon name="ban-circle" /></button>
          {isDeleteable && <button className="btn btn-default delete" disabled={updating} onClick={() => this.onDeleteBtnClick()}><Icon name="trash" /></button>}
          {isReportable && (
            <ReportErrorBtn
              className="btn btn-default report-error"
              collectionClozeSentence={ccs}
              disabled={updating}
              showText={false}
              url={collectionClozeSentencesErrorUrl}
            />
          )}
        </div>
      </div>
    );
  }

  onCopyBtnClick() {
    this.setState({
      copyPopoverVisible: !this.state.copyPopoverVisible
    });
  }

  onDeleteBtnClick() {
    this.props.destroy();
  }

  renderCopyPopover() {
    if(!this.state.copyPopoverVisible) {
      return null;
    }

    const { collectionClozeSentence, collectionsUrl, onCopy } = this.props;

    return (
      <CopySentencePopover
        collectionClozeSentence={collectionClozeSentence}
        collectionsUrl={collectionsUrl}
        onClose={() => this.setState({ copyPopoverVisible: false })}
        onCopy={onCopy}
      />
    );
  }

  renderText() {
    const ccs = this.props.collectionClozeSentence;
    const parts = ccs.text.split('{{');
    return (
      <span>{parts[0]}<strong><u>{parts[1].split('}}')[0]}</u></strong>{parts[1].split('}}')[1]}</span>
    );
  }

  render() {
    const ccs = this.props.collectionClozeSentence;
    const { isTextEditable, onSelectedChange, selected, update, updating } = this.props;
    return (
      <div className="collection-cloze-sentence" style={{ borderBottom: "1px solid #efefef", marginBottom: 10, paddingBottom: 10, paddingLeft: 30, position: "relative" }}>
        <div style={{ left: 0, position: "absolute", top: 3 }}>
          <Checkbox checked={selected} name="selected" onChange={(e) => onSelectedChange(e.target.checked)} style={{ margin: 0 }} />
        </div>
        <div className="pull-right" style={{ marginLeft: 10 }}>
          {this.renderControls()}
        </div>
        <div style={{ fontSize: '2rem' }}>{this.renderText()}{/* <button disabled={updating} className="btn btn-xs btn-link">Edit</button>*/}</div>
        {this.renderTokens()}
        {isTextEditable && <CollectionClozeSentenceEditableAttribute
          className="editable-text"
          label="Text"
          updating={updating}
          update={(value, callback) => update({ text: value }, callback)}
          value={ccs.text}
        />}
        <CollectionClozeSentenceEditableAttribute
          label="Translation"
          updating={updating}
          update={(value, callback) => update({ translation: value }, callback)}
          value={ccs.translation}
        />
        <CollectionClozeSentenceEditableAttribute
          label="Pronunciation"
          updating={updating}
          update={(value, callback) => update({ pronunciation: value }, callback)}
          value={ccs.pronunciation}
        />
        {!!ccs.transliteration && <div><small><strong>Transliteration:</strong> {ccs.transliteration}</small></div>}
        <CollectionClozeSentenceEditableAttribute
          label="Notes"
          updating={updating}
          update={(value, callback) => update({ notes: value }, callback)}
          value={ccs.notes}
        />
        <CollectionClozeSentenceEditableAttribute
          className="hint"
          label="Hint"
          name="hint"
          updating={updating}
          update={(value, callback) => update({ hint: value }, callback)}
          value={ccs.hint}
        />
        <CollectionClozeSentenceEditableAttribute
          className="alternative-answers"
          label="Alternative answers"
          name="alternative_answers"
          value={ccs.alternativeAnswers.join(", ")}
          helpText="Enter a comma separated list of alternative answers."
          updating={updating}
          update={(value, callback) => update({ alternative_answers: value }, callback)}
        />
        <div>
          {!!ccs.nextReview && this.renderStats()}
        </div>
        {/* TODO! if owner - alt answers, hint (hint either way?), % mastered control 0/25/etc */}
        {this.renderCopyPopover()}
      </div>
    );
  }
}
