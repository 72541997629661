import React from "react";

import Icon from "../Icon";

export default class CollectionClozeSentenceChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moreVisible: false
    };
  }

  render() {
    const c = this.props.change;
    const { moreVisible } = this.state;

    return (
      <div style={{ borderBottom: "1px solid #ccc", marginBottom: 10 }}>
        <ul>
          {c.languagePairing && <li><strong>{c.languagePairing}</strong></li>}
          <li><strong>Created at:</strong> {c.createdAt}</li>
          {c.collection && <li><strong>Collection:</strong> {c.collection}</li>}
          <li><strong>User:</strong> {c.user && c.user.username}</li>
          {c.deleted && <li><span className="label label-danger">Deleted</span></li>}
          {!c.deleted && Object.keys(c.newAttributes).filter((k) => (c.newAttributes[k] || c.oldAttributes[k]) && c.newAttributes[k] !== c.oldAttributes[k]).map((k) => (
            <li key={k}><strong>{k.charAt(0).toUpperCase() + k.slice(1).replace(/_/g, " ")}:</strong> {"" + c.oldAttributes[k]} ➡️ {"" + c.newAttributes[k]}</li>
          ))}
          <li style={{ listStyle: "none" }}>
            <small><a href="#" role="button" onClick={(e) => { e.preventDefault(); this.setState({ moreVisible: !moreVisible }); }}>JSON <Icon name={moreVisible ? "chevron-up" : "chevron-down"} /></a></small>
          </li>
        </ul>
        {moreVisible && (
          <pre>
            {JSON.stringify(c, (k, v) => v !== null ? v : undefined, 2)}
          </pre>
        )}
      </div>
    );
  }
}
