import React from "react";
import Markdown from "./Markdown";

const GenerateMorePrompt = function({ hasPayForExplanationsResponse, payForExplanationsResponsesUrl }) {
  const [loading, setLoading] = React.useState(false);
  const [sentResponse, setSentResponse] = React.useState(false);

  const sendResponse = function(response) {
    setLoading(true);
    $.ajax({
      data: { response },
      method: "post",
      url: payForExplanationsResponsesUrl
    })
      .always(() => {
        setSentResponse(true);
      });
  };

  if(hasPayForExplanationsResponse) {
    return null;
  }

  if(sentResponse) {
    return (
      <div><strong>Thanks for your input! 🙏</strong></div>
    );
  }

  if(loading) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <>
      <p><strong>Want to be able to generate more?</strong> We're considering making it possible with an add-on subscription to help support the added costs. <strong>Would you be willing to pay for an add-on subscription of $15 USD per month to generate up to 500 explanations per month?</strong></p>
      <ul className="list-inline" style={{ marginBottom: 0 }}>
        <li><button className="btn btn-default" onClick={() => sendResponse("yes")}>Yes definitely!</button></li>
        <li><button className="btn btn-default" onClick={() => sendResponse("no")}>Meh no thanks</button></li>
      </ul>
    </>
  );
};

export default function CollectionClozeSentenceExplanation({ collectionClozeSentence, isPro, onExplanation, test }) {
  const [countExplanationsThisMonth, setCountExplanationsThisMonth] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [explanation, setExplanation] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [maxExplanationsPerMonth, setMaxExplanationsPerMonth] = React.useState(null);
  const [monthResetsInDays, setMonthResetsInDays] = React.useState(null);

  const [hasPayForExplanationsResponse, setHasPayForExplanationResponse] = React.useState(false);
  const [payForExplanationsResponsesUrl, setPayForExplanationsResponsesUrl] = React.useState(null);

  React.useEffect(() => {
    if(collectionClozeSentence.explanation) {
      setExplanation(collectionClozeSentence.explanation);
    }
    else if(collectionClozeSentence.explanationJobUrl) {
      setLoading(true);
      getExplanation();
    }
    else {
      setError("No explanation available! Sorry about that. Please check back later.");
    }
  }, []);

  const getExplanation = () => {
    $.ajax({
      url: collectionClozeSentence.explanationJobUrl
    })
      .done(handleExplanationResponse)
      .fail(() => {
        setError("There was an error getting the explanation - sorry about that! Please close this modal and try again, and let us know if you see this message again.");
        console.log("FAILED! getExplanation");
      });
  };

  let getExplanationTimeout = null;
  const handleExplanationResponse = ({ hasPayForExplanationsResponse, payForExplanationsResponsesUrl, tracker, user }) => {
    console.log(tracker, user);

    const { explanation, status } = tracker;
    const { countExplanationsThisMonth, maxExplanationsPerMonth, monthResetsInDays } = user;

    setCountExplanationsThisMonth(countExplanationsThisMonth);
    setExplanation(explanation);
    setMaxExplanationsPerMonth(maxExplanationsPerMonth);
    setMonthResetsInDays(monthResetsInDays);

    setHasPayForExplanationResponse(hasPayForExplanationsResponse);
    setPayForExplanationsResponsesUrl(payForExplanationsResponsesUrl);

    if(!explanation && (!status || status === "pending") && countExplanationsThisMonth < (maxExplanationsPerMonth - 5)) {
      // skip showing generate button, go right to generating
      generateExplanation();
      return true;
    }

    if(explanation || !status || status === "pending" || status === "failed") {
      setLoading(false);
    }

    if(status === "complete") {
      onExplanation(explanation);
      clearTimeout(getExplanationTimeout);
      return true;
    }

    if(status === "failed") {
      setError("There was an error generating the explanation - sorry about that! Please close this modal and try again, and let us know if you see this message again.");
      console.log("FAILED! handleExplanationResponse");
      clearTimeout(getExplanationTimeout);
      return false;
    }

    if(status === "queued" || status === "working") {
      getExplanationTimeout = setTimeout(() => getExplanation(), test ? 100 : 1000);
    }
  };

  const generateExplanation = () => {
    setLoading(true);
    $.ajax({
      method: "post",
      url: collectionClozeSentence.explanationJobUrl
    })
      .done(handleExplanationResponse)
      .fail(() => {
        setError("There was an error starting the explanation generation - sorry about that! Please close this modal and try again, and let us know if you see this message again.");
        console.log("FAILED! generateExplanation");
      });
  };

  if(error) {
    return (
      <div className="alert alert-danger">{error}</div>
    );
  }

  if(explanation) {
    return (
      <Markdown>{explanation}</Markdown>
    );
  }

  if(loading) {
    return (
      <p>Loading...</p>
    );
  }

  if(!isPro) {
    // TODO!
  }

  return (
    <div>
      <p>Some sentences have an explanation from ChatGPT by default. Explanations for the rest are generated in real-time when you click Explain. There's currently a <strong>limit of {maxExplanationsPerMonth} per month</strong> to help prevent abuse as this is still a new feature. You currently have <strong>{Math.max(maxExplanationsPerMonth - countExplanationsThisMonth, 0)} remaining</strong>. Month resets in {monthResetsInDays} days. Need more? <a href="/contact" target="_blank">Let us know.</a></p>
      <p><button className="btn btn-default" disabled={countExplanationsThisMonth >= maxExplanationsPerMonth} onClick={generateExplanation}>Generate</button></p>
      {/*countExplanationsThisMonth >= maxExplanationsPerMonth && (
        <GenerateMorePrompt
          hasPayForExplanationsResponse={hasPayForExplanationsResponse}
          payForExplanationsResponsesUrl={payForExplanationsResponsesUrl}
        />
      )*/}
    </div>
  );
}
