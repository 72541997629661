import React from "react";
import ReactDOM from "react-dom";

import Icon from "./Icon";
import FadeIn from "./FadeIn";
import Loading from "./Loading";
import Modal from "./Modal";
import Panel from "./Panel";

export default class OnboardingWelcome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showWelcomeMessage: false
    };
  }

  componentDidMount() {
    const { formId } = this.props;

    if(window._refiner && formId) {
      let showWelcomeMessageTimeout = null;
      window._refiner("onShow", (formId) => {
        clearTimeout(showWelcomeMessageTimeout);
        this.setState({
          loading: false,
          showWelcomeMessage: false  // in case it was shown
        });
      });
      window._refiner("onClose", (formId) => {
        this.setState({
          loading: false,
          showWelcomeMessage: true
        });
      });
      window._refiner("showForm", formId, true);
      // in case form fails to load in 1s
      showWelcomeMessageTimeout = setTimeout(() => {
        this.setState({
          loading: false,
          showWelcomeMessage: true
        });
      }, 3000);
    }
    else {
      this.setState({
        loading: false,
        showWelcomeMessage: true
      });
    }
  }

  render() {
    const { loading, showWelcomeMessage } = this.state;
    const { isLanguagePairingSelected, startPath } = this.props;

    return (
      <div>
        {loading && <Loading />}
        {showWelcomeMessage && (
          <FadeIn>
            <div className="text-center joystix">
              <br />
              <br />
              <h1>Welcome to Clozemaster!</h1>
              <h4 style={{ marginBottom: 30, marginTop: 30 }}>Faster and more efficient language learning awaits you beyond this screen.</h4>
              <a className="btn btn-lg btn-success btn-block joystix" href={startPath}>Start <Icon name="chevron-right" /></a>
            </div>
          </FadeIn>
        )}
      </div>
    );
  }
}
