import React from "react";

export default function Icon(props) {
  // font awesome
  if(props.type === 'fa') {
    return <i aria-hidden="true" className={"fa fa-" + props.name} style={props.style} />;
  }

  // default is bootstrap glyphicon
  return <span className={'glyphicon glyphicon-' + props.name} style={props.style}></span>;
}
