import React from "react";

export default class Hint extends React.Component {
  componentDidMount() {
    const { fontSize } = this.props;
    this.$container = $(this.container);
    this.$container.tooltip({
      template: `
        <div class="hint tooltip font-size-${fontSize}" role="tooltip">
          <div class="tooltip-arrow"></div>
          <div class="tooltip-inner"></div>
        </div>
      `,
      title: this.props.hint,
      trigger: 'manual'
    });
    setTimeout(() => this.$container.tooltip('show'), 500); // let other animations finish
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.fontSize !== this.props.fontSize) {
      $(".hint.tooltip").removeClass("font-size-" + prevProps.fontSize);
      $(".hint.tooltip").addClass("font-size-" + this.props.fontSize);
      this.$container.tooltip("show"); // to reposition
    }
  }

  componentWillUnmount() {
    this.$container.tooltip('destroy');
  }

  render() {
    return (
      <span ref={(el) => this.container = el}>
        {this.props.children}
      </span>
    );
  }
}
