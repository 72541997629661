import React from "react";

import Block from "./Block";
import SentenceEditor from "./SentenceEditor";

export default class SentenceBlock extends React.Component {
  render() {
    const { index, onDelete, onChange, sentence } = this.props;
    const { text, translation, textError, translationError } = sentence;

    return (
      <Block
        item={{ id: sentence.id, type: "sentence" }}
        legend={"Sentence " + (index + 1)}
        onDelete={onDelete}
      >
        <SentenceEditor
          onTextChange={(text) => { sentence.text = text; onChange(sentence); }}
          onTranslationChange={(translation) => { sentence.translation = translation; onChange(sentence); }}
          text={text}
          textError={textError}
          translation={translation}
          translationError={translationError}
        />
      </Block>
    );
  }
}
