import React from "react";

import { collectionPctStr, pctStr } from "../../helpers";

import ChunkProgressBar from "./ChunkProgressBar";
import Icon from "../Icon";
import MasteredIcon from "../MasteredIcon";
import PlayingIcon from "../PlayingIcon";

export default function CollectionProgressBar(props) {
  const { collection, perChunk, useChunks } = props;
  const { numMastered, numPlaying, numSentences } = collection;
  const pctPlaying = collectionPctStr(collection, 'playing');
  const pctMastered = collectionPctStr(collection, 'mastered');

  if(useChunks) {
    return (
      <div style={props.style || { marginBottom: 10 }}>
        <ChunkProgressBar
          includePopoverHelperBtn={true}
          num={numPlaying}
          pct={pctPlaying}
          perChunk={perChunk}
          total={numSentences}
          type="playing"
        />
        <ChunkProgressBar
          num={numMastered}
          pct={pctMastered}
          perChunk={perChunk}
          total={numSentences}
          type="mastered"
        />
      </div>
    );
  }

  return (
    <div className="progress" style={props.style}>
      <div className="progress-bar progress-bar-success progress-bar-striped" data-toggle="tooltip" style={{ width: pctMastered }} title={collection.numMastered.toLocaleString() + ' sentences mastered (' + pctMastered + ')'}></div>
      <div className="progress-bar progress-bar-warning progress-bar-striped" data-toggle="tooltip" style={{ width: (parseFloat(pctPlaying) - parseFloat(pctMastered) + '%') }} title={collection.numPlaying.toLocaleString() + ' sentences played (' + pctPlaying + ')'}></div>
    </div>
  );
}
