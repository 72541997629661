import React from "react";

import BulkSentenceCreationEditor from "./BulkSentenceCreationEditor";
import Loading from "../Loading";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import TextareaAutosize from 'react-textarea-autosize';

export default class AddClozeFromWordsToCollectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phase: 'form',
      text: '',
      numSentences: '5'
    };
  }

  hide() {
    this.modal.hide();
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ phase: 'processing' });
    $.ajax({
      data: { text: this.state.text, num_sentences: this.state.numSentences },
      method: 'post',
      url: this.props.clozeFromWordsUrl,
    })
      .done((createData) => {
        this.handleCreateData(createData);
      })
      .fail(() => {
        this.setState({ phase: 'form' });
        alert("Oh no! There was an error processing the text - sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  handleCreateData(createData) {
    if(createData.errors) {
      alert("Oh no! There was an error processing the text: " + createData.errors + " Sorry about that. Please try again and let us know if you see this message again.");
      this.setState({ phase: 'form' });
      return false;
    }

    const statusInterval = setInterval(() => {
      $.ajax({
        url: createData.url
      })
        .done((statusData) => {
          const { error, result } = statusData;
          if(error) {
            clearInterval(statusInterval);
            this.setState({ phase: 'form' });
            alert("Oh no! There was an error processing the text: " + error + " - sorry about that. Please try again and let us know if you see this message again.");
          }
          if(result) {
            clearInterval(statusInterval);
            this.setState({ phase: 'edit', result: result.map((r, id) => ({ id, ...r })) });
          }
        })
        .fail(() => {
          this.setState({ phase: 'form' });
          alert("Oh no! There was an error processing the text - sorry about that. Please try again and let us know if you see this message again.");
        });
    }, 5000);
  }

  renderProcessing() {
    return (
      <Loading />
    );
  }

  renderForm() {
    const { text, numSentences } = this.state;
    const max = 10000;
    const over = Math.max(text.length - max, 0);

    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <p>Paste in your list of words separated by comma or newline. Max {max.toLocaleString()} characters.</p>
        <div className={'form-group' + (over ? ' has-error' : '')}>
          <select name="numSentences" value={numSentences} onChange={(e) => this.setState({ numSentences: e.target.value })} className="form-control">
            <option value="1">1 sentence per word</option>
            <option value="2">2 sentences per word</option>
            <option value="3">3 sentences per word</option>
            <option value="4">4 sentences per word</option>
            <option value="5">5 sentences per word</option>
            <option value="6">6 sentences per word</option>
            <option value="7">7 sentences per word</option>
            <option value="8">8 sentences per word</option>
            <option value="9">9 sentences per word</option>
            <option value="10">10 sentences per word</option>
          </select>
          <label htmlFor="text">Words</label>
          <TextareaAutosize
            className="form-control"
            name="text"
            onChange={(e) => this.setState({ text: e.target.value })}
            value={text}
          />
        </div>
        {!!over && <p className="alert alert-danger">Too many characters! Over by {over}.</p>}
        <button className="btn btn-success joystix" disabled={!!over}>Submit</button>
      </form>
    );
  }

  renderCreator() {
    return (
      <BulkSentenceCreationEditor
        collection={this.props.collection}
        onSentencesAdded={this.props.onSentencesAdded}
        sentences={this.state.result}
      />
    );
  }

  renderContent() {
    const { phase } = this.state;

    switch (phase) {
      case 'form':
        return this.renderForm();
      case 'processing':
        return this.renderProcessing();
      case 'edit':
        return this.renderCreator();
      default:
        throw new Error("unrecognized phase!");
    }
  }

  render() {
    return (
      <Modal
        dialogStyle={this.state.phase === 'edit' ? { width: '95%' } : null}
        footer={<ModalFooterCloseBtn />}
        onHidden={this.props.onHidden}
        ref={(el) => this.modal = el}
        size="large"
        title="Paste in Words"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
