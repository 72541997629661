import React from "react";

import { splitTextOnCloze } from "../../helpers";

import Checkbox from "../Checkbox";
import ClozeSentenceEntry from "../mods/ClozeSentenceEntry";
import CollectionClozeSentenceChangesModal from "../mods/CollectionClozeSentenceChangesModal";
import CollectionClozeSentenceEditor from "../mods/CollectionClozeSentenceEditor";
import CollectionClozeSentenceTts from "../mods/CollectionClozeSentenceTts";
import Icon from "../Icon";
import Loading from "../Loading";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import Pagination from "../Pagination";
import ProgressBar from "../ProgressBar";
import TokensEditor from "../mods/TokensEditor";

export default class ReportedCollectionClozeSentences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collections: null,
      includeResolved: true,
      includeSeen: true,
      languagePairings: null,
      loading: true,
      notifyUsersOnResolve: true,
      orderBy: null,
      page: 1,
      perPage: 50,
      reportedSentence: null,
      reportedSentences: [],
      resolveModalVisible: false,
      selectedCollectionId: parseInt(props.selectedCollectionId) || "",
      selectedLanguagePairingId: parseInt(props.selectedLanguagePairingId) || "",
      total: 0
    };
  }

  componentDidMount() {
    this.loadErrors();
  }

  loadErrors() {
    this.setState({ loading: true });

    const data = {
      include_resolved: this.state.includeResolved,
      include_marked_as_seen: this.state.includeSeen,
      order_by: this.state.orderBy,
      page: this.state.page
    };

    if(this.props.languagePairingsSelector) {
      data.collection_id = this.state.selectedCollectionId;
      data.language_pairing_id = this.state.selectedLanguagePairingId;
      data.include_language_pairings = true;
    }

    $.ajax({
      data,
      url: this.props.url
    })
      .done((data) => {
        console.log(data);
        const state = {
          loading: false,
          reportedSentences: data.reportedCollectionClozeSentences,
          total: data.total
        };

        if(this.props.languagePairingsSelector) {
          state.collections = data.collections;
          state.languagePairings = data.languagePairings;
          state.allLanguagePairingsResolvedCount = data.languagePairings.reduce((s, lp) => s + lp.resolved, 0)
          state.allLanguagePairingsTotalCount = data.languagePairings.reduce((s, lp) => s + lp.total, 0);
        }

        this.setState(state);
      })
      .fail(() => {
        alert(
          "Oh no! There was an error loading errors. Sorry about that. " +
          "Please try again and let us know if you see this message again."
        );
      });
  }

  onEditSentenceClick(e) {
    this.setState({ editingReportedSentence: e });
  }

  // onViewSentenceClick(e) {
  //   this.setState({ updating: true });
  //   $.ajax({
  //     url: e.collectionClozeSentenceUrl
  //   })
  //     .done((data) => {
  //       this.setState({
  //         collectionClozeSentence: data.collectionClozeSentence,
  //         updating: false
  //       });
  //     })
  //     .fail(() => {
  //       this.setState({ updating: false });
  //       alert("Oh no! There was an error loading the sentence. Sorry about that. Please try again and let us know if you see this message again.");
  //     });
  // }

  onClaimClick(reportedSentence) {
    this.updateClaim({ url: reportedSentence.claimUrl });
  }

  onRemoveClaimClick(reportedSentence) {
    this.updateClaim({ url: reportedSentence.removeClaimUrl });
  }

  updateClaim({ url }) {
    this.setState({ updating: true });
    $.ajax({
      method: "post",
      url
    })
      .done((data) => {
        console.log(data);
        const { reportedCollectionClozeSentence } = data;
        this.setState({
          reportedSentences: this.state.reportedSentences.map((e) => (
            e.id === reportedCollectionClozeSentence.id ? Object.assign({}, e, reportedCollectionClozeSentence) : e
          )),
          updating: false
        });
      })
      .fail(() => {
        this.setState({ updating: false });
        alert("Oh no! There was an error claiming the sentence! Sorry about that. Please try again let us know if you see this message again.");
      });
  }

  onMarkAsSeenClick(e) {
    this.setState({ updating: true });
    $.ajax({
      method: "post",
      url: e.markAsSeenUrl
    })
      .done((data) => {
        console.log(data);
        const { reportedCollectionClozeSentence } = data;
        this.setState({
          reportedSentences: this.state.reportedSentences.map((e) => (
            e.id === reportedCollectionClozeSentence.id ? Object.assign({}, e, reportedCollectionClozeSentence) : e
          )),
          updating: false
        });
      })
      .fail(() => {
        this.setState({ updating: false });
        alert("Oh no! There was an error marking the sentence as seen! Sorry about that. Please try again let us know if you see this message again.");
      });
  }

  onResolveClick(reportedSentence) {
    this.setState({ reportedSentence, resolveModalVisible: true });
  }

  resolve({ reportedSentence, notifyUsers = false }) {
    this.setState({ updating: true });
    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({ notify_users: notifyUsers }),
      method: "put",
      url: reportedSentence.resolveUrl
    })
      .done((data) => {
        console.log(data);
        const { reportedCollectionClozeSentence } = data;

        const state = {
          reportedSentences: this.state.reportedSentences.map((e) => (
            e.id === reportedCollectionClozeSentence.id ? Object.assign({}, e, reportedCollectionClozeSentence) : e
          )),
          updating: false
        };

        if(this.props.languagePairingsSelector) {
          state.languagePairings = this.state.languagePairings.map((lp) => (
            reportedSentence.languagePairingId === lp.id ? Object.assign({}, lp, { resolved: lp.resolved + 1 }) : lp
          ));
        }

        state.allLanguagePairingsResolvedCount = this.state.allLanguagePairingsResolvedCount + 1;

        this.setState(state);
      })
      .fail(() => {
        this.setState({ updating: false });
        alert("Oh no! There was an error resolving the sentence! Sorry about that. Please try again let us know if you see this message again.");
      });
  }

  renderResolveModal() {
    const { notifyUsersOnResolve, reportedSentence, resolveModalVisible } = this.state;
    if(!resolveModalVisible) {
      return null;
    }

    return (
      <Modal
        footer={<ModalFooterCloseBtn text="Cancel" />}
        onHidden={() => this.setState({ reportedSentence: null, resolveModalVisible: false })}
        ref={(el) => this.resolveModal = el}
        size="small"
        title="Resolve Sentence"
      >
        <p className="alert alert-danger">
          Are you sure? The sentence will no longer be editable and the users who reported the sentence will be notified. This action cannot be undone.
        </p>
        <Checkbox
          checked={notifyUsersOnResolve}
          label="Notify users"
          onChange={(e) => this.setState({ notifyUsersOnResolve: e.target.checked })}
        />
        <button
          className="btn btn-success joystix btn-block"
          onClick={() => this.resolveModal.hide({ onHidden: () => this.resolve({ notifyUsers: this.state.notifyUsersOnResolve, reportedSentence }) })}
        >
          Resolve
        </button>
      </Modal>
    );
  }

  renderPagination() {
    const { page, perPage, total, updating } = this.state;
    return (
      <Pagination
        onNextClick={() => this.setState({ page: this.state.page + 1 }, () => this.loadErrors())}
        onPrevClick={() => this.setState({ page: this.state.page - 1 }, () => this.loadErrors())}
        page={page}
        perPage={perPage}
        total={total}
        updating={updating}
      />
    );
  }

  // renderViewCollectionClozeSentenceModal() {
  //   const { collectionClozeSentence, updateUrl } = this.state;
  //   if(collectionClozeSentence && !updateUrl) {
  //     const { preClozeStr, cloze, postClozeStr } = splitTextOnCloze(collectionClozeSentence.text);
  //     return (
  //       <Modal
  //         footer={<ModalFooterCloseBtn />}
  //         onHidden={() => this.setState({ collectionClozeSentence: null })}
  //         size="small"
  //         title="View Sentence"
  //       >
  //         {["text", "translation", "pronunciation", "notes", "hint", "alternativeAnswers"].map((attr) => (
  //           <p key={attr}>
  //             <strong>
  //               {
  //                 attr.split(/([A-Z])/).map((x, i, a) => (
  //                   i === 0 ? x.charAt(0).toUpperCase() + x.slice(1) : (i % 2 === 1 ? " " : (a[i - 1].toLowerCase() + x))
  //                 )).join("")
  //               }:
  //             </strong>
  //             <br />
  //             {attr === "text" ? <span>{preClozeStr}<strong><u>{cloze}</u></strong>{postClozeStr}</span> : collectionClozeSentence[attr]}
  //           </p>
  //         ))}
  //         {!!collectionClozeSentence.ttsAudioUrl && <audio controls src={collectionClozeSentence.ttsAudioUrl} style={{ maxWidth: "100%" }}></audio>}
  //       </Modal>
  //     );
  //   }
  // }

  onDelete(reportedSentence) {
    this.setState({ updating: true });
    this.resolve({ notifyUsers: false, reportedSentence });
  }

  renderCollectionClozeSentenceEditorModal() {
    const { editingReportedSentence } = this.state;

    if(!editingReportedSentence) {
      return null;
    }
    
    const fftV2Collection = !!(editingReportedSentence.collectionName || "").match(/Fast Track Level/);

    return (
      <CollectionClozeSentenceEditor
        collectionClozeSentence={editingReportedSentence}
        collectionClozeSentenceUrl={editingReportedSentence.collectionClozeSentenceUrl}
        clozeSentenceUrl={editingReportedSentence.clozeSentenceUrl}
        onDelete={() => this.onDelete(editingReportedSentence)}
        onHidden={() => this.setState({ editingReportedSentence: false })}
        onUpdate={(updatedSentenceAttributes) => {
          const updatedSentence = Object.assign({}, editingReportedSentence, updatedSentenceAttributes);
          this.setState({
            editingReportedSentence: updatedSentence,
            reportedSentences: this.state.reportedSentences.map((reportedSentence) => {
              if(reportedSentence.id === editingReportedSentence.id) {
                return updatedSentence;
              }
              return reportedSentence;
            })
          });
        }}
        textEditingDisabled={!!(editingReportedSentence.languagePairingSlug.match(/^eng/) && fftV2Collection)}
        translationEditingDisabled={!!(editingReportedSentence.languagePairingSlug.match(/eng$/) && fftV2Collection)}
      />
    );
  }

  renderCollectionClozeSentenceTokensEditor() {
    const { tokensReportedSentence } = this.state;

    if(!tokensReportedSentence) {
      return null;
    }

    return (
      <TokensEditor
        clozeText={tokensReportedSentence.text}
        onHidden={() => this.setState({ tokensReportedSentence: null })}
        tokensUrl={tokensReportedSentence.collectionClozeSentenceTokensUrl}
      />
    );
  }

  renderCollectionClozeSentenceChangesModal() {
    const { changesReportedSentence } = this.state;

    if(!changesReportedSentence) {
      return null;
    }

    return (
      <CollectionClozeSentenceChangesModal
        onHidden={() => this.setState({ changesReportedSentence: null })}
        url={changesReportedSentence.collectionClozeSentenceChangesUrl}
      />
    );
  }

  renderCollectionClozeSentenceTtsModal() {
    const { ttsReportedSentence } = this.state;

    if(!ttsReportedSentence) {
      return null;
    }

    return (
      <CollectionClozeSentenceTts
        clozeSentenceTtsJobUrl={ttsReportedSentence.clozeSentenceTtsJobUrl}
        clozeSentenceUrl={ttsReportedSentence.clozeSentenceUrl}
        collectionClozeSentenceTtsJobUrl={ttsReportedSentence.collectionClozeSentenceTtsJobUrl}
        collectionClozeSentenceUrl={ttsReportedSentence.collectionClozeSentenceUrl}
        onHidden={() => this.setState({ ttsReportedSentence: null })}
      />
    );
  }
  
  renderSeen(e) {
    if(e.resolved || e.claimedByUserUsername) { 
      return null;
    }

    if(e.markedAsSeen) {
      return (
        <div style={{ marginTop: 5 }}>
          <span className="label label-info">👀 Marked as seen by {e.markedAsSeenByUserUsername}</span>
        </div>
      );
    }

    return (
      <div style={{ marginTop: 5 }}>
        <button className="btn btn-default btn-xs" disabled={this.state.updating} onClick={() => this.onMarkAsSeenClick(e)}>Mark as Seen</button>
      </div>
    );
  }

  renderClaim(e) {
    if(e.claimedByCurrentUser) {
      return (
        <div>
          <div>&#x1f449; Me!</div>
          {!e.resolved && (
            <button className="btn btn-warning btn-xs" disabled={this.state.updating} onClick={() => this.onClaimClick(e)}>
              Unclaim
            </button>
          )}
        </div>
      );
    }

    if(!e.claimedByUserUsername) {
      return (
        <button className="btn btn-default" disabled={this.state.updating} onClick={() => this.onClaimClick(e)}>
          Claim
        </button>
      );
    }

    const { admin } = this.props;

    return (
      <div>
        <a className="claimed-by" href={"/players/" + e.claimedByUserUsername} rel="noreferrer" target="_blank">{e.claimedByUserUsername}</a>
        {!!admin && (
          <div style={{ marginTop: 10 }}>
            <small><button className="btn btn-link btn-xs" disabled={this.state.updating} onClick={() => this.onRemoveClaimClick(e)}>Remove claim</button></small>
          </div>
        )}
      </div>
    );
  }

  renderEdit(e) {
    return (
      <button className="btn btn-default" disabled={!e.claimedByCurrentUser || this.state.updating} onClick={() => this.onEditSentenceClick(e)}>Edit</button>
    );
  }

  // renderView(e) {
  //   return (
  //     <button className="btn btn-default" disabled={!e.text || this.state.updating} onClick={() => this.onViewSentenceClick(e)}>View</button>
  //   );
  // }

  renderResolve(e) {
    if(e.resolved) {
      return (
        <div>Resolved! &#x1f389;</div>
      );
    }

    return (
      <p><button className="btn btn-default" disabled={!e.claimedByCurrentUser || this.state.updating} onClick={() => this.onResolveClick(e)}>Resolve</button></p>
    );
  }

  renderLanguagePairing() {
    const { baseLanguageName, targetLanguageName } = this.props;

    if(!baseLanguageName || !targetLanguageName) {
      return null;
    }

    return (
      <small>Learning {targetLanguageName} from {baseLanguageName}</small>
    );
  }

  renderLanguagePairingSelector() {
    if(this.props.languagePairingsSelector) {
      const { allLanguagePairingsResolvedCount, allLanguagePairingsTotalCount } = this.state;
      return (
        <div className="pull-right">
          <ul className="list-inline">
            <li>
              <select
                className="form-control"
                id="language-pairing-selector"
                onChange={(e) => this.setState({ collections: null, page: 1, selectedCollectionId: "", selectedLanguagePairingId: parseInt(e.target.value) || "" }, () => this.loadErrors())}
                value={this.state.selectedLanguagePairingId}
              >
                <option value="">All - {allLanguagePairingsResolvedCount}/{allLanguagePairingsTotalCount} ({Math.round(allLanguagePairingsResolvedCount / allLanguagePairingsTotalCount * 100)}%)</option>
                {this.state.languagePairings.map((lp) => {
                  const pct = Math.round(lp.resolved / lp.total * 100);
                  return (
                    <option key={lp.id} value={lp.id}>
                      {lp.targetLanguageEnglishName} from {lp.baseLanguageEnglishName} - {lp.resolved}/{lp.total} ({pct}%)
                    </option>
                  );
                })}
              </select>
            </li>
            <li>{this.renderCollectionSelector()}</li>
          </ul>
        </div>
      );
    }
  }

  renderCollectionSelector() {
    if(!this.state.selectedLanguagePairingId || !this.state.collections) {
      return null;
    }

    return (
      <select
        className="form-control"
        id="collection-selector"
        onChange={(e) => this.setState({ page: 1, selectedCollectionId: parseInt(e.target.value) || "" }, () => this.loadErrors())}
        value={this.state.selectedCollectionId}
      >
        <option value="">All Collections</option>
        {this.state.collections.map((c) => <option key={c.id} value={c.id}>{c.name}</option>)}
      </select>
    );
  }

  orderBy(x) {
    this.setState({ orderBy: x, page: 1 }, () => this.loadErrors());
  }

  toggleResolved(includeResolved) {
    this.setState({ includeResolved, page: 1 }, () => this.loadErrors());
  }

  toggleSeen(includeSeen) {
    this.setState({ includeSeen, page: 1 }, () => this.loadErrors());
  }

  renderControls() {
    const { includeResolved, includeSeen, orderBy } = this.state;

    return (
      <div>
        <small>Order by:</small>
        <ul className="list-inline">
          <li>
            <button className={"btn btn-sm btn-default" + (!orderBy ? " active" : "")} onClick={() => this.orderBy(null)}>
              Last report (newest first)
            </button>
          </li>
          <li>
            <button className={"btn btn-sm btn-default" + (orderBy === "oldest" ? " active" : "")} onClick={() => this.orderBy("oldest")}>
              Last report (oldest first)
            </button>
          </li>
          <li>
            <button className={"btn btn-sm btn-default" + (orderBy === "most_reports" ? " active" : "")} onClick={() => this.orderBy("most_reports")}>
              Most reports
            </button>
          </li>
          <li>
            <label style={{ fontWeight: "normal" }}>
              Include resolved <input checked={includeResolved} name="include_resolved" onChange={(e) => this.toggleResolved(e.target.checked)} type="checkbox" />
            </label>
          </li>
          <li>
            <label style={{ fontWeight: "normal" }}>
              Include seen <input checked={includeSeen} name="include_seen" onChange={(e) => this.toggleSeen(e.target.checked)} type="checkbox" />
            </label>
          </li>
        </ul>
      </div>
    );
  }

  renderProgressBar() {
    const { languagePairings, selectedLanguagePairingId } = this.state;
    let sum, total;

    if(selectedLanguagePairingId) {
      const languagePairing = languagePairings.find((lp) => lp.id === selectedLanguagePairingId);
      if(languagePairing) {
        sum = languagePairing.resolved;
        total = languagePairing.total;
      }
    }
    else {
      sum = this.state.allLanguagePairingsResolvedCount;
      total = this.state.allLanguagePairingsTotalCount;
    }

    if(!total) {
      return null;
    }

    const percent = Math.round(sum / total * 1000) / 10;

    return (
      <ProgressBar percent={percent}>{sum}/{total} ({percent}%) resolved</ProgressBar>
    );
  }

  renderTts(reportedSentence) {
    if(reportedSentence.hasTts) {
      return (
        <button
          className="btn btn-default"
          disabled={!reportedSentence.claimedByCurrentUser || this.state.updating}
          onClick={() => this.setState({ ttsReportedSentence: reportedSentence })}
        >
          TTS
        </button>
      );
    }
    else {
      return "N/A";
    }
  }

  renderTokensBtn(reportedSentence) {
    if(!reportedSentence.tokensCount) {
      return null;
    }

    return (
      <button
        className="btn btn-default"
        disabled={!reportedSentence.claimedByCurrentUser || this.state.updating}
        onClick={() => this.setState({ tokensReportedSentence: reportedSentence })}
      >
        Tokens
      </button>
    );
  }

  renderContent() {
    const { reportedSentences, loading } = this.state;

    if(loading) {
      return <Loading />;
    }

    return (
      <div>
        {this.renderProgressBar()}
        <div className="row">
          <div className="col-xs-12 col-sm-6">{this.renderControls()}</div>
          <div className="col-xs-12 col-sm-6">{this.renderLanguagePairingSelector()}</div>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Source</th>
              <th>Sentence</th>
              <th>User</th>
              <th>Category</th>
              <th>Feedback</th>
              {/*<th>View</th>*/}
              <th>Assigned</th>
              <th>Edit</th>
              <th>TTS</th>
              {/*<th>Tatoeba</th>*/}
              <th>Tokens</th>
              <th>Resolve</th>
            </tr>
          </thead>
          <tbody>
            {reportedSentences.map((e) => (
              <tr
                className={"reported-sentence " + (e.resolved ? "success resolved" : (e.claimedByCurrentUser ? "warning" : ""))}
                key={e.id}
                style={e.resolved ? { textDecorationLine: "line-through" } : null}
              >
                <td style={{ minWidth: 150 }}>
                  {!!e.languagePairingSlug && <div>{e.languagePairingSlug}</div>}
                  <div><small><strong>{e.collectionName}</strong></small></div>
                  <small>Last report: {e.lastCreatedAt}</small>
                </td>
                <td style={{ minWidth: 220 }}>
                  {e.text ?
                    <ClozeSentenceEntry
                      baseLanguageIso={e.baseLanguageIso}
                      clozeSentence={e}
                      includeTranslationLinks={true}
                      targetLanguageIso={e.targetLanguageIso}
                    />
                    : "DELETED"
                  }
                </td>
                <td>
                  {
                    e.usernames
                      .map((u) => (
                        <a 
                          className="user"
                          href={"/players/" + u}
                          key={u}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {u}
                        </a>
                      ))
                      .reduce((prev, curr) => [prev, ", ", curr])
                  }
                </td>
                <td>{e.categories.join(" /  ")}</td>
                <td>
                  {e.feedbacks.join(" / ")}
                  {!!e.text && (
                    <>
                      <br />
                      <small><a href={`https://forum.clozemaster.com/search?q=${encodeURI(e.text.replace(/\{\{|\}\}/g, ""))}`} target="_blank">Forum Comments ({e.commentsCount}) <Icon name="new-window" /></a></small>
                    </>
                  )}
                </td>
                {/*<td>{this.renderView(e)}</td>*/}
                <td>
                  {this.renderClaim(e)}
                  {this.renderSeen(e)}
                </td>
                <td>{this.renderEdit(e)}</td>
                <td>{this.renderTts(e)}</td>
                {/*<td>
                  {e.tatoebaId ?
                    <a className="btn btn-link" href={"https://tatoeba.org/eng/sentences/show/" + e.tatoebaId} rel="noreferrer" target="_blank">Tatoeba</a>
                    : "N/A"
                  }
                </td>*/}
                <td>{this.renderTokensBtn(e)}</td>
                <td>
                  {this.renderResolve(e)}
                  <button className="btn btn-xs btn-default" onClick={() => this.setState({ changesReportedSentence: e })}>Changes</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.renderPagination()}
        {/*{this.renderViewCollectionClozeSentenceModal()}*/}
        {this.renderCollectionClozeSentenceChangesModal()}
        {this.renderCollectionClozeSentenceEditorModal()}
        {this.renderCollectionClozeSentenceTtsModal()}
        {this.renderCollectionClozeSentenceTokensEditor()}
        {this.renderResolveModal()}
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <h1>Reported Sentences {this.renderLanguagePairing()}</h1>
        <p>How it works: claim a sentence you&apos;d like to fix, make the corrections, then click resolve. &#x1f389;</p>
        {this.renderContent()}
        <a href="/reported-sentences/report-counts-by-week">Report counts by week</a>
      </div>
    );
  }
}
