import React from "react";

import { v4 as uuidv4 } from "uuid";

import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import Pagination from "../Pagination";
import SentenceSearchResult from "./SentenceSearchResult";

export default class SentenceSearchModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      context: "text",
      loading: false,
      page: 1,
      perPage: 50,
      query: "",
      selectedMap: new Map(props.sentences.map((s) => [s.text, s.id])),
      sentences: [],
      total: 0,
    };
  }

  search() {
    this.setState({ loading: true });
    const {
      context,
      page,
      perPage,
      query
    } = this.state;
    $.ajax({
      data: {
        context,
        page,
        per_page: perPage,
        query,
      },
      method: "get",
      url: this.props.url
    })
      .done((data) => {
        console.log(data);
        this.setState({
          loading: false,
          sentences: data.sentences,
          total: data.total
        });
      })
      .fail(() => {
        this.setState({ loading: false });
        alert(
          "Oh no! There was an error searching sentences. " +
          "Please try again and let us know if you see this error message again."
        );
      });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ page: 1 }, () => this.search());
  }

  renderForm() {
    const { context, loading, query } = this.state;

    return (
      <form onSubmit={(e) => this.onSubmit(e)} style={{ marginBottom: 10 }}>
        <div className="row">
          <div className="col-xs-8">
            <div className="form-group">
              <label htmlFor="query">Query</label>
              <input
                className="form-control"
                disabled={loading}
                id="query"
                onChange={(e) => this.setState({ query: e.target.value })}
                type="text"
                value={query}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <div className="form-group">
              <label htmlFor="context">Context</label>
              <select
                className="form-control"
                disabled={loading}
                id="context"
                onChange={(e) => this.setState({ context: e.target.value })}
                value={context}
              >
                <option value="cloze">Cloze</option>
                <option value="text">Text</option>
                <option value="translation">Translation</option>
              </select>
            </div>
          </div>
        </div>
        <button
          className="btn btn-success btn-sm btn-block joystix"
          disabled={loading}
          type="submit"
        >
          Search
        </button>
      </form>
    );
  }

  renderPagination() {
    if(this.state.loading) {
      return null;
    }

    const { page, perPage, total } = this.state;

    return (
      <Pagination
        onNextClick={() => this.setState({ page: this.state.page + 1 }, () => this.search())}
        onPrevClick={() => this.setState({ page: this.state.page - 1 }, () => this.search())}
        page={page}
        perPage={perPage}
        total={total}
      />
    );
  }

  renderResults() {
    const { loading, sentences, selectedMap } = this.state;
    const { onSentenceAdd, onSentenceDelete } = this.props;

    if(loading) {
      return "Loading...";
    }

    if(!sentences.length) {
      return "No results!";
    }

    return (
      <>
        {sentences.map((s, i) => (
          <SentenceSearchResult
            key={i}
            onToggle={({ text, translation }) => {
              const id = selectedMap.get(text);
              if(id) {
                selectedMap.delete(text);
                this.setState({ selectedMap: new Map(selectedMap) });
                onSentenceDelete({ id });
              }
              else {
                const id = uuidv4();
                selectedMap.set(text, id);
                this.setState({ selectedMap: new Map(selectedMap) });
                onSentenceAdd({ id, text, translation });
              }
            }}
            selectedMap={selectedMap}
            sentence={s}
          />
        ))}
      </>
    );
  }

  renderContent() {
    return (
      <>
        {this.renderForm()}
        {this.renderResults()}
        {this.renderPagination()}
      </>
    );
  }

  render() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={this.props.onHidden}
        size="large"
        title="Sentence Search"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
