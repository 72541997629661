import React from "react";

export default class FollowClozemasterPanel extends React.Component {
  render() {
    return (
      <div className="panel panel-default clozemaster">
        <div className="panel-heading joystix">
          Follow Clozemaster
        </div>
        <div className="panel-body text-center follow-clozemaster">
          <ul className="list-inline" style={{ fontSize: '3em' }}>
            <li><a target="_blank" href="https://twitter.com/clozemaster"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
            <li><a target="_blank" href="https://www.reddit.com/r/clozemaster/"><i className="fa fa-reddit-alien" aria-hidden="true"></i></a></li>
            <li><a target="_blank" href="https://www.instagram.com/clozemaster/"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
            <li><a target="_blank" href="https://www.facebook.com/clozemaster"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
            {/*<li><a target="_blank" href="https://clozemaster.tumblr.com/"><i className="fa fa-tumblr" aria-hidden="true"></i></a></li>*/}
            <li><a target="_blank" href="https://www.pinterest.com/clozemaster/"><i className="fa fa-pinterest-p" aria-hidden="true"></i></a></li>
            {/*<li><a target="_blank" href="https://medium.com/@clozemaster"><i className="fa fa-medium" aria-hidden="true"></i></a></li>*/}
          </ul>
        </div>
      </div>
    );
  }
}
