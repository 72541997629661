import React from "react";

import ClozeSentenceSearch from "./ClozeSentenceSearch";
import Modal from "./Modal";
import ModalFooterCloseBtn from "./ModalFooterCloseBtn";
import ModalProPromo from "./ModalProPromo";

export default class ClozeSentenceSearchModal extends React.Component {
  renderProPromo() {
    return (
      <ModalProPromo
        can="search all the sentences available on Clozemaster and create their own collections."
        secondary="Take control of your learning. Get fluent faster."
      />
    );
  }

  renderContent() {
    if(!this.props.isPro) {
      return this.renderProPromo();
    }

    return (
      <ClozeSentenceSearch
        addToCollectionDisabled={this.props.addToCollectionDisabled}
        collectionsUrl={this.props.collectionsUrl}
        initialSelectedCollectionId={this.props.initialSelectedCollectionId}
        nextReviewByLevel={this.props.nextReviewByLevel}
        query={this.props.query}
        url={this.props.url}
      />
    );
  }

  render() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={this.props.onHidden}
        size="large"
        title="Sentence Search"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

