import React from "react";

import Loading from "../Loading";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import Pagination from "../Pagination";
import SharedCollection from "./SharedCollection";

export default class SharedCollectionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collections: [],
      loading: true,
      page: 1,
    };
  }

  componentDidMount() {
    this.loadCollections();
  }

  loadCollections() {
    this.setState({ loading: true });

    $.ajax({
      url: this.props.url
    })
      .done((data) => {
        this.setState({
          collections: data.collections,
          loading: false,
        });
      })
      .fail(() => {
        alert('Oh no! There was an error loading the collections. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  renderSharedCollection(c) {
    if(this.props.renderSharedCollection) {
      return this.props.renderSharedCollection(c);
    }

    return (
      <SharedCollection
        collection={c}
        isPro={this.props.isPro}
        key={c.id} 
        play={this.props.play}
      />
    );
  }

  renderContent() {
    const { collections, loading, page } = this.state;

    if(loading) {
      return <Loading />;
    }

    if(!collections.length) {
      return (
        <h3 className="text-center">
          No shared collections yet! Be the first - create and share your own!
        </h3>
      )
    }

    // just using js paging for now, will want to add server paging later
    const perPage = 10;

    return (
      <div id="shared-collections-modal">
        {!this.props.isPro && <p className="alert alert-success">Only Clozemaster Pro subscribers can play shared collections. <strong><a href="/pro">Get Pro today!</a></strong></p>}
        {collections.slice((page - 1) * perPage, page * perPage).map((c) => this.renderSharedCollection(c))}
        <Pagination
          onNextClick={() => this.setState({ page: page + 1 })}
          onPrevClick={() => this.setState({ page: page - 1 })}
          page={page}
          perPage={perPage}
          total={collections.length}
        />
      </div>
    );
  }

  hide(args) {
    this.modal.hide(args);
  }

  render() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn text="Cancel" />}
        onHidden={this.props.onHidden}
        ref={(el) => this.modal = el}
        size="large"
        title="Shared Collections"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
