import React from "react";

import Checkbox from "../../Checkbox";

export default function SearchForm(props) {
  const {
    context,
    disabled,
    excludeModerated,
    query,
    onChange,
    onSubmit,
    order,
    withHint,
    withNotes
  } = props;

  const asc = "⇧";
  const desc = "⇩";

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <div className="form-group">
            <label htmlFor="cloze-sentences-query">Query</label>
            <input
              className="form-control"
              disabled={disabled}
              id="cloze-sentences-query"
              name="query"
              onChange={onChange}
              type="text"
              value={query}
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-3">
          <div className="form-group">
            <label htmlFor="cloze-sentence-search-context">Context</label>
            <select
              className="form-control" 
              disabled={disabled} 
              id="cloze-sentence-search-context"
              name="context" 
              onChange={onChange}
              value={context} 
            >
              <option value="cloze">Cloze</option>
              <option value="text">Text</option>
              <option value="translation">Translation</option>
            </select>
          </div>
        </div>
        <div className="col-xs-12 col-sm-3">
          <div className="form-group">
            <label htmlFor="cloze-sentences-order">Order</label>
            <select
              className="form-control"
              disabled={disabled} 
              id="cloze-sentences-order"
              name="order" 
              onChange={onChange}
              value={order} 
            >
              <option value="difficulty_asc">Difficulty {asc}</option>
              <option value="difficulty_desc">Difficulty {desc}</option>
              <option value="moderated_asc">Moderated {asc}</option>
              <option value="moderated_desc">Moderated {desc}</option>
              <option value="num_ignored_asc">Num ignored {asc}</option>
              <option value="num_ignored_desc">Num ignored {desc}</option>
              <option value="num_incorrect_asc">Num incorrect {asc}</option>
              <option value="num_incorrect_desc">Num incorrect {desc}</option>
              <option value="num_played_asc">Num played {asc}</option>
              <option value="num_played_desc">Num played {desc}</option>
              <option value="text_length_asc">Text length {asc}</option>
              <option value="text_length_desc">Text length {desc}</option>
              <option value="text_asc">Text {asc}</option>
              <option value="text_desc">Text {desc}</option>
              <option value="tokens_count_asc">Tokens count {asc}</option>
              <option value="tokens_count_desc">Tokens count {desc}</option>
            </select>
          </div>
        </div>
      </div>
      <ul className="list-inline">
        <li>
          <div className="form-group">
            <Checkbox
              checked={!excludeModerated}
              label="Include moderated"
              name="excludeModerated"
              onChange={(e) => onChange({ target: { name: e.target.name, value: !e.target.checked } })}
            />
          </div>
        </li>
        <li className="form-inline">
          <div className="form-group">
            <label htmlFor="cloze-sentence-search-with-hint">With hint</label>
            <select
              className="form-control"
              disabled={disabled}
              id="cloze-sentence-search-with-hint"
              name="withHint"
              onChange={onChange}
              style={{ marginLeft: 5 }}
              value={withHint}
            >
              <option value=""></option>
              <option value="present">Present</option>
              <option value="blank">Blank</option>
            </select>
          </div>
        </li>
        <li className="form-inline">
          <div className="form-group">
            <label htmlFor="cloze-sentence-search-with-notes">With notes</label>
            <select
              className="form-control"
              disabled={disabled}
              id="cloze-sentence-search-with-notes"
              name="withNotes"
              onChange={onChange}
              style={{ marginLeft: 5 }}
              value={withNotes}
            >
              <option value=""></option>
              <option value="present">Present</option>
              <option value="blank">Blank</option>
            </select>
          </div>
        </li>
      </ul>
      <button disabled={disabled} type="submit" className="btn btn-success btn-sm btn-block joystix">Search</button>
    </form>
  );
};
