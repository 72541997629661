import React from "react";

import Checkbox from "./Checkbox";
import CollectionSelector from "./CollectionSelector";
import CollectionClozeSentenceEditor from "./CollectionClozeSentenceEditor";
import Icon from "./Icon";
import Toast from "./Toast";

export default class AddSentenceToCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collection: null,
      copied: false,
      errors: null,
      includeProgress: this.isNewSentence() ? null : (window.localStorage && window.localStorage.getItem("add-sentence-include-progress") === "true"),
      saving: false,
      sentenceEditorVisible: false,
      upsertUrl: null
    };
  }

  componentDidMount() {
  }

  isNewSentence() {
    return !this.props.collectionClozeSentence;
  }

  reset() {
    this.collectionClozeSentenceEditor.reset();
  }

  renderCollectionClozeSentenceEditor() {
    if(!this.state.sentenceEditorVisible) {
      return null;
    }

    return (
      <CollectionClozeSentenceEditor
        collectionClozeSentence={this.props.collectionClozeSentence}
        createUrl={this.state.collection.collectionClozeSentencesUrl}
        isPro={true}
        isTextEditable={true}
        nextReviewByLevel={this.props.nextReviewByLevel}
        onUpdate={(collectionClozeSentence) => this.props.onSentenceAdded(collectionClozeSentence, this.state.collection)}
        ref={(el) => this.collectionClozeSentenceEditor = el}
      />
    );
  }

  onCopySubmit(e) {
    e.preventDefault();

    const { collection, includeProgress } = this.state;
    const { collectionClozeSentence, onSentenceAdded } = this.props;

    this.setState({ errors: null, saving: true });

    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        include_progress: includeProgress,
        new_collection_id: collection.id
      }),
      dataType: "json",
      method: "post",
      url: collectionClozeSentence.copyUrl
    })
      .done((data) => {
        if(data.errors) {
          this.setState({ errors: data.errors, saving: false });
          return false;
        }
        this.setState({ copied: true });
        onSentenceAdded(collectionClozeSentence, collection);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        this.setState({ saving: false });
        alert(`Oh no! There was an error adding the sentence. ${textStatus} ${errorThrown} Sorry about that. Please try again and let someone know if you see this message again.`);
      });
  }

  renderControls() {
    const { collectionClozeSentence, copyable } = this.props;
    const { collection, errors, includeProgress, saving, sentenceEditorVisible } = this.state;

    if(!copyable || this.isNewSentence() || !collection) {
      return null;
    }

    // TODO! should probably split this component for new vs existing sentences
    // or merge / update CopySentencePopover to allow editing sentence

    return (
      <form disabled={saving} onSubmit={(e) => this.onCopySubmit(e)}>
        <div style={{ marginBottom: 15 }}>
          {!sentenceEditorVisible && !!errors && <p className="alert alert-danger">{errors}</p>}
          {!sentenceEditorVisible && (
            <div style={{ display: "inline-block", marginRight: 10 }}>
              <Checkbox
                checked={includeProgress}
                disabled={saving}
                label="Include progress"
                name="include_progress"
                onChange={(e) => this.setState({ includeProgress: e.target.checked }, () => {
                  window.localStorage && window.localStorage.setItem("add-sentence-include-progress", "" + this.state.includeProgress);
                })}
                style={{ margin: 0 }}
              /> 
            </div>
          )}
          <button
            className="btn btn-default"
            disabled={saving}
            onClick={(e) => {
              e.preventDefault(); // to prevent form submission
              this.setState({ sentenceEditorVisible: !sentenceEditorVisible })
            }}
          >
            Edit <Icon name={`chevron-${sentenceEditorVisible ? "up" : "down" }`} />
          </button>
        </div>
        {!sentenceEditorVisible && (
          <button
            autoFocus
            className="joystix btn btn-lg btn-success btn-block copy-save" 
            disabled={saving}
            ref={(el) => this.saveBtn = el}
          >
            Save
          </button>
        )}
      </form>
    );
  }

  renderToast() {
    if(this.state.copied) {
      return (
        <Toast
          content="Saved! &#x1f389;"
          context="success"
        />
      );
    }
  }

  render() {
    const { copyable } = this.props;
    const { sentenceEditorVisible } = this.state;

    return (
      <div>
        <CollectionSelector
          collectionsUrl={this.props.collectionsUrl}
          initialSelectedCollectionId={this.props.initialSelectedCollectionId}
          onSelect={(collection) => {
            // const collectionWasBlank = !this.state.collection;

            this.setState({
              collection,
              sentenceEditorVisible: copyable ? sentenceEditorVisible : true,
              upsertUrl: collection.collectionClozeSentencesUpsertUrl
            });

            // // timeout to get around modal fade in
            // const timeoutDuration = $(".modal").hasClass("fade") && collectionWasBlank ? 500 : 0;
            // setTimeout(() => {
            //   !!this.saveBtn && $(this.saveBtn).focus();
            // }, timeoutDuration);
          }}
        />
        {this.renderControls()}
        {this.state.sentenceEditorVisible && <hr />}
        {this.renderCollectionClozeSentenceEditor()}
        {this.renderToast()}
      </div>
    );
  }
}
