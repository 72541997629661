import React from "react";

import FlagSprite from "../FlagSprite";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

import { progressChunksExplainerText } from "../../helpers";

export default class LanguagePairingSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progressPerChunk: props.progressPerChunk || 100,
      theme: $('body').hasClass('dark-mode') ? 'dark' : 'light',
      useProgressChunks: props.useProgressChunks === true
    };
  }

  setDarkMode(theme) {
    const { gameSettingsUrl } = this.props;

    $.ajax({
      url: gameSettingsUrl,
      data: {
        settings: { theme }
      },
      method: 'post'
    })
      .fail(() => {
        // TODO!
      });

    this.setState({ theme }, () => {
      $('body').toggleClass('dark-mode', this.state.theme === 'dark');
    });
  }

  onProgressChunksToggle(e) {
    this.setState({ useProgressChunks: e.target.value === "true" }, this.saveProgressChunks.bind(this));
  }

  saveProgressChunks({ showAlert = false } = {}) {
    const { languagePairingUrl, onProgressChunksChange } = this.props;
    const { progressPerChunk, useProgressChunks } = this.state;

    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        user_language_pairing: {
          progress_per_chunk: progressPerChunk,
          use_progress_chunks: useProgressChunks
        }
      }),
      method: "put",
      url: languagePairingUrl
    })
      .done((data) => {
        if(data.errors) {
          alert(data.errors);
          return false;
        }

        if(showAlert) {
          alert("Saved!");
        }

        onProgressChunksChange({
          progressPerChunk,
          useProgressChunks
        });
      })
      .fail(() => {
        alert("Oh no! There was an error saving the changes. Sorry about that. Please try again and please let someone know if you see this message again.");
      });
  }

  renderBtnGroup({ active, onClick, values }) {
    return (
      <div className="btn-group" data-toggle="buttons">
        {values.map((v) => (
          <button
            className={"btn " + (active === v.value ? "btn-success active" : "btn-default")}
            key={v.value}
            type="button"
            value={v.value} onClick={onClick}
          >
            {v.label}
          </button>
        ))}
      </div>
    );
  }

  render() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    const {
      progressPerChunk,
      theme,
      useProgressChunks
    } = this.state;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        id="manage-language-pairing-modal"
        ref={(el) => this.modal = el}
        show={false}
        size="small"
        title={<span><FlagSprite flagIso={targetLanguageFlagIso} size={24} /> / <FlagSprite flagIso={baseLanguageFlagIso} size={24} /></span>}
      >
        <div className="text-center">
          <div className="dark-mode">
            <div className="joystix" style={{ marginBottom: 2 }}>Dark Mode</div>
            {this.renderBtnGroup({
              active: theme,
              onClick: (e) => this.setDarkMode(e.target.value),
              values: [
                { label: "On", value: "dark" },
                { label: "Off", value: "light" }
              ]
            })}
          </div>
          <div className="progress-chunks" style={{ marginTop: 30 }}>
            <div className="joystix" style={{ marginBottom: 2 }}>Progress Chunks</div>
            {this.renderBtnGroup({
              active: useProgressChunks,
              onClick: this.onProgressChunksToggle.bind(this),
              values: [
                { label: "On", value: true },
                { label: "Off", value: false }
              ]
            })}
            {useProgressChunks && (
              <>
                <div className="form-group" style={{ marginBottom: 10, marginTop: 10 }}>
                  <label htmlFor="progressPerChunk" style={{ display: "block" }}>Sentences per chunk</label>
                  <input className="form-control" max={1000} min={10} name="progressPerChunk" onChange={(e) => this.setState({ progressPerChunk: !!e.target.value ? parseInt(e.target.value) : e.target.value })} step={10} style={{ display: "inline-block", marginRight: 10, width: 80 }} type="number" value={progressPerChunk} />
                  <button className="btn btn-success joystix" onClick={() => this.saveProgressChunks({ showAlert: true })}>Save</button>
                </div>
              </>
            )}
            <div style={{ marginTop: 10 }}>{`How do you eat an elephant? 🐘 One bite at a time. Turning on Progress Chunks will change your progress bars to show your progress in chunks of ${progressPerChunk} sentences at a time. You can also change your chunk size. Turn off Progress Chunks to switch back to the default absolute progress bars.`}</div>
          </div>
        </div>
      </Modal>
    );
  }
}
