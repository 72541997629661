import React from "react";

import ClozeSentence from "../ClozeSentence";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class RoundHistoryModal extends React.Component {
  renderContent() {
    const { collectionClozeSentences, onEditClick, onHidden } = this.props;

    if(!collectionClozeSentences || !collectionClozeSentences.length) {
      return (
        <p className="text-center">Sentences you play through will be listed here. Keep going!</p>
      );
    }

    return (
      <table className="table">
        <tbody>
          {collectionClozeSentences.map((ccs, i) => (
            <tr className="round-history-sentence" key={ccs.id + "-" + i}>
              <td style={{ borderTopWidth: 0, fontSize: "1.25em" }}>{i + 1}.</td>
              <td style={{ borderTopWidth: 0, width: "100%" }}>
                <div style={{ fontSize: "1.25em" }}><ClozeSentence text={ccs.text} /></div>
                <div>{ccs.translation}</div>
              </td>
              <td style={{ borderTopWidth: 0 }}>
                <button className="btn btn-default manage" data-title="Manage sentence" onClick={() => onEditClick(ccs)} style={{ marginLeft: 8 }}>
                  <Icon name="list" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { onHidden } = this.props;
    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={onHidden}
        title="Round History"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
