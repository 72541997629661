import React from "react";

import { collectionPctStr } from "../../helpers";

import CollectionProgressBar from "./CollectionProgressBar";
import Icon from "../Icon";
import MasteredIcon from "../MasteredIcon";
import Panel from "../Panel";
import PlayingIcon from "../PlayingIcon";

export default class RandomCollectionComponent extends React.Component {
  render() {
    const { collection, isSignedIn, progressPerChunk, useProgressChunks } = this.props;

    return (
      <Panel className="random-collection">
        <div className="pull-right hidden-xs">
          <button disabled={!isSignedIn} onClick={() => this.props.play()} className="btn btn-success btn-lg btn-block joystix">Play <span className="glyphicon glyphicon-chevron-right"></span></button>
          {!isSignedIn && <div className="joystix text-center">Sign up!</div>}
        </div>
        <h2>
          Random Collection <button data-toggle="tooltip" data-title="Manage Collection" disabled={!isSignedIn} onClick={() => this.props.manage()} className="btn btn-default btn-sm manage-collection"><Icon name="list" /></button>
          <small style={{ display: 'block', marginTop: 8 }}>Play {collection.numSentences.toLocaleString()} sentences in random order.</small>
        </h2>
        <CollectionProgressBar
          collection={collection}
          perChunk={progressPerChunk}
          style={{ marginBottom: 10 }}
          useChunks={useProgressChunks}
        />
        <div>
          <span style={{ fontSize: '1.75rem' }}>
            <strong><PlayingIcon /> Playing {collection.numPlaying.toLocaleString()} <span className="hidden-xs">sentences </span>({collectionPctStr(collection, 'playing')})</strong>
          </span>
          <span style={{ marginLeft: 8, fontSize: '1.75rem' }}>
            <strong><MasteredIcon /> Mastered {collection.numMastered.toLocaleString()} <span className="hidden-xs">sentences </span>({collectionPctStr(collection, 'mastered')})</strong>
          </span>
          <button disabled={!isSignedIn} onClick={() => this.props.play()} className="btn btn-success btn-lg btn-block joystix visible-xs" style={{ marginTop: 10 }}>Play <span className="glyphicon glyphicon-chevron-right"></span></button>
          {!isSignedIn && <div className="joystix text-center visible-xs" style={{ marginTop: 4 }}>Sign up!</div>}
        </div>
      </Panel>
    );
  }
}
