// X duration total?
// O what happens on end round?
// X daily stats
// X help
// X feedback
// X last play on ucvs
// - infinite load
// - admin ui
// - dashboard panel
// - make public
import React from "react";

import moment from 'moment';

import { LANGUAGE_ACCENT_MAP, secondsToHHMMSSStr, splitTextOnCloze } from "../helpers";

import ClozeSentence from "./ClozeSentence";
import FadeOut from "./FadeOut";
import FlagSprite from "./FlagSprite";
import Icon from "./Icon";
import Incorrect from "./PlayCollection/Incorrect";
import ListeningControl from "./PlayCollection/ListeningControl";
import Loading from "./Loading";
import Modal from "./Modal";
import Points from "./PlayCollection/Points";
import ReportModal from "./PlayClozeListening/ReportModal";

export default class PlayClozeListening extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answered: false,
      correct: null,
      currentAnswer: '',
      currentSentenceIndex: null,
      promoModalVisible: false,
      reportModalVisible: false,
      loading: true,
      // points: null,
      // score: 0,
      sentences: [],
      sentenceControlVisible: false,
      sentenceVisible: false,
      textInputValue: '',
      totalDuration: 0
    };
  }

  componentDidMount() {
    this.loadSentences();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.loading && !this.state.loading) {
      this.initWavesurfer();
    }

    if(this.state.currentSentenceIndex !== prevState.currentSentenceIndex) {
      this.wavesurfer.load(this.getCurrentSentence().audioUrl);
    }

    const { isPro, maxBeforePromo, numPlayedToday } = this.props;
    if(!isPro && (this.state.currentSentenceIndex + numPlayedToday) >= maxBeforePromo && !this.state.promoModalVisible) {
      this.setState({ promoModalVisible: true });
    }
  }

  initWavesurfer() {
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: 'rgba(68,157,68,0.5)',
      progressColor: '#5cb85c'
    });
    this.wavesurfer.on('error', () => {
      alert('Oh no! There was an error. Sorry about that. Please try again and let us know if you see this message again.');
      this.setState({ sentenceVisible: true });
    });
    this.wavesurfer.on('pause', () => {
      this.setState({ isPlayingAudio: false });
    });
    this.wavesurfer.on('play', () => {
      this.setState({ isPlayingAudio: true });
    });
    this.wavesurfer.on('seek', () => {
      this.playAudio();
    });
    this.wavesurfer.on('ready', () => {
      if(!!this.state.currentSentenceIndex) {
        this.playAudio();
      }
    });
  }

  loadSentences() {
    this.setState({ loading: true });

    $.ajax({
      url: this.props.playUrl
    })
      .done((data) => {
        console.log(data);
        this.setState({
          currentSentenceIndex: 0,
          loading: false,
          sentences: data.sentences
        });
      })
      .fail(() => {
        alert("Oh no! There was an error loading the sentences. Sorry about that. Please refresh the page and let us know if you see this message again.");
      });
  }

  loadMoreSentences() {
    $.ajax({
      url: this.props.playUrl
    })
      .done((data) => {
        console.log(data);
        const existingSentenceIds = this.state.sentences.map((s) => s.id); 
        this.setState({
          sentences: this.state.sentences.concat(data.sentences.filter((s) => existingSentenceIds.indexOf(s.id) < 0))
        });
      })
      .fail(() => {
        alert("Oh no! There was an error loading more sentences. Sorry about that. Please refresh the page and let us know if you see this message again.");
      });
  }

  getCurrentSentence() {
    return this.state.sentences[this.state.currentSentenceIndex];
  }

  playPauseAudio() {
    this.wavesurfer.playPause();
  }

  playAudio(start) {
    this.wavesurfer.play(start);
    // return null;

    // if(this.sentenceAudio) {
    //   this.sentenceAudio.pause();
    // }

    // const currentSentence = this.getCurrentSentence();
    // // const { gameSettings } = this.state;

    // this.sentenceAudio = new Audio(currentSentence.audioUrl);
    // // this.sentenceAudio.playbackRate = speed || parseFloat(gameSettings.textToSpeechSpeed) || 1;
    // if(onPlay) {
    //   this.sentenceAudio.addEventListener('play', onPlay);
    // }
    // if(onEnded) {
    //   this.sentenceAudio.addEventListener('ended', onEnded);
    // }
    // if(onError) {
    //   this.sentenceAudio.addEventListener('error', onError);
    // }
    // this.sentenceAudio.play().catch(() => onError && onError());
    // return this.sentenceAudio;
  }

  onSubmitAnswer(e) {
    e.preventDefault();

    if(this.state.answered) {
      this.handleSrsResponse(3);
      return false;
    }

    const currentSentence = this.getCurrentSentence();
    const { preClozeStr, cloze, postClozeStr } = splitTextOnCloze(currentSentence.text);
    const correct = this.state.textInputValue.toLowerCase().replace(/^\s+|\s+$/g, '') === cloze.toLowerCase();
    // const points = correct ? 4 : 0;

    this.sendAnswer(correct);

    this.setState({
      answered: true,
      correct,
      // points,
      // score: this.state.score + points
      totalDuration: this.state.totalDuration + currentSentence.duration
    }, () => this.playAudio(0));
  }

  sendAnswer(correct) {
    const currentSentence = this.getCurrentSentence();
    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        sentence: { correct }
      }),
      method: 'post',
      url: currentSentence.answerUrl
    })
      .done((data) => {
        // TODO! update sentence?
      })
      .fail(() => {
        alert("Oh no! There was an error saving the answer. Sorry about that. Please let us know if you see this message again.");
      });
  }

  sendSrsResponse(q) {
    const currentSentence = this.getCurrentSentence();
    const easinessFactor = this.getEasinessFactor({ ef: currentSentence.easinessFactor, q });
    const repetitionInterval = this.getRepetitionInterval({ ef: easinessFactor, q, ri: currentSentence.repetitionInterval });
    this.updateCurrentSentence({
      easiness_factor: easinessFactor,
      last_played_date: moment().format("YYYY-MM-DD"),
      next_review: moment().add(repetitionInterval, 'days').format("YYYY-MM-DD"),
      repetition_interval: repetitionInterval,
    });
  }

  updateCurrentSentence(attrs) {
    const currentSentence = this.getCurrentSentence();
    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({ sentence: attrs }),
      method: 'put',
      url: currentSentence.url
    })
      .done((data) => {
        // TODO! update sentence?
      })
      .fail(() => {
        alert("Oh no! There was an error updating the sentence. Sorry about that. Please let us know if you see this message again.");
      });
  }

  ignore() {
    if(confirm("Are you sure? This will remove the sentence from your queue.")) {
      this.updateCurrentSentence({ ignored: true });
      this.next();
    }
  }

  reset() {
    this.updateCurrentSentence({
      easiness_factor: 2.5,
      repetition_interval: null
    });
    this.next();
  }

  replayToday() {
    this.updateCurrentSentence({
      easiness_factor: 2.5,
      last_played_date: moment().format("YYYY-MM-DD"),
      next_review: moment().format("YYYY-MM-DD"),
      repetition_interval: null,
    });
    this.next();
  }

  handleSrsResponse(q) {
    this.sendSrsResponse(q);
    this.next();
  }

  next() {
    if((this.state.sentences.length - this.state.currentSentenceIndex) <= 3) {
      this.loadMoreSentences();
    }

    return this.setState({
      answered: false,
      correct: null,
      currentSentenceIndex: this.state.currentSentenceIndex + 1,
      // points: 0,
      sentenceControlVisible: false,
      sentenceVisible: false,
      textInputValue: ''
    });
  }

  getRepetitionInterval({ ef, ri, q }) {
    if(!ri) { 
      return q;
    }
    return Math.ceil(ri * Math.max(ef, 1.3));
  }

  getEasinessFactor({ ef, q }) {
    return ef + (0.1-(5-q)*(0.08+(5-q)*0.02));
  }

  onAccentBtnClick(e, accent) {
    e.preventDefault();

    if(e.shiftKey) {
      accent = accent.toUpperCase();
    }

    const t = this.state.textInputValue;
    const caretPos = this.textInput.selectionStart;

    this.setState({
      textInputValue: t.substring(0, caretPos) + accent + t.substring(caretPos)
    }, () => {
      this.textInput.focus();
      this.textInput.selectionStart = caretPos + 1;
      this.textInput.selectionEnd = caretPos + 1;
    });
  }

  renderAccentButtons() {
    if(this.state.answered) {
      return null;
    }

    const accentMap = LANGUAGE_ACCENT_MAP[this.props.targetLanguageIso];

    if(!accentMap) {
      return null;
    }

    const btns = [];
    for(var key in accentMap) {
      accentMap[key].split('').forEach((accent) => {
        btns.push(
          <button
            className="btn btn-default btn-sm"
            key={accent}
            onClick={(e) => this.onAccentBtnClick(e, accent)}
            // title={'alt+' + key}
            type="button"
          >
            {accent}
          </button>
        );
      });
    }

    return <div className="accent-btns">{btns}</div>;
  }

  renderCurrentSentence() {
    if(!this.state.sentenceVisible) {
      return null;
    }

    const currentSentence = this.getCurrentSentence();
    const { answered, correct } = this.state;
    const { preClozeStr, cloze, postClozeStr } = splitTextOnCloze(currentSentence.text);

    return (
      <div>
        <div style={{ fontSize: '2em' }}>{preClozeStr}<strong>{answered ? (correct ? <span style={{ color: '#5cb85c' }}>{cloze}</span> : cloze) : <u>________</u>}</strong>{postClozeStr}</div>
        {answered && <div dangerouslySetInnerHTML={{ __html: currentSentence.translation }} />}
        <div style={{ margin: '10px  0' }}>{this.renderPlayCurrentSentenceAudioBtn()}</div>
        <form onSubmit={(e) => this.onSubmitAnswer(e)}>
          <input
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            className="form-control input-lg"
            name="answer"
            onChange={(e) => this.setState({ textInputValue: e.target.value })}
            ref={(el) => this.textInput = el}
            spellCheck="false"
            style={{ color: correct ? '#5cb85c' : null, margin: '20px auto', maxWidth: 800, textAlign: 'center' }}
            type="text"
            value={this.state.textInputValue}
          />
          {this.renderAccentButtons()}
          {!this.state.answered && <button className="btn btn-success btn-lg joystix" type="submit">Submit</button>}
        </form>
      </div>
    );
  }

  renderPlayCurrentSentenceAudioBtn() {
    let content = null;
    if(this.state.isPlayingAudio) {
      content = <span><Icon name="pause" /> Pause</span>;
    }
    else {
      content = <span><Icon name="volume-up" /> Play</span>;
    }

    return (
      <button className="btn btn-default joystix" onClick={() => this.playPauseAudio()} style={{ width: 150 }}>
        {content}
      </button>
    );
  }

  renderSentenceControl() {
    if(/*!this.state.sentenceControlVisible || */this.state.sentenceVisible) {
      return null;
    }

    return (
      <ul className="list-inline">
        <li>
          {this.renderPlayCurrentSentenceAudioBtn()}
        </li>
        <li><button className="btn btn-default joystix" onClick={() => this.setState({ sentenceVisible: true }, () => { this.playAudio(0); this.textInput.focus(); })} style={{ width: 150 }}><Icon name="arrow-down" /> Sentence</button></li>
      </ul>
    );
  }

  renderSrsControls() {
    if(!this.state.answered) {
      return null;
    }

    const currentSentence = this.getCurrentSentence();

    return (
      <ul className="list-inline">
        <li>
          <button className="btn btn-default joystix" onClick={() => this.ignore()}><Icon name="thumbs-down" /></button>
          <small style={{ display: 'block' }}>Ignore</small>
        </li>
        <li>
          <button className="btn btn-default joystix" onClick={() => this.reset()}><Icon name="refresh" /></button>
          <small style={{ display: 'block' }}>Reset</small>
        </li>
        <li>
          <button className="btn btn-default joystix" onClick={() => this.replayToday()}><Icon name="repeat" type="fa" /></button>
          <small style={{ display: 'block' }}>Today</small>
        </li>
        <li>
          <button className="btn btn-default joystix" onClick={() => this.handleSrsResponse(1)}>Hard</button>
          <small style={{ display: 'block' }}>{this.getRepetitionInterval({ ef: this.getEasinessFactor({ q: 1, ef: currentSentence.easinessFactor }), q: 1, ri: currentSentence.repetitionInterval })} days</small>
        </li>
        <li>
          <button className="btn btn-default joystix" onClick={() => this.handleSrsResponse(3)}>Normal</button>
          <small style={{ display: 'block' }}>{this.getRepetitionInterval({ ef: this.getEasinessFactor({ q: 3, ef: currentSentence.easinessFactor }), q: 3, ri: currentSentence.repetitionInterval })} days</small>
        </li>
        <li>
          <button className="btn btn-default joystix" onClick={() => this.handleSrsResponse(5)}>Easy</button>
          <small style={{ display: 'block' }}>{this.getRepetitionInterval({ ef: this.getEasinessFactor({ q: 5, ef: currentSentence.easinessFactor }), q: 5, ri: currentSentence.repetitionInterval })} days</small>
        </li>
      </ul>
    );
  }

  renderPoints() {
    if(!this.state.answered) {
      return null;
    }

    if(!this.state.correct) {
      return (
        <Incorrect />
      );
    }

    return (
      <FadeOut delay={3000}>
        <div style={{ position: 'relative' }}>
          <div style={{ color: '#5cb85c', fontSize: '3em', position: 'absolute', textAlign: 'center', width: '100%' }}>
            <i className="fa fa-check" aria-hidden="true"></i>
          </div>
        </div>
      </FadeOut>
    );
  }

  renderBottomControls() {
    return (
      <div className="text-right" style={{ marginTop: 30 }}>
        <ul className="list-inline">
          <li><button className="btn btn-sm btn-danger" onClick={() => this.setState({ reportModalVisible: true })}><Icon name="flag" /> Report</button></li>
          <li><button className="btn btn-sm btn-default" onClick={() => this.ignore()}><Icon name="thumbs-down" /> Ignore</button></li>
          {!this.state.roundEnded && <li><button className="btn btn-sm btn-default" onClick={() => this.endRound()}><Icon name="stop" /> End Round</button></li>}
          {/*<li><a className="btn btn-sm btn-default" href="/contact" target="_blank">Feedback</a></li>*/}
          <li><button className="btn btn-sm btn-default" data-target="#help-modal" data-toggle="modal"><Icon name="info-circle" type="fa" /> Help!</button></li>
        </ul>
      </div>
    );
  }

  renderReportModal() {
    if(!this.state.reportModalVisible) {
      return null;
    }

    const currentSentence = this.getCurrentSentence();

    return (
      <ReportModal
        onHidden={() => this.setState({ reportModalVisible: false })}
        url={currentSentence.reportUrl}
      />
    );
  }

  endRound() {
    if(confirm("Are you sure you want to end this round?")) {
      this.setState({ roundEnded: true });
    }
  }

  renderUltModal() {
    return (
      <Modal
        id="ult-modal"
        show={false}
        size="small"
        title="Unique Listening Time"
      >
        <p>The time in the top right represents <strong>unique listening time</strong> - the sum of the duration of each sentence you play. In other words if a sentence is 3 seconds long, only 3 seconds will be added regardless of how many times you listen to that sentence. The idea is to track the total time your brain is exposed to unique content (as opposed to including the same sentence multiple times on repeat).</p>
      </Modal>
    );
  }

  renderRoundResults() {
    const sentences = this.state.sentences.slice(0, this.state.currentSentenceIndex);
    return (
      <ul className="list-group round-results text-left" style={{ margin: "0 auto", marginTop: 20, maxWidth: 600 }}>
        {sentences.map((s) => {
          let a = null;
          return (
            <li className="list-group-item sentence" key={s.id}>
              <div className="pull-right">
                <button
                  className="btn btn-default" onClick={() => {
                    a = a || new Audio(s.audioUrl)
                    a.play().catch((e) => alert("Oh no! There was an error playing the sentence: " + e));
                  }}
                >
                  <Icon name="play" />
                </button>
              </div>
              <span style={{ fontSize: "1.5em" }}><ClozeSentence text={s.text} /></span>
              <br />
              <small dangerouslySetInnerHTML={{ __html: s.translation }} />
            </li>
          );
        })}
      </ul>
    );
  }

  renderHelpModal() {
    return (
      <Modal
        id="help-modal"
        show={false}
        title="Help!"
      >
        <p className="lead">Ready to rapidly improve your listening skills?</p>
        <p><strong>Listen to the sentence recorded by a native speaker, see the text and fill in the misisng word, then decide if and when you want to see the sentence again. Repeat.</strong></p>
        <p>Sentences and audio are from the Mozilla's awesome <a href="https://commonvoice.mozilla.org/en" target="_blank">Common Voice</a> dataset (check it out and contribute if you can!). Translations are from Google.</p>
        <p>The audio quality ranges from good to bad, the accents are different, the speed is variable - in other words it's an excellent challenge for your listening abilities and a fantastic way to get better at listening. <strong>If you can get to a point where you understand these sentences with ease, you'll have no problem understanding native speakers in the real world - with background noise, different accents, etc.</strong></p>
        <p>The spaced-repetition system is meant to help you listen to these sentences again and again at optimal intervals. You can also ignore sentences that aren't useful or have particularly bad audio - if a sentence gets enough ignores we'll remove it for everyone.</p>
        <p>The time in the top right represents "unique listening time" - the sum of the duration of each sentence you play. In other words if a sentence is 3 seconds long, only 3 seconds will be added regardless of how many times you listen to that sentence. The idea is to track the total time your brain is exposed to unique content (as opposed to including the same sentence multiple times on repeat).</p>
        <p>Feedback? Comments? Questions? <a href="/contact" target="_blank">Let us know!</a></p>
      </Modal>
    );
  }

  renderContent() {
    if(this.state.roundEnded) {
      return this.renderRoundResults();
    }

    return (
      <>
        {/*<ListeningControl
          audioUrl={currentSentence.audioUrl}
          key={currentSentenceIndex}
          onFirstPlayEnded={() => this.setState({ sentenceControlVisible: true })}
          onError={() => this.setState({ sentenceControlVisible: true })}
          playAudio={this.playAudio.bind(this)}
        />*/}
        <div id="waveform" style={{ marginBottom: 20, marginTop: 20 }}></div>
        {this.renderSentenceControl()}
        {this.renderCurrentSentence()}
        {this.renderSrsControls()}
      </>
    );
  }

  renderPromoModal() {
    if(!this.state.promoModalVisible) {
      return null;
    }

    const { maxBeforePromo } = this.props;

    return (
      <Modal
        footer={(
          <div style={{ textAlign: "left" }}>
            <a className="btn btn-default joystix" href="/dashboard"><Icon name="chevron-left" /> Dashboard</a>
          </div>
        )}
        noClose={true}
        size="small"
        title="Pro"
      >
        <div style={{ textAlign: "center" }}>
          <p style={{ fontSize: "1.25em" }}>Play {maxBeforePromo} sentences of Cloze-Listening per day for free.</p>
          <p style={{ fontSize: "1.5em" }}><strong>Get Clozemaster Pro for unlimited Cloze-Listening practice!</strong></p>
          <p style={{ fontSize: "1.25em" }}>Rapidly improve your listening skills. Get fluent faster.</p>
        </div>
        <a className="btn btn-success btn-lg btn-block joystix" href="/pro">Get Pro Today!</a>
      </Modal>
    );
  }

  render() {
    const { currentSentenceIndex, loading, score, sentenceControlVisible, sentenceVisible, totalDuration } = this.state;
    const { baseLanguageFlagIso, targetLanguageFlagIso } = this.props;

    if(this.state.loading) {
      return <Loading />;
    }

    const currentSentence = this.getCurrentSentence();

    return (
      <div className="container" id="cloze-listening" style={{ marginTop: 20, textAlign: "center" }}>
        {this.renderPoints()}
        <div className="text-left">
          <div className="pull-right joystix">
            <small>Played: </small>{currentSentenceIndex} <div className="visible-xs"></div><Icon name="clock-o" type="fa" /> {secondsToHHMMSSStr(totalDuration)} <button className="btn btn-xs btn-default" data-toggle="modal" data-target="#ult-modal"><Icon name="question-sign" /></button>
          </div>
          {/*<div className="pull-right joystix">
            <ul className="list-inline">
              <li>Score: {score} / Rank: {0}</li>
              <li><button className="btn btn-xs btn-link"><Icon name="question-sign" /></button></li>
            </ul>
          </div>*/}
          <div className="joystix">
            Cloze-Listening <FlagSprite flagIso={targetLanguageFlagIso} size={24} /> / <FlagSprite flagIso={baseLanguageFlagIso} size={24} /> <div className="visible-xs"></div>Beta
          </div>
        </div>
        {this.renderContent()}
        {this.renderBottomControls()}
        {this.renderHelpModal()}
        {this.renderUltModal()}
        {this.renderReportModal()}
        {this.renderPromoModal()}
      </div>
    );
  }
}
