import React from "react";

import { splitTextOnCloze } from "../helpers";

export default function ClozeSelectable(props) {
  const { onChange, text } = props;
  const { preClozeStr, cloze, postClozeStr } = splitTextOnCloze(text);

  const onMouseUp = (e) => {
    const selection = window.getSelection();
    const str = selection.toString().replace(/^\s+|\s+$/g, '');
    if(str && text.replace(/\{\{|\}\}/g, '').match(str)) {
      onChange(text.replace(/\{\{|\}\}/g, '').replace(str, '{{' + str + '}}'));
    }
  };

  return (
    <span onMouseUp={onMouseUp}>{preClozeStr}<b><u>{cloze}</u></b>{postClozeStr}</span>
  );
};
