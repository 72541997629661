import React from "react";

import { collectionPctStr } from "../../helpers";

import CollectionProgressBar from "./CollectionProgressBar";
import Icon from "../Icon";
import MarkedAsKnownIcon from "../MarkedAsKnownIcon";
import MasteredIcon from "../MasteredIcon";
import Panel from "../Panel";
import PlayingIcon from "../PlayingIcon";

export default function CollectionPanel({ className = "", collection, extraControls = null, fallbackDescription = "", helpText = null, isSignedIn, manage, name = null, panelId = null, play, progressPerChunk, useProgressChunks }) {
  return (
    <Panel className={`collection-panel ${className}`} id={panelId}>
      <div className="pull-right hidden-xs">
        <button disabled={!isSignedIn} onClick={play} className="btn btn-success btn-lg btn-block joystix">Play <Icon name="chevron-right" /></button>
        {!isSignedIn && <div className="joystix text-center">Sign up!</div>}
      </div>
      <h2>
        <span className="pull-right visible-xs">
          {extraControls}
          <button data-toggle="tooltip" data-title="Manage Collection" disabled={!isSignedIn} onClick={manage} className="btn btn-default btn-sm manage-collection" style={{ marginLeft: 8 }}><Icon name="list" /></button>
        </span>
        {name || collection.name}
        {!!helpText && (
          <button
            className="btn btn-xs btn-link"
            data-content={helpText}
            data-title={name || collection.name}
            data-toggle="popover"
            data-trigger="focus"
            style={{ fontSize: "65%", marginLeft: 8 }}
          >
            <Icon name="question-sign" />
          </button>
        )}
        <small style={{ display: "block", marginTop: 8 }}>{collection.description || fallbackDescription}</small>
      </h2>
      <CollectionProgressBar
        collection={collection}
        perChunk={progressPerChunk}
        style={{ marginBottom: 10 }}
        useChunks={useProgressChunks}
      />
      <div style={{ position: "relative" }}>
        <div style={{ fontSize: "2rem" }}>
          <strong style={{ marginRight: 8 }}><PlayingIcon /> Playing: {collection.numPlaying.toLocaleString()} <span className="hidden-xs"> / {collection.numSentences.toLocaleString()} sentences </span>({collectionPctStr(collection, "playing")})</strong>
          <div className="visible-xs" />
          <strong style={{ marginRight: 8 }}><MasteredIcon /> Mastered: {collection.numMastered.toLocaleString()} ({collectionPctStr(collection, "mastered")})</strong>
          <div className="visible-xs" />
          {!!collection.numMarkedAsKnown && (
            <strong><MarkedAsKnownIcon /> Marked as Known: {collection.numMarkedAsKnown.toLocaleString()}</strong>
          )}
        </div>
        {!!collection.numPlaying && <div style={{ marginTop: 10 }}>Played today: {collection.numPlayedToday} / New: {collection.numNewToday} / Review: {collection.numPlayedToday - collection.numNewToday}</div>}
        <div className="hidden-xs" style={{ bottom: 0, position: "absolute", right: 0 }}>
          {extraControls}
          <button disabled={!isSignedIn} onClick={manage} className="btn btn-default btn-xs manage-collection" style={{ marginLeft: 8 }}><Icon name="list" /> Manage</button>
        </div>
        <button disabled={!isSignedIn} onClick={play} className="btn btn-success btn-lg btn-block joystix visible-xs" style={{ marginTop: 10 }}>Play <Icon name="chevron-right" /></button>
        {!isSignedIn && <div className="joystix text-center visible-xs" style={{ marginTop: 4 }}>Sign up!</div>}
      </div>
    </Panel>
  );
}

