import React from "react";

import { getLocalStorageBoolean, setLocalStorageBoolean } from "../../helpers";

import CollectionEditorModal from "./CollectionEditorModal";
import CollectionTableRow from "./CollectionTableRow";
import Icon from "../Icon";
import Pagination from "../Pagination";
import SharedCollectionsModal from "./SharedCollectionsModal";

export default class MyCollectionsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentVisible: getLocalStorageBoolean('dashboardMyCollectionsVisible', false),
      createCollectionModalVisible: false,
      sharedCollectionsModalVisible: false,
      page: 1,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.contentVisible !== prevState.contentVisible) {
      setLocalStorageBoolean('dashboardMyCollectionsVisible', this.state.contentVisible);
    }
  }

  popoverContentStr() {
    // , via file upload, , submitting a list of words and phrases and having Clozemaster attempt to automatically select a sentence for each, or by submitting a text and using the sentences from the text or using Clozemaster sentences for each word in the text
    return "<p>Take complete control of your studies on Clozemaster - create and play your own sentence collections using sentences from Clozemaster or sentences you add yourself.</p><p>Add sentences manually or by searching and selecting sentences on Clozemaster.</p>";
  }

  renderHeader() {
    return (
      <div>
        <div className="pull-right" style={{ marginBottom: 15 }}>
          <button className="btn btn-success joystix" disabled={!this.props.isSignedIn} onClick={() => this.setState({ sharedCollectionsModalVisible: true })} style={{ marginRight: 10 }}><Icon name="users" type="fa" /> Shared</button>
          <button className="btn btn-success joystix" disabled={!this.props.isSignedIn} onClick={() => this.setState({ createCollectionModalVisible: true })}><Icon name="plus" /> Create</button>
        </div>
        <h2>
          Cloze-Collections
          <button className="btn btn-xs btn-link" data-content={this.popoverContentStr()} data-html="true" data-title="Cloze-Collections" data-toggle="popover" data-trigger="focus" style={{ fontSize: '65%', marginLeft: 8, textDecoration: 'none' }}>
            <span className="glyphicon glyphicon-question-sign"></span>
          </button>
          <span style={{ display: 'block', marginTop: 5 }}>
            <button className="btn btn-default toggle-collections" onClick={() => this.setState({ contentVisible: !this.state.contentVisible })}>
              <Icon name={'chevron-' + (this.state.contentVisible ? 'up' : 'down')} />
            </button>
            {/*<small style={{ marginLeft: 8 }}>Create and play your own collections of cloze sentences.</small>*/}
            <small style={{ marginLeft: 8 }}>Play shared collections of cloze sentences or create your own!</small>
          </span>
        </h2>
      </div>
    );
  }

  renderContent() {
    if(!this.state.contentVisible) {
      return null;
    }

    return (
      <div>
        {!!this.props.teachingUrl && <div className="text-right">Are you a teacher? Check out our <a href={this.props.teachingUrl}>teaching collections</a>.</div>}
        {this.renderCollections()}
        {this.renderPagination()}
      </div>
    );
  }

  renderPagination() {
    const { collections } = this.props;
    const { page } = this.state;

    if(collections.length <= 10) {
      return null;
    }

    return (
      <Pagination
        onNextClick={() => this.setState({ page: page + 1 })}
        onPrevClick={() => this.setState({ page: page - 1 })}
        page={page}
        perPage={10}
        total={collections.length}
      />
    );
  }

  renderCollections() {
    const { collections, progressPerChunk, useProgressChunks } = this.props;

    if(!collections || !collections.length) {
      return <div>None yet!</div>;
    }

    const { page } = this.state;
    const perPage = 10;

    const rows = collections.slice((page - 1) * perPage, page * perPage).map((c) => (
      <CollectionTableRow
        add={this.props.add}
        collection={c}
        isPro={this.props.isPro}
        isSignedIn={this.props.isSignedIn}
        editableCollection={c.isEditable}
        key={c.slug}
        manage={this.props.manage}
        onCollectionUpdate={this.props.onCollectionUpdate}
        onCollectionDelete={this.props.onCollectionDelete}
        play={this.props.play}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    ));

    return (
      <table className="table table-striped text-center">
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

  renderSharedCollectionsModal() {
    if(!this.state.sharedCollectionsModalVisible) {
      return null;
    }

    return (
      <SharedCollectionsModal
        isPro={this.props.isPro}
        play={(collection) => this.sharedCollectionsModal.hide({ onHidden: () => this.props.play(collection) })}
        onHidden={() => this.setState({ sharedCollectionsModalVisible: false })}
        ref={(el) => this.sharedCollectionsModal = el}
        url={this.props.sharedCollectionsUrl}
      />
    );
  }

  renderCreateCollectionModal() {
    if(!this.state.createCollectionModalVisible) {
      return null;
    }

    return (
      <CollectionEditorModal
        collectionsUrl={this.props.collectionsUrl}
        isPro={this.props.isPro}
        onCreate={(collection) => {
          this.setState({ contentVisible: true }, () => this.props.onCollectionCreate(collection));
        }}
        onHidden={() => this.setState({ createCollectionModalVisible: false })}
      />
    );
  }

  render() {
    return (
      <div className="panel panel-default my-collections">
        <div className="panel-body">
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderCreateCollectionModal()}
          {this.renderSharedCollectionsModal()}
        </div>
      </div>
    );
  }
}
