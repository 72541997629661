import React from "react";

export default function BlogPostLanguagePairing({ languagePairingId, languagePairings, url }) {
  const [currentLanguagePairingId, setCurrentLanguagePairingId] = React.useState(languagePairingId);
  const [loading, setLoading] = React.useState(false);

  const didMount = React.useRef(false);

  React.useEffect(() => {
    if(!didMount.current) {
      didMount.current = true;
    }
    else {
      setLoading(true);
      fetch(url, {
        body: JSON.stringify({ blog_post: { language_pairing_id: currentLanguagePairingId } }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        method: "put"
      })
        .then((response) => {
          if(!response.ok) {
            throw response.status;
          }
        })
        .catch((error) => {
          alert(`${error} - please refresh and try again. Please let someone know if error persists.`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentLanguagePairingId]);

  return (
    <select disabled={loading} onChange={(e) => setCurrentLanguagePairingId(e.target.value)} value={currentLanguagePairingId || ""}>
      <option></option>
      {languagePairings.map((languagePairing) => (
        <option key={languagePairing.id} value={languagePairing.id}>{languagePairing.name}</option>
      ))}
    </select>
  );
}
