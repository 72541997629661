import React from "react";

import ClozeSentenceTts from "./ClozeSentenceTts";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default function CollectionClozeSentenceTts(props) {
  const {
    clozeSentenceTtsJobUrl,
    clozeSentenceUrl,
    collectionClozeSentenceTtsJobUrl,
    collectionClozeSentenceUrl,
    onHidden
  } = props;

  const hasClozeSentence = !!clozeSentenceUrl;

  return (
    <Modal
      footer={<ModalFooterCloseBtn />}
      onHidden={onHidden}
      size={hasClozeSentence ? "large" : null}
      title="TTS"
    >
      <div className="row">
        <div className={"collection-cloze-sentence col-xs-12" + (hasClozeSentence ? " col-sm-6" : "")}>
          <p><strong>Collection Cloze Sentence</strong></p>
          <ClozeSentenceTts
            ttsJobUrl={collectionClozeSentenceTtsJobUrl}
            type="collection_cloze_sentence"
            url={collectionClozeSentenceUrl}
          />
        </div>
        {hasClozeSentence && (
          <div className="cloze-sentence col-xs-12 col-sm-6">
            <p><strong>Parent Cloze Sentence</strong></p>
            <ClozeSentenceTts
              ttsJobUrl={clozeSentenceTtsJobUrl}
              type="cloze_sentence"
              url={clozeSentenceUrl}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
