import React from "react";

import AutoScrollablePre from "../AutoScrollablePre";
import BulkSentenceCreationEditor from "./BulkSentenceCreationEditor";
import Loading from "../Loading";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class AddFileToCollectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      separator: null,
      phase: 'form',
    };
  }

  hide() {
    this.modal.hide();
  }

  onSubmit(e) {
    e.preventDefault();

    if(!this.isValidFile()) {
      return false;
    }

    const data = new FormData();
    data.append('file', this.state.file);
    data.append('separator', this.state.separator);

    this.setState({ phase: 'processing' });
    $.ajax({
      contentType: false,
      dataType: 'json',
      data,
      method: 'post',
      processData: false,
      url: this.props.collectionFileImportUrl,
    })
      .done((createData) => {
        this.handleCreateData(createData);
      })
      .fail(() => {
        this.setState({ phase: 'form' });
        alert("Oh no! There was an error processing the text - sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  handleCreateData(createData) {
    if(createData.errors) {
      alert("Oh no! There was an error processing the text: " + createData.errors + " Sorry about that. Please try again and let us know if you see this message again.");
      this.setState({ phase: 'form' });
      return false;
    }

    const statusInterval = setInterval(() => {
      $.ajax({
        url: createData.url
      })
        .done((statusData) => {
          const { error, result, status } = statusData;
          if(error) {
            clearInterval(statusInterval);
            this.setState({ phase: 'form' });
            alert("Oh no! There was an error processing the text: " + error + " - sorry about that. Please try again and let us know if you see this message again.");
            return false;
          }
          if(status === "complete") {
            clearInterval(statusInterval);
            this.setState({ phase: 'edit', result: result.map((r, id) => ({ id, ...r })) });
          }
          else {
            this.setState({ phase: "processing", result });
          }
        })
        .fail(() => {
          this.setState({ phase: 'form' });
          alert("Oh no! There was an error processing the text - sorry about that. Please try again and let us know if you see this message again.");
        });
    }, 3000);
  }

  isValidFile() {
    const { file, separator } = this.state;
    const invalid = !file || file.size > 1000000 || !file.name.match(/\.srt$|\.tsv$|\.csv$/i) || (file.name.match(/\.tsv$|\.csv$/i) && !separator);
    return !invalid;
  }

  renderFileErrors() {
    if(!this.state.file) {
      return null;
    }

    let error = "";

    if(!this.state.file.name.match(/\.srt$|\.tsv$|\.csv$/i)) {
      error = "Please select a .srt, .csv, or .tsv file.";
    }
    else if(this.state.file.size > 1000000) {
      error = "Too big! Max 1MB.";
    }

    if(error) {
      return <p className="alert alert-danger">{error}</p>;
    }
  }

  renderProcessing() {
    const { result } = this.state;
    return (
      <Loading>
        {!!result && (
          <AutoScrollablePre style={{ height: 200, marginTop: 10 }}>
            {result.map((r, i) => ((i + 1) + ". " +r.text)).join("\n")}
          </AutoScrollablePre>
        )}
      </Loading>
    );
  }

  renderCSVOptions() {
    const { file, separator } = this.state;

    if(file && file.name.match(/\.tsv$|\.csv$/i)) {
      return (
        <div className={'form-group'}>
          <label htmlFor="text">Column separator:</label>&nbsp;
          <input name="separator" type="radio" id="comma_separator" value="comma" checked={separator === "comma"} onChange={(e) => this.setState({ separator: e.target.value })} />&nbsp;
          <label htmlFor="comma_separator">Comma</label>&nbsp;
          <input name="separator" type="radio" id="tab_separator" value="tab" checked={separator === "tab"} onChange={(e) => this.setState({ separator: e.target.value })} />&nbsp;
          <label htmlFor="tab_separator">Tab</label>
        </div>
      )
    }
  }

  renderForm() {
    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <p>Upload your file. Max 1MB.</p>
        <p>You can edit sentences and choose different cloze words after uploading and before saving.</p>
        <p>Supported files are SRT{/*, EPUB*/} and TSV/CSV.</p>
        <p>TSV/CSV should have the following structure:</p>
        <p><code>Sentence*,Translation,Cloze,Pronunciation,Note</code><br />5 columns, Sentence is mandatory. If Cloze is provided, first matching Cloze in Sentence will be used as the cloze word, otherwise a cloze word will be automatically selected for each sentence</p>
        <p>If the sentence itself contains commas please sure to wrap the sentence in quotes, "".</p>
        <p>Anki and Readlang cloze formats are supported.</p>
        <div className={'form-group'}>
          <label htmlFor="text">File</label>
          <input name="file" type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} />
        </div>
        {this.renderCSVOptions()}
        {this.renderFileErrors()}
        <button className="btn btn-success joystix" disabled={!this.isValidFile()}>Submit</button>
      </form>
    );
  }

  renderEditor() {
    return (
      <BulkSentenceCreationEditor
        collection={this.props.collection}
        onSentencesAdded={this.props.onSentencesAdded}
        sentences={this.state.result}
      />
    );
  }

  renderContent() {
    const { phase } = this.state;

    switch(phase) {
      case 'form':
        return this.renderForm();
      case 'processing':
        return this.renderProcessing();
      case 'edit':
        return this.renderEditor();
      default:
        throw new Error("unrecognized phase!");
    }
  }

  render() {
    return (
      <Modal
        dialogStyle={this.state.phase === 'edit' ? { width: '95%' } : null}
        footer={<ModalFooterCloseBtn />}
        onHidden={this.props.onHidden}
        ref={(el) => this.modal = el}
        title="Import File"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

