import React from "react";

import { collectionNameSort } from "../../helpers";

export default function CollectionsModalTable(props) {
  return (
    <table className="table table-striped collections-modal-table">
      <thead>
        <tr>
          <th>Collection</th>
          <th>Count</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.collections.slice(0).sort(collectionNameSort).map((collection) => (
          <tr key={collection.slug}>
            <td style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{collection.name}</td>
            <td style={{ fontSize: '1.5em' }}>{collection[props.attr]}</td>
            <td style={{ width: '25%' }}>
              <button className={'btn btn-block joystix ' + (props.btnClassName || 'btn-default')} onClick={() => props.onBtnClick(collection)}>
                {props.btnText}
                {!!props.btnIcon && <span style={{ marginLeft: 8 }}>{props.btnIcon}</span>}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
