import React from "react";

import {
  getLocalStorageBoolean,
  secondsToHHMMSSStr,
  setLocalStorageBoolean
} from "../../helpers";

import Icon from "../Icon";
import MasteredIcon from "../MasteredIcon";
import PlayingIcon from "../PlayingIcon";

export default class ClozeListeningPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentVisible: getLocalStorageBoolean('dashboardClozeListeningVisible', false)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.contentVisible !== prevState.contentVisible) {
      setLocalStorageBoolean('dashboardClozeListeningVisible', this.state.contentVisible);
    }
  }

  renderContent() {
    if(!this.state.contentVisible) {
      return null;
    }

    const {
      numPlaying,
      numReadyForReview,
      uniqueListeningTimeToday,
      uniqueListeningTimePast7Days,
      uniqueListeningTimePast30Days,
      uniqueListeningTimeTotal,
      url
    } = this.props;

    // <p>Take your listening skills to the next level!</p>
    // <p>Listen to a sentence read by a native speaker, then see the sentence with a word missing and type in the missing word.</p>
    // <p>Cloze-listening works similar to playing the listening skill for the Fluency Fast Track or Most Common Words collection, except there is no set collection, you can choose the difficulty, and native speaker audio is used along with machine translations.</p>
    // <p>Reviews use an always increasing spaced-repetition interval unless a sentence is answered incorrectly, in which case the interval is reset.</p>
    // <p>Sentences and audio are from Mozilla's Common Voice project, and translations by Google Translate.</p>
    // <p><strong>Play a round per day for free or get Clozemaster Pro for unlimited listening practice!</strong></p>

    return (
      <div>
        <hr />
        <ul>
          <li>Cloze-Listening is the ultimate challenge and the best way to improve your listening skills at the intermediate level and above.</li>
          <li>Hear the sentence. Then see it with a word missing. Fill in the missing word. Repeat.</li>
          <li>There are different accents, background noise, and the audio quality is variable - if you can understand and master these sentences, you'll have no problem in the real world.</li>
          <li>Sentences and audio are from Mozilla's <a href="https://commonvoice.mozilla.org/en" target="_blank">Common Voice</a> project (contribute if you can!). Translations are by Google Translate. The spaced-repetition help you train your ear more efficiently.</li>
          <li>Not quite ready for Cloze-Listening? Most collections offer the listening skill option which lets you practice with slower and clearer text-to-speech.</li>
        </ul>
        <hr />
        <ul className="list-inline">
          <li><Icon name="clock-o" type="fa" /> Today: <span className="joystix">{secondsToHHMMSSStr(uniqueListeningTimeToday)}</span></li>
          <li>Past 7 days: <span className="joystix">{secondsToHHMMSSStr(uniqueListeningTimePast7Days)}</span></li>
          <li>Past 30 days: <span className="joystix">{secondsToHHMMSSStr(uniqueListeningTimePast30Days)}</span></li>
          <li>All-Time: <span className="joystix">{secondsToHHMMSSStr(uniqueListeningTimeTotal)}</span></li>
        </ul>
        <hr />
        <ul className="list-inline" style={{ marginLeft: -10, marginRight: -10 }}>
          <li style={{ fontSize: '1.75rem', fontWeight: 'bold', paddingLeft: 10, paddingRight: 10 }}><PlayingIcon /> Playing: {numPlaying}</li>
          {/*<li style={{ fontSize: '1.75rem', fontWeight: 'bold', paddingLeft: 10, paddingRight: 10 }}><MasteredIcon /> Mastered: {mastered}</li>*/}
          <li style={{ fontSize: '1.75rem', fontWeight: 'bold', paddingLeft: 10, paddingRight: 10 }}><span className="hidden-xs">Ready for </span>Review: {numReadyForReview}</li>
          <li style={{ fontSize: '1.75rem', fontWeight: 'bold', paddingLeft: 10, paddingRight: 10 }}>
            <a className="btn btn-success joystix" href={url}>Play <Icon name="chevron-right" /></a>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div className="panel panel-default cloze-listening">
        <div className="panel-body">
          <div className="pull-right" style={{ marginTop: 10 }}>
            <i className="fa fa-headphones fa-4x" aria-hidden="true" style={{ marginLeft: 4 }}></i>
          </div>
          <h2 className="title">
            <span style={{ display: 'inline-block', paddingBottom: 4 }}>Cloze-Listening</span>
            {/*<button className="btn btn-xs btn-link" data-content="<p>Take your listening skills to the next level!</p><p>Listen to a sentence recorded by a native speaker, then see the sentence with a word missing and type in the missing word.</p><p>Cloze-listening works similar to playing the listening skill for the Fluency Fast Track or Most Common Words collection, except there is no set collection, you can choose the difficulty, and native speaker audio is used along with machine translations.</p><p>Sentences and audio from Mozilla's Common Voice project, and translations by Google Translate.</p>" data-html="true" data-title="Cloze-Listening" data-toggle="popover" data-trigger="focus" style={{ fontSize: '65%', marginLeft: 8, textDecoration: 'none' }}>
              <span className="glyphicon glyphicon-question-sign"></span>
            </button>*/}
            <br className="hidden-xs" />
            <button className="btn btn-default toggle" onClick={() => this.setState({ contentVisible: !this.state.contentVisible })}>
              <Icon name={'chevron-' + (this.state.contentVisible ? 'up' : 'down')} />
            </button>
            <small style={{ marginLeft: 8 }}>
              Level up your listening skills with sentences recorded by native speakers. <button className="btn btn-sm btn-default" data-content={'This feature is currently in beta. We\'re still figuring out what will be most useful, and there may be some bugs. Feedback? <a href="/contact" target="blank">Let us know!</a>'} data-html="true" data-toggle="popover">BETA</button>
            </small>
          </h2>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}
