import React from "react";

import Checkbox from "../Checkbox";
import Radio from "../Radio";

const DefaultMaxReviewsPerCollectionPerDay = function({ disabled, onChange, value }) {
  return (
    <div className="form-inline" style={{ marginBottom: 15 }}>
      <div className="form-group">
        <label htmlFor="default_max_reviews_per_collection_per_day" style={{ marginRight: 5 }}>{/*Default */}Max reviews per collection per day</label>
        <Checkbox checked={value != null && value >= 0} disabled={disabled} label=" Enabled" name="default_max_reviews_per_collection_per_day_enabled" onChange={(e) => onChange(e.target.checked ? 20 : -1)} style={{ marginRight: 5 }} />
        <input
          className="form-control"
          disabled={disabled || value == null || value < 0}
          name="default_max_reviews_per_collection_per_day"
          min="0"
          max="9999"
          onChange={(e) => onChange(e.target.value)}
          type="number"
          value={value == null || value < 0 ? "" : value}
        />
      </div>
    </div>
  );
};

export default class ReviewSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alreadyMasteredNextReview: '',
      defaultMaxReviewsPerCollectionPerDay: null,
      level0: 0,
      level1: 1,
      level2: 10,
      level3: 30,
      level4: 180,
      loading: true,
      maxReviewsPerRound: 5,
      nextReviewFuzzEnabled: true,
      reviewsOrderedBy: "next_review ASC"
    };
  }

  componentDidMount() {
    this.loadReviewSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.level4 !== this.state.level4 && this.state.level4 === parseInt(36500)) {
      this.setState({ alreadyMasteredNextReview: '' });
    }

  }

  loadReviewSettings() {
    this.setState({ loading: true });
    $.ajax({
      method: 'get',
      url: this.props.url
    })
      .done((data) => {
        const { customSettings } = data;
        const newState = {
          loading: false,
        };

        if(customSettings.maxReviewsPerRound != null) {
          newState['maxReviewsPerRound'] = customSettings.maxReviewsPerRound;
        }

        if(customSettings.nextReviewByLevel) {
          for(let level in customSettings.nextReviewByLevel) {
            newState['level' + level] = customSettings.nextReviewByLevel[level];
          }
        }

        if(customSettings.reviewsOrderedBy != null) {
          newState['reviewsOrderedBy'] = customSettings.reviewsOrderedBy;
        }

        if(customSettings.alreadyMasteredNextReview != null) {
          newState['alreadyMasteredNextReview'] = customSettings.alreadyMasteredNextReview;
        }

        newState.defaultMaxReviewsPerCollectionPerDay = customSettings.defaultMaxReviewsPerCollectionPerDay;
        newState.nextReviewFuzzEnabled = customSettings.nextReviewFuzzEnabled;

        this.setState(newState);
      })
      .fail(() => {
        this.setState({ loading: false });
      });
  }

  onSubmit(e) {
    e.preventDefault();
    const { isPro } = this.props;
    if(isPro) {
      this.saveReviewSettings();
    }
  }

  showErrorMessage(e) {
    alert('Oh no! There was an error updating the review settings' + (e ? (': ' + e) : '') + '. Sorry about that. Please try again and let us know if you see this message again.');
  }

  saveReviewSettings() {
    this.setState({ loading: true });
    const {
      alreadyMasteredNextReview,
      defaultMaxReviewsPerCollectionPerDay,
      level0,
      level1,
      level2,
      level3,
      level4,
      maxReviewsPerRound,
      nextReviewFuzzEnabled,
      reviewsOrderedBy
    } = this.state;
    $.ajax({
      data: {
        custom_settings: {
          already_mastered_next_review: alreadyMasteredNextReview,
          default_max_reviews_per_collection_per_day: defaultMaxReviewsPerCollectionPerDay,
          max_reviews_per_round: maxReviewsPerRound,
          next_review_by_level: {
            0: level0,
            1: level1,
            2: level2,
            3: level3,
            4: level4
          },
          next_review_fuzz_enabled: nextReviewFuzzEnabled,
          reviews_ordered_by: reviewsOrderedBy
        }
      },
      method: 'put',
      url: this.props.url
    })
      .done((data) => {
        if(data.errors) {
          this.showErrorMessage(data.errors);
          this.setState({ loading: false });
        }
        else {
          this.setState({ loading: false }, () => this.props.onUpdate())
        }
      })
      .fail(() => {
        this.showErrorMessage();
        this.setState({ loading: false });
      });
  }

  renderIntervalOptions(level) {
    const intervals = Array.from(Array(30), (x, i) => i + 1)
      .concat(Array.from(Array(12), (x, i) => 30 + (i + 1) * 5))
      .concat(Array.from(Array(8), (x, i) => 90 + (i + 1) * 30))
      .concat([365]);

    const options = intervals.map((days) => (
      <option value={days} key={days}>{days} days</option>
    ));

    if(level === 0) {
      options.push(
        <option value="0" key="0">0 days</option>
      );
    }

    if(level === 4) {
      options.push(
        <option value="36500" key="never">Never</option>
      );
    }

    return options;
  }

  renderAlreadyMasteredNextReview() {
    // return null;

    const { alreadyMasteredNextReview, level4 } = this.state;

    // don't show if set to never
    if(parseInt(level4) === 36500) {
      return null;
    }

    return (
      <div>
        <p>For sentences answered correctly that are <strong>already</strong> <span className="joystix">100% Mastered</span>:</p>
        <Radio
          checked={alreadyMasteredNextReview === 'srs'}
          id="already-mastered-next-review-srs"
          label="Use an always increasing next review interval with Hard/Normal/Easy buttons after answering to modify the rate of increase"
          name="already_mastered_next_review"
          onChange={(e) => this.setState({ alreadyMasteredNextReview: e.target.value })}
          value="srs"
        />
        <Radio
          checked={alreadyMasteredNextReview === 'static'}
          id="already-mastered-next-review-static"
          label="Use Hard/Normal/Easy buttons after answering to set next review to 50/100/200% of 100% Mastered review interval setting"
          name="already_mastered_next_review"
          onChange={(e) => this.setState({ alreadyMasteredNextReview: e.target.value })}
          value="static"
        />
        <Radio
          checked={!alreadyMasteredNextReview}
          id="already-mastered-next-review"
          label="Always use 100% Mastered review interval setting"
          name="already_mastered_next_review"
          onChange={(e) => this.setState({ alreadyMasteredNextReview: e.target.value })}
          value=""
        />
      </div>
    );
  }

  render() {
    const { isPro } = this.props;
    const {
      defaultMaxReviewsPerCollectionPerDay,
      loading,
      maxReviewsPerRound,
      nextReviewFuzzEnabled,
      reviewsOrderedBy
    } = this.state;

    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        <p>
          Clozemaster uses a <a href="https://en.wikipedia.org/wiki/Spaced_repetition" target="_blank">spaced repetition system</a> to help you remember what you learn.
        </p>
        {!isPro && (
          <div className="alert alert-success tw-mb-5">
            <div className="tw-mb-5">Clozemaster Pro users can customize their review intervals and set the number of reviews per round.</div>
            <div className="tw-mb-5">Take control of your learning. Get fluent faster.</div>
            <a href="/pro" className="joystix btn btn-success btn-block btn-lg">Get Pro Today!</a>
          </div>
        )}
        <fieldset disabled={!isPro}>
          <div className="form-inline">
            <div className="form-group" style={{ marginBottom: 15 }}>
              <label htmlFor="max_reviews_per_round" style={{ marginRight: 5 }}>Max reviews per round when playing new sentences</label>
              <select className="form-control" name="max_reviews_per_round" disabled={loading} value={maxReviewsPerRound} onChange={(e) => this.setState({ maxReviewsPerRound: e.target.value })}>
                {[...new Array(51)].map((x, n) => (
                  <option key={n} value={n}>{n}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-inline">
            <div className="form-group" style={{ marginBottom: 15 }}>
              <label htmlFor="reviews_ordered_by" style={{ marginRight: 5 }}>Play review sentences ordered by</label>
              <select className="form-control" name="reviews_ordered_by" disabled={loading} value={reviewsOrderedBy} onChange={(e) => this.setState({ reviewsOrderedBy: e.target.value })}>
                <option value="next_review ASC">due date, oldest first</option>
                <option value="next_review DESC">due date, most recent first</option>
                <option value="level ASC">percent mastered, 0% first</option>
                <option value="level DESC">percent mastered, 100% first</option>
                <option value="random()">random</option>
              </select>
            </div>
          </div>
          <DefaultMaxReviewsPerCollectionPerDay
            disabled={loading}
            onChange={(defaultMaxReviewsPerCollectionPerDay) => this.setState({ defaultMaxReviewsPerCollectionPerDay })}
            value={defaultMaxReviewsPerCollectionPerDay}
          />
          <div className="panel panel-default">
            <div className="panel-heading joystix text-center">Review Intervals</div>
            <div className="panel-body">
              <table className="levels table">
                <thead>
                  <tr>
                    <th className="text-center">A sentence that is</th>
                    <th>will be seen again in</th>
                  </tr>
                </thead>
                <tbody>
                  {[...new Array(5)].map((x, level) => (
                    <tr key={level} className="level">
                      <td className="text-center pct-mastered joystix">
                        <label htmlFor={'settings[next_review_by_level][' + level + '][value]'}>{level * 25}% Mastered</label>
                      </td>
                      <td>
                        <select disabled={loading} name={'settings[next_review_by_level][' + level + '][value]'} value={this.state['level' + level]} onChange={(e) => this.setState({ ['level' + level]: e.target.value })} className="value form-control">
                          {this.renderIntervalOptions(level)}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {this.renderAlreadyMasteredNextReview()}
              <hr />
              <div>
                <p>If the next interval is &ge;8 days in the future, a small amount of "fuzz", a random +/-5% of the interval, can be added to prevent clusters of sentences always coming up for review on the same day.</p>
                <Checkbox checked={nextReviewFuzzEnabled} label="Next review fuzz enabled" name="settings[next_review_fuzz_enabled]" onChange={(e) => this.setState({ nextReviewFuzzEnabled: e.target.checked })} />
              </div>
            </div>
          </div>
          <button className="btn btn-success btn-block joystix">Save</button>
        </fieldset>
      </form>
    );
  }
}
