import React from "react";

import TextareaAutosize from 'react-textarea-autosize';

import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class ReportErrorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: 'sentence',
      feedback: '',
      submitted: false,
      submitting: false
    };
  }

  renderTatoebaNotice() {
    const { collectionClozeSentence } = this.props;

    if(!collectionClozeSentence.tatoebaId) {
      return null;
    }

    return (
      <p className="alert alert-info">This sentence is originally from Tatoeba. Please <u><strong><a href={'https://tatoeba.org/eng/sentences/show/' + collectionClozeSentence.tatoebaId} target="_blank">click here</a></strong></u> to update it there.</p>
    );
  }

  onSubmit(e) {
    e.preventDefault();

    const { collectionClozeSentence, url } = this.props;
    const { category, feedback } = this.state;

    this.setState({ submitting: true });

    $.ajax({
      data: {
        collection_cloze_sentence_error: {
          category,
          collection_cloze_sentence_id: collectionClozeSentence.id,
          feedback
        }
      },
      method: 'post',
      url
    })
      .done((data) => {
        this.setState({ submitted: true });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        alert('Oh no! There was an error reporting the error. "' + textStatus + ' => ' + errorThrown + '". Yikes, sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  renderPleaseIgnore() {
    return (
      <p className="alert alert-danger"><strong>Note!</strong> If you would like to remove the sentence from your review queue, you can ignore it by clicking the <span className="glyphicon glyphicon-ban-circle"></span> button after answering.</p>
    );
  }

  renderContent() {
    if(this.state.submitted) {
      return (
        <>
          <p className="alert alert-success" role="alert">Thanks for reporting the issue!</p>
          {this.renderTatoebaNotice()}
          {this.renderPleaseIgnore()}
        </>
      );
    }

    const { collectionClozeSentence } = this.props;
    const { category, feedback, submitting } = this.state;

    return (
      <>
        <p>Thanks for your help to improve Clozemaster! You can report any issues here.</p>
        {this.renderTatoebaNotice()}
        {this.renderPleaseIgnore()}
        <p><strong>Text:</strong> {collectionClozeSentence.text}</p>
        <p><strong>Translation:</strong> {collectionClozeSentence.translation}</p>
        {collectionClozeSentence.pronunciation && <p><strong>Pronunciation:</strong> {collectionClozeSentence.pronunciation}</p>}
        <hr />
        <form onSubmit={(e) => this.onSubmit(e)}>
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select className="form-control" name="category" disabled={submitting} value={category} onChange={(e) => this.setState({ category: e.target.value })}>
              <option value="sentence">Sentence</option>
              <option value="translation">Translation</option>
              {collectionClozeSentence.pronunciation && <option value="pronunciation">Pronunciation</option>}
              <option value="other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="feedback">Feedback (optional)</label>
            <TextareaAutosize
              name="feedback"
              className="form-control"
              disabled={submitting}
              value={feedback}
              onChange={(e) => this.setState({ feedback: e.target.value })}
            />
          </div>
          <button disabled={submitting} className="btn btn-success btn-block joystix">Submit</button>
        </form>
      </>
    );
  }

  render() {
    const { backdrop, onHidden } = this.props;
    const { submitted } = this.state;

    return (
      <Modal
        backdrop={backdrop}
        footer={<ModalFooterCloseBtn text={submitted ? "Close" : "Cancel"} />}
        onHidden={onHidden}
        size="small"
        title="Report Error"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
