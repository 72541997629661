import React from "react";

import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';

import { LANGUAGE_ACCENT_MAP, secondsSinceEpoch } from "../helpers";

import AddSentenceToCollectionModal from "./AddSentenceToCollectionModal";
import ClozeSentenceSearchModal from "./ClozeSentenceSearchModal";
import CollectionClozeSentenceEditorModal from "./CollectionClozeSentenceEditorModal";
import CopySentencePopover from "./CopySentencePopover";
import DiscussionModal from "./PlayCollection/DiscussionModal";
import ExplanationRequest from "./ExplanationRequest";
import FadeIn from "./FadeIn";
import FadeOut from "./FadeOut";
import FlagSprite from "./FlagSprite";
import GameSettings from "./PlayCollection/GameSettings";
import HelpModal from "./PlayCollection/HelpModal";
import Hint from "./PlayCollection/Hint";
import Icon from "./Icon";
import Incorrect from "./PlayCollection/Incorrect";
import IntraRoundAd from "./PlayCollection/IntraRoundAd";
import LeveledUpModal from "./PlayCollection/LeveledUpModal";
import ListeningControl from "./PlayCollection/ListeningControl";
import Loading from "./Loading";
import ManageCollectionModal from "./ManageCollectionModal";
import Modal from "./Modal";
import ModalFooterCloseBtn from "./ModalFooterCloseBtn";
import ModalProPromo from "./ModalProPromo";
import MultipleChoiceOptions from "./PlayCollection/MultipleChoiceOptions";
import Points from "./PlayCollection/Points";
import ReportErrorBtn from "./PlayCollection/ReportErrorBtn";
import RoundHistoryModal from "./PlayCollection/RoundHistoryModal";
import RoundResults from "./PlayCollection/RoundResults";
import SelectionPopover from "./SelectionPopover";
import SlideDown from "./SlideDown";
// import SpeechToText from "./SpeechToText";
import Tokens from "./PlayCollection/Tokens";

const PlayCollectionModes = {
  fullTextInput: "full_text_input",
  multipleChoice: 'multiple_choice',
  textInput: 'text_input'
};

const ultimatePunctuationRegex = /[!-/:-@[-`{-~¡-©«-¬®-±´¶-¸»¿×÷˂-˅˒-˟˥-˫˭˯-˿͵;΄-΅·϶҂՚-՟։-֊־׀׃׆׳-״؆-؏؛؞-؟٪-٭۔۩۽-۾܀-܍߶-߹।-॥॰৲-৳৺૱୰௳-௺౿ೱ-ೲ൹෴฿๏๚-๛༁-༗༚-༟༴༶༸༺-༽྅྾-࿅࿇-࿌࿎-࿔၊-၏႞-႟჻፠-፨᎐-᎙᙭-᙮᚛-᚜᛫-᛭᜵-᜶។-៖៘-៛᠀-᠊᥀᥄-᥅᧞-᧿᨞-᨟᭚-᭪᭴-᭼᰻-᰿᱾-᱿᾽᾿-῁῍-῏῝-῟῭-`´-῾\u2000-\u206e⁺-⁾₊-₎₠-₵℀-℁℃-℆℈-℉℔№-℘℞-℣℥℧℩℮℺-℻⅀-⅄⅊-⅍⅏←-⏧␀-␦⑀-⑊⒜-ⓩ─-⚝⚠-⚼⛀-⛃✁-✄✆-✉✌-✧✩-❋❍❏-❒❖❘-❞❡-❵➔➘-➯➱-➾⟀-⟊⟌⟐-⭌⭐-⭔⳥-⳪⳹-⳼⳾-⳿⸀-\u2e7e⺀-⺙⺛-⻳⼀-⿕⿰-⿻\u3000-〿゛-゜゠・㆐-㆑㆖-㆟㇀-㇣㈀-㈞㈪-㉃㉐㉠-㉿㊊-㊰㋀-㋾㌀-㏿䷀-䷿꒐-꓆꘍-꘏꙳꙾꜀-꜖꜠-꜡꞉-꞊꠨-꠫꡴-꡷꣎-꣏꤮-꤯꥟꩜-꩟﬩﴾-﴿﷼-﷽︐-︙︰-﹒﹔-﹦﹨-﹫！-／：-＠［-｀｛-･￠-￦￨-￮￼-�]|\ud800[\udd00-\udd02\udd37-\udd3f\udd79-\udd89\udd90-\udd9b\uddd0-\uddfc\udf9f\udfd0]|\ud802[\udd1f\udd3f\ude50-\ude58]|\ud809[\udc00-\udc7e]|\ud834[\udc00-\udcf5\udd00-\udd26\udd29-\udd64\udd6a-\udd6c\udd83-\udd84\udd8c-\udda9\uddae-\udddd\ude00-\ude41\ude45\udf00-\udf56]|\ud835[\udec1\udedb\udefb\udf15\udf35\udf4f\udf6f\udf89\udfa9\udfc3]|\ud83c[\udc00-\udc2b\udc30-\udc93]/g;

export default class PlayCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign(this.getInitialGameState(), {
      gameSettings: props.gameSettings || {},
      hasRunTutorial: props.hasRunTutorial || !!window.localStorage.getItem("hasRunPlayTutorial"),
      intraRoundAd: props.intraRoundAd,
      isRunningTutorial: false,
      translationVisible: true
    });
  }

  componentDidMount() {
    this.setupHotkeys();
    this.initTts();
    this.initSpeaking();

    $('body')
      .tooltip({ selector: '[data-toggle="tooltip"]' })
      .on('click', (e) => {
        if(!!this.state.selectionPopoverId && !$(e.target).parents('.selection-popover, .sentence').length) {
          this.setState({
            selectionPopoverId: null,
            selectedSentenceClozeStr: null,
            selectedSentenceText: null
          });
        }
      })
      .on('mouseup', (e) => this.handleSelectionOnMouseUp(e));

    $('footer.footer a').on('click', function(e) {
      e.preventDefault();
      if(confirm('Are you sure you want to leave this page?')) {
        window.location = e.currentTarget.href;
      }
    });

    if(this.shouldShowIntraRoundAd()) {
      this.setState({
        intraRoundAdVisible: true,
        loading: false
      });
    }
    else {
      this.incrementRoundsPlayedCount();
      this.loadCollectionClozeSentences();
    }
  }

  initTts() {
    // run check to load voices
    this.isSystemTtsAvailable();
  }

  initSpeaking() {
    if(!this.isPlayingSpeaking()) {
      return false;
    }

    if(window['webkitSpeechRecognition']) {
      this.recognition = new webkitSpeechRecognition();
    }
    else if(window['SpeechRecongition']) {
      this.recognition = new SpeechRecongition();
    }
    else {
      return alert('Unable to play speaking on this browser! Sorry about that. Please try the latest Google Chrome.');
    }

    this.recognition.continuous = true;
    this.recognition.lang = this.props.targetLanguageIso.toLowerCase() + '-' + this.props.targetLanguageIso.toUpperCase();
    this.recognition.interimResults = true;
    
    this.recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      this.setState({ speakingTranscript: transcript });
      if(event.results[0].isFinal) {
        this.stopRecordingAndResetRecordBtn();
        const matchingAnswer = this.getCurrentSentenceAnswers().find((a) => transcript.toLowerCase().match(a.toLowerCase()));
        if(!this.state.answered && matchingAnswer) {
          if(this.isPlayingTextInput()) {
            this.setState({ textInputValue: transcript });
          }
          this.submitAnswer(matchingAnswer);
        }
      }
    };

    this.recognition.onerror = (e) => this.onSpeechRecognitionError(e);
  }

  onSpeechRecognitionError(e) {
    alert("Oh no! There was an error: " + e.error + " " + e.message + " - sorry about that. Speaking is an experimental feature. You may need to refresh the page or update your browser permissions. Please let us know if the issue persists. Speaking works best on the latest version of Chrome on desktop.");
    this.stopRecordingAndResetRecordBtn();
  }

  setupAudio() {
    if(this.hasSetupAudio) {
      return true;
    }

    this.correctAudio.volume = 0.25;
    this.incorrectAudio.volume = 0.25;

    this.hasSetupAudio = true;
  }

  getInitialGameState() {
    return {
      answered: false,
      collectionClozeSentences: [],
      correct: null,
      correctlyAnsweredSentenceCount: 0,
      currentSentenceIndex: 0,
      discussionModalVisible: false,
      elapsedTime: 0,
      endingInfiniteRound: false,
      hasShownTokens: false,
      intraRoundAdNextRoundable: false,
      intraRoundAdVisible: false,
      listeningControlPlayComplete: false,
      loading: true,
      multipleChoice: false, // used while playing text input
      numCorrect: 0,
      numIncorrect: 0,
      numMastered: 0,
      numNew: 0,
      numReview: 0,
      playedNew: {},
      playedReview: {},
      points: null,
      previousAnswer: null,
      roundHistoryModalVisible: false,
      score: 0,
      sentenceEditorModalVisible: false,
      speakingTranscript: '',
      startedTextInput: false,
      textInputChangedAfterAnswering: false,
      textInputValue: '',
      tokensVisible: false,
      usedHint: false
    };
  }

  setupHotkeys() {
    $(window).on('keydown', (e) => {
      if($('.modal').is(':visible')) {
        return true;
      }

      // teaching collection name input
      if(this.isRoundComplete() && $('input').is(':focus')) {
        return true;
      }

      const { answered, gameSettings, multipleChoice, usedHint } = this.state;

      if(gameSettings.hotkeys !== 'on') {
        return true;
      }

      ////////////////////////////////////////
      // enter
      if(e.which === 13) {
        if(e.ctrlKey) {
          // fallback hotkey option for speaking
          if(this.isPlayingSpeaking()) {
            e.preventDefault();
            this.startRecording();
          }
        }
        else if(this.isRoundComplete()) {
          this.nextRound();
        }
        else if(answered) {
          if(this.answerQualityOptionsVisible()) {
            this.handleAnswerQualityResponse(1);
          }
          else {
            e.preventDefault();
            this.next();
          }
        }
        else if(this.isPlayingTextInput() || this.isPlayingFullTextInput()) {
          if(!this.state.textInputValue && !this.state.startedTextInput) {
            return false;
          }
          else if(!this.state.textInputValue && gameSettings.enterSubmitsEmpty === 'off') {
            return false;
          }
          this.submitAnswer(this.state.textInputValue);
        }
      }

      ////////////////////////////////////////
      // space
      if(e.which === 32) {
        if(this.isRoundComplete()) {
          e.preventDefault();
          this.nextRound();
        }
        else if(e.ctrlKey) {
          e.preventDefault();
          return this.playSentenceAudio();
        }
        else if(e.altKey && this.isPlayingSpeaking()) {
          e.preventDefault();
          this.startRecording();
        }
        else if(answered && !this.isPlayingTextInput() && !this.isPlayingFullTextInput()) {
          e.preventDefault();
          if(this.answerQualityOptionsVisible()) {
            this.handleAnswerQualityResponse(1);
          }
          else {
            this.next();
          }
        }
      }

      ////////////////////////////////////////
      // right arrow
      if(e.which === 39) {
        if(this.isRoundComplete()) {
          e.preventDefault();
          this.nextRound();
        }
        else if(answered && !$(this.textInput).is(':focus')) {
          e.preventDefault();
          this.next();
        }
      }

      ////////////////////////////////////////
      // 1-4 number keys
      if(this.multipleChoiceOptions && !answered && [49, 50, 51, 52].indexOf(e.which) >= 0) {
        e.preventDefault();
        this.multipleChoiceOptions.clickOptionAtIndex(e.which - 49);
      }
      // numpad
      if(this.multipleChoiceOptions && !answered && [97, 98, 99, 100].indexOf(e.which) >= 0) {
        e.preventDefault();
        this.multipleChoiceOptions.clickOptionAtIndex(e.which - 97);
      }
      // 1-3 keys for srs
      if(answered && this.answerQualityOptionsVisible() && [49, 50, 51].indexOf(e.which) >= 0) {
        e.preventDefault();
        this.handleAnswerQualityResponse(e.which - 49);
      }
      // numpad for srs
      if(answered && this.answerQualityOptionsVisible() && [97, 98, 99].indexOf(e.which) >= 0) {
        e.preventDefault();
        this.handleAnswerQualityResponse(e.which - 97);
      }

      ////////////////////////////////////////
      // a
      if(e.which === 65 && e.altKey && answered) {
        e.preventDefault();
        this.addCurrentSentenceToCollection();
      }

      ////////////////////////////////////////
      // c
      if(e.which === 67 && e.altKey && answered) {
        e.preventDefault();
        this.showManageCollectionModal();
      }

      ////////////////////////////////////////
      // d
      if(e.which === 68 && e.altKey && answered) {
        e.preventDefault();
        this.showSentenceEditorModal();
      }

      ////////////////////////////////////////
      // e
      if(e.which === 69 && e.altKey && answered) {
        e.preventDefault();
        $('.report-error.btn').click();
      }

      ////////////////////////////////////////
      // f
      if(e.which === 70 && e.altKey && answered) {
        e.preventDefault();
        $('.favorite-clozeable').click();
      }

      ////////////////////////////////////////
      // g
      if(e.which === 71 && e.altKey && answered) {
        e.preventDefault();
        this.onGrammarBtnClick();
      }

      ////////////////////////////////////////
      // h
      if(e.which === 72 && e.altKey) {
        if(this.isPlayingTextInput() && !answered && !multipleChoice && !usedHint) {
          e.preventDefault();
          this.useTextInputHint();
        }
      }

      ////////////////////////////////////////
      // i
      if(e.which === 73 && e.altKey && answered) {
        e.preventDefault();
        this.ignoreModal.show();
      }

      ////////////////////////////////////////
      // k
      if(e.which === 75 && e.altKey && answered) {
        e.preventDefault();
        if(gameSettings.manualMasterResetConfirm === 'on') {
          this.knowItModal.show();
        }
        else {
          this.onKnowItClick();
        }
      }

      ////////////////////////////////////////
      // m
      if(e.which === 77 && e.altKey && answered) {
        e.preventDefault();
        if(gameSettings.manualMasterResetConfirm === 'on') {
          this.masterModal.show();
        }
        else {
          this.onMasterClick();
        }
      }

      ////////////////////////////////////////
      // p
      if(e.which === 80 && e.altKey) {
        if(this.isPlayingTextInput() && !answered && !multipleChoice) {
          e.preventDefault();
          this.setState({ multipleChoice: true });
        }
      }

      ////////////////////////////////////////
      // r
      if(e.which === 82 && e.altKey && answered) {
        e.preventDefault();
        if(gameSettings.manualMasterResetConfirm === 'on') {
          this.resetModal.show();
        }
        else {
          this.onResetClick();
        }
      }

      ////////////////////////////////////////
      // s
      if(e.which === 83 && e.altKey) {
        e.preventDefault();
        this.showSentenceSearchModal();
      }

      ////////////////////////////////////////
      // t
      if(e.which === 84 && e.altKey) { 
        e.preventDefault();
        this.toggleTranslation();
      }

      ////////////////////////////////////////
      // u
      if(e.which === 85 && e.altKey && answered) { 
        e.preventDefault();
        this.setState({ discussionModalVisible: !this.state.discussionModalVisible });
      }

      ////////////////////////////////////////
      // y
      if(e.which === 89 && e.altKey) { 
        e.preventDefault();
        this.setState({ roundHistoryModalVisible: !this.state.roundHistoryModalVisible });
      }
    });

    $(window).on('keyup', (e) => {
      // esc
      if(e.which === 27 && this.state.leveledUpModalVisible) {
        // extra safeguard to remove leveledUpModal
        // reports of black background getting stuck
        // modal shown after answer response returns, may create race condition somewhere
        this.setState({ leveledUpModalVisible: false });
      }
    });
  }

  isPlayingFullTextInput() {
    return this.props.mode === PlayCollectionModes.fullTextInput;
  }

  isPlayingMultipleChoice() {
    return this.props.mode === PlayCollectionModes.multipleChoice;
  }

  isPlayingTextInput() {
    return this.props.mode === PlayCollectionModes.textInput;
  }

  isPlayingListening() {
    return this.props.skill === 'listening';
  }

  isPlayingSpeaking() {
    return this.props.skill === 'speaking';
  }

  shouldShowIntraRoundAd() {
    if(!this.state.intraRoundAd || this.props.isTeachingCollection) {
      return false;
    }
    const roundsPlayedCount = this.getRoundsPlayedCount();
    return !!roundsPlayedCount && roundsPlayedCount % 4 === 0;
  }

  getRoundsPlayedCount() {
    return parseInt(window.localStorage.getItem("roundsPlayedCount")) || 0;
  }

  incrementRoundsPlayedCount() {
    const roundsPlayedCount = this.getRoundsPlayedCount();
    window.localStorage.setItem("roundsPlayedCount", "" + (roundsPlayedCount + 1));
  }

  resetRoundsPlayedCount() {
    window.localStorage.removeItem("roundsPlayedCount");
  }

  nextRound() {
    const { intraRoundAdNextRoundable, intraRoundAdVisible } = this.state;

    if(this.shouldShowIntraRoundAd() && !intraRoundAdVisible) {
      return this.setState({ intraRoundAdVisible: true });
    }

    if(intraRoundAdVisible && !intraRoundAdNextRoundable) {
      return false;
    }

    this.incrementRoundsPlayedCount();
    this.setState(this.getInitialGameState(), () => this.loadCollectionClozeSentences());
  }

  loadMoreCollectionClozeSentences() {
    const { playUrl, mode, skill, scope, count } = this.props;

    if(this.isRoundComplete() || this.state.endingInfiniteRound) {
      return false;
    }

    $.ajax({
      url: playUrl,
      data: {
        mode,
        skill,
        scope,
        count
      }
    })
      .done((data) => {
        console.log(data);

        const {
          collectionClozeSentences
        } = this.state;

        const collectionClozeSentencesHash = collectionClozeSentences.reduce((h, ccs) => { h[ccs.id] = true; return h }, {});
        const newCollectionClozeSentences = data.collectionClozeSentences.filter((ccs) => !collectionClozeSentencesHash[ccs.id]);
        
        this.setState({
          loading: false,
          collectionClozeSentences: collectionClozeSentences.concat(newCollectionClozeSentences),
        });
      })
      .fail(() => {
        alert('Oh no! There was an error loading more sentences. Sorry about that. Please refresh the page to try again and let us know if you see the message again.');
      });
  }

  loadCollectionClozeSentences() {
    this.setState({ loading: true });

    const { playUrl, mode, skill, scope, count } = this.props;

    $.ajax({
      url: playUrl,
      data: {
        mode,
        skill,
        scope,
        count
      }
    })
      .done((data) => {
        console.log(data);
        this.setState({
          loading: false,
          alreadyMasteredNextReview: data.languagePairing.alreadyMasteredNextReview,
          clozeSentencesUrl: data.languagePairing.clozeSentencesUrl,
          collectionClozeSentences: data.collectionClozeSentences,
          collectionClozeSentencesUrl: data.collection.collectionClozeSentencesUrl,
          collectionClozeSentencesUpsertUrl: data.collection.collectionClozeSentencesUpsertUrl,
          collectionClozeSentencesIgnoreAllUrl: data.collection.collectionClozeSentencesIgnoreAllUrl,
          collectionClozeSentencesAnswerUrl: data.collection.collectionClozeSentencesAnswerUrl,
          collectionClozeSentencesSrsResponseUrl: data.collection.collectionClozeSentencesSrsResponseUrl,
          collectionsUrl: data.collectionsUrl,
          currentSentenceTimerStart: secondsSinceEpoch(),
          gameSettingsUrl: data.languagePairing.gameSettingsUrl,
          intraRoundAd: data.intraRoundAd,
          isCollectionEditable: data.collection.isEditable,
          isPro: data.user.isPro,
          isSignedIn: data.user.isSignedIn,
          languagePairingId: data.languagePairing.id,
          languagePairingLevel: data.languagePairing.level,
          languagePairingUrl: data.languagePairing.url,
          nextReviewByLevel: data.languagePairing.nextReviewByLevel,
          numMastered: 0,
          numNew: 0,
          numPlaying: 0,
          numReview: 0,
          playedNew: {},
          playedReview: {},
          reportErrorUrl: data.collection.collectionClozeSentencesErrorUrl,
          searchTranslationEntriesUrl: data.languagePairing.searchTranslationEntriesUrl,
          startingNumMastered: data.collection.numMastered,
          startingNumPlaying: data.collection.numPlaying,
          startingNumReadyForReview: data.collection.numReadyForReview,
          tokensEnabled: data.collection.tokensEnabled,
          tokensUrl: data.collection.tokensUrl,
          userSelectionTranslationsUrl: data.languagePairing.userSelectionTranslationsUrl,
          wordBank: data.wordBank,
        });
      })
      .fail(() => {
        alert('Oh no! There was an error loading sentences. Sorry about that. Please refresh the page to try again and let us know if you see the message again.');
      });
  }

  isReviewScope() {
    return this.props.scope === 'ready_for_review';
  }

  isFavoritedScope() {
    return this.props.scope === 'favorited';
  }

  renderPlayingName() {
    let action = 'Playing';

    if(this.isReviewScope()) {
      action = 'Reviewing';
    }
    else if(this.isFavoritedScope()) {
      action = 'Favorites';
    }

    return (
      <div className="playing-name">
        {action}: <strong>{this.props.collectionName}</strong>
      </div>
    );
  }

  renderRoundStats() {
    const { answered, currentSentenceIndex, collectionClozeSentences, correctlyAnsweredSentenceCount, numCorrect, numIncorrect } = this.state;
    const pctComplete = correctlyAnsweredSentenceCount / collectionClozeSentences.filter((s) => !s.wasIncorrect).length * 100;

    return (
      <div className="container-fluid">
        <div className="row status" ref={(el) => this.statusBar = el} style={{ display: 'block' }}>
          <div className="col-xs-4 text-right">
            <span className="hidden-xs">Correct</span> <span className="glyphicon glyphicon-ok">:</span> <span className="num correct">{numCorrect}</span>
          </div>
          <div className="col-xs-4 text-center">
            <span className="hidden-xs">Incorrect</span> <span className="glyphicon glyphicon-remove">:</span> <span className="num incorrect">{numIncorrect}</span>
          </div>
          <div className="col-xs-4 text-left">
            <span className="hidden-xs">To go</span> <span className="glyphicon glyphicon-arrow-right">:</span> <span className="num togo">{this.isInfiniteRound() && !this.state.endingInfiniteRound ? 'Inf' : (collectionClozeSentences.length - currentSentenceIndex - (answered ? 1 : 0))}</span>
          </div>
        </div>
        {(!this.isInfiniteRound() || this.state.endingInfiniteRound) && (
          <div className="row progress round" style={{ marginBottom: 0 }}>
            <div aria-valuemax="100" aria-valuemin="0" aria-valuenow={pctComplete} className="progress-bar progress-bar-success" role="progressbar" style={{ width: (pctComplete) + '%' }}></div>
          </div>
        )}
        {this.renderPlayingNameBanner()}
        {this.renderSignUpInPromo()}
        {this.renderRoundCompleteBanner()}
      </div>
    );
  }

  renderPlayingNameBanner() {
    if(this.props.isSignedIn || this.props.isTeachingCollection) {
      return (
        <div className={'row bg-' + (this.isReviewScope() ? 'primary' : 'success') + ' text-center lead'} style={{ padding: '4px 0',  marginBottom: 0 }}>
          {this.renderPlayingName()}
        </div>
      );
    }
  }

  renderSignUpInPromo() {
    if(this.props.isSignedIn || this.props.isTeachingCollection) {
      return null;
    }

    return (
      <div className="row bg-success text-center lead" style={{ padding: '4px 0', marginBottom: 0 }}>
        <a className="btn btn-sm btn-success joystix" href="/sign-up">Sign up</a>
        <span style={{ margin: '0 8px' }}>or</span>
        <a className="btn btn-sm btn-success joystix" href="/login" style={{ marginRight: 8 }}>Login</a>
        to save your progress!
      </div>
    );
  }

  renderRoundCompleteBanner() {
    if(!this.isRoundComplete()) {
      return null;
    }

    if(this.isNoSentences()) {
      return null;
    }

    return (
      <div className="row">
        <SlideDown>
          <div className={'round-complete-banner notification notice' + (this.isReviewScope() ? ' review' : '')}>
            <h1>Round Complete!</h1>
          </div>
        </SlideDown>
      </div>
    );
  }

  renderPoints() {
    if(this.state.answered && !this.state.correct) {
      return (
        <Incorrect />
      );
    }

    return (
      <Points
        points={this.state.points}
      />
    );
  }

  renderHeader() {
    const { baseLanguageFlagIso, targetLanguageFlagIso } = this.props;
    const { score, collectionClozeSentences, currentSentenceIndex, points, answered, correct } = this.state;
    const currentSentence = collectionClozeSentences[currentSentenceIndex];
    const pctMastered = Math.round(currentSentence.level / 4 * 100);

    return (
      <div className="stats row">
        {this.renderPoints()}
        <div className="col-xs-5 col-sm-4 joystix column text-left">
          <div className="content-wrapper">
            <div className="languages">
              <FlagSprite flagIso={targetLanguageFlagIso} size={24} /> / <FlagSprite flagIso={baseLanguageFlagIso} size={24} />
            </div>
            <div className="score total">
              <span className="text">Score:</span> <span className="value">{score}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-2 col-sm-offset-5 col-xs-4 col-xs-offset-3 column text-right">
          <div className="content-wrapper">
            <div className="level">
              {/*
              <div className="progress">
                <div aria-valuemax="100" aria-valuemin="0" className="progress-bar progress-bar-success" role="progressbar" style={{ width: pctMastered + '%' }}></div>
              </div>
              */}
              <div style={{ color: "#aaa", fontSize: "1.5em" }}>
                <Icon name="ok-circle" style={{ color: currentSentence.level >= 1 ? "#5cb85c" : null, margin: "0 5px" }} />
                <Icon name="ok-circle" style={{ color: currentSentence.level >= 2 ? "#5cb85c" : null, margin: "0 5px" }} />
                <Icon name="ok-circle" style={{ color: currentSentence.level >= 3 ? "#5cb85c" : null, margin: "0 5px" }} />
                <Icon name="ok-circle" style={{ color: currentSentence.level >= 4 ? "#5cb85c" : null, margin: "0 5px" }} />
              </div>
              <div className="joystix percent-mastered" style={{ fontSize: "0.9em" }}>
                <span className="value">{pctMastered}%</span> Mastered
                <span data-placement="bottom" data-title="Answer a sentence correctly 4x in a row to get it to 100% Mastered" data-toggle="tooltip">
                  <Icon name="question-sign" style={{ color: "#000", marginLeft: 5 }} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }

  getCurrentSentence() {
    const { collectionClozeSentences, currentSentenceIndex } = this.state;
    return collectionClozeSentences[currentSentenceIndex];
  }

  getCurrentSentenceCloze() {
    return this.getCurrentSentence().text.split('{{')[1].split('}}')[0];
  }

  ignoreAll(callback) {
    this.setState({ updating: true });
    const currentSentence = this.getCurrentSentence();
    $.ajax({
      url: this.state.collectionClozeSentencesIgnoreAllUrl,
      method: 'put',
      data: {
        collection_cloze_sentence_id: currentSentence.id
      }
    })
      .done((data) => {
        // TODO! doesn't update other sentences that might have the same cloze
        const newCollectionClozeSentences = this.getUpdatedCollectionClozeSentences(currentSentence.id, { ignored: true });
        this.setState({
          collectionClozeSentences: newCollectionClozeSentences,
          updating: false
        }, callback);
      })
      .fail(() => {
        this.setState({ updating: false }, callback);
        alert('Oh no! There was an error updating the sentences. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  updateCurrentSentence(attr, value, callback) {
    const currentSentence = this.getCurrentSentence();
    this.updateSentence({ sentence: currentSentence, attr, value, callback });
  }

  // expects attr string and value, or updates object
  updateSentence({ attr = null, callback, sentence, updates = null, value = null }) {
    this.setState({ updating: true });
    let update = { id: sentence.id };

    if(updates) {
      update = Object.assign(updates, update);
    }
    else {
      update[attr] = value;
    }

    $.ajax({
      url: sentence.collectionClozeSentencesUpsertUrl || this.state.collectionClozeSentencesUpsertUrl,
      method: 'post',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ 
        updates: [update]
      })
    })
      .then((data) => {
        return $.ajax({
          data: { ids: data.ids },
          method: 'post',
          url: data.collectionClozeSentencesBatchUrl
        });
      })
      .done((data) => {
        const updated = data.collectionClozeSentences[0];
        this.setState({
          collectionClozeSentences: this.state.collectionClozeSentences.map((ccs) => updated.id === ccs.id ? Object.assign(ccs, updated) : ccs),
          updating: false
        }, callback);
      })
      .fail(() => {
        this.setState({ updating: false }, callback);
        alert('Oh no! There was an error updating. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  renderFavoriteToggle() {
    const { isSignedIn } = this.props;

    if(!this.state.isPro) {
      return (
        <button className="btn btn-default favorite-clozeable control" disabled={!isSignedIn} title="Favorite sentence (alt+f)" data-toggle="modal" data-target="#favorite-pro-promo-modal">
          <span className={'glyphicon glyphicon-star-empty not-favorited'}></span>
        </button>
      );
    }

    const currentSentence = this.getCurrentSentence();

    return (
      <button className={'btn btn-default favorite-clozeable control' + (currentSentence.favorited ? ' favorited active' : '')} title={`${currentSentence.favorited ? "Unf" : "F"}avorite sentence (alt+f)`} onClick={() => this.updateCurrentSentence('favorited', !currentSentence.favorited)}>
        <span className={'glyphicon glyphicon-star' + (currentSentence.favorited ? ' favorited' : '-empty not-favorited')}></span>
      </button>
    );
  }

  toggleTranslation() {
    this.setState({ translationVisible: !this.state.translationVisible });
  }

  renderTranslationsToggle() {
    const { gameSettings, answered } = this.state;

    if(gameSettings.translations === 'visible' || (gameSettings.translations === 'show-after' && answered)) {
      return null;
    }

    const button = (
      <button
        className="btn btn-default toggle-translation control"
        onClick={() => this.toggleTranslation()}
        title="Toggle translation (alt+t)"
      >
        <span className="glyphicon glyphicon-transfer"></span>
      </button>
    );

    return button;
  }

  renderTransliterationToggle() {
    const { gameSettings } = this.state;

    if(gameSettings.transliteration === 'on') {
      return null;
    }

    if(!this.getCurrentSentence().transliteration) {
      return null;
    }

    return (
      <button className="btn btn-default toggle-transliteration control" onClick={() => this.setState({ transliterationVisible: !this.state.transliterationVisible })} title="Toggle transliteration">
        T
      </button>
    );
  }

  renderPronunciationToggle() {
    const { gameSettings } = this.state;

    if(gameSettings.pronunciation === 'on') {
      return null;
    }

    if(!this.getCurrentSentence().pronunciation && !this.getCurrentSentence().transliteration) {
      return null;
    }

    return (
      <button className="btn btn-default toggle-pronunciation control" onClick={() => this.setState({ pronunciationVisible: !this.state.pronunciationVisible })} title="Toggle pronunciation">
        P
      </button>
    );
  }

  // provides next letter
  useTextInputHint() {
    const textInputValue = this.state.textInputValue;
    const cloze = this.getCurrentSentenceCloze();
    let index = 0;

    while(textInputValue[index] && cloze[index] && textInputValue[index].toLowerCase() === cloze[index].toLowerCase()) {
      index = index + 1;
    }

    this.setState({
      textInputValue: cloze.substring(0, index + 1),
      usedHint: true
    }, () => this.textInput.focus());
  }

  onHintBtnClick(e) {
    $(e.currentTarget).tooltip('destroy');
    this.useTextInputHint();
  }

  renderTextInputHintBtn() {
    if(this.isPlayingMultipleChoice() || this.state.multipleChoice || this.state.usedHint) {
      return null;
    }

    return (
      <button
        className="btn btn-default text-input-hint"
        onClick={(e) => this.onHintBtnClick(e)}
        title="Single letter hint - you only get 1 per sentence and score half the points! alt+h"
      >
        <Icon name="question-sign" />
      </button>
    );
  }

  renderTextInputToMultipleChoiceBtn() {
    if(this.isPlayingMultipleChoice() || this.state.multipleChoice) {
      return null;
    }

    return (
      <button
        className="btn btn-default switch-to-multiple-choice"
        onClick={(e) => this.onSwitchToMultipleChoiceBtnClick(e)}
        title="Switch sentence to multiple choice alt+p"
      >
        <Icon name="th-large" />
      </button>
    );
  }

  onSwitchToMultipleChoiceBtnClick(e) {
    $(e.currentTarget).tooltip('destroy');
    this.setState({ multipleChoice: true }, () => this.textInput.focus());
  }

  playSentenceRecording({ onEnded = null, onError = null, onPlay = null, sentence = this.getCurrentSentence(), speed = null } = {}) {
    if(this.sentenceAudio) {
      this.sentenceAudio.pause();
    }

    const { gameSettings } = this.state;

    this.sentenceAudio = new Audio(sentence.audioRecordingUrl);
    this.sentenceAudio.playbackRate = speed || parseFloat(gameSettings.textToSpeechSpeed) || 1;
    if(onPlay) {
      this.sentenceAudio.addEventListener('play', onPlay);
    }
    if(onEnded) {
      this.sentenceAudio.addEventListener('ended', onEnded);
    }
    if(onError) {
      this.sentenceAudio.addEventListener('error', onError);
    }
    this.sentenceAudio.play().catch(() => onError && onError());
    return this.sentenceAudio;
  }

  playSentenceTts({ onEnded = null, onError = null, onPlay = null, sentence = this.getCurrentSentence(), speed = null } = {}) {
    if(this.sentenceAudio) {
      this.sentenceAudio.pause();
    }

    const { gameSettings } = this.state;

    this.sentenceAudio = new Audio(sentence.ttsAudioUrl);
    this.sentenceAudio.playbackRate = speed || parseFloat(gameSettings.textToSpeechSpeed) || 1;
    if(onPlay) {
      this.sentenceAudio.addEventListener('play', onPlay);
    }
    if(onEnded) {
      this.sentenceAudio.addEventListener('ended', onEnded);
    }
    if(onError) {
      this.sentenceAudio.addEventListener('error', onError);
    }
    this.sentenceAudio.play().catch(() => onError && onError());
    return this.sentenceAudio;
  }

  getSystemTtsVoices() {
    const { targetLanguageIso, targetLanguageCode } = this.props;
    return window.clozemaster.getSystemTtsVoices(targetLanguageIso, targetLanguageCode);
  }

  playSystemTts({ onEnded = null, onError = null, onPlay = null, sentence = this.getCurrentSentence(), speed = null } = {}) {
    const { targetLanguageIso, targetLanguageCode } = this.props;
    const { gameSettings } = this.state;
    const voices = this.getSystemTtsVoices();
    let voice = voices.filter((v) => v.name === gameSettings.textToSpeechVoice)[0];
    // fallback to random voice
    if(!voice) {
      voice = voices[Math.floor(Math.random()*voices.length)];
    }
    const rate = speed || parseFloat(gameSettings.textToSpeechSpeed) || 1;
    const u = new SpeechSynthesisUtterance(sentence.text.replace(/\{\{|\}\}/g, ''));
    u.voice = voice;
    u.lang = voice.lang;
    u.rate = window.clozemaster.isIOS8() ? (rate * 0.4) : rate;
    u.onstart = onPlay;
    u.onend = onEnded;
    u.onerror = onError;
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(u);
    return u;
  }

  playSentenceAudio({ onEnded = null, onError = null, onPlay = null, sentence = this.getCurrentSentence(), speed = null } = {}) {
    const { gameSettings } = this.state;

    if(sentence.audioRecordingUrl && this.state.gameSettings.audioRecordings === "on") {
      return this.playSentenceRecording({ onEnded, onEnded, onPlay, sentence, speed });
    }

    if(sentence.ttsAudioUrl && (this.state.gameSettings.clozemasterTextToSpeech === 'on' || !this.isSystemTtsAvailable())) {
      return this.playSentenceTts({ onEnded, onError, onPlay, sentence, speed });
    }

    return this.playSystemTts({ onEnded, onError, onPlay, sentence, speed });
  }

  isTtsAvailable(sentence = this.getCurrentSentence()) {
    return sentence.ttsAudioUrl || this.isSystemTtsAvailable();
  }

  isSystemTtsAvailable() {
    const { targetLanguageIso, targetLanguageCode } = this.props;
    return window.clozemaster.systemTtsAvailable(targetLanguageIso, targetLanguageCode);
  }

  renderTtsBtn() {
    if(!this.isTtsAvailable()) {
      return null;
    }

    return (
      <div className="btn-group" role="group" aria-label="Text-to-speech controls" style={{ marginRight: 4 }}>
        <button className="btn btn-default control" title="Play sentence audio (ctl+space)" style={{ marginRight: 0 }} onClick={() => this.playSentenceAudio()}>
          <span className="glyphicon glyphicon-volume-up"></span>
        </button>
        <button className="btn btn-default control" title="Play sentence audio half speed" style={{ margin: '0 0 0 -1px' }} onClick={() => this.playSentenceAudio({ speed: 0.5 })}>
          &frac12;
        </button>
      </div>
    );
  }

  renderAudioHintBtn() {
    return null;
    return (
      <button className="btn btn-default" title="Audio hint - half points!">
        <Icon name="question-sign" /> <span className="glyphicon glyphicon-volume-up"></span> 
      </button>
    );
  }

  renderManualResetBtn() {
    const currentSentence = this.getCurrentSentence();
    const { gameSettings } = this.state;
    const { manualMasterResetConfirm } = gameSettings;
    const { isSignedIn } = this.props;

    return (
      <button
        className="btn btn-default manual-reset"
        data-toggle={manualMasterResetConfirm === "on" ? "modal" : null}
        data-target={manualMasterResetConfirm === "on" ? "#reset-modal" : null}
        disabled={!isSignedIn || !currentSentence.level}
        onClick={manualMasterResetConfirm === "on" ? null : () => this.onResetClick()}
        title="Reset to 0% Mastered (alt+r)"
      >
        <span className="glyphicon glyphicon-remove"></span>
      </button>
    );
  }

  renderManualMasterBtn() {
    const currentSentence = this.getCurrentSentence();
    const { gameSettings } = this.state;
    const { manualMasterResetConfirm } = gameSettings;
    const { isSignedIn } = this.props;

    return (
      <button
        className="btn btn-default manual-master"
        data-toggle={manualMasterResetConfirm === "on" ? "modal" : null}
        data-target={manualMasterResetConfirm === "on" ? "#master-modal" : null}
        disabled={!isSignedIn || currentSentence.level === 4}
        onClick={manualMasterResetConfirm === "on" ? null : () => this.onMasterClick()}
        title="Set this sentence to 100% Mastered (alt+m)"
      >
        <span className="glyphicon glyphicon-ok"></span>
      </button>
    );
  }

  renderKnowItBtn() {
    const currentSentence = this.getCurrentSentence();
    const { gameSettings } = this.state;
    const { manualMasterResetConfirm } = gameSettings;
    const { isSignedIn } = this.props;

    return (
      <button
        className="btn btn-default know-it"
        disabled={!isSignedIn || !!(currentSentence.nextReview || "").match(/2100/)}
        data-toggle={manualMasterResetConfirm === "on" ? "modal" : null}
        data-target={manualMasterResetConfirm === "on" ? "#know-it-modal" : null}
        onClick={manualMasterResetConfirm === "on" ? null : () => this.onKnowItClick()}
        title="Mark this sentence as known - 100% Mastered and next review year 2100 (alt+k)"
      >
        🧠
      </button>
    );
  }

  renderCurrentSentenceControls() {
    const { answered, gameSettings, nextReviewControlsVisible } = this.state;

    if(!answered) {
      return (
        <div className="controls">
          {this.renderAudioHintBtn()}
          {this.renderTranslationsToggle()}
          {this.renderTextInputToMultipleChoiceBtn()}
          {this.renderTextInputHintBtn()}
        </div>
      );
    }

    const { isSignedIn } = this.props;
    const currentSentence = this.getCurrentSentence();

    const defaultControls = (
      <>
        {this.renderTtsBtn()}
        {this.renderTranslationsToggle()}
        {this.renderPronunciationToggle()}
        {this.renderTransliterationToggle()}
      </>
    );

    return (
      <div className="controls">
          <button className={`btn btn-default control${nextReviewControlsVisible ? " active" : ""}`} onClick={() => this.setState({ nextReviewControlsVisible: !nextReviewControlsVisible })}>
            <Icon name="time" /> {/*Next review: */}{moment(currentSentence.nextReview).diff(moment(), "days")} days
          </button>
        {defaultControls}
        <span className="signed-in-controls">
          {this.renderFavoriteToggle()}
          {/*this.renderKnowItBtn()*/}
          {/*this.renderManualMasterBtn()*/}
          {/*this.renderManualResetBtn()*/}
          <button className={'btn btn-default ignore control' + (currentSentence.ignored ? ' active' : '')} disabled={!isSignedIn} title={`${currentSentence.ignored ? "Uni" : "I"}gnore sentence (alt+i)`} data-toggle="modal" data-target="#ignore-modal">
            <span className="glyphicon glyphicon-ban-circle"></span>
          </button>
          {this.renderGrammarBtn()}
          {/*Discuss <span className="badge comment-count"><span className="value">0</span></span>*/}
          <button className="btn btn-default control" disabled={!isSignedIn} id="discussion-modal-btn" title="Discuss sentence (alt+u)" onClick={() => this.setState({ discussionModalVisible: true })}>
            <span className="glyphicon glyphicon-comment"></span>
            {!!currentSentence.commentsCount && <span className="badge comment-count" style={{ marginLeft: 4 }}><span className="value">{currentSentence.commentsCount}</span></span>}
          </button>
          <button className="btn btn-default control edit" disabled={!isSignedIn} title="Edit sentence (alt+d)" onClick={() => this.showSentenceEditorModal()}>
            <span className="glyphicon glyphicon-pencil"></span>
          </button>
          <button className="btn btn-default control add-to-collection" disabled={!isSignedIn} title="Add to collection (alt+a)" onClick={() => this.addCurrentSentenceToCollection()}>
            <span className="glyphicon glyphicon-plus"></span>
          </button>
          {/*
          <span style={{ position: "relative" }}>
            <button className="btn btn-default control copy-to-collection" disabled={!isSignedIn} title="Copy to collection" onClick={() => this.setState({ copyPopoverVisible: true })}>
              <Icon name="copy" type="fa" />
            </button>
            {this.renderCopyCurrentSentenceToCollectionPopover()}
          </span>
          */}
        </span>
      </div>
    );
  }

  renderGrammarBtn() {
    const currentSentence = this.getCurrentSentence();
    const { tokensEnabled, tokensVisible } = this.state;
    const { isSignedIn } = this.props;

    if(isSignedIn && tokensEnabled && currentSentence.tokensCount) {
      return (
        <button className="btn btn-default control grammar" title="Grammar (alt+g)" onClick={this.onGrammarBtnClick.bind(this)}>
          <Icon name={tokensVisible ? "chevron-up" : "chevron-down"} />
        </button>
      );
    }
  }

  onGrammarBtnClick() {
    const { hasShownTokens, tokensVisible } = this.state;

    this.setState({ 
      hasShownTokens: hasShownTokens || tokensVisible,
      tokensVisible: !tokensVisible
    });
  }

  showSentenceEditorModal() {
    this.setState({ sentenceEditorModalVisible: true });
  }

  onTextInputBeforeInput(e) {
    const { answered, gameSettings } = this.state;

    // prevent text input change when opening modal via shortcut hotkey
    // a, c, d, e, f, i, m, r, u
    if(gameSettings.hotkeys === "on" && answered && e.altKey && [65, 67, 68, 69, 70, 73, 77, 82, 85].indexOf(e.which) >= 0) {
      e.preventDefault();
      return true;
    }
  }

  onTextInputKeyDown(e) {
    const { answered, gameSettings } = this.state;

    if(answered && gameSettings.keyDownClearsTextInputAfterAnswering === "on" && !this.afterAnswerKeyDownTriggered) {
      // if enter then skip
      if(e.which === 13) {
        return true;
      }
      this.afterAnswerKeyDownTriggered = true;
      this.setState({ textInputValue: '' });
    }
    this.handleAccentsOnKeyDown(e);
  }

  onTextInputKeyUp(e) {
    if(!this.state.startedTextInput) {
      this.setState({ startedTextInput: true });
    }
    this.handleAccentsOnKeyUp(e);
  }

  onTextInputChange(e) {
    this.setState({
      textInputChangedAfterAnswering: this.state.answered,
      textInputValue: e.target.value
    });
  }

  handleAccentsOnKeyDown(e) {
    // not ideal, getting tick mark when using send_keys
    // suspect related to https://github.com/SeleniumHQ/selenium/issues/2704
    if(this.props.test && e.which === 0) {
      e.preventDefault();
      return false;
    }

    if(this.state.gameSettings.accentShortcuts !== 'on') {
      return null;
    }

    const caretPos = this.textInput.selectionStart;
    const c = String.fromCharCode(e.which);
    const accents = (LANGUAGE_ACCENT_MAP[this.props.targetLanguageIso] || {})[c.toUpperCase()];
    const accentDownCount = this.state.accentDownCount || 0;

    if(!e.altKey || !accents) {
      return null;
    }

    e.preventDefault();

    if(!accentDownCount) {
      this.accentingText = this.state.textInputValue;
    }

    let accent = accents[accentDownCount % accents.length];
    if(e.shiftKey) {
      accent = accent.toUpperCase();
    }

    const t = this.accentingText;
    this.setState({
      accentDownCount: (accentDownCount || 0) + 1,
      textInputValue: t.substring(0, caretPos) + accent + t.substring(caretPos)
    }, () => {
      this.textInput.selectionStart = caretPos;
      this.textInput.selectionEnd = caretPos + 1;
    });
  }

  handleAccentsOnKeyUp(e) {
    // not ideal, getting tick mark when using send_keys
    // suspect related to https://github.com/SeleniumHQ/selenium/issues/2704
    if(this.props.test && e.which === 0) {
      e.preventDefault();
      return false;
    }

    if(this.state.gameSettings.accentShortcuts !== 'on') {
      return null;
    }

    // 17 = ctrl key, 18 = alt key
    if(e.which === 18 && this.state.accentDownCount > 0) {
      const caretPos = this.textInput.selectionStart;
      this.setState({ accentDownCount: 0 });
      this.textInput.selectionStart = caretPos + 1;
      this.textInput.selectionEnd = caretPos + 1;
    }
  }

  getCurrentSentenceAnswers() {
    const currentSentence = this.getCurrentSentence();
    const cloze = this.getCurrentSentenceCloze();
    return [cloze].concat(currentSentence.alternativeAnswers || []);
  }

  isTextInputCorrectSoFar() {
    const lowerCaseTextInputValue = this.replaceAccents((this.state.textInputValue || '').toLowerCase());
    return !!this.getCurrentSentenceAnswers().find((a) => this.replaceAccents(a.toLowerCase()).indexOf(lowerCaseTextInputValue) === 0);
  }

  replaceAccents(str) {
    // treat ё as е for russian
    if(this.props.targetLanguageIso === 'ru') {
      return str.replace(/ё/g, 'е').replace(/Ё/g, 'Е');
    }

    return str;
  }

  onAddToCollectionClick() {
    // show modal with custom sentence based on selection
    //
    // show modal
    this.setState({
      addSentenceToCollectionModalVisible: true,
      selectionPopoverId: null, // hide the popover
    }, () => this.clearSelection()); // clear the selection to ensure popover stays closed
    // native check button - we post this sentence for people learning from target language to confirm whether sentence is correct
  }

  onSearchCollectionClick() {
    this.showManageCollectionModal();
  }

  showManageCollectionModal() {
    this.setState({
      manageCollectionModalVisible: true,
      selectionPopoverId: null
    });
  }

  onSearchSentencesClick() {
    this.showSentenceSearchModal();
  }

  showSentenceSearchModal() {
    const { answered } = this.state;

    if(!answered) {
      return null;
    }

    this.setState({
      clozeSentenceSearchModalVisible: true,
      selectionPopoverId: null
    });
  }

  getSelectionPopoverProps() {
    return {
      baseLanguageIso: this.props.baseLanguageIso,
      baseLanguageCode: this.props.baseLanguageCode,
      isPro: this.state.isPro,
      isSearchCollectionAvailable: !!this.props.collectionId,
      onClose: () => this.onSelectionPopoverClose(),
      onAddToCollectionClick: () => this.onAddToCollectionClick(),
      onSearchCollectionClick: () => this.onSearchCollectionClick(),
      onSearchSentencesClick: () => this.onSearchSentencesClick(),
      searchTranslationEntriesUrl: this.state.searchTranslationEntriesUrl,
      targetLanguageCode: this.props.targetLanguageCode,
      targetLanguageEnglishName: this.props.targetLanguageEnglishName,
      targetLanguageIso: this.props.targetLanguageIso,
      userSelectionTranslationsUrl: this.state.userSelectionTranslationsUrl
    };
  }

  renderCloze() {
    const { mode } = this.props;
    const { answered, gameSettings, textInputValue } = this.state;
    const currentSentence = this.getCurrentSentence();
    const cloze = this.getCurrentSentenceCloze();

    if(this.isPlayingTextInput()) {
      return (
        <input
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className={'input ' + this.getTextInputColorClassName()}
          name="text_input_value"
          onChange={(e) => this.onTextInputChange(e)}
          onKeyDown={(e) => this.onTextInputKeyDown(e)}
          onKeyUp={(e) => this.onTextInputKeyUp(e)}
          ref={(el) => {
            this.textInput = el;

            // if(!!this.textInput && typeof InputEvent.prototype.getTargetRanges === "function") {
            //   if(this.textInputBeforeInputListener) {
            //     this.textInput.removeEventListener("beforeinput", this.textInputBeforeInputListener);
            //   }
            //   this.textInputBeforeInputListener = this.textInput.addEventListener("beforeinput", this.onTextInputBeforeInput.bind(this));
            // }
          }}
          spellCheck="false"
          style={(answered || gameSettings.fitTextInputWidth === 'on') ? { width: this.getAnswerWidth() } : { width: $(window).width() < 1024 ? 140 : 200 }}
          type="text"
          value={textInputValue}
        />
      );
    }

    if(answered) {
      return (
        <SelectionPopover
          {...this.getSelectionPopoverProps()}
          selection={cloze}
          visible={this.state.selectionPopoverId === 'cloze'}
        >
          <strong
            className="cloze"
            role="button"
            onClick={(e) => {
              this.setState({
                selectionPopoverId: this.state.selectionPopoverId === 'cloze' ? null : 'cloze',
                selectedSentenceClozeStr: currentSentence.text,
                selectedSentenceText: cloze
              });
            }}
          >
            <u>{cloze}</u>
          </strong>
        </SelectionPopover>
      );
    }

    return (
      <span>__________</span>
    );
  }

  getTextInputColorClassName() {
    const { answered, correct, gameSettings, textInputChangedAfterAnswering, textInputValue } = this.state;

    if(answered && !textInputChangedAfterAnswering) {
      return correct ? 'correct' : '';
    }

    return (answered || gameSettings.typingColorHint === 'on')
      && textInputValue 
      && (this.isTextInputCorrectSoFar() ? 'correct' : 'incorrect');
  }

  getAnswerWidth() {
    const cloze = this.getCurrentSentenceCloze();
    $('<span id="width">').text(cloze).appendTo('.stage');
    const width = $('#width').width() + 10;
    $('#width').remove();
    return width;
  }

  onWordClick(word, id, startIndex) {
    const text = this.getCurrentSentencePlainText();
    this.setState({
      selectedSentenceClozeStr: text.substring(0, startIndex) + text.substring(startIndex).replace(word, "{{" + word + "}}"),
      selectedSentenceText: word,
      selectionPopoverId: this.state.selectionPopoverId === id ? null : id
    });
  }

  renderWord(word, id, startIndex) {
    // when i click a word or select text, need to show popover
    return (
      <SelectionPopover
        {...this.getSelectionPopoverProps()}
        key={id}
        selection={word}
        visible={this.state.selectionPopoverId === id}
      >
        <span className="word" role="button" onClick={(e) => this.onWordClick(word, id, startIndex)}>{word}</span>
      </SelectionPopover>
    );
  }

  wordify({ text, idPrefix }) {
    const regex = /[^\s.,;\?!]+/g;
    let startIndex = 0;
    // using #!# as our divider
    return text
      .replace(regex, "#!#$&#!#")
      .split(/#!#/g)
      .map((x, i) => {
        const word = !!x.match(regex) ?  this.renderWord(x, (idPrefix || '') + i, startIndex) : x;
        startIndex += x.length;
        return word;
      });
  }

  getCurrentSentencePlainText() {
    return this.getSentencePlainText(this.getCurrentSentence().text);
  }

  getSentencePlainText(text) {
    return text.replace(/{{|}}/g, '');
  }

  // https://stackoverflow.com/questions/3169786/clear-text-selection-with-javascript
  clearSelection() {
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }
  }

  onSelectionPopoverClose() {
    this.clearSelection();
    this.setState({
      selectionPopoverId: null,
      selectedSentenceClozeStr: null,
      selectedSentenceText: null
    });
  }

  handleSelectionOnMouseUp(e) {
    // returning true so it doesn't interfere with ccs editor onmouseup
    // (and maybe other mouseups on react elements)

    if($(e.target).parents('.selection-popover').length) {
      return true;
    }

    if($(e.target).parents('.modal').length) {
      return true;
    }

    const selection = window.getSelection();
    let str = selection.toString();
    const { answered } = this.state;

    if(!str.length && this.textInput && $(this.textInput).is(':focus')) {
      str += this.textInput.value.substring(this.textInput.selectionStart, this.textInput.selectionEnd);
    }

    if(!str.length || !answered) {
      return true;
    }

    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer.parentNode.cloneNode(false);
    container.appendChild(range.cloneContents());
    const html = container.innerHTML;
    const $selection = $('<span>' + html + '</span>');

    if($selection.find('.input').length && $selection.find('.pre, .post').length) {
      $selection.find('.input').replaceWith(this.state.textInputValue);
      str = $selection.text().replace(/\n/g, ' ').replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
    }

    if(!this.getCurrentSentencePlainText().match(str)) {
      return true;
    }

    e.preventDefault();
    e.stopPropagation();

    // timeout to skip listener on body that clears selection
    setTimeout(() => {
      this.setState({
        selectionPopoverId: 'sentence',
        selectedSentenceClozeStr: this.getSelectionClozeStr(selection),
        selectedSentenceText: str
      });
    }, 50);
  }

  getSelectionClozeStr(selection) {
    let start = selection.anchorOffset;
    let end = selection.focusOffset;
    let anchorNode = selection.anchorNode;
    let focusNode = selection.focusNode;

    if(start > end) {
      start = end;
      end = selection.anchorOffset;
      anchorNode = focusNode;
      focusNode = selection.anchorNode;
    }

    const dfs = (node) => {
      if(node.nodeName === "#text") {
        if(node === anchorNode && node === focusNode) {
          return node.textContent.substring(0, start) + '{{' + node.textContent.substring(start, end) + '}}' + node.textContent.substring(end);
        }
        if(node === anchorNode) {
          return node.textContent.substring(0, start) + '{{' + node.textContent.substring(start);
        }
        if(node === focusNode) {
          return node.textContent.substring(0, end) + '}}' + node.textContent.substring(end);
        }
        return node.textContent;
      }

      if(node['name'] === "text_input_value") {
        if(node === anchorNode && node === focusNode) {
          return node.value.substring(0, start) + '{{' + node.value.substring(start, end) + '}}' + node.value.substring(end);
        }
        if(node === anchorNode) {
          return node.value.substring(0, start) + '{{' + node.value.substring(start);
        }
        if(node === focusNode) {
          return node.value.substring(0, end) + '}}' + node.value.substring(end);
        }
        return node.value;
      }

      let str = '';
      for(let i = 0, n = node.childNodes.length; i < n; i++) {
        str += dfs(node.childNodes[i]);
      }
      return str;
    };

    return dfs($('.sentence')[0]);
  }

  getCurrentSentenceParts() {
    const currentSentence = this.getCurrentSentence();
    let parts = currentSentence.text.split('{{');
    const preClozeStr = parts[0];
    parts = parts[1].split('}}');
    return { preClozeStr, cloze: parts[0], postClozeStr: parts[1] };
  }

  onTokenClick({ cloze, text }) {
    const currentSentence = this.getCurrentSentence();
    this.setState({
      selectedSentenceClozeStr: currentSentence.text.replace(cloze || text, "{{" + (cloze || text) + "}}"),
      selectedSentenceText: text,
      selectionPopoverId: "sentence"
    });
  }

  renderTokens() {
    const { hasShownTokens, isPro, tokensUrl, tokensVisible } = this.state;
    const { targetLanguageCode, targetLanguageIso } = this.props;

    if(!tokensVisible) {
      return null;
    }

    if(!isPro && hasShownTokens) {
      return (
        <SlideDown>
          <div className="alert alert-success" style={{ maxWidth: 600, margin: "10px auto 10px", padding: "20px 10px 20px" }}>
            <p style={{ fontSize: "1.5em", fontWeight: "bold", marginBottom: 10 }}>Get full access to sentence grammar with Clozemaster Pro - subscribe today and get fluent faster!</p>
            <p><a className="btn btn-success btn-lg joystix" href="/pro">Get Pro!</a></p>
          </div>
        </SlideDown>
      );
    }

    const currentSentence = this.getCurrentSentence();

    return (
      <SlideDown>
        <Tokens
          onClick={this.onTokenClick.bind(this)}
          targetLanguageCode={targetLanguageCode}
          targetLanguageIso={targetLanguageIso}
          tokenizeableId={currentSentence.id}
          tokenizeableType={"CollectionClozeSentence"}
          url={tokensUrl || currentSentence.tokensUrl}
        />
      </SlideDown>
    );
  }

  renderCurrentSentenceFullTextInput() {
    const { baseLanguageRtl, targetLanguageRtl } = this.props;
    const currentSentence = this.getCurrentSentence();
    let { preClozeStr, cloze, postClozeStr } = this.getCurrentSentenceParts();
    const plainText = (preClozeStr + cloze + postClozeStr);
    const { answered, gameSettings, textInputValue } = this.state;
    const text = plainText.split("").map((x, i) => {
      if(textInputValue[i] === x || x.match(/\s/)) {
        return x;
      }
      return x.match(ultimatePunctuationRegex) ? x : "_";
    }).join("")

    return (
      <div>
        <div
          className={"sentence" + (targetLanguageRtl ? " rtl" : "")}
          style={{ fontSize: "2em" }}
        >
          {text}
        </div>
        <TextareaAutosize
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className={`input ${!!textInputValue ? (plainText.indexOf(textInputValue) === 0 ? "correct" : "incorrect") : ""} form-control text-center`}
          name="text_input_value"
          onChange={(e) => this.setState({ textInputValue: e.target.value }, () => {
            if(this.isCorrectAnswer(this.state.textInputValue)) {
              this.submitAnswer(this.state.textInputValue);
            }
          })}
          ref={(el) => this.textInput = el}
          style={{ fontSize: "1.5em", margin: "15px auto", maxWidth: 500, padding: 5 }}
          value={textInputValue}
        />
        {(!this.isPlayingListening() || gameSettings.translations === 'visible' || this.state.translationVisible) && (
          <div
            className={"translation " + (baseLanguageRtl ? "rtl " : "") + ("font-size-" + gameSettings.translationFontSize)}
          >
            {currentSentence.translation}
          </div>
        )}
        {!!currentSentence.pronunciation && answered && (gameSettings.pronunciation === 'on' || this.state.pronunciationVisible) && (
          <>
            <div>{currentSentence.pronunciation}</div>
          </>
        )}
        {!!currentSentence.transliteration && answered && (gameSettings.transliteration === 'on' || this.state.transliterationVisible) && (
          <>
            <div>{currentSentence.transliteration}</div>
          </>
        )}
        {this.renderAccentButtons()}
        <div style={{ marginTop: 10 }}>
          <button
            className="btn btn-default btn-lg text-input-hint"
            onClick={() => {
              const { textInputValue } = this.state;
              const text = this.getCurrentSentencePlainText();
              let i = 0;
              while(text[i] && textInputValue[i] === text[i]) {
                i = i + 1;
              }
              this.setState({ textInputValue: text.substring(0, i + 1) }, () => {
                this.textInput && this.textInput.focus();
              });
            }}
            title="Hint!"
          >
            <Icon name="question-sign" />
          </button>
        </div>
        <div style={{ marginTop: 10 }}>
          <button
            className="btn btn-success joystix btn-lg"
            onClick={() => this.submitAnswer(textInputValue)}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }

  renderCurrentSentence() {
    if(this.isListening()) {
      return null;
    }

    if(!this.state.answered && this.isPlayingFullTextInput()) {
      return this.renderCurrentSentenceFullTextInput();
    }

    const { answered, gameSettings, nextReviewControlsVisible, tokensVisible } = this.state;
    const currentSentence = this.getCurrentSentence();
    let { preClozeStr, cloze, postClozeStr } = this.getCurrentSentenceParts();
    const { baseLanguageRtl, targetLanguageRtl } = this.props;

    if(answered) {// && !targetLanguageCode.match(/^cmn|^jpn/)) {
      preClozeStr = this.wordify({ text: preClozeStr, idPrefix: 'pre' });
      postClozeStr = this.wordify({ text: postClozeStr, idPrefix: 'post' });
    }

    let sentence = (
      <SelectionPopover
        {...this.getSelectionPopoverProps()}
        selection={this.state.selectedSentenceText}
        visible={this.state.selectionPopoverId === 'sentence'}
      >
        <span className="pre">{preClozeStr}</span>{this.renderCloze()}<span className="post">{postClozeStr}</span>
      </SelectionPopover>
    );

    if(!answered && !!currentSentence.hint && gameSettings.hints === 'on') {
      sentence = (
        <Hint
          fontSize={gameSettings.hintFontSize}
          hint={currentSentence.hint}
        >
          {sentence}
        </Hint>
      );
    }

    const nextReviewDays = moment(currentSentence.nextReview).diff(moment(), "days");

    const content = (
      <>
        <div className={'sentence' + (answered ? ' answered' : '') + (targetLanguageRtl ? ' rtl' : '')}>
          {sentence}
        </div>
        {(gameSettings.translations === 'visible' || this.state.translationVisible) && (
          <div
            className={"translation " + (baseLanguageRtl ? "rtl " : "") + ("font-size-" + gameSettings.translationFontSize)}
          >
            {currentSentence.translation}
          </div>
        )}
        {!!currentSentence.pronunciation && answered && (gameSettings.pronunciation === 'on' || this.state.pronunciationVisible) && (
          <>
            <div>{currentSentence.pronunciation}</div>
          </>
        )}
        {!!currentSentence.transliteration && answered && (gameSettings.transliteration === 'on' || this.state.transliterationVisible) && (
          <>
            <div>{currentSentence.transliteration}</div>
          </>
        )}
        {answered && !!currentSentence.notes && <div className={"notes font-size-" + gameSettings.notesFontSize}><strong>Notes:</strong> {currentSentence.notes}</div>}
        {answered && tokensVisible && this.renderTokens()}
        {this.renderAccentButtons()}
        {this.renderCurrentSentenceControls()}
        {answered && nextReviewControlsVisible && (
          <ul className="list-inline text-center">
            <li style={{ paddingTop: 5, verticalAlign: "top" }}><span className="joystix">Next review:</span></li>
            <li style={{ verticalAlign: "top" }}><span data-toggle="tooltip" data-title="0% Mastered" style={{ display: "inline-block" }}>{this.renderManualResetBtn()}</span><br /><small>0 days</small></li>
            <li style={{ verticalAlign: "top" }}><span data-toggle="tooltip" data-title="Sooner" style={{ display: "inline-block" }}><button className="btn btn-default" disabled={currentSentence.level === 0} style={{ height: 34, fontFamily: "joystix" }}><Icon name="minus-sign" /></button></span><br /><small>{Math.round(nextReviewDays * 0.75)} days</small></li>
            <li style={{ verticalAlign: "top" }}><span data-toggle="tooltip" data-title="Later" style={{ display: "inline-block" }}><button className="btn btn-default" style={{ height: 34, fontFamily: "joystix" }}><Icon name="plus-sign" /></button></span><br /><small>{Math.round(nextReviewDays * 1.25) || 2} days</small></li>
            <li style={{ verticalAlign: "top" }}><span data-toggle="tooltip" data-title="100% Mastered" style={{ display: "inline-block" }}>{this.renderManualMasterBtn()}</span><br /><small>180 days</small></li>
            <li style={{ verticalAlign: "top" }}><span data-toggle="tooltip" data-title="Known - 100% Mastered" style={{ display: "inline-block" }}>{this.renderKnowItBtn()}</span><br /><small>Never</small></li>
            <li style={{ verticalAlign: "top" }}><span data-toggle="tooltip" data-title="Custom" style={{ display: "inline-block" }}><button className="btn btn-default" style={{ height: 34, fontFamily: "joystix" }}><Icon name="calendar" /></button></span><br /><small>Custom</small></li>
          </ul>
        )}
      </>
    );

    if(answered) {
      return content;
    }

    return (
      <FadeIn>{content}</FadeIn>
    );
  }

  renderAccentButtons() {
    if(this.isPlayingMultipleChoice()) {
      return null;
    }

    if(this.state.answered) {
      return null;
    }

    const accentMap = LANGUAGE_ACCENT_MAP[this.props.targetLanguageIso] || {};

    const btns = [];
    for(var key in accentMap) {
      accentMap[key].split('').forEach((accent) => {
        btns.push(
          <button
            className="btn btn-default btn-sm"
            key={accent}
            onClick={(e) => this.onAccentBtnClick(e, accent)}
            title={'alt+' + key}
          >
            {accent}
          </button>
        );
      });
    }

    if(this.isPlayingFullTextInput()) {
      const punctuation = this.getCurrentSentencePlainText().match(ultimatePunctuationRegex);
      // filter to get uniques
      punctuation.filter((v, i, a) => a.indexOf(v) === i).forEach((match) => {
        btns.push(
          <button
            className="btn btn-default btn-sm"
            key={"punct-" + match}
            onClick={(e) => this.onAccentBtnClick(e, match)}
          >
            {match}
          </button>
        );
      });
    }

    if(!btns.length) {
      return null;
    }

    return <div className="accent-btns">{btns}</div>;
  }

  onAccentBtnClick(e, accent) {
    if(e.shiftKey) {
      accent = accent.toUpperCase();
    }

    const t = this.state.textInputValue;
    const caretPos = this.textInput.selectionStart;

    this.setState({
      textInputValue: t.substring(0, caretPos) + accent + t.substring(caretPos)
    }, () => {
      this.textInput.focus();
      this.textInput.selectionStart = caretPos + 1;
      this.textInput.selectionEnd = caretPos + 1;
    });
  }

  showOffBy(distance) {
    const content = '<span class="off-by">Almost! Off by ' + distance + ' letter' + (distance === 1 ? '' : 's') + '.</span>';
    if($('.off-by').length) {
      $('.off-by').replaceWith(content);
    }
    else {
      $(this.textInput).popover({ content, html: true, trigger: 'manual', placement: 'top' }).popover('show');
    }
  }

  sendAnswer({ correct, timeDiff }) {
    if(!this.props.isSignedIn) {
      return false;
    }

    const { collectionClozeSentencesAnswerUrl, gameSettings, multipleChoice, usedHint } = this.state;
    const currentSentence = this.getCurrentSentence();
    const { mode, skill, scope } = this.props;

    // fire off answer
    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        id: currentSentence.id,
        correct,
        // sending full_text_input as just text_input for now
        // TODO! update stat tracking to keep track of full_text_input if we decide to keep
        mode: (this.isPlayingMultipleChoice() || multipleChoice) ? PlayCollectionModes.multipleChoice : (this.isPlayingFullTextInput() ? "text_input" : mode),
        skill,
        playing_favorites: this.isFavoritedScope(),
        time: timeDiff,
        used_hint: usedHint
      }),
      method: 'put',
      url: currentSentence.collectionClozeSentencesAnswerUrl || collectionClozeSentencesAnswerUrl,
    })
      .done((data) => {
        const leveledUp = data.languagePairing.level > this.state.languagePairingLevel;
        this.setState({
          languagePairingLevel: data.languagePairing.level,
          leveledUp: this.state.leveledUp || leveledUp,
          leveledUpModalVisible: gameSettings.leveledUpNotifications === 'on' && leveledUp
        });
      })
      .fail(() => {
        alert('Oh no! There was an error saving that answer. Sorry about that. Your progress up until now has been saved. Please refresh the page and try again, and let us know if you see this message again.');
      });
  }

  isCorrectAnswer(a) {
    if(this.isPlayingFullTextInput()) {
      return a === this.getCurrentSentencePlainText();
    }

    const answers = this.getCurrentSentenceAnswers();
    const clean = this.replaceAccents(a.toLowerCase().replace(/^\s+|\s+$/g, ''));
    return !!answers.filter((answer) => (clean === this.replaceAccents(answer.toLowerCase()))).length;
  }

  submitAnswer(a) {
    const currentSentence = this.getCurrentSentence();
    const cloze = this.getCurrentSentenceCloze();
    const { mode, skill, scope } = this.props;
    const {
      collectionClozeSentences,
      collectionClozeSentencesAnswerUrl,
      correctlyAnsweredSentenceCount,
      currentSentenceTimerStart,
      elapsedTime,
      gameSettings,
      multipleChoice,
      nextReviewByLevel,
      numCorrect,
      numIncorrect,
      numMastered,
      numNew,
      numReview,
      playedNew,
      playedReview,
      previousAnswer,
      score,
      translationVisible,
      textInputValue,
      usedHint
    } = this.state;

    const correct = this.isCorrectAnswer(a);

    let distance = 0;
    if(this.isPlayingTextInput() && !multipleChoice && !correct && a !== previousAnswer && gameSettings.spellingHints === 'on' && Levenshtein && (distance = Levenshtein.get(this.replaceAccents(a.toLowerCase()), this.replaceAccents(cloze.toLowerCase()))) <= 2) {
      this.setState({ previousAnswer: a });
      this.showOffBy(distance);
      return true;
    }
    $(this.textInput).popover('destroy');

    const updatedCollectionClozeSentences = JSON.parse(JSON.stringify(collectionClozeSentences));

    let points = 0;
    const levelWas = currentSentence.level;
    let level = currentSentence.level;
    if(correct) {
      if(this.isFavoritedScope()) {
        points = correct ? 2 : 0;
      }
      else {
        level = Math.min(currentSentence.level + 1, 4);
        points = level * ((this.isPlayingMultipleChoice() || multipleChoice) ? 4 : 8);
        if(usedHint) {
          points = points * 0.5;
        }
        if(currentSentence.nextReview && currentSentence.nextReview > moment().format("YYYY-MM-DD")) {
          points = points * 0.5;
        }
      }
    }
    else {
      if(!this.isFavoritedScope()) {
        level = 0;
      }
      currentSentence.wasIncorrect = true;
      updatedCollectionClozeSentences.push(currentSentence);
    }

    const countsAsNew = !currentSentence.nextReview;
    const countsAsReview = !countsAsNew && !playedNew[currentSentence.id] && !playedReview[currentSentence.id] && !!currentSentence.nextReview;
    const nextReview = this.isFavoritedScope() ? currentSentence.nextReview : moment().add(nextReviewByLevel[level], 'days').format("YYYY-MM-DD");

    updatedCollectionClozeSentences.forEach((ccs) => {
      if(ccs.id === currentSentence.id) {
        ccs.level = level;
        ccs.nextReview = nextReview;
      }
    });

    const timeDiff = Math.min(secondsSinceEpoch() - currentSentenceTimerStart, 30); // max 30 seconds per answer

    this.sendAnswer({ correct, timeDiff });

    if((!!currentSentence.audioRecordingUrl || this.isTtsAvailable()) && gameSettings.textToSpeech === 'on') {
      this.playSentenceAudio();
    }
    this.playAnswerSoundEffect(correct);

    $('[data-toggle="tooltip"]').tooltip('hide');

    this.afterAnswerKeyDownTriggered = false;
    this.setState({
      answered: true,
      answerSubmitted: a,
      collectionClozeSentences: updatedCollectionClozeSentences,
      correct,
      correctlyAnsweredSentenceCount: correctlyAnsweredSentenceCount + (correct ? 1 : 0),
      elapsedTime: elapsedTime + timeDiff,
      numCorrect: numCorrect + (correct ? 1 : 0),
      numIncorrect: numIncorrect + (correct ? 0 : 1),
      numMastered: numMastered + (currentSentence.level < 4 && level === 4 ? 1 : 0),
      numNew: numNew + (countsAsNew ? 1 : 0),
      numReview: numReview + (countsAsReview ? 1 : 0),
      playedNew: countsAsNew ? Object.assign({}, playedNew, { [currentSentence.id]: true }) : playedNew,
      playedReview: countsAsReview ? Object.assign({}, playedReview, { [currentSentence.id]: true }) : playedReview,
      points,
      pronunciationVisible: false,
      score: score + points,
      alreadyMastered: levelWas === level && level === 4,
      textInputValue: cloze,
      translationVisible: (gameSettings.translations === 'hidden' && translationVisible) || gameSettings.translations === 'show-after',
      transliterationVisible: false
    });
  }

  playAnswerSoundEffect(correct) {
    if(this.state.gameSettings.soundEffects !== 'on') {
      return false;
    }

    this.setupAudio();

    this[(correct ? 'correct' : 'incorrect') + 'Audio'].play();
  }

  endInfiniteRound() {
    const updatedCollectionClozeSentences = this.state.collectionClozeSentences.filter((s, i) => {
      return i <= this.state.currentSentenceIndex || s.wasIncorrect;
    });

    this.setState({
      collectionClozeSentences: updatedCollectionClozeSentences,
      endingInfiniteRound: true
    }, () => this.next());

    // this.setState({
    //   collectionClozeSentences: JSON.parse(JSON.stringify(this.state.collectionClozeSentences)).slice(0, this.state.currentSentenceIndex + 1),
    //   currentSentenceIndex: this.state.currentSentenceIndex + 1 // or call next
    // });
  }

  renderEndInfiniteRoundBtn() {
    if(!this.isInfiniteRound() || this.state.endingInfiniteRound) {
      return null;
    }

    return (
      <button
        className="btn btn-xs btn-danger joystix text-center end-round"
        data-title="Don't stop! Keep going!"
        data-toggle="tooltip"
        onClick={() => this.endInfiniteRound()}
        style={{ marginRight: 10 }}
      >
        <small>End<br />Round</small>
      </button>
    );
  }

  answerQualityOptionsVisible() {
    const currentSentence = this.getCurrentSentence();
    return !this.isFavoritedScope() &&
      !!this.state.alreadyMasteredNextReview &&
      this.state.alreadyMastered &&
      !(currentSentence.nextReview || "").match(/2100/) &&
      currentSentence.level === 4;
  }

  renderNextBtn() {
    if(!this.state.answered) {
      return null;
    }

    let control = null;
    if(this.answerQualityOptionsVisible()) {
      control = (
        <span className="srs-controls">
          <button className="btn btn-lg btn-danger" onClick={() => this.handleAnswerQualityResponse(0)}>
            <small>1. </small><span className="joystix">Hard</span>
          </button>
          <button className="btn btn-lg btn-warning" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => this.handleAnswerQualityResponse(1)}>
            <small>2. </small><span className="joystix">Normal</span>
          </button>
          <button className="btn btn-lg btn-success" onClick={() => this.handleAnswerQualityResponse(2)}>
            <small>3. </small><span className="joystix">Easy</span>
          </button>
        </span>
      );
    }
    else {
      control = (
        <button className="btn btn-lg btn-success next joystix" onClick={() => this.next()}>
          Next
          <span className="glyphicon glyphicon-chevron-right"></span>
        </button>
      );
    }

    return (
      <div className="navigation" style={{ display: 'block' }}>
        {this.renderEndInfiniteRoundBtn()}
        {control}
      </div>
    );
  }

  handleAnswerQualityResponse(quality) {
    if(this.state.alreadyMasteredNextReview === 'srs') {
      const srsResponseQuality = [1, 4, 7];
      this.sendSrsResponse(srsResponseQuality[quality]);
    }
    else if(this.state.alreadyMasteredNextReview === 'static') {
      const { nextReviewByLevel } = this.state;
      // 0.5, 1, 2
      const m = (quality === 0 ? 0.5 : quality);
      this.updateCurrentSentence('next_review', moment().add(Math.ceil(nextReviewByLevel[4] * m), 'days').format("YYYY-MM-DD"));
    }
    else {
      alert("Something's gone terribly wrong! Sorry about that. Your answer's been saved, but not your Hard/Normal/Easy selection. Please let us know.");
    }
    this.next();
  }

  sendSrsResponse(quality) {
    const currentSentence = this.getCurrentSentence();
    this.setState({ updating: true });

    $.ajax({
      url: currentSentence.collectionClozeSentencesSrsResponseUrl || this.state.collectionClozeSentencesSrsResponseUrl,
      method: 'post',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ 
        srs_response: {
          collection_cloze_sentence_id: currentSentence.id,
          quality
        }
      })
    })
      .done((data) => {
        this.setState({
          collectionClozeSentences: this.state.collectionClozeSentences.map((ccs) => (
            ccs.id === currentSentence.id ? data.collectionClozeSentence : ccs
          )),
          updating: false
        });
      })
      .fail(() => {
        this.setState({ updating: false });
        alert('Oh no! There was an error updating. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  isInfiniteRound() {
    return this.props.count === 'infinite';
  }

  runTutorial() {
    this.setState({ isRunningTutorial: true });

    const popovers = [
      {
        selector: ".level .progress",
        opts: {
          content: "<p>Every sentence has a % Mastered.</p>",
        }
      },
      {
        selector: ".level .progress",
        opts: {
          content: "<p>Answer a sentence correctly 4 times in a row to get it to 100% Mastered.</p>",
        }
      },
      {
        selector: [".level .next-review", ".level .progress"],
        opts: {
          content: "<p>Each time a sentence is answered correctly, it's queued up to be reviewed further in the future.</p>",
        }
      },
      {
        selector: ".score.total",
        opts: {
          content: "<p>A correct answer scores points.</p>",
        }
      },
      {
        selector: ".score.total",
        opts: {
          content: "<p>A sentence with a higher % Mastered scores more points, and playing text input scores more points than multiple choice.</p>",
        }
      },
      {
        selector: ".score.total",
        opts: {
          content: "<p>An incorrect answer scores 0 points and resets the sentence to 0% Mastered.</p>",
        }
      },
      {
        selector: ".btn.game-settings",
        opts: {
          content: "<p>You can change the game settings here. Some settings are different for multiple choice vs. text input.</p>"
        }
      },
      {
        selector: [".next", ".stage .sentence"],
        opts: {
          content: "<p>That's it! 🙌 More questions? Click the Help button bottom right.</p>",
        }
      },
    ];
    let popoverIndex = 0;

    const defaultOpts = {
      animation: false,
      container: "body",
      html: true,
      placement: "bottom",
      template: `
        <div class="popover tutorial" role="tooltip">
          <div class="arrow"></div>
          <h3 class="popover-title"></h3>
          <div class="popover-content"></div>
        </div>
      `,
      trigger: "manual"
    };

    const showNextPopover = () => {
      this.clearTutorialPopovers();

      if(popoverIndex >= popovers.length) {
        this.completedTutorial();
        return false;
      }

      const { opts } = popovers[popoverIndex];
      let { selector } = popovers[popoverIndex];
      const isLastPopover = popoverIndex + 1 >= popovers.length;
      opts.content = `
        <div style="font-size: 1.25em">
          ${opts.content}
        </div>
        <div style="text-align: right">
          <span class="joystix" style="margin-right: 10px">${popoverIndex + 1}/${popovers.length}</span>
          <button class="btn btn-success joystix next">${isLastPopover ? "Done" : "Next"}</button>
          ${isLastPopover ? "" : `<button class="btn btn-default joystix skip">Skip</button>`}
        </div>
      `;

      // allows for selector to be an array with a fallback selector if the first is not present
      if(selector instanceof Array) {
        if(!$(selector[0]).length) {
          selector = selector[1];
        }
        else {
          selector = selector[0];
        }
      }

      $(selector)
        .addClass("tutorial-target")
        .popover(Object.assign({}, defaultOpts, opts))
        .popover("show");

      const $popover = $(".popover");
      const selectorTop = $(selector).offset().top;
      const popoverTop = $popover.offset().top;
      const popoverBottom = popoverTop + $popover.height();
      const screenTop = $(window).scrollTop();
      const screenHeight = $(window).height();
      const screenBottom = screenTop + screenHeight;
      let scrollTop = null;
      if(popoverBottom > screenBottom) {
        scrollTop = popoverBottom - screenHeight;
      }
      if(selectorTop < screenTop) {
        scrollTop = selectorTop - 20; // - 20 for buffer
      }
      if(scrollTop) {
        $("html, body").animate({ scrollTop });
      }
    };

    $("body").on("click.tutorial", ".popover .btn.next", () => {
      popoverIndex += 1;
      showNextPopover();
    });
    $("body").on("click.tutorial", ".popover .btn.skip", () => {
      this.completedTutorial();
    });

    showNextPopover();
  }

  clearTutorialPopovers() {
    $(".tutorial-target").popover("destroy").removeClass("tutorial-target");
  }

  completedTutorial() {
    const { isSignedIn } = this.props;
    const { hasRunTutorial } = this.state;

    this.setState({
      hasRunTutorial: true,
      isRunningTutorial: false
    }, () => this.clearTutorialPopovers());

    // if it was already true can skip update
    if(hasRunTutorial) {
      return true;
    }

    if(isSignedIn) {
      $.ajax({
        contentType: "application/json",
        data: JSON.stringify({
          user: { has_run_play_tutorial: true }
        }),
        method: "put",
        url: "/api/v1/users"
      });
    }
    else {
      window.localStorage.setItem("hasRunPlayTutorial", "true");
    }
  }

  next() {
    const { shouldRunTutorial } = this.props;
    const {
      currentSentenceIndex,
      collectionClozeSentences,
      gameSettings,
      hasRunTutorial,
      hasShownTokens,
      isRunningTutorial,
      tokensVisible
    } = this.state;

    if(this.isRoundComplete()) {
      return false;
    }

    if(this.sentenceAudio) {
      this.sentenceAudio.pause();
    }

    if(this.isPlayingSpeaking()) {
      this.stopRecordingAndResetRecordBtn();
    }

    if(isRunningTutorial) {
      this.completedTutorial();
    }
    else if(shouldRunTutorial && !hasRunTutorial) {
      return this.runTutorial();
    }

    this.setState({
      alreadyMastered: false,
      answered: false,
      correct: null,
      points: null,
      currentSentenceIndex: currentSentenceIndex + 1,
      currentSentenceTimerStart: secondsSinceEpoch(),
      hasShownTokens: hasShownTokens || tokensVisible,
      listeningControlPlayComplete: false,
      multipleChoice: false,
      previousAnswer: null,
      speakingTranscript: '',
      startedTextInput: false,
      textInputChangedAfterAnswering: false,
      textInputValue: '',
      tokensVisible: false,
      translationVisible: gameSettings.translations === 'visible',
      usedHint: false
    }, () => {
      if(this.textInput) {
        this.textInput.focus();
      }
      // infinite and within N of the end, then load more
      if(this.isInfiniteRound() && this.state.currentSentenceIndex > this.state.collectionClozeSentences.length - 5) {
        this.loadMoreCollectionClozeSentences();
      }
    });
  }

  onMultipleChoiceOptionClick(answer) {
    if(this.state.answered) {
      this.next();
    }
    else {
      this.submitAnswer(answer);
    }
  }

  renderMultipleChoiceOptions() {
    if(!this.isPlayingMultipleChoice() && !this.state.multipleChoice) {
      return null;
    }

    const { answered, answerSubmitted, currentSentenceIndex, correct, wordBank } = this.state;
    const currentSentenceMultipleChoiceOptions = this.getCurrentSentence().multipleChoiceOptions;
    const options =  currentSentenceMultipleChoiceOptions.length ? currentSentenceMultipleChoiceOptions : wordBank

    return (
      <MultipleChoiceOptions
        actualAnswer={this.getCurrentSentenceCloze()}
        answered={answered}
        answerSubmitted={answerSubmitted}
        correct={correct}
        onOptionClick={(o) => this.onMultipleChoiceOptionClick(o)}
        onClickableOptionClick={(o) => this.setState({
          selectionPopoverId: "sentence",
          selectedSentenceText: o
        })}
        options={options}
        ref={(el) => this.multipleChoiceOptions = el}
        sentenceIndex={currentSentenceIndex}
        targetLanguageCode={this.props.targetLanguageCode}
      />
    );
  }

  renderListeningControl() {
    const { skill } = this.props;
    const { currentSentenceIndex, gameSettings } = this.state;

    if(!this.isPlayingListening()) {
      return null;
    }

    const currentSentence = this.getCurrentSentence();

    return (
      <ListeningControl
        key={currentSentenceIndex}
        onPlayComplete={() => this.setState({ listeningControlPlayComplete: true }, () => this.textInput && this.textInput.focus())}
        onError={() => this.setState({ listeningControlPlayComplete: true }, () => this.textInput && this.textInput.focus())}
        playAudio={this.playSentenceAudio.bind(this)}
        playCompleteOnFirstPlayEnded={gameSettings.listeningSkillAutoShowSentence === 'on'}
      />
    );
  }

  startRecording() {
    if(this.state.recording) {
      return false;
    }
    this.setState({ recording: true });
    try {
      this.recognition.start();
      this.recognitionTimeout = setTimeout(() => {
        this.stopRecordingAndResetRecordBtn();
      }, 10000); // 10s record limit
    } catch(e) {
      this.onSpeechRecognitionError(e);
    }
  }

  stopRecordingAndResetRecordBtn() {
    if(this.recognition) {
      this.recognition.stop();
    }
    this.setState({ recording: false });
    clearTimeout(this.recognitionTimeout);
  }


  renderSpeakingControl() {
    if(!this.isPlayingSpeaking()) {
      return null;
    }

    return (
      <div style={{ marginBottom: 10 }}>
        <button
          className="btn btn-default btn-lg"
          disabled={this.state.recording}
          onClick={() => this.startRecording()}
        >
          <span className="glyphicon glyphicon-record" style={{ color: '#f00' }}></span> {this.state.recording ? 'Recording...' : 'Record'}
        </button>
        <div>{this.state.speakingTranscript}</div>
      </div>
    );
  }

  renderRoundResults() {
    const {
      collectionClozeSentences,
      collectionClozeSentencesUpsertUrl,
      collectionsUrl,
      elapsedTime,
      hasShownTokens,
      isCollectionEditable,
      isPro,
      languagePairingUrl,
      leveledUp,
      nextReviewByLevel,
      numCorrect,
      numIncorrect,
      numMastered,
      numNew,
      numReview,
      reportErrorUrl,
      startingNumMastered,
      startingNumPlaying,
      startingNumReadyForReview,
      score,
      tokensUrl,
      updating
    } = this.state;
    const {
      baseLanguageEnglishName,
      baseLanguageFlagIso,
      collectionId,
      isTeachingCollection,
      languagePairingWebUrl,
      scope,
      targetLanguageCode,
      targetLanguageEnglishName,
      targetLanguageFlagIso,
      targetLanguageIso,
      targetLanguageName,
      teachingCollectionResultsUrl
    } = this.props;
    const uniqueSentences = [];
    const uniqueSentenceIds = {};
    for(let i = 0, n = collectionClozeSentences.length; i < n; i++) {
      if(!uniqueSentenceIds[collectionClozeSentences[i].id]) {
        uniqueSentences.push(collectionClozeSentences[i]);
        uniqueSentenceIds[collectionClozeSentences[i].id] = true;
      }
    }
    return (
      <SlideDown>
        <RoundResults
          answerTape={collectionClozeSentences.map((ccs) => ({ id: ccs.id, correct: !ccs.wasIncorrect }))}
          baseLanguageEnglishName={baseLanguageEnglishName}
          baseLanguageFlagIso={baseLanguageFlagIso}
          collectionClozeSentences={uniqueSentences}
          collectionClozeSentencesUpsertUrl={collectionClozeSentencesUpsertUrl}
          collectionId={collectionId}
          collectionsUrl={collectionsUrl}
          elapsedTime={elapsedTime}
          hasShownTokens={hasShownTokens}
          isCollectionEditable={isCollectionEditable}
          isPro={isPro}
          isSignedIn={this.props.isSignedIn}
          isTeachingCollection={isTeachingCollection}
          isTtsAvailable={this.isTtsAvailable.bind(this)}
          languagePairingUrl={languagePairingUrl}
          leveledUp={leveledUp}
          nextReviewByLevel={nextReviewByLevel}
          numCorrect={numCorrect}
          numIncorrect={numIncorrect}
          numMastered={numMastered}
          numNew={numNew}
          numReview={numReview}
          onNextRound={() => this.nextRound()}
          playSoundEffect={() => {
            if(this.state.gameSettings.soundEffects === 'on' && this.props.roundCompleteAudioUrl) {
              const a = new Audio(this.props.roundCompleteAudioUrl);
              a.volume = 0.25;
              a.play();
            }
          }}
          playTts={this.playSentenceAudio.bind(this)}
          reportErrorUrl={reportErrorUrl}
          scope={scope}
          score={score}
          startingNumMastered={startingNumMastered}
          startingNumPlaying={startingNumPlaying}
          startingNumReadyForReview={startingNumReadyForReview}
          targetLanguageCode={targetLanguageCode}
          targetLanguageEnglishName={targetLanguageEnglishName}
          targetLanguageFlagIso={targetLanguageFlagIso}
          targetLanguageIso={targetLanguageIso}
          targetLanguageName={targetLanguageName}
          teachingCollectionResultsUrl={teachingCollectionResultsUrl}
          tokensUrl={tokensUrl}
          updateHasShownTokens={() => this.setState({ hasShownTokens: true })}
          updateSentence={this.updateSentence.bind(this)}
          updating={updating}
        />
        {this.renderFavoriteProPromoModal()}
      </SlideDown>
    );
  }

  renderFavoriteProPromoModal() {
    if(this.state.isPro) {
      return null;
    }

    return (
      <Modal
        id="favorite-pro-promo-modal"
        show={false}
        title="Favorite"
      >
        <ModalProPromo
          can="favorite sentences and then practice just their favorites or download their favorites (to print out, for import into Anki, etc.)."
          secondary="Take control of your learning. Get fluent faster."
        />
      </Modal>
    );
  }

  onKnowItClick() {
    this.knowItModal.hide();
    const currentSentence = this.getCurrentSentence();
    this.updateSentence({
      sentence: currentSentence,
      updates: {
        level: 4,
        next_review: "2100-01-01"
      }
    });
  }

  renderKnowItModal() {
    return (
      <Modal
        id="know-it-modal"
        onShown={() => this.knowItModal.$modal.find('.btn').focus()}
        ref={(el) => this.knowItModal = el}
        show={false}
        size="small"
        title="Known"
      >
        <h4 className="text-center" style={{ marginTop: 0 }}>Feeling confident?</h4>
        <p>
          <button className="btn btn-success joystix btn-block go" onClick={() => this.onKnowItClick()}>Mark as Known</button>
        </p>
        <div className="text-center">This will set the sentence to 100% Mastered and set the next review date to the year 2100.</div>
      </Modal>
    );
  }

  onMasterClick() {
    this.masterModal.hide();
    this.updateCurrentSentence('level', 4);
  }

  renderMasterModal() {
    const { nextReviewByLevel } = this.state;
    let nextReview = moment().add(nextReviewByLevel[4], 'days').format("YYYY-MM-DD");
    if(nextReview > "2100-01-01") {
      nextReview = "the year 2100";
    }

    return (
      <Modal
        id="master-modal"
        onShown={() => this.masterModal.$modal.find('.btn').focus()}
        ref={(el) => this.masterModal = el}
        show={false}
        size="small"
        title="Master"
      >
        <h4 className="text-center" style={{ marginTop: 0 }}>Already know this sentence?</h4>
        <p>
          <button className="btn btn-success joystix btn-block go" onClick={() => this.onMasterClick()}>Set to 100% Mastered</button>
        </p>
        <div className="text-center">Next review due date will be {nextReview}.</div>
      </Modal>
    );
  }

  onResetClick() {
    this.resetModal.hide();
    this.updateCurrentSentence('level', 0);
  }

  renderResetModal() {
    const { nextReviewByLevel } = this.state;
    let nextReview = moment().add(nextReviewByLevel[0], 'days').format("YYYY-MM-DD");

    return (
      <Modal
        id="reset-modal"
        onShown={() => this.resetModal.$modal.find('.btn').focus()}
        ref={(el) => this.resetModal = el}
        show={false}
        size="small"
        title="Reset"
      >
        <h4 className="text-center" style={{ marginTop: 0 }}>Want to see this sentence again sooner?</h4>
        <p>
          <button className="btn btn-success joystix btn-block go" onClick={() => this.onResetClick()}>Reset to 0% Mastered</button>
        </p>
        <div className="text-center">Next review due date will be {nextReview}.</div>
      </Modal>
    );
  }

  onIgnoreAllClick() {
    this.ignoreModal.hide();
    this.ignoreAll(() => this.next());
  }

  onIgnoreClick() {
    this.ignoreModal.hide();
    this.updateCurrentSentence('ignored', true, () => this.next());
  }

  renderIgnoreModal() {
    const { pro } = this.state;

    return (
      <Modal
        id="ignore-modal"
        onShown={() => this.ignoreModal.$modal.find('.btn').first().focus()}
        ref={(el) => this.ignoreModal = el}
        show={false}
        size="small"
        title="Ignore"
      >
        <p>
          Ignoring a sentence removes it from your queue. You'll no longer see it and it won't count towards your progress.
          <button className="btn btn-danger btn-block joystix ignore-sentence" style={{ marginTop: 5 }} onClick={() => this.onIgnoreClick()}>Ignore</button>
        </p>
        {!!this.props.collectionId && (
          <p>
            Or you can ignore all sentences with the same missing word.
            <button className="btn btn-danger btn-block joystix ignore-all" onClick={() => this.onIgnoreAllClick()} style={{ marginTop: 5 }}>Ignore All</button>
          </p>
        )}
        {/*
        <p>
          <strong>If there's an error please report it by clicking the flag button bottom right.</strong>
        </p>
        */}
        {!pro && (
          <small>
            <strong><a href="/pro">Clozemaster Pro</a> subscribers can unignore sentences as well as search all sentences!</strong>
          </small>
        )}
      </Modal>
    );
  }

  renderGameSettingsControl() {
    return (
      <button className="btn btn-default game-settings" data-toggle="modal" data-target="#game-settings-modal">
        <span className="glyphicon glyphicon-cog"></span>
      </button>
    );
  }

  renderGameSettingsModal() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        id="game-settings-modal"
        show={false}
        title="Game Settings"
      >
        <GameSettings
          audioRecordingsAvailable={this.audioRecordingsAvailable()}
          clozemasterTtsAvailable={!!this.getCurrentSentence().ttsAudioUrl}
          initialSettings={this.state.gameSettings}
          isPlayingListening={this.isPlayingListening()}
          isPlayingMultipleChoice={this.isPlayingMultipleChoice()}
          isSignedIn={this.props.isSignedIn}
          onUpdate={(gameSettings) => {
            let translationVisible = this.state.translationVisible;

            if(gameSettings.translations === 'show-after' && !this.state.answered || gameSettings.translations === 'hidden') {
              translationVisible = false;
            }

            this.setState({
              gameSettings,
              translationVisible
            });
          }}
          ref={(el) => this.gameSettings = el}
          systemTtsAvailable={this.isSystemTtsAvailable()}
          systemTtsVoices={this.getSystemTtsVoices()}
          targetLanguageName={this.props.targetLanguageName}
          transliterationsAvailable={this.transliterationsAvailable()}
          url={this.state.gameSettingsUrl}
        />
      </Modal>
    );
  }

  transliterationsAvailable() {
    const { collectionClozeSentences } = this.state;
    return !!collectionClozeSentences.find((ccs) => !!ccs.transliteration);
  }

  audioRecordingsAvailable() {
    const { collectionClozeSentences } = this.state;
    return !!collectionClozeSentences.find((ccs) => !!ccs.audioRecordingUrl);
  }

  renderTextInputSubmitBtn() {
    if(this.isPlayingTextInput() && !this.state.multipleChoice && !this.state.answered) {
      const { textInputValue } = this.state;
      return (
        <button className="btn btn-success joystix btn-lg" onClick={() => this.submitAnswer(textInputValue)} disabled={!textInputValue}>Submit</button>
      );
    }
  }

  renderSoundEffects() {
    const {
      correctAudioUrl,
      incorrectAudioUrl,
    } = this.props;

    return (
      <div className="audio">
        <audio ref={(el) => this.correctAudio = el} src={correctAudioUrl} />
        <audio ref={(el) => this.incorrectAudio = el} src={incorrectAudioUrl} />
      </div>
    );
  }

  getUpdatedCollectionClozeSentences(id, updates) {
    const newCollectionClozeSentences = JSON.parse(JSON.stringify(this.state.collectionClozeSentences));
    for(let i = 0, n = newCollectionClozeSentences.length; i < n; i++) {
      if(newCollectionClozeSentences[i].id === id) {
        newCollectionClozeSentences[i] = Object.assign(newCollectionClozeSentences[i], updates);
      }
    }
    return newCollectionClozeSentences;
  }

  onSentenceEditorUpdate(sentence) {
    this.sentenceEditorModal && this.sentenceEditorModal.hide();
    const currentSentence = this.getCurrentSentence();
    const newCollectionClozeSentences = this.getUpdatedCollectionClozeSentences(currentSentence.id, sentence);
    this.setState({ collectionClozeSentences: newCollectionClozeSentences, textInputChangedAfterAnswering: sentence.text !== currentSentence.text });
  }

  addCurrentSentenceToCollection() {
    const currentSentence = this.getCurrentSentence();
    this.setState({
      addSentenceToCollectionModalVisible: true
    });
  }

  renderAddSentenceToCollectionModal() {
    if(!this.state.addSentenceToCollectionModalVisible) {
      return null;
    }

    const { isPro, nextReviewByLevel, selectedSentenceClozeStr, selectedSentenceText } = this.state;
    const sentence = Object.assign({}, this.getCurrentSentence());
    delete sentence.id;
    let copyable = true;

    if(selectedSentenceClozeStr && selectedSentenceClozeStr.match(/.*\{\{.+\}\}.*/)) { // if present and contains cloze
      sentence.text = selectedSentenceClozeStr;
      copyable = false;
    }
    else if(selectedSentenceText) {
      sentence.text = sentence.text.replace(/{{|}}/g, '');
      sentence.text = sentence.text.replace(selectedSentenceText, '{{' + selectedSentenceText + '}}');
      copyable = false;
    }

    return (
      <AddSentenceToCollectionModal
        collectionClozeSentence={sentence}
        collectionsUrl={this.state.collectionsUrl}
        copyable={copyable}
        isPro={isPro}
        nextReviewByLevel={nextReviewByLevel}
        onHidden={() => this.setState({
          addSentenceToCollectionModalVisible: false,
          selectedSentenceClozeStr: null, // clear cloze str
          selectedSentenceText: null // clear selected text
        })}
        onSentenceAdded={(sentence) => this.addSentenceToCollectionModal && this.addSentenceToCollectionModal.hide()}
        ref={(el) => this.addSentenceToCollectionModal = el}
      />
    );
  }

  renderSentenceEditorModal() {
    if(!this.state.sentenceEditorModalVisible) {
      return null;
    }

    const currentSentence = this.getCurrentSentence();

    return (
      <CollectionClozeSentenceEditorModal
        alreadyMasteredNextReview={this.state.alreadyMasteredNextReview}
        answerQualityOptionsVisible={this.answerQualityOptionsVisible()}
        isPro={this.state.isPro}
        isTextEditable={this.state.isCollectionEditable}
        nextReviewByLevel={this.state.nextReviewByLevel}
        onHidden={() => this.setState({ sentenceEditorModalVisible: false })}
        onUpdate={(sentence) => this.onSentenceEditorUpdate(sentence)}
        ref={(el) => this.sentenceEditorModal = el}
        sentence={currentSentence}
        upsertUrl={currentSentence.collectionClozeSentencesUpsertUrl || this.state.collectionClozeSentencesUpsertUrl}
      />
    );
  }

  renderLeveledUpModal() {
    if(!this.state.leveledUpModalVisible) {
      return null;
    }

    const {
      baseLanguageEnglishName,
      baseLanguageFlagIso,
      leveledUpImageUrl,
      leveledUpShareUrl,
      targetLanguageEnglishName,
      targetLanguageFlagIso
    } = this.props;

    return (
      <LeveledUpModal
        baseLanguageEnglishName={baseLanguageEnglishName}
        baseLanguageFlagIso={baseLanguageFlagIso}
        imagePath={leveledUpImageUrl}
        level={this.state.languagePairingLevel}
        onHidden={() => this.setState({ leveledUpModalVisible: false })}
        shareUrl={leveledUpShareUrl}
        targetLanguageEnglishName={targetLanguageEnglishName}
        targetLanguageFlagIso={targetLanguageFlagIso}
      />
    );
  }

  renderManageCollectionModal() {
    if(!this.state.manageCollectionModalVisible) {
      return null;
    }

    const {
      baseLanguageEnglishName,
      targetLanguageCode,
      targetLanguageEnglishName,
      targetLanguageIso,
      targetLanguageName
    } = this.props;

    return (
      <ManageCollectionModal
        baseLanguageEnglishName={baseLanguageEnglishName}
        collectionClozeSentencesUrl={this.state.manageCollectionCollectionClozeSentencesUrl || this.state.collectionClozeSentencesUrl}
        collectionsUrl={this.state.collectionsUrl}
        isPro={this.state.isPro}
        onHidden={() => this.setState({
          manageCollectionCollectionClozeSentencesUrl: null,
          manageCollectionModalVisible: false,
          manageCollectionQuery: ""
        })}
        onUpdate={(updatedCollectionClozeSentences) => {
          const { collectionClozeSentences } = this.state;
          const updatedHash = updatedCollectionClozeSentences.reduce((h, ccs) => {
            h[ccs.id] = ccs;
            return h;
          }, {});
          const newCollectionClozeSentences = [];
          for(let i = 0, n = collectionClozeSentences.length; i < n; i++) {
            newCollectionClozeSentences.push(
              Object.assign({}, collectionClozeSentences[i], updatedHash[collectionClozeSentences[i].id] || {})
            );
          }
          this.setState({ collectionClozeSentences: newCollectionClozeSentences });
        }}
        query={this.state.selectedSentenceText || this.state.manageCollectionQuery}
        targetLanguageCode={targetLanguageCode}
        targetLanguageEnglishName={targetLanguageEnglishName}
        targetLanguageIso={targetLanguageIso}
        targetLanguageName={targetLanguageName}
      />
    );
  }

  isListening() {
    const { skill } = this.props;
    const { listeningControlPlayComplete } = this.state;
    return this.isPlayingListening() && !listeningControlPlayComplete;
  }

  renderGameControls() {
    if(this.isListening()) {
      return null;
    }

    return (
      <FadeIn>
        {this.renderTextInputSubmitBtn()}
        {this.renderNextBtn()}
        {this.renderMultipleChoiceOptions()}
      </FadeIn>
    );
  }

  renderClozeSentenceSearchModal() {
    if(!this.state.clozeSentenceSearchModalVisible) {
      return null;
    }

    return (
      <ClozeSentenceSearchModal 
        collectionsUrl={this.state.collectionsUrl}
        isPro={this.state.isPro}
        nextReviewByLevel={this.state.nextReviewByLevel}
        onHidden={() => {
          this.setState({ clozeSentenceSearchModalVisible: false });
        }}
        query={this.state.selectedSentenceText}
        url={this.state.clozeSentencesUrl}
      />
    );
  }

  renderDiscussionModal() {
    const { discussionModalVisible } = this.state;

    if(!discussionModalVisible) {
      return null;
    }

    const { baseLanguageEnglishName, targetLanguageEnglishName, targetLanguageName } = this.props;
    const currentSentence = this.getCurrentSentence();
    const { translation } = currentSentence;

    return (
      <DiscussionModal
        {...this.getCurrentSentenceParts()}
        baseLanguageEnglishName={baseLanguageEnglishName}
        isPro={this.state.isPro}
        onHidden={() => this.setState({ discussionModalVisible: false })}
        sentencePlainText={this.getCurrentSentencePlainText()}
        translation={translation}
        targetLanguageEnglishName={targetLanguageEnglishName}
        targetLanguageName={targetLanguageName}
      />
    );
  }

  renderRoundHistoryModal() {
    const { collectionClozeSentences, currentSentenceIndex, isPro, roundHistoryModalVisible } = this.state;

    if(!roundHistoryModalVisible) {
      return null;
    }

    return (
      <RoundHistoryModal
        collectionClozeSentences={collectionClozeSentences.slice(0, currentSentenceIndex)}
        isPro={isPro}
        onEditClick={(collectionClozeSentence) => {
          this.setState({
            manageCollectionCollectionClozeSentencesUrl: collectionClozeSentence.collectionClozeSentencesUrl,
            manageCollectionModalVisible: true,
            manageCollectionQuery: this.getSentencePlainText(collectionClozeSentence.text)
          });
        }}
        onHidden={() => this.setState({ roundHistoryModalVisible: false })}
      />
    );
  }

  renderHelpModal() {
    return (
      <HelpModal
        accentMap={LANGUAGE_ACCENT_MAP[this.props.targetLanguageIso]}
        isPlayingTextInput={this.isPlayingTextInput()}
        isUsingSrsControls={!!this.state.alreadyMasteredNextReview}
        runTutorial={this.runTutorial.bind(this)}
      />
    );
  }

  renderModals() {
    return (
      <>
        {this.renderAddSentenceToCollectionModal()}
        {this.renderClozeSentenceSearchModal()}
        {this.renderFavoriteProPromoModal()}
        {this.renderGameSettingsModal()}
        {this.renderIgnoreModal()}
        {this.renderLeveledUpModal()}
        {this.renderManageCollectionModal()}
        {this.renderKnowItModal()}
        {this.renderMasterModal()}
        {this.renderResetModal()}
        {this.renderSentenceEditorModal()}
        {this.renderDiscussionModal()}
        {this.renderRoundHistoryModal()}
        {this.renderHelpModal()}
      </>
    );
  }

  isRoundComplete() {
    const { currentSentenceIndex, collectionClozeSentences, loading } = this.state;
    return !loading && currentSentenceIndex >= collectionClozeSentences.length;
  }

  renderSearchSentencesBtn() {
    if(!this.state.isSignedIn) {
      return null;
    }
  }

  renderManageCollectionBtn() {
    if(!this.state.isSignedIn || !this.props.collectionId) {
      return null;
    }
  }

  renderRoundHistoryBtn() {
    return (
      <button
        className="btn btn-default round-history"
        onClick={() => this.setState({ roundHistoryModalVisible: true })}
        style={{ marginRight: 10 }}
        title="Round history (alt+y)"
      >
        <Icon name="history" type="fa" />
      </button>
    );
  }

  renderReportErrorBtn() {
    if(!this.state.isSignedIn) {
      return null;
    }

    const collectionClozeSentence = this.getCurrentSentence();
    return (
      <ReportErrorBtn
        className="btn btn-default"
        collectionClozeSentence={collectionClozeSentence}
        url={collectionClozeSentence.collectionClozeSentencesErrorUrl || this.state.reportErrorUrl}
      />
    );
  }

  renderHelpBtn() {
    return (
      <button className="btn btn-default" data-target="#help-modal" data-toggle="modal" style={{ marginRight: 10 }}>
        <i className="fa fa-info-circle" aria-hidden="true"></i> Help!
      </button>
    );
  }

  isNoSentences() {
    const { currentSentenceIndex, collectionClozeSentences, loading } = this.state;
    return !loading && currentSentenceIndex === 0 && !collectionClozeSentences.length;
  }

  renderNoSentences() {
    return (
      <div className="text-center">
        <h2>No more sentences to play!</h2>
        <p><img src="https://cdn.clozemaster.com/images/sad-pikachu.gif" /></p>
        <p>Check back later as sentences become ready for review.</p>
        <a href="/dashboard" className="btn btn-default joystix btn-lg">
          <Icon name="chevron-left" /> Dashboard
        </a>
      </div>
    );
  }

  renderCurrentSentenceSourceLink() {
    const currentSentence = this.getCurrentSentence();
    const { answered } = this.state;

    if(answered && currentSentence.tatoebaId) {
      return (
        <div style={{ marginTop: 10 }}>
          <a className="btn btn-xs btn-default" href={`https://tatoeba.org/eng/sentences/show/${currentSentence.tatoebaId}`} target="_blank" rel="noopener">
            Sentence Source <Icon name="new-window" />
          </a>
        </div>
      );
    }
  }

  renderExplanationRequest() {
    const currentSentence = this.getCurrentSentence();
    const { collectionId } = this.props;
    const { answered, languagePairingId } = this.state;

    if(!answered) {
      return null;
    }

    return (
      <ExplanationRequest
        collectionId={collectionId}
        collectionClozeSentenceId={currentSentence.id}
        languagePairingId={languagePairingId}
      />
    );
  }

  renderCopyCurrentSentenceToCollectionPopover() {
    if(!this.state.copyPopoverVisible) {
      return null;
    }

    const currentSentence = this.getCurrentSentence();
    const { collectionsUrl } = this.state;

    return (
      <CopySentencePopover
        collectionClozeSentence={currentSentence}
        collectionsUrl={collectionsUrl}
        onClose={() => this.setState({ copyPopoverVisible: false })}
        onCopy={() => null}
      />
    );
  }

  renderStage() {
    if(this.isNoSentences()) {
      return this.renderNoSentences();
    }

    if(this.isRoundComplete()) {
      return this.renderRoundResults();
    }

    return (
      <div className="stage">
        <div className="clozeable" style={{ display: 'block' }}>
          {this.renderHeader()}
          {this.renderListeningControl()}
          {this.renderSpeakingControl()}
          {this.renderCurrentSentence()}
          {this.renderGameControls()}
          <div className="text-right" style={{ marginTop: 10 }}>
            {this.renderHelpBtn()}
            {this.renderRoundHistoryBtn()}
            {this.renderSearchSentencesBtn()}
            {this.renderManageCollectionBtn()}
            {this.renderGameSettingsControl()}
            <span style={{ marginLeft: 10 }}>{this.renderReportErrorBtn()}</span>
            {this.renderCurrentSentenceSourceLink()}
          </div>
          {this.props.explanationRequestsEnabled && this.renderExplanationRequest()}
          {this.renderModals()}

          {/*
          {this.renderReplay()}
          {this.renderReportControl()}
          */}

          {this.props.test && this.isPlayingTextInput() && <span id="text-input-answer">{this.getCurrentSentenceCloze()}</span>}
          {this.props.test && this.isPlayingFullTextInput() && <span id="text-input-answer">{this.getCurrentSentencePlainText()}</span>}
        </div>
      </div>
    );
  }

  onStageFadeInComplete() {
    this.textInput && setTimeout(() => this.textInput.focus(), 0);
  }

  render() {
    const { loading, intraRoundAd, intraRoundAdVisible } = this.state;

    if(loading) {
      return <Loading />;
    }

    if(intraRoundAdVisible) {
      return (
        <IntraRoundAd
          ad={intraRoundAd}
          nextRound={() => this.nextRound()}
          onLinkClick={() => this.resetRoundsPlayedCount()}
          onNextRoundable={() => this.setState({ intraRoundAdNextRoundable: true })}
        />
      );
    }

    return (
      <div>
        <SlideDown>{this.renderRoundStats()}</SlideDown>
        <div className="container">
          <FadeIn complete={() => this.onStageFadeInComplete()}>{this.renderStage()}</FadeIn>
        </div>
        {/*
        <SpeechToText
          languageLocale="es-ES"
          speechTokenUrl="/api/v1/speech_tokens"
        />
        */}
        {this.renderSoundEffects()}
      </div>
    );
  }
}
