import React from "react";

export default class Contributors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  onChange(enabled) {
    this.setState({ loading: true });

    const { collection } = this.props;

    this.setState({ loading: true });

    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        collection: { contributors_enabled: enabled }
      }),
      method: 'patch',
      url: collection.url,
    })
      .fail(() => {
        alert("Error updating contributors status! Sorry about that. Please let someone know and refresh the page to try again.");
      });

    this.props.onChange(enabled);
  }

  render() {
    const { collection, enabled } = this.props;

    return (
      <div>
        <div className="checkbox">
          <label>
            <input checked={enabled} name="contributors_enabled" onChange={(e) => this.onChange(e.target.checked)} type="checkbox" />
            Allow others to add/edit sentences in this collection via the link below. No sign up/in required.
          </label>
        </div>
        <div className="form-group">
          <input className="form-control" readOnly={true} value={collection.sentencesUrl} />
        </div>
      </div>
    );
  }
}
