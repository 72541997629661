import React from "react";

export default class Radio extends React.Component {
  render() {
    const { checked, disabled, id, label, name, onChange, value } = this.props;

    return (
      <div className="radio">
        <label>
          <input
            checked={!!checked}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            type="radio"
            value={value}
          />
          {label}
        </label>
      </div>
    );
  }
}
