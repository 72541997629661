import React from "react";

import moment from 'moment';

import Checkbox from "./Checkbox";
import CollectionClozeSentence from "./ManageCollectionModal/CollectionClozeSentence";
import Icon from "./Icon";
import MasteredIcon from "./MasteredIcon";
import Modal from "./Modal";
import ModalFooterCloseBtn from "./ModalFooterCloseBtn";
import ModalProPromo from "./ModalProPromo";
import Pagination from "./Pagination";
import PlayingIcon from "./PlayingIcon";

export default class ManageCollectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      context: props.context || 'sentences',
      collectionClozeSentences: null,
      hasCopiedSentences: false,
      hasDeletedSentences: false,
      loading: false,
      page: 1,
      perPage: 20,
      query: props.query || '',
      scope: props.scope || 'all',
      selectedCollectionClozeSentencesMap: new Map(),
      total: 0,
      updating: false
    };
  }

  componentDidMount() {
    if(this.props.isPro) {
      this.loadCollectionClozeSentences();
    }
  }
  
  onSubmit(e) {
    e && e.preventDefault();
    this.setState({ page: 1 }, () => this.loadCollectionClozeSentences());
  }

  loadCollectionClozeSentences() {
    this.setState({ loading: true });

    const { collectionClozeSentencesUrl } = this.props;
    const { scope, context, query, page, perPage } = this.state;

    $.ajax({
      url: collectionClozeSentencesUrl,
      data: {
        context,
        page,
        perPage,
        query,
        scope
      }
    })
      .done((data) => {
        $(".modal").scrollTop(0); // back to the top
        console.log(data);
        this.setState({
          collectionClozeSentences: data.collectionClozeSentences,
          collectionName: data.collection.name,
          deleteUrl: data.collectionClozeSentencesDeleteUrl,
          errorUrl: data.collectionClozeSentencesErrorUrl,
          isCollectionEditable: data.collection.isEditable,
          loading: false,
          selectedCollectionClozeSentencesMap: new Map(),
          total: data.total,
          upsertUrl: data.collectionClozeSentencesUpsertUrl
        });
      })
      .fail(() => {
        this.setState({ loading: false });
        alert('Oh no! There was an error retrieving sentences. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  renderForm() {
    const { scope, context, query, loading } = this.state;
    const { updating } = this.props;
    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        <div className="form-group">
          <input disabled={loading || updating} autoFocus={true} placeholder="Enter query..." name="query" type="text" className="form-control" value={query} onChange={(e) => this.setState({ query: e.target.value })} />
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <div className="form-group">
              <label htmlFor="scope">Search</label>
              <select disabled={loading || updating} name="scope" className="form-control" value={scope} onChange={(e) => this.setState({ scope: e.target.value })}>
                <option value="all">All</option>
                <option value="new">New</option>
                <option value="playing">Playing</option>
                <option value="0pct_mastered">0% Mastered</option>
                <option value="25pct_mastered">25% Mastered</option>
                <option value="50pct_mastered">50% Mastered</option>
                <option value="75pct_mastered">75% Mastered</option>
                <option value="mastered">100% Mastered</option>
                <option value="known">Marked as Known</option>
                <option value="ready_for_review">Ready for review</option>
                <option value="favorited">Favorited</option>
                <option value="ignored">Ignored</option>
                <option value="difficult">Difficult (incorrect >1x)</option>
              </select>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="form-group">
              <label htmlFor="context">Context</label>
              <select disabled={loading || updating} name="context" className="form-control" value={context} onChange={(e) => this.setState({ context: e.target.value })}>
                <option value="sentence">Sentences</option>
                <option value="translation">Translations</option>
                <option value="cloze">Clozes</option>
              </select>
            </div>
          </div>
        </div>
        <button disabled={loading || updating} className="btn btn-success btn-sm btn-block joystix">Search</button>
      </form>
    );
  }

  renderPageControls() {
    const { collectionClozeSentences, selectedCollectionClozeSentencesMap, updating } = this.state;

    let allPlaying = true,
      allFavorited = true,
      allKnown = true,
      allLevelEq0 = true,
      allMastered = true,
      allIgnored = true;

    collectionClozeSentences.forEach((ccs) => {
      if(!selectedCollectionClozeSentencesMap.get(ccs.id)) return false;
      if(!ccs.nextReview) allPlaying = false;
      if(ccs.nextReview !== "2100-01-01" || ccs.level !== 4) allKnown = false;
      if(!ccs.favorited) allFavorited = false;
      if(ccs.level) allLevelEq0 = false;
      if(!ccs.level || ccs.level < 4) allMastered = false;
      if(!ccs.ignored) allIgnored = false;
    });

    const selectedCollectionClozeSentences = collectionClozeSentences.filter((ccs) => selectedCollectionClozeSentencesMap.get(ccs.id));
    const hasSelectedCollectionClozeSentences = !!selectedCollectionClozeSentences.length;

    return (
      <div style={{ borderBottom: '1px solid #efefef', marginTop: 20, marginBottom: 10, paddingBottom: 10, paddingLeft: 30, position: "relative" }}>
        <div style={{ bottom: 5, left: 0, position: "absolute" }}>
          <Checkbox
            checked={selectedCollectionClozeSentences.length === collectionClozeSentences.length}
            name="all_selected"
            onChange={(e) => this.setState({ selectedCollectionClozeSentencesMap: e.target.checked ? new Map(collectionClozeSentences.map((ccs) => [ccs.id, true])) : new Map() })}
            style={{ margin: 0 }}
          />
        </div>
        <small>Apply to all selected:</small>
        <div className="btn-group btn-group-xs" role="group" aria-label="Sentence options">
          <button disabled={updating || !hasSelectedCollectionClozeSentences || allPlaying} className="btn btn-default" onClick={() => this.update(selectedCollectionClozeSentences, { next_review: moment().format("YYYY-MM-DD") })}><Icon name="plus" /> to Reviews</button>
          <button disabled={updating || !hasSelectedCollectionClozeSentences || !allPlaying} className="btn btn-default" onClick={() => this.update(selectedCollectionClozeSentences, { level: 0, next_review: null })}><Icon name="minus" /> from Reviews</button>
          <button disabled={updating || !hasSelectedCollectionClozeSentences} className="btn btn-default" onClick={() => this.update(selectedCollectionClozeSentences, { favorited: allFavorited ? false : true })}><Icon name="star" /> Favorite</button>
          <button disabled={updating || !hasSelectedCollectionClozeSentences || allKnown} className="btn btn-default" onClick={() => this.update(selectedCollectionClozeSentences, { level: 4, next_review: "2100-01-01" })}>🧠 Known</button>
          <button disabled={updating || !hasSelectedCollectionClozeSentences || allMastered} className="btn btn-default" onClick={() => this.update(selectedCollectionClozeSentences, { level: 4 })}><Icon name="ok" /> Master</button>
          <button disabled={updating || !hasSelectedCollectionClozeSentences || allLevelEq0} className="btn btn-default" onClick={() => this.update(selectedCollectionClozeSentences, { level: 0 })}><Icon name="remove" /> Reset</button>
          <button disabled={updating || !hasSelectedCollectionClozeSentences} className="btn btn-default" onClick={() => this.update(selectedCollectionClozeSentences, { ignored: allIgnored ? false : true })}><Icon name="ban-circle" /> Ignore</button>
        </div>
      </div>
    );
  }

  renderPaging() {
    const { page, perPage, total, updating } = this.state;
    return (
      <Pagination
        onNextClick={() => this.setState({ page: this.state.page + 1 }, () => this.loadCollectionClozeSentences())}
        onPrevClick={() => this.setState({ page: this.state.page - 1 }, () => this.loadCollectionClozeSentences())}
        page={page}
        perPage={perPage}
        total={total}
        updating={updating}
      />
    );
  }

  // changing level needs to change next review
  // should be handled server side
  // upsert should respond with redirect/get updated sentences
  // how many ids can we fit in get request?
  // should client simply re-request index with given params?
  // should upsert simply respond with updated sentences?
  // on delete - re-load index + scroll to location if possible (prevent weird paging behavior)
  update(collectionClozeSentences, update, callback) {
    const { upsertUrl } = this.state;

    this.setState({ updating: true });

    $.ajax({
      url: upsertUrl,
      method: 'post',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({
        updates: collectionClozeSentences.map((ccs) => {
          return Object.assign({ id: ccs.id }, update);
        })
      })
    })
      .then((data) => {
        return $.ajax({
          data: { ids: data.ids },
          method: 'post',
          url: data.collectionClozeSentencesBatchUrl
        });
      })
      .done((data) => {
        const updatedCollectionClozeSentencesHash = data.collectionClozeSentences.reduce((hash, ccs) => {
          hash[ccs.id] = ccs;
          return hash;
        }, {});

        const collectionClozeSentences = this.state.collectionClozeSentences.map((ccs) => {
          if(updatedCollectionClozeSentencesHash[ccs.id]) {
            return updatedCollectionClozeSentencesHash[ccs.id];
          }
          return ccs;
        });

        this.props.onUpdate && this.props.onUpdate(collectionClozeSentences);

        this.setState({
          collectionClozeSentences,
          updating: false,
        }, callback && callback(true));

        // // TODO! perform batch request update
        // const newCollectionClozeSentences = this.state.collectionClozeSentences.slice(0);
        // const updateIdsHash = {};
        // collectionClozeSentences.forEach((ccs) => updateIdsHash[ccs.id] = true);
        // newCollectionClozeSentences.forEach((ccs) => {
        //   if(updateIdsHash[ccs.id]) {
        //     ccs[attr] = value;
        //   }
        // });
        // this.props.onUpdate && this.props.onUpdate(data, newCollectionClozeSentences);
        // this.setState({
        //   updating: false,
        //   collectionClozeSentences: newCollectionClozeSentences
        // }, callback && callback(true));
      })
      .fail(() => {
        this.setState({ updating: false }, callback && callback(false));
        alert('Oh no! There was an error updating the sentences. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  destroy(collectionClozeSentences, callback) {
    // delete then reload
    const { collectionClozeSentencesUrl } = this.props;
    const { deleteUrl, context, query, page, perPage, scope, selectedCollectionClozeSentencesMap } = this.state;
    const id = collectionClozeSentences[0].id;
    this.setState({ updating: true });
    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        collection_cloze_sentence_id: id // TODO! eventually send array
      }),
      method: 'post',
      url: deleteUrl
    })
      .then(() => {
        return $.ajax({
          url: collectionClozeSentencesUrl,
          data: {
            context,
            page,
            perPage,
            query,
            scope
          }
        })
      })
      .done((data) => {
        selectedCollectionClozeSentencesMap.delete(id);
        this.setState({
          collectionClozeSentences: data.collectionClozeSentences,
          hasDeletedSentences: true,
          selectedCollectionClozeSentencesMap: new Map(selectedCollectionClozeSentencesMap),
          total: data.total,
          updating: false
        });
      })
      .fail(() => {
        this.setState({ updating: false });
        alert('Oh no! There was an error deleting sentences. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  renderCollectionClozeSentences() {
    const {
      baseLanguageEnglishName,
      collectionsUrl,
      targetLanguageCode,
      targetLanguageEnglishName,
      targetLanguageIso,
      targetLanguageName
    } = this.props;
    const isCollectionEditable = this.props.isCollectionEditable || this.state.isCollectionEditable;
    const { collectionClozeSentences, errorUrl, loading, selectedCollectionClozeSentencesMap } = this.state;

    if(loading) {
      return <p className="text-center" style={{ marginTop: 20 }}>Loading...</p>;
    }
    if(!collectionClozeSentences) {
      return null;
    }
    if(!collectionClozeSentences.length) {
      return <p className="text-center" style={{ marginTop: 20 }}>No results found!</p>;
    }

    return (
      <div>
        {this.renderPageControls()}
        {collectionClozeSentences.map((ccs) => (
          <CollectionClozeSentence
            baseLanguageEnglishName={baseLanguageEnglishName}
            collectionClozeSentence={ccs}
            collectionClozeSentencesErrorUrl={errorUrl}
            collectionsUrl={collectionsUrl}
            destroy={() => {
              if(confirm('Are you sure you want to delete this sentence? This action cannot be undone.')) {
                this.destroy([ccs]);
              }
            }}
            isDeleteable={isCollectionEditable}
            isReportable={!isCollectionEditable}
            isTextEditable={isCollectionEditable}
            key={ccs.id}
            onCopy={() => this.setState({ hasCopiedSentences: true })}
            onSelectedChange={(selected) => {
              console.log("SELECTED", selected);
              selectedCollectionClozeSentencesMap.set(ccs.id, selected);
              this.setState({ selectedCollectionClozeSentencesMap: new Map(selectedCollectionClozeSentencesMap) });
            }}
            onTokenClick={({ cloze, text }) => {
              this.setState({
                query: text
              }, () => $(".modal").animate({ scrollTop: 0 })); // back to the top
            }}
            selected={!!selectedCollectionClozeSentencesMap.get(ccs.id)}
            targetLanguageCode={targetLanguageCode}
            targetLanguageEnglishName={targetLanguageEnglishName}
            targetLanguageIso={targetLanguageIso}
            targetLanguageName={targetLanguageName}
            update={(update, callback) => this.update([ccs], update, callback)}
            updating={this.state.updating}
          />
        ))}
        {this.renderPaging()}
      </div>
    );
  }

  deleteCollectionProgress() {
    this.setState({ updating: true });

    $.ajax({
      method: 'delete',
      url: this.props.deleteCollectionProgressUrl
    })
      .done(() => {
        this.modal.hide();
      })
      .fail(() => {
        this.setState({ updating: false });
        alert('Oh no! There was an error deleting the collection\'s progress. Sorry about that. Please try again and let us know if you see this error message again.');
      });
  }

  onResetProgressClick() {
    if(confirm('Are you sure? THIS WILL DELETE ALL YOUR PROGRESS FOR THIS COLLECTION. THIS CANNOT BE UNDONE.')) {
      if(confirm('Checking one more time - ARE YOU POSITIVE YOU WANT TO RESET YOUR PROGRESS FOR THIS COLLECTION? There\'s no going back.')) {
        this.deleteCollectionProgress();
      }
    }
  }

  renderModalFooterDeleteCollectionProgress() {
    if(!this.props.deleteCollectionProgressUrl) {
      return null;
    }

    if(this.props.isSignedIn === false) {
      return null;
    }

    const { loading, updating } = this.state;

    return (
      <span className="pull-left text-left">
        <button disabled={updating || loading} className="btn btn-danger btn-xs reset-progress" onClick={() => this.onResetProgressClick()}>
          <span className="glyphicon glyphicon-erase"></span> Reset Progress
        </button>
        <small style={{ display: 'block' }}><strong>Warning!</strong> This will delete <u>all</u> your progress for this collection.</small>
      </span>
    );
  }

  renderModalFooter() {
    return (
      <>
        {this.renderModalFooterDeleteCollectionProgress()}
        <ModalFooterCloseBtn />
      </>
    );
  }

  renderProPromo() {
    return (
      <ModalProPromo
        can="manage collections - search sentences within a collection, edit translations, master, ignore, and favorite sentences en masse, and more."
        secondary="Take control of your learning. Get fluent faster."
      />
    );
  }

  renderContent() {
    if(!this.props.isPro) {
      return this.renderProPromo();
    }

    return (
      <>
        {this.renderForm()}
        {this.renderCollectionClozeSentences()}
      </>
    );
  }

  render() {
    const { onHidden } = this.props;
    const { hasCopiedSentences, hasDeletedSentences } = this.state;

    return (
      <Modal
        backdrop="static"
        footer={this.renderModalFooter()}
        id="manage-collection-modal"
        onHidden={() => onHidden({ hasCopiedSentences, hasDeletedSentences })}
        ref={(el) => this.modal = el}
        size={this.state.isPro ? 'large' : ''}
        title={this.props.collectionName || this.state.collectionName || "Manage Collection"}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
