import React from "react";

import Checkbox from "./Checkbox";
import CollectionSelector from "./CollectionSelector";

export default class CopySentencePopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collection: null,
      copied: false,
      errors: null,
      includeProgress: false,
      loading: false
    };
  }

  renderControls() {
    const { collection, includeProgress } = this.state;

    if(!collection) {
      return null;
    }

    return (
      <>
        <Checkbox
          checked={includeProgress}
          label="Include progress"
          name="include_progress"
          onChange={(e) => this.setState({ includeProgress: e.target.checked })}
        />
        <button autoFocus className="btn btn-block btn-success joystix" onClick={() => this.onSubmit()}>Copy</button>
      </>
    );
  }

  onSubmit() {
    const { collection, includeProgress } = this.state;
    const { collectionClozeSentence, onClose, onCopy } = this.props;

    this.setState({
      loading: true
    });

    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        include_progress: includeProgress,
        new_collection_id: collection.id
      }),
      dataType: "json",
      method: "post",
      url: collectionClozeSentence.copyUrl
    })
      .done((data) => {
        if(data.errors) {
          this.setState({ errors: data.errors, loading: false });
          return false;
        }
        onCopy && onCopy({ collection, collectionClozeSentence });
        this.setState({ copied: true, loading: false });
      })
      .fail(() => {
        this.setState({ loading: false });
        alert("Oh no! There was an error copying the sentence. Sorry about that. Please try again and let someone know if you see this message again.");
      });
  }

  renderContent() {
    const { collectionsUrl } = this.props;
    const { copied, errors, loading } = this.state;

    if(loading) {
      return "Loading...";
    }

    if(copied) {
      return (
        <div className="alert alert-success" style={{ marginBottom: 0, textAlign: "center" }}>Copied! 🎉</div>
      );
    }

    return (
      <>
        {!!errors && <p className="text-danger">{errors}.</p>}
        <CollectionSelector
          collectionsUrl={collectionsUrl}
          onSelect={(collection) => this.setState({ collection })}
        />
        {this.renderControls()}
      </>
    );
  }

  render() {
    const { onClose, style } = this.props;

    return (
      <div className="popover" role="tooltip" style={Object.assign({ display: "block", left: "unset", right: 0, top: 30 }, style)}>
        <div className="arrow"></div>
        <h3 className="popover-title">
          <button type="button" className="close" aria-label="Close" onClick={() => onClose()} style={{ color: "#000", marginLeft: 10, marginTop: -4 }}>&times;</button>
          Copy to Collection
        </h3>
        <div className="popover-content" style={{ width: 220 }}>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}
