import React from "react";

import AddSentenceToCollectionModal from "../AddSentenceToCollectionModal";
import Icon from "../Icon";
import ManageCollectionModal from "../ManageCollectionModal";

export default class SearchResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addToCollection: false,
      collections: null,
      loadingCollections: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
  }

  loadCollections() {
    this.setState({ loadingCollections: true });

    $.ajax({
      url: this.props.sentence.collectionsUrl
    })
      .done((data) => {
        this.setState({
          collections: data.clozeSentence.collections,
          languagePairing: data.languagePairing,
          loadingCollections: false
        });
      })
      .fail(() => {
        alert("Oh no! There was an error loading the collections. Sorry about that. Please try again and let us know if you see this message again.");
        this.setState({ loadingCollections: false });
      });
  }

  renderTatoebaBtn() {
    const { tatoebaId } = this.props.sentence;

    if(tatoebaId) {
      return (
        <a
          className="btn btn-xs btn-default"
          href={'https://tatoeba.org/eng/sentences/show/' + tatoebaId}
          style={{ marginRight: 5 }}
          target="_blank"
        >
          Source <Icon name="new-window" />
        </a>
      );
    }
  }

  renderAudioBtn() {
    const { ttsAudioUrl } = this.props.sentence;

    if(ttsAudioUrl) {
      return (
        <span>
          <button className="btn btn-xs btn-default" onClick={() => this.audio.play()} style={{ marginRight: 5 }}>
            <Icon name="volume-up" />
          </button>
          <audio ref={(el) => this.audio = el} src={ttsAudioUrl} />
        </span>
      );
    }
  }

  renderSentence() {
    const { addToCollectionDisabled, sentence } = this.props;
    const {
      pronunciation,
      text,
      translation
    } = sentence;

    const parts = text.split('{{');

    return (
      <div className="cloze-sentence" style={addToCollectionDisabled ? { paddingLeft: 0 } : null}>
        {!addToCollectionDisabled && (
          <>
            <input type="checkbox" checked={this.props.selected} onChange={(e) => this.props.onSelect(e.target.checked)} style={{ left: 0, position: 'absolute' }} />
            <div className="pull-right">
              <button disabled={this.props.disabled} className="btn btn-xs btn-success add-to-collection" style={{ marginLeft: 4 }} onClick={() => this.setState({ addToCollection: !this.state.addToCollection })}>Add to Collection</button>
            </div>
          </>
        )}
        <div className="text" style={{ fontSize: '2rem' }}>
          <span>{parts[0]}<strong><u>{parts[1].split('}}')[0]}</u></strong>{parts[1].split('}}')[1]}</span>
        </div>
        <div><small>{translation}</small></div>
        {pronunciation && <div><small>{pronunciation}</small></div>}
        <div>
          {this.renderAudioBtn()}
          {this.renderTatoebaBtn()}
          {this.renderCollections()}
        </div>
      </div>
    );
  }

  renderCollections() {
    if(this.state.loadingCollections) {
      return <small>Loading...</small>;
    }
    else if(!this.state.collections) {
      return (
        <button className="btn btn-xs btn-default" onClick={() => this.loadCollections()}>Collections</button>
      );
    }
    else if(this.state.collections.length) {
      return (
        <small>
          Collections:
          {this.state.collections.map((c) => (
            <button className="btn btn-default btn-xs" key={c.name} onClick={() => this.setState({ managingCollection: c })} style={{ marginLeft: 5 }}>{c.name}</button>
          ))}
        </small>
      );
    }
    else {
      return <small>Not found in any collections!</small>;
    }
  }

  renderAddToCollection() {
    if(!this.state.addToCollection) {
      return null;
    }

    const collectionClozeSentence = Object.assign({}, this.props.sentence);
    delete collectionClozeSentence.id;
    collectionClozeSentence.clozeSentenceId = this.props.sentence.id;

    return (
      <AddSentenceToCollectionModal
        collectionClozeSentence={collectionClozeSentence}
        collectionsUrl={this.props.collectionsUrl}
        initialSelectedCollectionId={this.props.initialSelectedCollectionId}
        isPro={true}
        nextReviewByLevel={this.props.nextReviewByLevel}
        onHidden={() => this.setState({ addToCollection: false })}
        onSentenceAdded={() => {
          this.addSentenceToCollectionModal.hide();
          if(this.state.collections) {
            this.loadCollections();
          }
        }}
        ref={(el) => this.addSentenceToCollectionModal = el}
      />
    );
  }

  renderManageCollectionModal() {
    const { languagePairing, managingCollection } = this.state;
    const { sentence } = this.props;
    const { text } = sentence;

    if(managingCollection) {
      return (
        <ManageCollectionModal
          {...languagePairing}
          collectionClozeSentencesUrl={managingCollection.collectionClozeSentencesUrl}
          isPro={true}
          onHidden={({ hasCopiedSentences, hasDeletedSentences }) => {
            this.setState({
              managingCollection: null
            }, () => {
              if(hasCopiedSentences || hasDeletedSentences) {
                this.loadCollections();
              }
            });
          }}
          onUpdate={(updatedCollectionClozeSentences) => {
            console.log("updatedCollectionClozeSentences", updatedCollectionClozeSentences);
          }}
          query={text.replace(/{{|}}/g, "")}
        />
      );
    }
  }

  render() {
    return (
      <div className="cloze-sentence-search-result" ref={(el) => this.el = el} style={{ opacity: this.props.disabled ? 0.3 : 1 }}>
        {this.renderSentence()}
        {this.renderAddToCollection()}
        {this.renderManageCollectionModal()}
      </div>
    );
  }
}
