import React from "react";

import DiscussionModal from "./PlayCollection/DiscussionModal";

import { splitTextOnCloze } from "../helpers";

export default class DiscussionBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    };
  }

  renderModal() {
    const { modalVisible } = this.state;

    if(!modalVisible) {
      return null;
    }

    const {
      baseLanguageEnglishName,
      clozeSentence,
      isPro,
      targetLanguageEnglishName,
      targetLanguageName,
    } = this.props;

    const { preClozeStr, cloze, postClozeStr } = splitTextOnCloze(clozeSentence.text);
    const { text, translation } = clozeSentence;
    const sentencePlainText = text.replace(/{{|}}/g, "");

    return (
      <DiscussionModal
        baseLanguageEnglishName={baseLanguageEnglishName}
        cloze={cloze}
        isPro={isPro}
        onHidden={() => this.setState({ modalVisible: false })}
        postClozeStr={postClozeStr}
        preClozeStr={preClozeStr}
        sentencePlainText={sentencePlainText}
        translation={translation}
        targetLanguageEnglishName={targetLanguageEnglishName}
        targetLanguageName={targetLanguageName}
      />
    );
  }

  render() {
    const { className, clozeSentence, disabled, title } = this.props;
    const { commentsCount } = clozeSentence;

    return (
      <>
        <button className={className || "btn btn-default"} disabled={disabled} onClick={() => this.setState({ modalVisible: true })}>
          <span className="glyphicon glyphicon-comment"></span>
          {!!commentsCount && <span className="badge comment-count" style={{ marginLeft: 4 }}><span className="value">{commentsCount}</span></span>}
        </button>
        {this.renderModal()}
      </>
    );
  }
}

