import React from "react";

export default class ChallengeFriendsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  renderSuccessMessage() {
    if(!this.state.success) {
      return null;
    }

    return (
      <div className="notice success">
        <strong>Invite sent to:</strong> <span className="email">{this.state.email}</span>
        <br />
        <small>Thanks for sharing Clozemaster!</small>
      </div>
    );
  }

  renderErrorMessage() {
    if(!this.state.error) {
      return null;
    }

    return (
      <div className="notice error">Please enter a valid email address.</div>
    );
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      error: false,
      loading: true,
      success: false
    });

    $.ajax({
      data: { email: this.state.email },
      method: 'post',
      url: this.props.friendInvitesUrl
    })
      .done((data) => {
        this.setState({
          error: data.error,
          loading: false,
          success: !data.error
        });
      })
      .fail(() => {
        alert('Oh no! There was an error sending the email. Sorry about that. Please try again and let us know if you see this message again.');
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div className="invite-friend panel panel-default clozemaster">
        <div className="panel-heading joystix">Challenge Friends</div>
        <div className="panel-body">
          <strong>Send a personal invite email</strong>
          <form className="display-table" onSubmit={(e) => this.onSubmit(e)} style={{ width: '100%' }}>
            <div className="display-table-row">
              <div className="display-table-cell" style={{ width: '100%' }}>
                <input disabled={!this.props.isSignedIn || this.state.loading} className="form-control" name="friend_invite[email]" placeholder="Email address" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, error: false, success: false })} />
              </div>
              <div className="display-table-cell" style={{ paddingLeft: 6 }}>
                <button className="btn btn-default" disabled={!this.props.isSignedIn || this.state.loading}>Send</button>
              </div>
            </div>
          </form>
          {this.renderSuccessMessage()}
          {this.renderErrorMessage()}
          {!this.state.success && !this.state.error && <small>Fill in your friends’ email address and we’ll send them a personal invite.</small>}
        </div>
      </div>
    );
  }
}
