import React from "react";

import DailyGoalModal from "./DailyGoalModal";
import DailyGoalStats from "./DailyGoalStats";

export default class DailyGoalPanelV2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      pointsPerDay: props.pointsPerDay,
      streak: props.streak
    };
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    const { streak } = this.props;

    return (
      <DailyGoalModal
        onHidden={() => this.setState({ modalVisible: false })}
        onUpdate={({ pointsPerDay, streak }) => this.setState({ pointsPerDay, streak }, () => this.modal.hide())}
        pointsPerDay={this.state.pointsPerDay}
        ref={(el) => this.modal = el}
        streak={streak}
        targetLanguageName={this.props.targetLanguageName}
        updateUrl={this.props.updateUrl}
      />
    );
  }

  renderBody() {
    const pointsPerDay = parseInt(this.state.pointsPerDay);
    const { isSignedIn } = this.props;

    if(!pointsPerDay) {
      return (
        <div className="text-center" style={{ marginTop: 20 }}>
        {/*Learning a language requires practice every day.<br />Stay motivated!*/}
          <button className="btn btn-default btn-block joystix wrap" disabled={!isSignedIn} onClick={() => this.setState({ modalVisible: true })}>
            Set a daily goal
          </button>
        </div>
      );
    }

    const { streak } = this.state; 
    const { hoursLeftToday, numPointsToday, timeZone } = this.props;

    return (
      <>
        <div className="joystix">
          <span style={{ color: numPointsToday > pointsPerDay ? "#5cb85c" : "inherit", fontSize: "2em" }}>{numPointsToday}</span>/{pointsPerDay}
        </div>
        <div>{streak} days in a row</div>
        {numPointsToday > pointsPerDay && <div className="hidden-xs">Includes today 🎉</div>}
      </>
    );

    // return (
    //   <DailyGoalStats
    //     hoursLeftToday={hoursLeftToday}
    //     numPointsToday={numPointsToday}
    //     pointsPerDay={pointsPerDay}
    //     streak={streak}
    //     timeZone={timeZone}
    //   />
    // );
  }

  render() {
    return (
      <div className="panel panel-default">
        <div className="panel-body" style={{ height: 128 }}>
          <h3 className="joystix" style={{ fontSize: 16, margin: 0 }}>
            Daily Goal
            {!!this.state.pointsPerDay && (
              <button onClick={() => this.setState({ modalVisible: true })} className="btn btn-default btn-xs" style={{ marginLeft: 8 }}>
                <span className="glyphicon glyphicon-pencil"></span>
              </button>
            )}
          </h3>
          {this.renderBody()}
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

