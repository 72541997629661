import React from "react";

import Icon from "./Icon";

export default function DailyGoalStats(props) {
  const pointsPerDay = parseInt(props.pointsPerDay);
  const numPointsToday = parseInt(props.numPointsToday);
  const includesToday = (numPointsToday >= pointsPerDay);
  const streak = parseInt(props.streak);
  const hoursLeftToday = props.hoursLeftToday;

  return (
    <div className="daily-goal-stats" style={props.style}>
      <p className="points">
        <strong className="stat">Points:</strong> <span className="joystix"><span className={'value ' + (includesToday ? 'success' : '')}>{numPointsToday}</span> / {pointsPerDay}</span>
        {!!includesToday && <span style={{ fontSize: "1.25em", marginLeft: 10 }}><Icon name="ok" style={{ color: "#5cb85c" }} /> 🎉</span>}
      </p>
      {/*
      <p className="streak">
        <strong className="stat">Streak:</strong> <span className={'joystix value ' + (streak === 0 ? 'zero' : '')}>{streak}</span> <small className={'days joystix ' + (streak === 0 ? 'zero' : '')}>Day{streak === 1 ? '' : 's'}</small> <span key={'tooltip-' + pointsPerDay} className={'glyphicon glyphicon-fire includes-today ' + (includesToday ? 'active' : '')} data-toggle="tooltip" title={includesToday ? 'Streak includes today.' : ((pointsPerDay - numPointsToday) + ' points to go for today!')}></span>
      </p>
      */}
      <p className="hours-left">
        <strong className="stat">Time remaining:</strong>
        <span className="joystix">{hoursLeftToday}</span> <small className="joystix">Hours</small>
      </p>
      {!!props.timeZone && (
        <p className="time-zone">
          <strong className="stat">Time zone:</strong>
          <span className="value joystix"><a href="/settings#timezone"><small>{props.timeZone}</small></a></span>
        </p>
      )}
    </div>
  );
}
