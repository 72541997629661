import React from "react";

import Loading from "../Loading";
import Modal from "../Modal";

export default class DiscussionModal extends React.Component {
  constructor(props) {
    super(props);

    const { baseLanguageEnglishName, targetLanguageName, translation } = props;

    this.state = {
      content: "",
      formattingHelpVisible: false
    };
  }

  // copied from app/assets/javascripts/challenges/play.js
  onModalReady() {
    // Init modal
    const $discussionModal = $('#discussion-modal');
    
    //// Find topic in discourse if its there, return false if not
    const getSentenceTopic = () => {
      // place spinning gif
      modalFadeOn();
      // Remove existing comments
      $discussionModal.find('.discussion .comments').html("Loading...")
      $.ajax({
        url: "/discourse/sentence_topic",
        method: "get",
        contentType: "text/html",
        data: {
          clozeable_sentence: this.props.sentencePlainText
        }
      }).done(function(data){
        modalFadeOff();
        // parse response
        // update the modal button
        $discussionModal.find('.discussion .comments').html(data)
        // activate likes, replies & input etc.
        $discussionModal.find('.discussion .comments a').attr("target", "_blank");
        activateReplies();
        activateEdits();
        validateInput();
        addReplyAuthors();
        addTopicLink();
        // Add comment number to modal button
        var count = $(data).filter('.post').length;
        $('.comment-count').find('.value').text(count);
        
      }).error(function(){
        $discussionModal.find('.discussion .comments').html('')
        modalFadeOff();
        modalScrollTop();
        errorMessage("Something went wrong with the post, please try again or try reloading the page");
      })
    }
    
    //// Post new comment to discourse, return list of comments
    $discussionModal.on('submit', 'form', (e) => {
      // Take control of the form
      e.preventDefault();
      var $form = $discussionModal.find('form');
      // Disable further input
      $form.find(':input').prop({ readonly: true });
      $form.find(':submit').prop('disabled', true);
      // Set up clozeable sentence & slug as a hidden field
      // Set up necessary params on the form
      $('<input>').attr({'type':'hidden', 'name':'sentence'}).val(this.props.sentencePlainText).appendTo($form);
      $('<input>').attr({'type':'hidden', 'name':'targetLanguage'}).val(this.props.targetLanguageEnglishName).appendTo($form);

      // fade
      modalFadeOn()

      const { baseLanguageEnglishName, targetLanguageName, translation } = this.props;
      const data = $form.serializeArray().map(
        (p) => p.name === "content" && !p.value.match(/^\[details=.+Translation/) ? {
          name: p.name,
          value: `[details="${baseLanguageEnglishName} Translation"]${translation}[/details]\n\n${p.value}`,
        } : p
      );

      // send post to controller
      $.ajax({
        url: $form.attr('action'),
        method: 'post',
        data
      }).done(function(data) {
        // console.log(data);
        removeDiscussionInput();
        $(".discussion .comments").html(data)
        // activate likes
        $(".discussion .comments a").attr("target", "_blank");
        activateReplies();
        activateEdits();
        newForm();
        addReplyAuthors();
        // Increase comment count on button
        incrementCommentCount();
        addTopicLink();
        
        // Remove the fade
        modalFadeOff();

      }).error(function(e) {
        modalFadeOff();
        modalScrollTop();
        errorMessage("Something went wrong with the post, please try again or try reloading the page");
        // Parse errors to page
      }).always(function() {
        // Return the form to user control
        $form.find(':input').prop({ readonly: false });
        $form.find(':submit').prop('disabled', false);
      });
      return false;
    });
    
    /// replace form with reply
    var activateReplies = function(){
      // Return if not a pro user
      if(!$('.comments').data('proUser')){
        return
      }
      
      // Wipe all click event handlers that might be there
      $('.comments .reply').off('click')
      // Add click event for replies
      $('.comments .reply').on('click', function(c){
        
        modalFadeOn();
        $discussionModal.find('.post').removeClass('highlight');
        // Get data from each post
        var parentPost = $(this).closest(".post")
        parentPost.addClass('highlight');
        // Scroll to top
        modalScrollTop();
        
        // Ajax request for form data
        $.ajax({
          url: "/discourse/get_form",
          method: "get",
          contentType: "text/html",
          data: {
            post_id: parentPost.data('postId'),
            post_number: parentPost.data('postNumber'),
            post_author: parentPost.data('author'),
            post_topic_id: parentPost.data('topicId'),
            post_type: "reply"
          }
        }).done(function(data){
          modalFadeOff();
          // parse response
          // update the modal button
          $discussionModal.find('.discussion .form').replaceWith(data)
          // activate likes
          validateInput();
          activateCancelForm();
          // Add comment number to modal button

        }).error(function(){
          modalFadeOff();
          modalScrollTop();
          errorMessage("Something went wrong with the reply, please try again or try reloading the page");
        })
      })
    }
    
    var activateCancelForm = function() {
      $('.cancel-form').on('click', function(e){
        e.preventDefault();
        newForm();
        return false;
      })
    };
    
    var activateEdits = function(){
      // Return if not a pro user
      if(!$('.comments').data('proUser')){
        return
      }
      // Wipe all click event handlers that might be there
      $('.comments .edit').off('click');
      
      // add click handler
      $('.comments .edit').on('click', function(e) {
        e.preventDefault();
        modalFadeOn();
        // Get data from each post
        var parentPost = $(this).closest(".post")
        // Scroll to top
        modalScrollTop();
        
        // Ajax request for form data
        $.ajax({
          url: "/discourse/get_form",
          method: "get",
          contentType: "text/html",
          data: {
            post_id: parentPost.data('postId'),
            post_content: parentPost.find('.content p').text(),
            post_type: "edit"
          }
        }).done(function(data){
          modalFadeOff();
          // parse response
          // update the modal button
          $discussionModal.find('.discussion .form').replaceWith(data)
          // activate likes
          validateInput();
          activateCancelForm();
          // Add comment number to modal button

        }).error(function() {
          modalFadeOff();
          modalScrollTop();
          errorMessage("Something went wrong with the edit, please try again or try reloading the page");
        });

        return false;
      });
    };
    
    var newForm = function(){
      modalFadeOn();
      // Ajax request for form data
      $.ajax({
        url: "/discourse/get_form",
        method: "get",
        contentType: "text/html",
        data: {
          post_type: "post"
        }
      }).done(function(data){
        modalFadeOff();
        // parse response
        // update the modal button
        $discussionModal.find('.discussion .form').replaceWith(data)
        $discussionModal.find("#discussion-input").focus();
        // activate likes
        validateInput();
        // Add comment number to modal button

      }).error(function(){
        modalFadeOff();
        modalScrollTop();
        errorMessage("Something went wrong, please try again");
      })
    }
    
    var addReplyAuthors = function(){
      const replies = $('.comments [data-reply-to-post-number]');
      replies.each(function(){
        const reply = $(this);
        const replyPost = $(reply).data('replyToPostNumber');
        const replyAuthor = $(".comments").find("[data-post-number='" + replyPost + "']").data('author');
        reply.find('.reply-author').html(replyAuthor);
      })
    };

    $discussionModal.on('click', '.post .reply-author', function(e) {
      e.preventDefault();
      $discussionModal.find('.post').removeClass('highlight');
      var $post = $(this).closest('.post');
      var replyToPostNumber = $post.data('reply-to-post-number');
      var $replyToPost = $discussionModal.find('.post[data-post-number="' + replyToPostNumber + '"]');
      $discussionModal.animate({ scrollTop: $replyToPost.offset().top - $discussionModal.offset().top }, 'slow', function() {
        $replyToPost.addClass('highlight');
      });
      return false;
    });
    
    var removeDiscussionInput = function(){
      $('#discussion-input').val("");
    }
    // Modal fade
    var modalFadeOn = function(){
      $('.modal-overlay').css({"visibility": "visible"})
      $('.modal-loading').css("visibility", "visible")
    }
    var modalFadeOff = function(){
      $('.modal-overlay').css({"visibility": "hidden"})
      $('.modal-loading').css("visibility", "hidden")
    }
    
    // Validate content input - disable submit until its greater than 20 characters
    var validateInput = function(){
      $('#discussion-input').on("input", function(e){
        var $submit = $('#discussion-input').parents("form:first").find(":submit")
        if($('#discussion-input').val().length < 20){
          $submit.prop("disabled",true);
          $('.post-length').show();
        } else {
          // Don't enable if its a normal user
          if($submit.attr('pro') == "true"){
            $submit.prop("disabled",true);
          } else {
            $submit.prop("disabled",false);
            $('.post-length').hide();
          }
        
        }
      });
    }
    // Initial call
    validateInput();

    // Function to toggle likes on a comment
    $discussionModal.on('click', '.post .like', function(e) {
      e.preventDefault();
      var $likeButton = $(this);
      var $post = $likeButton.closest('.post');
      var $likes = $post.find('.likes');
      $.ajax({
        url: "/discourse/toggle_post_like",
        method: "put",
        data: {
          post_id: $post.data('postId'),
        }
      }).done(function(data){
        $likeButton.toggleClass('liked');
        // update the modal button
        $likes.html(data);
      }).error(function(e){
        console.log("error", e)
        modalScrollTop();
        errorMessage("Something went wrong liking this post, please try again or refresh the page.");
        // Parse errors to page
      });
      return false;
    });

    // Activate Flaggable posts
    $discussionModal.on('click', '.post .flag', function(e) {
      e.preventDefault();
      var $flagButton = $(this);
      if($flagButton.hasClass('flagged')) {
        return false;
      }
      if(!confirm('Are you sure you want to flag this comment for the mods? Please only flag comments that are inappropriate or off-topic.')) {
        return false;
      }
      var postId = $flagButton.closest('.post').data('postId');
      $.ajax({
        url: "/discourse/flag_post",
        method: "put",
        data: {
          post_id: postId,
        }
      }).done(function(data){
        // update the button
        $flagButton.addClass('flagged');
      }).error(function(e){
        console.log("error", e);
        modalScrollTop();
        errorMessage("Something went wrong flagging this post, please try again or refresh the page.");
      });
      return false;
    });

    // Increases comment on the discussion modal button
    var incrementCommentCount = function(){
      var newCount = parseInt($('.comment-count .value').text(), 10) + 1;
      $('.comment-count .value').text(newCount);
    }

    // Scrolls the browser view to the top of the discussion modal
    var modalScrollTop = function(){
      $discussionModal.animate({ scrollTop: $discussionModal.find('.form').offset().top }, 'slow');
    };

    // Handles error messages for actions
    var errorMessage = function(msg) {
      $discussionModal.find('.errors').show().html(msg)
    };
    
    // Adds topic link
    var addTopicLink = function(){
      var topicId = $('.comments').find('.post').first().data('topicId');
      var href = '/forum/t/' + topicId;
      $discussionModal.find('.forum-topic-link').attr('href', href).toggle(!!topicId);
      if(topicId) {
        $discussionModal.find('.modal-footer a').attr('href', href);
      }
    };

    $discussionModal.find('.sentence .text').html(this.props.preClozeStr + '<strong>' + this.props.cloze + '</strong>' + this.props.postClozeStr);
    getSentenceTopic();
  }

  onFormattingHelpClick(e) {
    e.preventDefault();

    this.setState({ formattingHelpVisible: !this.state.formattingHelpVisible });
  }

  renderFormattingHelp() {
    if(!this.state.formattingHelpVisible) {
      return null;
    }

    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "50%" }}>What you type:</th>
              <th>How it will look:</th>
            </tr>
          </thead>
          <tbody>
            <tr><td>*italics*</td><td><em>italics</em></td></tr>
            <tr><td>**bold**</td><td><strong>bold</strong></td></tr>
            <tr><td>~~strikethrough~~</td><td><strike>strikethrough</strike></td></tr>
            <tr>
              <td>* item 1<br />* item 2<br />* item 3</td>
              <td><ul><li>item 1</li><li>item 2</li><li>item 3</li></ul></td>
            </tr>
            <tr><td>{"> quoted text"}</td><td><blockquote style={{ fontSize: "unset" }}>quoted text</blockquote></td></tr>
            <tr>
              <td>{"[details=\"heading\"]some text[/details]"}</td>
              <td><details><summary style={{ display: "list-item" }}>heading</summary>some text</details></td>
            </tr>
          </tbody>
        </table>
        <hr />
      </>
    );
  }

  renderPostForm() {
    if(!this.props.isPro) {
      return (
        <div className="form text-center">
          <h3>Sign up for Clozemaster Pro to join the discussion!</h3>
          {/*<p>To ensure high quality comments and foster a sense of community, only Pro users are able to contribute to the forum and sentence discussions.</p>*/}
          <a href="/pro" className="btn btn-lg btn-success btn-block joystix">
            Go Pro <span className="glyphicon glyphicon-chevron-right"></span>
          </a>
        </div>
      );
    }

    const { content } = this.state;

    return (
      <div className="form">
        <form action="/discourse/new_sentence_post" className="discussion-form" data-type="post">
          <input type="hidden" name="form_type" value="post" />
          <div className="form-group">
            <textarea
              autoFocus
              className="form-control"
              id="discussion-input"
              name="content"
              onChange={(e) => this.setState({ content: e.target.value })}
              rows="4"
              value={content}
            />
            <div className="text-right">
              <button className="btn-link btn-xs" onClick={(e) => this.onFormattingHelpClick(e)}>
                Formatting help
              </button>
            </div>
          </div>
          {this.renderFormattingHelp()}
          <button className="btn btn-success joystix" disabled={true}>Post</button>
          <small className="post-length" style={{ marginLeft: 5 }}>
            Discussion comments must be 20 characters or more. 
          </small>
        </form>
      </div>
    );
  }

  render() {
    const { isPro, onHidden } = this.props;
    
    return (
      <Modal
        footer={(
          <>
            <span style={{ marginRight: 5 }}>All sentence comments also appear in the forum - <a href="/forum" target="_blank">check it out</a>!</span>
            <button className="btn btn-default" data-dismiss="modal">Close</button>
          </>
        )}
        id="discussion-modal"
        modalContentExtra={(
          <>
            <div className="modal-overlay"></div>
            <div className="modal-loading">
              <Loading />
            </div>
          </>
        )}
        onHidden={onHidden}
        onShow={() => this.onModalReady()}
        show={true}
        title={(
          <>
            Discussion
            <small style={{ paddingLeft: 10 }}>
              <a className="forum-topic-link" style={{ color: '#fff', display: 'none' }} target="_blank">
                View in the forum <span className="glyphicon glyphicon-new-window"></span>
              </a>
            </small>
          </>
        )}
      >
        <div className="sentence">
          <div className="text"></div>
          <div className="translation"></div>
        </div>
        <div className="discussion">
          <div className="comments" data-pro-user={isPro ? "true" : "false"}></div>
          <div className="errors"></div>
          {this.renderPostForm()}
        </div>
      </Modal>
    );
  }
}
