import React from "react";

import Modal from "./Modal";
import ModalFooterCloseBtn from "./ModalFooterCloseBtn";

export default class DailyReminderModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dailyReminderEmail: props.dailyReminderEmail
    };
  }

  hide() {
    this.modal.hide();
  }

  renderHourOptions() {
    const options = [<option value="" key="blank">No reminder.</option>];
    for(let h = 0, n = 24, value, m; h < n; h++) {
      m = h % 12;
      value = (m === 0 ? 12 : m) + (h > 11 ? 'pm' : 'am');
      options.push(
        <option value={value} key={value}>{value}</option>
      );
    }
    return options;
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    $.ajax({
      data: {
        daily_reminder_email: this.state.dailyReminderEmail
      },
      method: 'put',
      url: this.props.updateUrl
    })
      .done(() => {
        this.setState({ loading: false }, () => this.props.onUpdate(this.state.dailyReminderEmail));
      })
      .fail(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading } = this.state;

    return (
      <Modal
        footer={<ModalFooterCloseBtn text="Cancel" disabled={loading} />}
        onHidden={() => this.props.onHidden()}
        ref={(el) => this.modal = el}
        size="small"
        title="Daily Reminder"
      >
        <p>Keep up the great work and master your language faster! Like they say, practice makes perfect and you have to exercise that muscle. 💪</p>
        <form onSubmit={(e) => this.onSubmit(e)}>
          <div className="form-group">
            <label htmlFor="daily_reminder_email">Email me when I forget to practice by:</label>
            <select disabled={loading} className="form-control" name="daily_reminder_email" value={this.state.dailyReminderEmail || ""} onChange={(e) => this.setState({ dailyReminderEmail: e.target.value })}>
              {this.renderHourOptions()}
            </select>
          </div>
          <button disabled={loading} className="btn btn-success btn-block joystix">Save</button>
        </form>
      </Modal>
    );
  }
}
