import React from "react";

import Panel from "../Panel";

export default function GoogleAdPanel(props) {
  return (
    <Panel>
      {props.children}
      <small style={{ display: "block", marginTop: 5, textAlign: "center" }}>
        <a href="/pro">
          🚀 Remove ads
        </a>
      </small>
    </Panel>
  );
}
