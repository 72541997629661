import React from "react";

import Cookie from "universal-cookie";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";

const speechsdk = require("microsoft-cognitiveservices-speech-sdk");

export default class SpeechToText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayText: "Ready...",
      recording: false
    };
  }

  componentDidMount() {
    this.getTokenOrRefresh();
  }

  getTokenOrRefresh() {
    const { speechTokenUrl } = this.props;

    return new Promise((resolve, reject) => {
      const cookie = new Cookie();
      const speechToken = cookie.get("speech-token-2");

      if(speechToken) {
        const idx = speechToken.indexOf(":");
        return resolve({
          authToken: speechToken.slice(idx + 1),
          region: speechToken.slice(0, idx)
        });
      }

      $.ajax({
        url: speechTokenUrl
      })
        .done((data) => {
          const { region, token } = data;

          cookie.set("speech-token", region + ":" + token, { maxAge: 540, path: "/" });

          resolve({
            authToken: token,
            region
          });
        })
        .fail(() => {
          resolve({ authToken: null, error: err.response.data });
        });
    });
  }

  async speechToText() {
    const { languageLocale, onRecordingComplete } = this.props;

    const tokenObj = await this.getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
    speechConfig.speechRecognitionLanguage = languageLocale; // 'en-US';

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

    this.setState({
      displayText: "Recording...",
      recording: true
    });

    recognizer.recognizeOnceAsync(
      (result) => {
        console.log("RESULT", result);
        let displayText;
        if (result.reason === ResultReason.RecognizedSpeech) {
          displayText = result.text;
          // onRecordingComplete({ text: result.text });
        } else {
          displayText = "Oh no! Recording was cancelled or could not be recognized. Please ensure your microphone is working properly and try again.";
        }

        this.setState({
          displayText,
          recording: false
        });
      },
      (error) => {
        console.log("ERROR", error);
        this.setState({
          displayText: "Error",
          recording: false
        });
      }
    );
  }

  render() {
    const { displayText, recording } = this.state;

    return (
      <div>
        <p>
          {displayText}
        </p>
        <button className="btn btn-default" disabled={recording} onClick={this.speechToText.bind(this)}>Record</button>
      </div>
    );
  }
}
