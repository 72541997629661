import React from "react";

import { getLocalStorageBoolean, setLocalStorageBoolean } from "../../helpers";

import CollectionTableRow from "./CollectionTableRow";
import Icon from "../Icon";

export default class GrammarCollectionsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      grammarCollectionsVisible: getLocalStorageBoolean('dashboardGrammarCollectionsVisible', false)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.grammarCollectionsVisible !== prevState.grammarCollectionsVisible) {
      setLocalStorageBoolean('dashboardGrammarCollectionsVisible', this.state.grammarCollectionsVisible);
    }
  }

  renderCollections() {
    if(!this.state.grammarCollectionsVisible) {
      return null;
    }

    const { progressPerChunk, useProgressChunks } = this.props;

    const rows = this.props.collections.map((c) => (
      <CollectionTableRow
        collection={c}
        isPro={this.props.isPro}
        isSignedIn={this.props.isSignedIn}
        key={c.id}
        manage={this.props.manage}
        play={this.props.play}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    ));

    return (
      <table className="table table-striped text-center">
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="panel panel-default grammar-collections">
        <div className="panel-body">
          <div className="pull-right" style={{ marginTop: 10 }}>
            <i className="fa fa-trophy fa-5x grammar-challenge-trophy pro-gold" aria-hidden="true"></i>
          </div>
          <h2>
            Grammar Challenges
            <button className="btn btn-xs btn-link" data-content="Get fluent faster by practicing difficult grammatical skills in context. Grammar is tough. The Grammar Challenges give you all the practice you need to level up and break through those grammar sticking points." data-title="Grammar Challenges" data-toggle="popover" data-trigger="focus" style={{ fontSize: '65%', marginLeft: 8, textDecoration: 'none' }}>
              <span className="glyphicon glyphicon-question-sign"></span>
            </button>
            <span style={{ display: 'block', marginTop: 5 }}>
              <button className="btn btn-default toggle" onClick={() => this.setState({ grammarCollectionsVisible: !this.state.grammarCollectionsVisible })}>
                <Icon name={'chevron-' + (this.state.grammarCollectionsVisible ? 'up' : 'down')} />
              </button>
              <small style={{ marginLeft: 8 }}>Master difficult grammar in context.</small>
            </span>
          </h2>
          {this.renderCollections()}
        </div>
      </div>
    );
  }
}
