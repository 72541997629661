import React from "react";

import Icon from "../Icon";

export default class ListeningControl extends React.Component {
  constructor(props) {
    super(props);

    // https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    this.state = {
      firstPlayEnded: false,
      playing: false,
      playComplete: false,
      showPlayButton: params.auto_play === "off" // show play button and don't auto play if auto play is off
    };
  }

  componentDidMount() {
    $(window).on("keydown.listening-control", (e) => {
      if(this.state.firstPlayEnded && !this.state.playComplete) {
        // space
        if(e.which === 32) {
          e.preventDefault();
          this.playAudio();
        }
        // enter
        else if(e.which === 13) {
          e.preventDefault();
          this.setState({ playComplete: true });
        }
      }
    });

    if(!this.state.showPlayButton) {
      this.playAudio();
    }
  }

  componentWillUnmount() {
    $(window).off("keydown.listening-control");
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.playComplete && !prevState.playComplete) {
      this.props.onPlayComplete();
    }
  }

  // hear sentence
  // if auto-show sentence on
  // --> show sentence / play ended
  // else
  // --> should see flip button
  // on flip button click --> play ended

  playAudio() {
    // // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
    // const promise = this.audio.play();
    // promise.then(_ => {
    //   // Autoplay started!
    // }).catch(error => {
    //   this.setState({ showPlayButton: true });
    // });
    this.audio = this.props.playAudio({
      onPlay: () => {
        this.setState({
          playing: true,
          showPlayButton: false
        });
      },
      onEnded: () => {
        this.setState({
          firstPlayEnded: true,
          playing: false,
          playComplete: this.state.playComplete || this.props.playCompleteOnFirstPlayEnded
        });
      },
      onError: (e) => {
        // check if error triggered by window.speechSynthesis.cancel call
        if(window.SpeechSynthesisEvent && e instanceof window.SpeechSynthesisEvent && e.elapsedTime > 0) {
          return null;
        }

        if(!this.state.showPlayButton && !this.state.firstPlayEnded) {
          return this.setState({ showPlayButton: true });
        }

        alert('Oh no! There was an error with the audio.\n' + e + ' ' + (e || {}).name + '\nSorry about that. Please try again and let us know if you see this message again.');
        this.props.onError();
        // same state as onEnded, could consolidate
        this.setState({
          firstPlayEnded: true,
          playing: false,
          playComplete: this.state.playComplete || this.props.playCompleteOnFirstPlayEnded
        });
      }
    });
  }

  componentWillUnmount() {
    if(this.audio instanceof SpeechSynthesisUtterance) {
      window.speechSynthesis.cancel();
    }
    else if(this.audio) {
      this.audio.pause();
    }
  }

  render() {
    const { firstPlayEnded, playComplete, playing, showPlayButton } = this.state;
    return (
      <div>
        <div className="listening-control" style={{ opacity: playing ? 0.6 : 1 }} onClick={() => this.playAudio()}>
          <Icon name="volume-up" />
          {showPlayButton ? <div style={{ fontSize: '2rem' }}>Click to play!</div> : null}
        </div>
        {firstPlayEnded && !playComplete && (
          <div className="text-center">
            <div style={{ display: "inline-block", marginRight: 5 }}>
              <button aria-label="replay audio" className="btn btn-default btn-lg" id="listening-control-replay-btn" onClick={() => this.playAudio()} style={{ width: 120 }}><Icon name="volume-up" style={{ fontSize: "1.5em" }} /></button>
              <small style={{ display: "block" }}>(space)</small>
            </div>
            <div style={{ display: "inline-block", marginLeft: 5 }}>
              <button aria-label="show sentence" className="btn btn-default btn-lg" id="listening-control-sentence-btn" onClick={() => this.setState({ playComplete: true })} style={{ width: 120 }}><Icon name="eye-open" style={{ fontSize: "1.5em" }} /></button>
              <small style={{ display: "block" }}>(enter)</small>
            </div>
          </div>
        )}
      </div>
    );
  }
}
