import React from "react";

import { underscoresToCamelCase } from "../../helpers";

export default class ClozeSentenceTts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tts: [],
      ttsErrorMessage: null,
      ttsStatus: null
    };
  }

  componentDidMount() {
    this.getTts();
  }

  getTts() {
    const { type, url } = this.props;

    this.setState({ loading: true });

    $.ajax({
      data: { include: "tts,tts_voices" },
      url
    })
      .done((data) => {
        const ttsable = data[underscoresToCamelCase(type)];

        if(("" + ttsable.ttsStatus).match(/queued|working/)) {
          setTimeout(() => this.getTts(), 2000);
          return false;
        }

        this.setState({
          loading: false,
          text: ttsable.text,
          tts: ttsable.tts,
          ttsErrorMessage: ttsable.ttsErrorMessage,
          ttsStatus: ttsable.ttsStatus,
          ttsVoiceId: ttsable.ttsVoiceId,
          ttsVoices: data.ttsVoices
        });
      })
      .fail(() => {
        alert("Failed to get TTS! Please let someone know.");
      });
  }

  regenerate() {
    const { ttsJobUrl, type } = this.props;
    const { ttsVoiceId } = this.state;

    this.setState({ loading: true });

    $.ajax({
      contentType: "application/json",
      dataType: "json",
      method: "post",
      data: JSON.stringify({
        [type]: {
          tts_voice_id: ttsVoiceId || null
        }
      }),
      url: ttsJobUrl
    })
      .done(() => {
        setTimeout(() => this.getTts(), 2000);
      })
      .fail(() => {
        alert("Failed to regenerate TTS! Please let someone know.");
      });
  }

  render() {
    const { type } = this.props;
    const { loading, text, tts, ttsErrorMessage, ttsStatus, ttsVoiceId, ttsVoices } = this.state;

    if(loading || ("" + ttsStatus).match(/queued|working/)) {
      return "Loading... This may take a few minutes.";
    }

    return (
      <div>
        {!!ttsErrorMessage && <p className="alert alert-danger">{ttsErrorMessage}</p>}
        <p>{text}</p>
        <pre style={{ display: "none" }}>{JSON.stringify(tts, null, 2)}</pre>
        {tts.map((t) => (
          <p key={t["voice_id"]}>
            <strong className="voice-id">{t["voice_id"]}</strong>
            <br />
            <audio controls src={t["audioUrl"]} preload="none" style={{ maxWidth: "100%" }} />
          </p>
        ))}
        <hr />
        <div className="form-group">
          <label>Voice</label>
          <pre style={{ display: "none" }}>{JSON.stringify(ttsVoices, null, 2)}</pre>
          <select className="form-control" name={type + "[tts_voice_id]"} onChange={(e) => this.setState({ ttsVoiceId: e.target.value || "" })} value={ttsVoiceId}>
            <option value="">Random</option>
            {(ttsVoices || []).map((ttsVoice) => (
              <option key={ttsVoice.id} value={ttsVoice.id}>({ttsVoice.languageCode}) {ttsVoice.name} ({ttsVoice.gender}) ({ttsVoice.ttsProvider})</option>
            ))}
          </select>
        </div>
        {/*
        <div className="form-group">
          <label>SSML</label>
          <small><strong>Caution!</strong></small>
          <input className="form-control" />
        </div>
        */}
        <hr />
        <button className="btn btn-default btn-block joystix" onClick={() => this.regenerate()}>Regenerate</button>
      </div>
    );
  }
}
