import React from "react";

import DailyGoalModal from "./DailyGoalModal";
import DailyGoalStats from "./DailyGoalStats";

export default class DailyGoalPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      pointsPerDay: props.pointsPerDay,
      streak: props.streak
    };
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    const { streak } = this.props;

    return (
      <DailyGoalModal
        onHidden={() => this.setState({ modalVisible: false })}
        onUpdate={({ pointsPerDay, streak }) => this.setState({ pointsPerDay, streak }, () => this.modal.hide())}
        pointsPerDay={this.state.pointsPerDay}
        ref={(el) => this.modal = el}
        streak={streak}
        targetLanguageName={this.props.targetLanguageName}
        updateUrl={this.props.updateUrl}
      />
    );
  }

  renderBody() {
    const pointsPerDay = parseInt(this.state.pointsPerDay);
    const { isSignedIn } = this.props;

    if(!pointsPerDay) {
      return (
        <div className="text-center">
          Learning a language requires practice every day. Stay motivated!
          <button className="btn btn-link wrap" disabled={!isSignedIn} style={{ fontSize: '1.25em', fontWeight: 'bold' }} onClick={() => this.setState({ modalVisible: true })}>
            Set a daily goal
          </button>
        </div>
      );
    }

    const { streak } = this.state; 
    const { hoursLeftToday, numPointsToday, timeZone } = this.props;

    return (
      <DailyGoalStats
        hoursLeftToday={hoursLeftToday}
        numPointsToday={numPointsToday}
        pointsPerDay={pointsPerDay}
        streak={streak}
        timeZone={timeZone}
      />
    );
  }

  render() {
    return (
      <div className="daily-goal panel panel-default clozemaster">
        <div className="panel-heading joystix">
          <div className="pull-right">
            <button disabled={!this.props.isSignedIn} onClick={() => this.setState({ modalVisible: true })} className="update-daily-goal btn btn-default btn-xs">
              <span className="glyphicon glyphicon-cog"></span>
            </button>
          </div>
          Daily Goal
        </div>
        <div className="panel-body">
          {this.renderBody()}
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

