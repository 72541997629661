import React from "react";

import FastTrackStats from "./FastTrackStats";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class FastTrackStatsModal extends React.Component {
  render() {
    const { collection, onHidden } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={onHidden}
        size="large"
        title="Fast Track Stats"
      >
        <FastTrackStats
          collection={collection}
        />
      </Modal>
    );
  }
}
