import React from "react";

import Icon from "../Icon";
import Pagination from "../Pagination";

import { splitTextOnCloze } from "../../helpers";

export default class SharedCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSentences: false,
      page: 1,
      sentences: null,
      sentencesVisible: false,
      total: 0
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevState.sentencesVisible && this.state.sentencesVisible && !this.state.sentences && !this.state.loadingSentences) {
      this.loadSentences();
    }
  }

  loadSentences() {
    $.ajax({
      data: { page: this.state.page },
      url: this.props.collection.collectionClozeSentencesUrl
    })
      .done((data) => {
        this.setState({
          loadingSentences: false,
          sentences: data.collectionClozeSentences,
          total: data.total
        });
      })
      .fail(() => {
        alert('Oh no! There was an error loading the sentences. Sorry about that. Please try again and let us know if you see this message again.');
        this.setState({
          loadingSentences: false,
          page: 1,
          sentences: null,
          sentencesVisible: false
        });
      });
  }

  renderSentences() {
    const { loadingSentences, page, sentences, sentencesVisible, total } = this.state;

    if(!sentencesVisible) {
      return null;
    }

    if(!sentences || loadingSentences) {
      return <p style={{ marginTop: 10 }}>Loading sentences...</p>;
    }

    return (
      <div className="sentences-container">
        <div className="sentences scrollbar-always-visible">
          {sentences.map((s) => {
            const parts = splitTextOnCloze(s.text);
            return (
              <div className="sentence" key={s.id}>
                <div className="text">{parts.preClozeStr}<strong><u>{parts.cloze}</u></strong>{parts.postClozeStr}</div>
                <div className="translation"><small>{s.translation}</small></div>
              </div>
            );
          })}
        </div>
        <Pagination
          onNextClick={() => this.setState({ page: page + 1 }, () => this.loadSentences())}
          onPrevClick={() => this.setState({ page: page - 1 }, () => this.loadSentences())}
          page={page}
          perPage={20}
          total={total}
          updating={loadingSentences}
        />
        <div className="text-right">
          <button className="btn btn-default" onClick={() => this.setState({ sentencesVisible: false })}>Hide Sentences</button>
        </div>
      </div>
    );
  }

  render() {
    const { collection } = this.props;

    return (
      <div className="shared-collection" style={{ borderBottom: '1px solid #efefef', marginBottom: 10, paddingBottom: 10 }}>
        <div className="pull-right">
          <button className="btn btn-default" onClick={() => this.setState({ sentencesVisible: !this.state.sentencesVisible })} style={{ marginRight: 10, verticalAlign: 'top' }}>Sentences</button>
          <div style={{ display: 'inline-block', textAlign: 'center' }}>
            <button className="btn btn-success joystix" disabled={!this.props.isPro} onClick={() => this.props.play(collection)}>Play <Icon name="chevron-right" /></button>
            {!this.props.isPro && <div className="joystix">Pro!</div>}
          </div>
        </div>
        <h3>{collection.name}</h3>
        <div>{collection.numSentences} sentences / Created by <a href={collection.user.url} target="_blank">{collection.user.username}</a></div>
        <div>{collection.description}</div>
        {this.renderSentences()}
      </div>
    );
  }
}
