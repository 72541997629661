import React from "react";

import { collectionPctStr } from "../../helpers";

import CollectionProgressBar from "./CollectionProgressBar";
import CollectionTableRow from "./CollectionTableRow";
import Icon from "../Icon";
import MarkedAsKnownIcon from "../MarkedAsKnownIcon";
import MasteredIcon from "../MasteredIcon";
import PlayingIcon from "../PlayingIcon";

export default class FastTrackV2Panel extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   frequencyCollectionsVisible: getLocalStorageBoolean('dashboardFrequencyCollectionsVisible', false)
    // };
  }

  componentDidUpdate(prevProps, prevState) {
    // if(this.state.frequencyCollectionsVisible !== prevState.frequencyCollectionsVisible) {
    //   setLocalStorageBoolean('dashboardFrequencyCollectionsVisible', this.state.frequencyCollectionsVisible);
    // }
  }

  renderCollections() {
    // if(!this.state.frequencyCollectionsVisible) {
    //   return null;
    // }

    const { progressPerChunk, useProgressChunks } = this.props;

    const rows = this.props.collections.sort((a, b) => a.presentationOrder - b.presentationOrder).map((c) => (
      <CollectionTableRow
        collection={c}
        isSignedIn={this.props.isSignedIn}
        key={c.id}
        manage={this.props.manage}
        play={this.props.play}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    ));

    return (
      <div>
        <table className="table table-striped text-center">
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }

  playAll() {
    const { collections, play } = this.props;
    const collection = collections.find((c) => c.numPlaying < c.numSentences) ||
      collections.find((c) => c.numMastered < c.numSentences) ||
      collections[0];
    play(collection);
  }

  render() {
    const { collections, hasFastTrackV1Progress, progressPerChunk, useProgressChunks } = this.props;
    const combinedCollection = {
      numMarkedAsKnown: 0,
      numMastered: 0,
      numPlaying: 0,
      numSentences: 0
    };
    collections.forEach((c) => {
      combinedCollection.numMarkedAsKnown += c.numMarkedAsKnown;
      combinedCollection.numMastered += c.numMastered;
      combinedCollection.numPlaying += c.numPlaying;
      combinedCollection.numSentences += c.numSentences;
    });

    return (
      <div className="panel panel-default fast-track-v2-collections">
        <div className="panel-body">
          {!!hasFastTrackV1Progress && (
            <p class="alert alert-success">
              <strong><i>New!</i></strong> We've released a new version of the Fast Track 🎉 10,000 sentences split into collections of 1,000 sentences each with almost all new content. Read more <a href="https://forum.clozemaster.com/t/difference-between-legacy-and-fast-track-fluency/46204/7" target="_blank">here</a>.
              <br /><br />
              And don't worry! The old Fast Track has simply been renamed to the Legacy Fast Track, and it and all your progress can still be found <strong><a href="#legacy-fast-track">here</a></strong>.
            </p>
          )}
          <div className="pull-right" style={{ marginTop: 10 }}>
            <button className="btn btn-lg btn-success joystix" onClick={this.playAll.bind(this)}>Play <Icon name="chevron-right" /></button>
          </div>
          <h2>
            Fluency Fast Track
            <button
              className="btn btn-xs btn-link closeable"
              data-content="Fast and efficient language learning. With the Fast Track collections, sentences are played in order of difficulty (ranking on a frequency list from most common to least common words), so you're always making progress."
              data-title="Fluency Fast Track"
              data-toggle="popover"
              data-trigger="focus"
              style={{ fontSize: '65%', marginLeft: 8, textDecoration: 'none' }}
            >
              <span className="glyphicon glyphicon-question-sign"></span>
            </button>
            <span style={{ display: 'block', marginTop: 5 }}>
              <small>Play {combinedCollection.numSentences.toLocaleString()} sentences in order of difficulty.</small>
            </span>
          </h2>

          <CollectionProgressBar
            collection={combinedCollection}
            perChunk={progressPerChunk}
            style={{ marginBottom: 10 }}
            useChunks={useProgressChunks}
          />

          <div style={{ fontSize: "2rem", marginBottom: 15 }}>
            <strong style={{ marginRight: 8 }}><PlayingIcon /> Playing: {combinedCollection.numPlaying.toLocaleString()} <span className="hidden-xs"> / {combinedCollection.numSentences.toLocaleString()} sentences </span>({collectionPctStr(combinedCollection, "playing")})</strong>
            <div className="visible-xs" />
            <strong style={{ marginRight: 8 }}><MasteredIcon /> Mastered: {combinedCollection.numMastered.toLocaleString()} ({collectionPctStr(combinedCollection, "mastered")})</strong>
            <div className="visible-xs" />
            {!!combinedCollection.numMarkedAsKnown && (
              <strong><MarkedAsKnownIcon /> Marked as Known: {combinedCollection.numMarkedAsKnown.toLocaleString()}</strong>
            )}
          </div>

          {this.renderCollections()}
        </div>
      </div>
    );
  }
}

