import React from "react";

import { splitTextOnCloze, tatoebaUrl, underscoresToCamelCase } from "../../helpers";

import ClozeSelectable from "../ClozeSelectable";
import Icon from "../Icon";

const editableAttrs = [
  'alternativeAnswers',
  'hint',
  'idiom',
  'moderated',
  'multipleChoiceOptions',
  'notes',
  'nsfw',
  'pronunciation',
  'tatoebaId',
  'text',
  'translation',
];

export default class ClozeSentenceEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign(
      {
        deleted: false,
        errors: null,
        loading: true,
        tatoebaLink: true
      },
      this.getSentenceState(props.sentence || {})
    );
  }

  componentDidMount() {
    const { sentence, type, url } = this.props;

    if(!!sentence) {
      this.setState({ loading: false });
      return true;
    }

    $.ajax({
      url
    })
      .done((data) => {
        this.setState(
          Object.assign(
            { loading: false },
            this.getSentenceState(data[underscoresToCamelCase(type)])
          )
        );
      })
      .fail(() => {
        alert("Failed to load sentence! Please let someone know.");
      });
  }

  isDisabled() {
    return this.state.deleted || this.props.disabled || this.state.loading;
  }

  getSentenceState(sentence) {
    sentence = sentence || {}; // in case new / blank sentence
    const state = {};

    editableAttrs.forEach((attr) => {
      if(attr === 'alternativeAnswers') {
        return state[attr] = (sentence[attr] || []).join(",");
      }
      if(attr === 'multipleChoiceOptions') {
        return state[attr] = (sentence[attr] || []).join(",");
      }
      return state[attr] = sentence[attr] || '';
    });

    return state;
  }

  handleChange(e) {
    const update = {};
    if(e.target.type === "checkbox") {
      update[e.target.name] = e.target.checked;
    }
    else {
      update[e.target.name] = e.target.value;
    }
    this.setState(update);
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      errors: null,
      loading: true
    });

    const { onUpdate, type, url } = this.props;

    const {
      alternativeAnswers,
      hint,
      idiom,
      multipleChoiceOptions,
      notes,
      nsfw,
      pronunciation,
      tatoebaId,
      text,
      translation
    } = this.state;

    const requestData = {
      alternative_answers: alternativeAnswers,
      hint,
      idiom,
      multiple_choice_options: multipleChoiceOptions,
      notes,
      nsfw,
      pronunciation,
      tatoeba_id: tatoebaId,
      text,
      translation
    };

    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        [type]: requestData
      }),
      method: "patch",
      url
    })
      .done((data) => {
        if(data.errors) {
          this.setState({
            errors: data.errors,
            loading: false
          }, () => {
            $('.modal').animate({ scrollTop: 0 });
          });
          return false;
        }

        const sentence = data[underscoresToCamelCase(type)];
        if(onUpdate) {
          onUpdate({
            attrs: editableAttrs.reduce((h, a) => {
              h[a] = sentence[a];
              return h;
            }, {}),
            data: requestData
          });
        }
        const attrs = this.getSentenceState(sentence);
        this.setState(Object.assign(attrs, { loading: false }));
      })
      .fail(() => {
        alert("Failed to save sentence! Sorry about that. Please let someone know.");
      });
  }

  onDeleteClick() {
    if(prompt("Are you sure you want to delete this sentence? This action cannot be undone. Please type delete to confirm.") !== "delete") {
      return false;
    }

    const { onDelete, url } = this.props;

    $.ajax({
      method: "delete",
      url
    })
      .done(() => {
        this.setState({
          deleted: true,
          loading: false
        });
        onDelete && onDelete();
      })
      .fail(() => {
        alert("Failed to delete sentence! Sorry about that. Please let someone know.");
      });
  }

  render() {
    const {
      alternativeAnswers,
      deleted,
      errors,
      hint,
      idiom,
      multipleChoiceOptions,
      notes,
      nsfw,
      pronunciation,
      tatoebaId,
      tatoebaLink,
      text,
      translation,
    } = this.state;

    const { textEditingDisabled, translationEditingDisabled, type } = this.props;

    return (
      <div className={type.replace(/_/g, '-')}>
        <form onSubmit={(e) => this.onSubmit(e)}>
          {errors && <p className="alert alert-danger">{errors}</p>}
          {deleted && <p className="alert alert-danger">Deleted!</p>}
          <p style={{ fontSize: '1.5em' }}>
            <ClozeSelectable
              onChange={(text) => this.setState({ text })}
              text={text}
            />
          </p>
          <div className="form-group">
            <label htmlFor="text">Text</label>
            <input disabled={this.isDisabled() || textEditingDisabled} className="form-control" name="text" ref={(el) => this.textInput = el} value={text} onChange={(e) => this.handleChange(e)} />
            <small>Wrap the cloze-word in <code>{'{{'}</code><code>{'}}'}</code>, for example <code>A {'{{'}missing{'}}'} word.</code>.</small>
            {textEditingDisabled && (
              <div><small>Text editing is disabled for this collection. Please let someone at Clozemaster know if this text should be changed.</small></div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="translation">Translation</label>
            <input disabled={this.isDisabled() || translationEditingDisabled} className="form-control" name="translation" value={translation} onChange={(e) => this.handleChange(e)} />
            {translationEditingDisabled && (
              <div><small>Translation editing is disabled for this collection. Please let someone at Clozemaster know if this translation should be changed.</small></div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="pronunciation">Pronunciation</label>
            <input disabled={this.isDisabled()} className="form-control" name="pronunciation" value={pronunciation} onChange={(e) => this.handleChange(e)} />
          </div>
          <div className="form-group">
            <label htmlFor="notes">Notes</label>
            <input disabled={this.isDisabled()} className="form-control" name="notes" value={notes} onChange={(e) => this.handleChange(e)} />
          </div>
          {/*
          <div className="form-group">
            <label htmlFor="hint">Hint</label>
            <input disabled={this.isDisabled()} className="form-control" name="hint" value={hint} onChange={(e) => this.handleChange(e)} />
          </div>
          */}
          <div className="form-group">
            <label htmlFor="alternativeAnswers">Alternative answers (comma-separated list)</label>
            <input disabled={this.isDisabled()} className="form-control" name="alternativeAnswers" value={alternativeAnswers} onChange={(e) => this.handleChange(e)} />
          </div>
          {!!multipleChoiceOptions && !!multipleChoiceOptions.length && (
            <div className="form-group">
              <label htmlFor="multipleChoiceOptions">Multiple choice options (comma-separated list)</label>
              <input disabled={this.isDisabled()} className="form-control" name="multipleChoiceOptions" value={multipleChoiceOptions} onChange={(e) => this.handleChange(e)} />
            </div>
          )}
          {this.props.sentence && this.props.sentence.tatoebaId && (
            <div className="checkbox">
              <label>
                <input
                  name="tatoeba_link"
                  type="checkbox"
                  onChange={(e) => this.setState({
                    tatoebaId: e.target.checked ? this.props.sentence.tatoebaId : null,
                    tatoebaLink: e.target.checked
                  })}
                  checked={tatoebaLink}
                />
                Link to Tatoeba
              </label>
              <a href={tatoebaUrl(tatoebaId)} rel="noreferrer" style={{ marginLeft: 5 }} target="_blank">View <Icon name="new-window" /></a>
              {!tatoebaLink && (
                <p className="alert alert-danger" style={{ marginTop: 5 }}>This will remove the Tatoeba ID associated with this sentence. This cannot be undone at the moment. Please be sure.</p>
              )}
            </div>
          )}
          {/*
          <div className="form-group">
            <label htmlFor="tatoebaId">Tatoeba ID{!!tatoebaId && <span> (<a href={tatoebaUrl(tatoebaId)} rel="noreferrer" target="_blank">Tatoeba <Icon name="new-window" /></a>)</span>}</label>
            <input disabled={this.isDisabled()} className="form-control" name="tatoebaId" value={tatoebaId} onChange={(e) => this.handleChange(e)} />
          </div>
          */}
          <div className="checkbox">
            <label>
              <input name="idiom" type="checkbox" onChange={(e) => this.handleChange(e)} checked={idiom} />
              Idiom
            </label>
          </div>
          <div className="checkbox">
            <label>
              <input name="nsfw" type="checkbox" onChange={(e) => this.handleChange(e)} checked={nsfw} />
              NSFW
            </label>
          </div>
          <p>
            <button disabled={this.isDisabled()} className="joystix btn btn-lg btn-success btn-block">Save</button>
          </p>
          <p><small>Text-to-speech (TTS) is automatically updated when text is changed.</small></p>
        </form>
        <div>
          <button className="btn btn-xs btn-danger" disabled={this.isDisabled()} onClick={() => this.onDeleteClick()}>Delete</button>
          <small style={{ marginLeft: 5 }}>Please avoid deleting sentences whenever possible. Edits are preferred.</small>
        </div>
      </div>
    );
  }
}
