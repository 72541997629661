import React from "react";

import Icon from "../Icon";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";
import Panel from "../Panel";

export default class DownloadFavoritesModal extends React.Component {
  renderContent() {
    if(!this.props.isPro) {
      return (
        <ModalProPromo
          can="favorite sentences and then practice just their favorites or download their favorites (to print out, for import into Anki, etc.)."
          secondary="Take control of your learning. Get fluent faster."
        />
      );
    }

    return (
      <>
        <p>Your favorites for {this.props.collection.name} will be generated as a <strong>TSV (Tab Separated Values) file</strong>.</p>
        <Panel heading="How it works">
          <p>Each line in the TSV file will contain <strong>4 columns</strong> separated by tabs:</p>
          <ol>
            <li>column 1 contains the sentence</li>
            <li>column 2 contains the cloze word</li>
            <li>column 3 contains the sentence and cloze word formatted for a cloze type note in Anki, for example: <code>Clozemaster is {'{{c1::awesome}}'}.</code></li>
            <li>column 4 contains the translations</li>
          </ol>
          <div>This file can then be used to import your favorites into Anki.</div>
        </Panel>
        <Panel heading="How to import for Anki">
          <ol>
            <li>Open Anki.</li>
            <li>Go to File then select Import.</li>
            <li>Select the downloaded TSV file and click 'Open'.</li>
            <li>Be sure to select Type 'Cloze' and 'Fields separated by: Tab'.</li>
            <li>Import options are otherwise up to you and the deck you're using. See the column descriptions above for field mappings.</li>
            <li>Also be sure to see <a href="http://ankisrs.net/docs/manual.html#importing" target="_blank">the Anki docs</a> for reference.</li>
          </ol>
        </Panel>
        <p className="text-center">
          <a href={this.props.collection.downloadFavoritesUrl} className="btn btn-primary btn-lg btn-block download"><Icon name="cloud-download" /> Download</a>
        </p>
      </>
    );
  }

  // { data: { download_path: download_favorites_path(@challenge, format: :json), download_status_path: download_favorites_status_path(@challenge, format: :json), pending_download: current_user.pending_favorites_download.try(:id) }, class: [@last_download.try(:processing) && 'processing', @last_download.present? && 'has-last-download'] }
  render() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={this.props.onHidden}
        title="Download Favorites"
      >
        {this.renderContent()}
      </Modal>
    );
  }
};
