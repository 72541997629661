import React from "react";

import CollectionsModalTable from "./CollectionsModalTable";
import FlagSprite from "../FlagSprite";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class ResetProgressModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  resetLanguagePairing() {
    const { onResetLanguagePairing, resetProgressUrl } = this.props;
      
    onResetLanguagePairing();

    this.modal.hide();

    $.ajax({
      method: 'post',
      url: resetProgressUrl
    })
      .done(() => {
        window.location.href = '';
      })
      .fail(() => {
        alert('Oh no! There was an error. Sorry about that. Please refresh the page and try again.');
      });
  }

  resetCollectionProgress(collection) {
    const { onResetCollection } = this.props;
      
    onResetCollection();

    this.modal.hide();

    $.ajax({
      method: 'post',
      url: collection.resetProgressUrl
    })
      .done(() => {
        window.location.href = '';
      })
      .fail(() => {
        alert('Oh no! There was an error. Sorry about that. Please refresh the page and try again.');
      });
  }

  onResetCollectionClick(collection) {
    if(confirm('Are you sure? THIS WILL PERMANENTLY RESET YOUR PROGRESS for ' + collection.name + '. This action cannot be undone.')) {
      this.resetCollectionProgress(collection);
    }
  }

  onResetLanguagePairingClick() {
    if(confirm('Are you sure? THIS WILL PERMANENTLY RESET *ALL* YOUR PROGRESS.')) {
      this.resetLanguagePairing();
    }
  }

  renderCollections() {
    if(!this.props.collections.length) {
      return null;
    }

    return (
      <>
        <hr />
        <div style={{ marginBottom: 0 }}>
          <CollectionsModalTable
            attr="numPlaying"
            btnClassName="btn-danger btn-sm"
            btnText="Reset"
            collections={this.props.collections}
            onBtnClick={(collection) => this.onResetCollectionClick(collection)}
          />
        </div>
      </>
    );

    return this.props.collections.map((c) => (
      <p>{c.name} {c.playing}</p>
    ));
  }

  render() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        id="reset-progress-modal"
        ref={(el) => this.modal = el}
        show={false}
        title={<span><FlagSprite flagIso={targetLanguageFlagIso} size={24} /> / <FlagSprite flagIso={baseLanguageFlagIso} size={24} /></span>}
      >
        <div className="alert alert-danger" style={{ marginBottom: 0 }}>
          <h4 style={{ marginTop: 0 }}>Reset all {targetLanguageName} progress</h4>
          <p style={{ marginBottom: 10 }}><strong>Warning!</strong> This will reset <strong><u>all</u></strong> your progress for learning {targetLanguageName} from {baseLanguageName}. <strong><u>This action is permanent and cannot be undone.</u></strong></p>
          <button className="btn btn-danger btn-block" onClick={() => this.onResetLanguagePairingClick()}>Reset all progress</button>
        </div>
        {this.renderCollections()}
      </Modal>
    );
  }
}

