import React from "react";

const Strike = ({ children, className, ...props }) => {
  return (
    <div className={["strike", className].join(" ")} {...props}>
      <span>{children}</span>
    </div>
  );
};

export default Strike;
