import React from "react";

import { isRTL, tatoebaUrl } from "../../helpers";

import ClozeSentence from "../ClozeSentence";
import Icon from "../Icon";

export default function ClozeSentenceEntry(props) {
  const { baseLanguageIso, clozeSentence, clozeSentenceStyle, includeTranslationLinks, targetLanguageIso } = props;
  const cs = clozeSentence; // convenience
  const plainText = cs.text.replace(/{{|}}/g, "");

  const deepLLanguages = ['bg', 'cs', 'da', 'de', 'el', 'en', 'es', 'et', 'fi', 'fr', 'hu', 'it', 'ja', 'lt', 'lv', 'nl', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'sv', 'zh'];
  const deepLAvailable = deepLLanguages.indexOf(baseLanguageIso) >= 0 && deepLLanguages.indexOf(targetLanguageIso) >= 0;
  console.log("baseLanguageIso", baseLanguageIso, "targetLanguageIso", targetLanguageIso);

  // google translate hebrew is apparently iw, not sure where else we might need to make this change
  const googleTranslateTargetLanguageIso = (targetLanguageIso === "he" ? "iw" : targetLanguageIso);
  const googleTranslateBaseLanguageIso = (baseLanguageIso === "he" ? "iw" : baseLanguageIso);

  return (
    <>
      <div style={clozeSentenceStyle}>
        <div className={isRTL(cs.text) ? "rtl" : null}>
          <ClozeSentence text={cs.text} />
        </div>
      </div>
      {includeTranslationLinks && (
        <ul className="list-inline" style={{ marginBottom: 0 }}>
          <li><small><a href={`https://translate.google.com/?sl=${googleTranslateTargetLanguageIso}&tl=${googleTranslateBaseLanguageIso}&text=${encodeURIComponent(plainText)}&op=translate`} target="_blank">Google <Icon name="new-window" /></a></small></li>
          {deepLAvailable && <li><small><a href={`https://www.deepl.com/translator#${targetLanguageIso}/${baseLanguageIso}/${encodeURIComponent(plainText)}`} target="_blank">DeepL <Icon name="new-window" /></a></small></li>}
          <li><small><a href={`https://www.bing.com/translator/?from=${targetLanguageIso}&to=${baseLanguageIso}&text=${encodeURIComponent(plainText)}`} target="_blank">Bing <Icon name="new-window" /></a></small></li> 
        </ul>
      )}
      <div>
        <div className={isRTL(cs.translation) ? "rtl" : null}>
          {cs.translation}
        </div>
        {includeTranslationLinks && (
          <ul className="list-inline" style={{ marginBottom: 0 }}>
            <li><small><a href={`https://translate.google.com/?sl=${googleTranslateBaseLanguageIso}&tl=${googleTranslateTargetLanguageIso}&text=${encodeURIComponent(cs.translation)}&op=translate`} target="_blank">Google <Icon name="new-window" /></a></small></li>
            {deepLAvailable && <li><small><a href={`https://www.deepl.com/translator#${baseLanguageIso}/${targetLanguageIso}/${encodeURIComponent(cs.translation)}`} target="_blank">DeepL <Icon name="new-window" /></a></small></li>}
            <li><small><a href={`https://www.bing.com/translator/?from=${baseLanguageIso}&to=${targetLanguageIso}&text=${encodeURIComponent(cs.translation)}`} target="_blank">Bing <Icon name="new-window" /></a></small></li> 
          </ul>
        )}
      </div>
      {!!cs.pronunciation && <div><small><strong>Pronunciation:</strong> {cs.pronunciation}</small></div>}
      {!!cs.hint && <div><small><strong>Hint:</strong> {cs.hint}</small></div>}
      {!!cs.notes && <div><small><strong>Notes:</strong> {cs.notes}</small></div>}
      {!!(cs.alternativeAnswers || []).length && <div><small><strong>Alternative answers:</strong> {cs.alternativeAnswers.join(",")}</small></div>}
      {!!cs.tatoebaId && (
        <small><a href={tatoebaUrl(cs.tatoebaId)} rel="noreferrer" target="_blank">Tatoeba <Icon name="new-window" /></a></small>
      )}
    </>
  );
};
