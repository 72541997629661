import React from "react";

import TextareaAutosize from 'react-textarea-autosize';

export default class UserProfileAttr extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      originalValue: props.value,
      saving: false,
      value: props.value
    };
  }

  save() {
    this.setState({ saving: true });

    $.ajax({
      data: { user: { [this.props.name]: this.state.value } },
      method: 'put',
      url: this.props.url
    })
      .done((data) => {
        if(data.errors) {
          alert('Oh no! ' + data.errors);
          this.setState({ saving: false });
          return false;
        }
        this.setState({ editing: false, originalValue: this.state.value, saving: false });
      })
      .fail(() => {
        alert('Oh no! There was an error updating. Sorry about that. Please try again and let us know if you see this message again.');
        this.setState({ saving: false });
      });
  }

  render() {
    if(this.state.editing) {
      const inputProps = {
        className: 'form-control',
        disabled: this.state.saving,
        name: this.props.name,
        onChange: (e) => this.setState({ value: e.target.value }),
        ref: (el) => { this.input = el },
        value: this.state.value
      };

      return (
        <div className={'user-' + this.props.name} style={{ display: 'inline-block', maxWidth: 300, textAlign: 'left' }}>
          <div className="form-group">
            <label htmlFor={this.props.name}>{this.props.label}:</label>
            {this.props.textarea ?
              <TextareaAutosize {...inputProps} /> :
              <input {...inputProps} />
            }
          </div>
          <p className="text-right">
            <button className="btn btn-sm btn-success" disabled={this.state.saving} onClick={() => this.save()}>Save</button>
            <button className="btn btn-sm btn-link" disabled={this.state.saving} onClick={() => this.setState({ editing: false, value: this.state.originalValue })}>Cancel</button>
          </p>
        </div>
      );
    }

    return (
      <p className={'stat user-' + this.props.name} style={{ display: 'inline-block', maxWidth: 300 }}>
        {this.props.label}: {this.state.value || 'Unknown'}{this.props.isCurrentUser && <button className="btn btn-link btn-xs edit" onClick={() => this.setState({ editing: true }, () => this.input && this.input.focus())}><span className="glyphicon glyphicon-pencil" /></button>}
      </p>
    );
  }
}
