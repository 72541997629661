import React from "react";

import TTSVoicesTableRow from "./TTSVoicesTableRow";

export default class TTSSVoicesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      voices: props.voices
    };
  }

  renderVoiceRow(voice) {
    const { languages } = this.props;
    const { voices } = this.state;

    return (
      <TTSVoicesTableRow
        key={voice.id}
        languages={languages}
        onUpdate={(attrs) => this.setState({ voices: voices.map((v) => v.id === voice.id ? attrs : v) })}
        voice={voice}
      />
    );
  }

  render() {
    const { voices } = this.state;

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover table-condensed">
          <thead>
            <tr>
              <th>Active</th>
              <th>Provider</th>
              <th>Language code</th>
              <th>Name</th>
              <th>Sample</th>
              <th>Language</th>
              <th>Display name</th>
              <th>Gender</th>
            </tr>
          </thead>
          <tbody>
            {voices.map((v) => this.renderVoiceRow(v))}
          </tbody>
        </table>
      </div>
    );
  }
}
