import React from "react";

export default class AccentButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shiftKeyDown: false
    };
  }

  componentDidMount() {
    $(window)
      .on("keydown", (e) => {
        this.setState({ shiftKeyDown: e.shiftKey });
      })
      .on("keyup", (e) => {
        this.setState({ shiftKeyDown: e.shiftKey });
      });
  }

  render() {
    const { accentMap, extras, onAccentBtnClick } = this.props;
    const { shiftKeyDown } = this.state;

    const btns = [];
    for(var key in accentMap) {
      accentMap[key].split('').forEach((accent) => {
        btns.push(
          <button
            className="btn btn-default btn-sm"
            key={accent}
            onClick={(e) => onAccentBtnClick(e, accent)}
            title={'alt+' + key}
          >
            {shiftKeyDown ? accent.toUpperCase() : accent}
          </button>
        );
      });
    }

    extras.forEach((extra) => {
      btns.push(
        <button
          className="btn btn-default btn-sm"
          key={"extra-" + extra}
          onClick={(e) => onAccentBtnClick(e, extra)}
        >
          {extra}
        </button>
      );
    });

    if(!btns.length) {
      return null;
    }

    return <div className="accent-btns">{btns}</div>;
  }
}
