import React from "react";

import LoadingOverlay from "./LoadingOverlay";

export default class PaddleCheckout extends React.Component {
  constructor(props) {
    super(props);

    this.checkUserStatusCount = 0;

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    const { sandbox, vendor } = this.props;

    if(sandbox) {
      Paddle.Environment.set('sandbox');
      // to simulate checkout completed in tests
      window.handleCheckoutCompleted = (data) => this.handleCheckoutCompleted(data);
    }

    Paddle.Setup({
      eventCallback: (data) => {
        if(data.event === "Checkout.Close") {
          if(data.eventData.checkout.completed) {
            this.handleCheckoutCompleted(data);
          }
        }
      },
      vendor: parseInt(vendor)
    });

    this.setPrices();
  }

  setPrices() {
    const { discountPct, bigYearlyPriceFont, sandbox } = this.props;

    const product_ids = $(".price").map(function() {
      return $(this).find("[data-product]").data("product");
    }).toArray().join(",");

    $.ajax({
      dataType: "jsonp",
      data: { product_ids },
      timeout: 3000,
      url: `https://${sandbox ? "sandbox-" : ""}checkout.paddle.com/api/2.0/prices`
    })
      .done((data) => {
        if(!data.success) {
          this.fallbackSetPrices();
          return false;
        }

        try {
          data.response.products.forEach((r) => {
            const $price = $(`[data-product="${r.product_id}"]`).parents(".price");
            const listPrice = r.subscription ? r.subscription.list_price.gross : r.list_price.gross;
            const prettifyPrice = (price) => (
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: r.currency
              }).format(price)
            );
            const prettyListPrice = prettifyPrice(listPrice);

            $price.find(".default .dollars").text(prettyListPrice);

            if(r.subscription && r.subscription.trial_days) {
              $price.siblings(".period").after(`<strong>${r.subscription.trial_days} day free trial</strong>`);

              if(r.subscription.interval === "year") {
                $price.siblings(".period").after(`<div class="per-month" style="color: #aaa">${prettifyPrice(listPrice / 12)}/month</strong>`);
                $price.siblings(".guarantee").hide();
              }
            }

            if(discountPct) {
              const discountedPrice = listPrice * (1 - discountPct);
              const prettyDiscountedPrice = prettifyPrice(discountedPrice);
              $price.find(".default .dollars").css({
                "font-size": "2em",
                "text-decoration": "line-through"
              });
              $price.append(`
                <div class="discounted-price" style="color: #5cb85c; font-size: 3em; font-weight: bold">
                  ${prettyDiscountedPrice}
                </div>
              `);
              if(r.subscription && r.subscription.interval === "year") {
                $price.siblings(".per-month").text(`${prettifyPrice(discountedPrice / 12)}/month`);
                if(bigYearlyPriceFont) {
                  $price.find(".discounted-price").css({ fontSize: "4em" });
                }
              }
            }
          });
        } catch(e) {
          this.fallbackSetPrices();
        }
      })
      .fail(() => {
        this.fallbackSetPrices();
      });
  }

  fallbackSetPrices() {
    const { bigYearlyPriceFont, discountPct } = this.props;

    $(".price").each(function() {
      const $price = $(this);
      const product = $price.find("[data-product]").data("product");

      Paddle.Product.Prices(product, function(data) {
        console.log(data);
        const grossPrice = data.recurring ? data.recurring.price.gross : data.price.gross;
        $price.find(".default .dollars").text(grossPrice);
        const yearly = data.recurring && data.recurring.subscription && data.recurring.subscription.type === "year";

        if(data.recurring && data.recurring.subscription && data.recurring.subscription.trial_days) {
          $price.siblings(".period").after(`<strong>${data.recurring.subscription.trial_days} day free trial</strong>`);

          if(yearly) {
            $price.siblings(".guarantee").hide();
          }
        }

        // TODO! https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
        if(discountPct && !grossPrice.match(/,/)) {
          const m = grossPrice.match(/(\D+)(\d.+)/);
          const discounted = m[1] + parseFloat(m[2] * (1 - discountPct)).toFixed(2);
          $price.find(".default .dollars").css({
            "font-size": "2em",
            "text-decoration": "line-through"
          });
          $price.append(`
            <div class="discounted-price" style="color: #5cb85c; font-size: ${yearly && bigYearlyPriceFont ? "4em" : "3em"}; font-weight: bold">${discounted}</div>
          `);
        }
      });
    });
  }

  handleCheckoutCompleted(data) {
    const { sandbox } = this.props;

    if(sandbox) {
      console.log(data);
    }

    this.setState({ 
      loading: true
    }, () => this.checkUserStatus(data));
  }

  // track then redirect
  onFinishedWaiting(eventCallbackData) {
    const { ga, mixpanel, tap } = window;
    const { eventData } = (eventCallbackData || {});
    const { userUuid } = this.props;

    try {
      // google analytics
      ga && ga('send', 'event', 'User', 'subscribed to pro');
      // mixpanel
      mixpanel && mixpanel.track && mixpanel.track('User subscribed to pro');
    }
    catch(e) {
      // do nothing, getting to the redirect is more important for ux
    }

    setTimeout(() => this.redirect(), 500); // give tracking requests half a second to send
  }

  redirect() {
    window.location = "/pro-welcome";
  }

  checkUserStatus(eventCallbackData) {
    if(this.checkUserStatusCount >= 5) {
      return this.onFinishedWaiting(eventCallbackData);
    }

    const { isProSubscriberUrl } = this.props;

    $.ajax({
      url: isProSubscriberUrl
    }) 
      .done((data) => {
        if(data.user.isPro) {
          return this.onFinishedWaiting(eventCallbackData);
        }
        this.checkUserStatusCount += 1;
        setTimeout(() => this.checkUserStatus(eventCallbackData), 2000);
      })
      .fail(() => {
        this.onFinishedWaiting(eventCallbackData);
      });
  }

  render() {
    const { loading } = this.state;

    if(loading) {
      return <LoadingOverlay className="heavy" />;
    }

    return null;
  }
}
