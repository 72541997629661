import React from "react";

import CollectionEditor from "./CollectionEditor";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";

export default class CollectionEditorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    };
  }

  deleteCollection() {
    if(!confirm('Are you sure? This will delete the collection and all of its sentences and progress. This action cannot be undone.')) {
      return null;
    }

    this.setState({ deleting: true });

    $.ajax({
      method: 'delete',
      url: this.props.collection.url
    })
      .done((data) => {
        this.modal.hide({ onHidden: () => this.props.onDelete(this.props.collection) });
      })
      .fail(() => {
        this.setState({ deleting: false });
        alert('Oh no! There was an error deleting the collection. Sorry about that. Please try again and let us know if you see this message again.');
      });
  }

  renderDeleteBtn() {
    const collection = this.props.collection;
    if(collection && collection.id) {
      return <button onClick={() => this.deleteCollection()} className="btn btn-sm btn-danger" style={{ float: 'left' }}><Icon name="trash" /></button>;
    }
  }

  renderFooter() {
    return (
      <>
        {this.renderDeleteBtn()}
        <ModalFooterCloseBtn text="Cancel" />
      </>
    );
  }

  renderProPromo() {
    const { proPromoCan, proPromoSecondary } = this.props;

    return (
      <ModalProPromo
        can={proPromoCan || "create, add sentences to, and play their own custom cloze-collections."}
        secondary={proPromoSecondary || "Take control of your learning. Get fluent faster."}
      />
    );
  }

  renderContent() {
    if(!this.props.isPro) {
      return this.renderProPromo();
    }
    if(this.state.deleting) {
      return <p>Deleting...</p>;
    }
    return this.renderCollectionEditor();
  }

  renderCollectionEditor() {
    const { collection, collectionsUrl, onCreate, onDelete, onHidden, onUpdate } = this.props;
    return (
      <CollectionEditor
        collection={collection}
        collectionsUrl={collectionsUrl}
        onCreate={(collection) => {
          onCreate(collection);
          this.modal.hide();
        }}
        onUpdate={(collection) => {
          onUpdate(collection);
          this.modal.hide();
        }}
      />
    );
  }

  render() {
    const { collection, isPro, onHidden } = this.props;

    return (
      <Modal
        footer={this.renderFooter()}
        onHidden={onHidden}
        ref={(el) => this.modal = el}
        size={isPro ? 'small' : ''}
        title={(collection && collection.id ? 'Edit' : 'Create') + ' Collection'}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
