import React from "react";

import AddSentenceToCollectionModal from "../AddSentenceToCollectionModal";

export default class ClozeTextAddToCollectionBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    };
  }

  componentDidMount() {
    $('body')
      .on('click', '#translation-popover .add-to-collection.btn', (e) => {
        e.preventDefault();

        const $sentence = $('.sentence.popovered');
        const text = $sentence.data('text');
        const selection = $('#translation-popover').find('.selection').text();
        const parts = text.split(selection, 2);

        $sentence.popover('destroy').removeClass('highlight');

        this.setState({
          collectionClozeSentence: { text: parts[0] + '{{' + selection + '}}' + parts[1] },
          modalVisible: true,
        });
      })
      .on('click', '.sentence .add-to-collection', (e) => {
        const cs = $(e.currentTarget).data('cloze-sentence');
        this.setState({
          collectionClozeSentence: { text: cs['preClozeStr'] + '{{' + cs['cloze'] + '}}' + cs['postClozeStr'] },
          modalVisible: true,
        });
      });
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    return (
      <AddSentenceToCollectionModal
        collectionClozeSentence={this.state.collectionClozeSentence}
        collectionsUrl={this.props.collectionsUrl}
        isPro={this.props.isPro}
        nextReviewByLevel={this.props.nextReviewByLevel}
        onHidden={() => this.setState({ modalVisible: false, collectionClozeSentence: null })}
        onSentenceAdded={() => this.addSentenceToCollectionModal.hide()}
        ref={(el) => this.addSentenceToCollectionModal = el}
      />
    );
  }

  render() {
    return (
      <>
        <button className="btn btn-success btn-block btn-sm joystix" onClick={() => this.setState({ modalVisible: true })}>
          <span className="glyphicon glyphicon-plus"></span> Add to Collection
        </button>
        {this.renderModal()}
      </>
    );
  }
}
