import React from "react";

import Icon from "./Icon";
import Loading from "./Loading";
import Modal from "./Modal";

export function ResourceLinksEditor({ isPro, onCancel, onUpdate, resourceLinksUrl }) {
  const [resourceLinks, setResourceLinks] = React.useState(null);
  const [sample, setSample] = React.useState("cats");
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    $.ajax({
      url: resourceLinksUrl
    })
      .done(({ resourceLinks }) => {
        setResourceLinks(resourceLinks);
      })
      .fail(() => {
        alert("Error!");
      });
  }, []);

  const save = () => {
    setSaving(true);
    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({ resource_links: resourceLinks }),
      method: "put",
      url: resourceLinksUrl
    })
      .done(({ resourceLinks }) => {
        onUpdate(resourceLinks);
      })
      .fail(() => {
        alert("Error!");
        setSaving(false);
      });
  };

  if(!resourceLinks) {
    return <Loading />;
  }

  return (
    <div>
      <p>Add links to your favorite resources for any word or text selected after answering. Each URL needs the word <code>QUERY</code>. <code>QUERY</code> will be replaced with the search term.</p>
      <p>For example, if you add <code>Google</code> with the URL <code>https://www.google.com/search?q=QUERY</code> and click the word "cats" after answering, you'll see a link to "Google" that will take you to <code>https://www.google.com/search?q=cats</code>.</p>
      {!isPro && (
        <p className="alert alert-success">
          Get <span className="joystix">Clozemaster Pro</span> to customize resource links and add your own!
          <br /><br />
          <a href="/pro" className="btn btn-lg btn-success btn-block joystix">Get Pro</a>
          </p>
      )}
      <p>
        Enter a sample query, then click "Try it" beneath each URL below.
        <input aria-label="sample query" className="form-control" disabled={!isPro || saving} onChange={(e) => setSample(e.target.value)} placeholder="Sample query" value={sample} />
      </p>
      <hr />
      {resourceLinks.map((link, i) => (
        <div className={(!link.name || !link.url.match(/QUERY/)) ? "has-error bg-danger" : null} key={i} style={{ marginLeft: -5, marginRight: -5, marginBottom: 5, padding: 5 }}>
          <input aria-label="name" className="form-control" disabled={!isPro || saving} onChange={(e) => setResourceLinks(resourceLinks.map((l, j) => i === j ? { ...l, name: e.target.value } : l))} placeholder="Name" style={{ marginBottom: 10 }} value={link.name} />
          <input aria-label="url" className="form-control" disabled={!isPro || saving} onChange={(e) => setResourceLinks(resourceLinks.map((l, j) => i === j ? { ...l, url: e.target.value } : l))} placeholder="URL" style={{ marginBottom: 10 }} value={link.url} />
          {!link.url.match(/QUERY/) && <small className="text-danger" style={{ marginBottom: 5, marginTop: -5 }}>URL missing QUERY. QUERY is replaced with the search query. For example https://www.google.com/search?q=QUERY.</small>}
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <small><a href={link.url.replace(/QUERY/, encodeURI(sample))} target="_blank" rel="noopener noreferrer">Try it <Icon name="new-window" /></a></small>
            <button aria-label="delete" className="btn btn-xs btn-default" disabled={!isPro || saving} onClick={() => setResourceLinks(resourceLinks.filter((l, j) => j !== i))}>Delete</button>
          </div>
        </div>
      ))}
      <hr />
      <button className="btn btn-success" disabled={!isPro || saving || !resourceLinks.length || resourceLinks.find((l) => !l.name || !l.url.match(/QUERY/))} onClick={save} style={{ marginRight: 10 }}>Save</button>
      <button className="btn btn-default" disabled={!isPro || saving} onClick={onCancel} style={{ marginRight: 10 }}>Cancel</button>
      <button className="btn btn-default" disabled={!isPro || saving} onClick={() => setResourceLinks([...resourceLinks, { name: "", url: "" }])} style={{ marginRight: 10 }}>Add Link</button>
    </div>
  );
}

export default function ResourceLinks({ onEditClick, query, resourceLinks, targetLanguageCode }) {
  const replaceWith = encodeURI(targetLanguageCode === "deu" ? query : query.toLowerCase());
  return (
    <ul className="links list-inline">
      {resourceLinks.sort((a, b) => a.name > b.name ? 1 : -1).map((link, i) => (
        <li className="link" key={link.url} style={{ paddingLeft: 0 }}>
          <a className="btn btn-link btn-xs" href={link.url.replace(/QUERY/, replaceWith)} rel="noopener noreferrer" target="_blank">{link.name} <Icon name="new-window" /></a>
        </li>
      ))}
      <li><button aria-label="edit links" className="btn btn-default btn-xs" id="edit-resource-links-btn" onClick={onEditClick}><Icon name="pencil" /></button></li>
    </ul>
  );
}
