import React from "react";

export default function ShortcutsList() {
  return (
    <div>
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>Enter</td>
            <td className="text-left">Submit answer, next sentence, next round</td>
          </tr>
          <tr>
            <td>1-4</td>
            <td className="text-left">Select multiple choice answer</td>
          </tr>
          <tr>
            <td>ctl+Space</td>
            <td className="text-left">Replay audio (when available)</td>
          </tr>
          <tr>
            <td>ctl+Enter</td>
            <td className="text-left">Record audio (for speaking)</td>
          </tr>
          <tr>
            <td>alt+a</td>
            <td className="text-left">Add current sentence to a collection  <small className="joystix"><em>Pro!</em></small></td>
          </tr>
          <tr>
            <td>alt+c</td>
            <td className="text-left">Open collection search <small className="joystix"><em>Pro!</em></small></td>
          </tr>
          <tr>
            <td>alt+d</td>
            <td className="text-left">Edit current sentence <small className="joystix"><em>Pro!</em></small></td>
          </tr>
          <tr>
            <td>alt+g</td>
            <td className="text-left">Toggle grammar after answering (when available)</td>
          </tr>
          <tr>
            <td>alt+e</td>
            <td className="text-left">Report current sentence error</td>
          </tr>
          <tr>
            <td>alt+f</td>
            <td className="text-left">Toggle favorite <small className="joystix"><em>Pro!</em></small></td>
          </tr>
          <tr>
            <td>alt+h</td>
            <td className="text-left">Use hint when playing text input</td>
          </tr>
          <tr>
            <td>alt+i</td>
            <td className="text-left">Ignore current sentence</td>
          </tr>
          <tr>
            <td>alt+k</td>
            <td className="text-left">Mark current sentence as 'Known' (100% Mastered and next review year 2100)</td>
          </tr>
          <tr>
            <td>alt+m</td>
            <td className="text-left">Set current sentence to '100% Mastered'</td>
          </tr>
          <tr>
            <td>alt+p</td>
            <td className="text-left">Switch current sentence to multiple choice</td>
          </tr>
          <tr>
            <td>alt+r</td>
            <td className="text-left">Reset current sentence to '0% Mastered'</td>
          </tr>
          <tr>
            <td>alt+s</td>
            <td className="text-left">Open sentence search <small className="joystix"><em>Pro!</em></small></td>
          </tr>
          <tr>
            <td>alt+t</td>
            <td className="text-left">Toggle translation</td>
          </tr>
          <tr>
            <td>alt+u</td>
            <td className="text-left">Open sentence discussion <small className="joystix"><em>Pro!</em></small></td>
          </tr>
          <tr>
            <td>alt+x</td>
            <td className="text-left">Toggle image (if available)</td>
          </tr>
          <tr>
            <td>alt+y</td>
            <td className="text-left">Open round history</td>
          </tr>
        </tbody>
      </table>
      <em><small>*Different browsers may handle shortcut keys differently. Please let us know if you notice any issues.</small></em>
    </div>
  );
}
