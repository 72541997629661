import React from "react";
import moment from 'moment';

import Modal from "./Modal";
import ModalFooterCloseBtn from "./ModalFooterCloseBtn";

const options = [
  ['Basic', 10],
  ['Beginner', 50],
  ['Casual', 100],
  ['Regular', 200],
  ['Serious', 500],
  ['Hardcore', 1000],
];

export default class DailyGoalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      custom: props.pointsPerDay && !options.map((o) => o[1]).includes(props.pointsPerDay),
      loading: false,
      pointsPerDay: props.pointsPerDay || 10,
      reset: false
    };
  }

  hide() {
    this.modal.hide();
  }

  renderGoalOptions() {
    const { custom, loading, pointsPerDay } = this.state;
    return [...options, ["Custom", "custom"]].map((o) => (
      <div className="radio" key={o}>
        <label>
          <input
            name="goal"
            type="radio"
            value={o[1]}
            checked={custom ? o[1] === "custom" : pointsPerDay === o[1]}
            onChange={(e) => this.setState(o[1] === "custom" ? { custom: true } : { custom: false, pointsPerDay: o[1] })}
          />
          <strong>{o[0]}</strong> {o[1] === "custom" ? " - set your own!" : `${o[1]} points per day`}
        </label>
      </div>
    ));
  }

  onSubmit(e) {
    e.preventDefault();

    const { pointsPerDay, reset } = this.state;

    if(!pointsPerDay) {
      return false;
    }

    this.setState({ loading: true });

    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        date: moment().format("YYYY-MM-DD"),
        points_per_day: pointsPerDay,
        reset
      }),
      method: "put",
      url: this.props.updateUrl
    })
      .done((data) => {
        const { pointsPerDay, streak } = data.dailyGoal;
        this.setState({ loading: false }, () => this.props.onUpdate({
          pointsPerDay,
          streak
        }));
      })
      .fail(() => {
        this.setState({ loading: false });
      });
  }

  renderCustomGoalInput() {
    const { custom, pointsPerDay } = this.state;

    if(!custom) {
      return null;
    }

    return (
      <div className="form-group">
        <label htmlFor="daily-goal-custom-text-input">Points per day</label>
        <input
          className="form-control"
          id="daily-goal-custom-text-input"
          onChange={(e) => this.setState({ pointsPerDay: e.target.value ? (Math.min(parseInt(e.target.value), 99999) || null) : null })}
          type="text"
          value={pointsPerDay}
        />
      </div>
    );
  }

  render() {
    const { loading, pointsPerDay, reset } = this.state;
    return (
      <Modal
        footer={<ModalFooterCloseBtn text="Cancel" disabled={loading} />}
        onHidden={() => this.props.onHidden()}
        ref={(el) => this.modal = el}
        size="small"
        title="Daily Goal"
      >
        <p>Selecting a daily goal will help you stay motivated{!!this.props.targetLanguageName && `while learning ${this.props.targetLanguageName}`}! You can change your goal at any time.</p>
        <form onSubmit={(e) => this.onSubmit(e)} style={{ marginBottom: 10 }}>
          <div className="goals">
            {this.renderGoalOptions()}
          </div>
          {this.renderCustomGoalInput()}
          {/*
          {this.props.streak > 0 && (
            <div className="form-group">
              <label className="reset">
                <input checked={reset} disabled={loading} type="checkbox" name="reset_daily_goal" id="reset_daily_goal" onChange={(e) => this.setState({ reset: e.target.checked })} />
                <span style={{ marginLeft: 10 }}>Reset daily goal streak</span>
              </label>
            </div>
          )}
          */}
          <button disabled={loading || !pointsPerDay} className="btn btn-success joystix btn-block">Save</button>
        </form>
      </Modal>
    );
  }
}
