import React from "react";

import NewCollectionForm from "./NewCollectionForm";

export default class CollectionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showNewCollectionForm: false
    };
  }

  componentDidMount() {
    this.loadCollections();
  }

  componentDidUpdate(prevProps, prevState) {
    const { onSelect } = this.props;
    const { collections, selectedId } = this.state;
    if(selectedId && selectedId !== prevState.selectedId && !!onSelect) {
      onSelect(collections.find((c) => c.id === selectedId));
    }
  }

  loadCollections() {
    const { collectionsUrl } = this.props;
    $.ajax({
      data: {
        filter: 'mine',
        order: 'updatedAt'
      },
      method: 'get',
      url: collectionsUrl
    })
      .done((data) => {
        const { initialSelectedCollectionId } = this.props;
        const { collections } = data;
        let { selectedId } = this.state;

        if(!selectedId && initialSelectedCollectionId && collections.filter((c) => c.id === initialSelectedCollectionId).length) {
          selectedId = initialSelectedCollectionId;
        }
        else if(!selectedId || !collections.filter((c) => c.id === selectedId).length) {
          selectedId = collections[0] && collections[0].id;
        }

        this.setState({
          collections,
          createCollectionUrl: collectionsUrl,
          loading: false,
          selectedId
        });
      })
      .fail(() => {
        alert("Oh no! There was an error loading collections. Sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  renderNewCollectionForm({ cancelable }) {
    return (
      <NewCollectionForm
        cancelable={cancelable}
        createUrl={this.state.createCollectionUrl}
        onCancel={() => this.setState({ showNewCollectionForm: false })}
        onCreate={(collection) => {
          this.setState({
            collections: JSON.parse(JSON.stringify(this.state.collections)).concat([collection]),
            selectedId: collection.id,
            showNewCollectionForm: false
          });
        }}
      />
    );
  }

  renderSelectControl() {
    const { collections, selectedId } = this.state;
    return (
      <>
        <label htmlFor="collection_id">Collection</label>
        <select className="form-control" name="collection_id" value={selectedId} onChange={(e) => this.setState({ selectedId: parseInt(e.target.value) })}>
          {collections.map((c, i) => (
            <option key={c.id} value={c.id}>{c.name}</option>
          ))}
        </select>
      </>
    );
  }

  render() {
    const { collections, loading, selectedId, showNewCollectionForm } = this.state;

    if(loading) {
      return <div>Loading...</div>;
    }

    if(!collections.length) {
      return this.renderNewCollectionForm({ cancelable: false });
    }

    if(showNewCollectionForm) {
      return this.renderNewCollectionForm({ cancelable: true });
    }

    return (
      <div>
        {this.renderSelectControl()}
        <div className="text-right">
          <button className="btn btn-link" onClick={(e) => { e.preventDefault(); this.setState({ showNewCollectionForm: !showNewCollectionForm }); }}>New Collection</button>
        </div>
      </div>
    );
  }
}
