import React from "react";

import Ad from "../Ad";
import Icon from "../Icon";

export default class IntraRoundAd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: 3
    };
  }

  componentDidMount() {
    const { onNextRoundable } = this.props;
    this.interval = setInterval(() => {
      this.setState({ timer: this.state.timer - 1 }, () => {
        if(this.state.timer <= 0) {
          clearInterval(this.interval);
          onNextRoundable && onNextRoundable();
        }
      });
    }, 1000);
  }

  renderTimer() {
    let content = (
      <button className="btn btn-success joystix" onClick={this.props.nextRound}>Continue <Icon name="chevron-right" /></button>
    );

    if(this.state.timer > 0) {
      content = (
        <>
          Continue in {this.state.timer}...
        </>
      );
    }
    
    return (
      <p className="intra-round-vert-timer">
        {content}
      </p>
    );
  }

  renderAd() {
    const { ad, onLinkClick } = this.props;
    return (
      <Ad
        ad={ad}
        className="intra-round-vert-content"
        onCopyLinkClick={onLinkClick}
        onCallToActionClick={onLinkClick}
        onLinkClick={onLinkClick}
        onRemoveAdsClick={onLinkClick}
      />
    );
  }

  render() {
    return (
      <div className="text-center intra-round-vert">
        <p>Advertisement</p>
        {this.renderAd()}
        {this.renderTimer()}
      </div>
    );
  }
}
