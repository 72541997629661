import React from "react";
import ReactDOM from "react-dom";
import FocusTrap from "focus-trap-react";

export default class Slideout extends React.Component {
  componentDidMount() {
    this.$slideout = $(this.slideout);
    this.$backdrop = $(this.backdrop);

    const width = this.$slideout.width();
    this.$slideout.css({ right: -width, width });

    this.show();
  }

  show() {
    const { onShown } = this.props;

    this.$slideout.animate({
      right: 0,
      opacity: 1
    }, () => onShown && onShown());

    this.$backdrop
      .css({ opacity: 0 })
      .show()
      .animate({ opacity: 0.5 });
  }

  hide() {
    const { onHidden } = this.props;
    const width = this.$slideout.width();
    this.$backdrop.animate({ opacity: 0 }, () => this.$backdrop.hide());
    this.$slideout.animate({
      opacity: 0,
      right: -width
    }, () => onHidden && onHidden());
  }

  onBackdropClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.hide();
  }

  renderBackdrop() {
    return (
      <div ref={(el) => this.backdrop = el} onClick={(e) => this.onBackdropClick(e)} style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1055, backgroundColor: '#000', opacity: 0 }}></div>
    );
  }

  renderContent() {
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {this.props.header !== false && (
          <div style={{ background: '#464646', color: '#fff', height: 40, padding: '10px 15px' }}>
            <span className="joystix">{this.props.heading}</span>
            <button type="button" className="close" onClick={() => this.hide()} aria-label="Close" style={{ color: "#fff" }}><span aria-hidden="true">×</span></button>
          </div>
        )}
        <div style={{ display: "flex", flex: 1, flexDirection: "column", padding: 15 }}>
          {this.props.children}
        </div>
        {this.props.footer}
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(
      (
        <>
          {this.renderBackdrop()}
          <div className={"slideout " + this.props.className} ref={(el) => this.slideout = el}>
            {this.renderContent()}
          </div>
        </>
      ),
      document.querySelector('body')
    );
  }
}
