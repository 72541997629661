import React from "react";

import { v4 as uuidv4 } from "uuid";

import ClozeSentenceSearchModal from "./ClozeSentenceSearchModal";
import SelectionPopover from "./SelectionPopover";

export default class SelectionPopoverableLink extends React.Component {
  constructor(props) {
    super(props);

    this.id = uuidv4();

    this.state = {
      popoverVisible: false
    };
  }

  componentDidMount() {
    $("body").on("click.selection-popoverable-link-" + this.id, (e) => {
      if(this.state.popoverVisible && !$(e.target).parents(".selection-popover").length) {
        this.setState({ popoverVisible: false });
      }
    });
  }

  componentWillUnmount() {
    $("body").off("click.selection-popoverable-link-" + this.id);
  }

  onLinkClick(e) {
    e.preventDefault();
    this.setState({ popoverVisible: true });
  }

  onAddToCollectionClick() {
    // TODO!
  }

  onSearchSentencesClick() {
    this.setState({
      clozeSentenceSearchModalVisible: true,
      popoverVisible: false
    });
  }

  renderSentenceSearch() {
    if(!this.state.clozeSentenceSearchModalVisible) {
      return null;
    }

    const {
      clozeSentencesUrl,
      collectionsUrl,
      isPlayingLanguagePairing,
      isPro,
      nextReviewByLevel,
      text
    } = this.props;

    return (
      <ClozeSentenceSearchModal 
        addToCollectionDisabled={!isPlayingLanguagePairing}
        collectionsUrl={collectionsUrl}
        isPro={isPro}
        nextReviewByLevel={nextReviewByLevel}
        onHidden={() => {
          this.setState({ clozeSentenceSearchModalVisible: false });
        }}
        query={text}
        url={clozeSentencesUrl}
      />
    );

  }

  onClose() {
    this.setState({ popoverVisible: false });
  }

  render() {
    const {
      baseLanguageIso,
      baseLanguageCode,
      className,
      isPro,
      style,
      targetLanguageCode,
      targetLanguageEnglishName,
      targetLanguageIso,
      text,
      userSelectionTranslationsUrl
    } = this.props;

    const { popoverVisible } = this.state;

    return (
      <SelectionPopover
        baseLanguageIso={baseLanguageIso}
        baseLanguageCode={baseLanguageCode}
        isPro={isPro}
        onClose={() => this.onClose()}
        /*onAddToCollectionClick={() => this.onAddToCollectionClick()}*/
        onSearchSentencesClick={() => this.onSearchSentencesClick()}
        selection={text}
        targetLanguageCode={targetLanguageCode}
        targetLanguageEnglishName={targetLanguageEnglishName}
        targetLanguageIso={targetLanguageIso}
        userSelectionTranslationsUrl={userSelectionTranslationsUrl}
        visible={popoverVisible}
      >
        <a className={className} href="#" onClick={(e) => this.onLinkClick(e)} role="button" style={style}>{text}</a>
        {this.renderSentenceSearch()}
      </SelectionPopover>
    );
  }
}
