import React from "react";

import CollectionPanel from "./CollectionPanel";
import FastTrackStatsModal from "./FastTrackStatsModal";
import Icon from "../Icon";

export default function FastTrackPanel(props) {
  const [statsModalVisible, setStatsModalVisible] = React.useState(false);

  return (
    <>
      <CollectionPanel
        extraControls={(
          <>
            <button className="btn btn-xs btn-default stats-btn" onClick={() => setStatsModalVisible(true)}><Icon name="stats" /></button>
            <button className="btn btn-sm btn-default stats-btn" onClick={() => setStatsModalVisible(true)}><Icon name="stats" /></button>
          </>
        )}
        {...props}
      />
      {statsModalVisible && (
        <FastTrackStatsModal
          collection={props.collection}
          onHidden={() => setStatsModalVisible(false)}
        />
      )}
    </>
  );
}
