import React from "react";

import { getLocalStorageBoolean, setLocalStorageBoolean } from "../../helpers";

import CollectionTableRow from "./CollectionTableRow";
import Icon from "../Icon";

export default class FrequencyCollectionsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      frequencyCollectionsVisible: getLocalStorageBoolean('dashboardFrequencyCollectionsVisible', false)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.frequencyCollectionsVisible !== prevState.frequencyCollectionsVisible) {
      setLocalStorageBoolean('dashboardFrequencyCollectionsVisible', this.state.frequencyCollectionsVisible);
    }
  }

  renderCollections() {
    if(!this.state.frequencyCollectionsVisible) {
      return null;
    }

    const { progressPerChunk, useProgressChunks } = this.props;

    const rows = this.props.collections.sort((a, b) => a.presentationOrder - b.presentationOrder).map((c) => (
      <CollectionTableRow
        collection={c}
        isSignedIn={this.props.isSignedIn}
        key={c.id}
        manage={this.props.manage}
        play={this.props.play}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    ));

    return (
      <div>
        <table className="table table-striped text-center">
          <tbody>
            {rows}
          </tbody>
        </table>
        <p>
          <span style={{ fontSize: '1.25em', fontWeight: 'bold' }}>
            Or play sentences from all these collections selected randomly. <button className="btn btn-success joystix play-all" disabled={!this.props.isSignedIn} onClick={() => this.props.play({ name: 'All', playWebUrl: this.props.playLanguagePairingWebUrl }, 'frequency_collections')}>Play <Icon name="chevron-right" /></button>
          </span>
          {!this.props.isSignedIn && <span className="joystix" style={{ marginLeft: 4 }}>Sign up!</span>}
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="panel panel-default frequency-collections">
        <div className="panel-body">
          <div className="pull-right" style={{ marginTop: 10 }}>
            <i className="fa fa-list-ol fa-5x" aria-hidden="true"></i>
          </div>
          <h2>
            Most Common Words
            <button
              className="btn btn-xs btn-link closeable"
              data-content="Get more practice at your level. Each word in each sentence is matched against a frequency list (a list of words ordered by how often they're likely to occur). The most difficult (least common) word is then selected as the missing word. The sentences are then grouped by their difficulty. Each missing word occurs a maximum of 10 times per collection, so you get plenty of practice in context."
              data-title="Most Common Words"
              data-toggle="popover"
              data-trigger="focus"
              style={{ fontSize: '65%', marginLeft: 8, textDecoration: 'none' }}
            >
              <span className="glyphicon glyphicon-question-sign"></span>
            </button>
            <span style={{ display: 'block', marginTop: 5 }}>
              <button className="btn btn-default toggle" onClick={() => this.setState({ frequencyCollectionsVisible: !this.state.frequencyCollectionsVisible })}>
                <Icon name={'chevron-' + (this.state.frequencyCollectionsVisible ? 'up' : 'down')} />
              </button>
              <small style={{ marginLeft: 8 }}>Play sentences grouped by difficulty.</small>
            </span>
          </h2>
          {this.renderCollections()}
        </div>
      </div>
    );
  }
}

