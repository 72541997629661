import React from "react";

import FadeOut from "../FadeOut";

export default class Incorrect extends React.Component {
  render() {
    return (
      <FadeOut delay={3000}>
        <div style={{ position: 'relative' }}>
          <div style={{ color: '#e00', fontSize: '3em', position: 'absolute', textAlign: 'center', width: '100%' }}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </FadeOut>
    );
  }
}
