import React from "react";
import DailyReminderModal from "./DailyReminderModal";

export default class DailyReminderPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dailyReminderEmail: props.dailyReminderEmail,
      modalVisible: false
    };
  }

  renderContent() {
    const { isSignedIn, timeZone } = this.props;
    const { dailyReminderEmail } = this.state;

    if(dailyReminderEmail) {
      return (
        <div className="status-text reminder-set">
          Daily reminder email is set for {dailyReminderEmail} {timeZone}.
          <button className="btn btn-link" onClick={() => this.setState({ modalVisible: true })}>Update</button>
        </div>
      );
    }

    return (
      <div className="status-text">
        <div style={{ fontSize: '1.25em', fontWeight: 'bold' }}>Keep your streak alive!</div>
        <button className="btn btn-link btn-lg wrap" disabled={!isSignedIn} style={{ fontWeight: 'bold' }} onClick={() => this.setState({ modalVisible: true })}>Set a daily reminder email</button>
      </div>
    );
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    return (
      <DailyReminderModal
        dailyReminderEmail={this.state.dailyReminderEmail}
        onHidden={() => this.setState({ modalVisible: false })}
        onUpdate={(dailyReminderEmail) => this.setState({ dailyReminderEmail }, () => this.modal.hide())}
        ref={(el) => this.modal = el}
        updateUrl={this.props.updateUrl}
      />
    );
  }

  render() {
    return (
      <div>
        <div className="panel panel-default text-center">
          <div className="panel-body" style={{ paddingBottom: 5 }}>
            {this.renderContent()}
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}
