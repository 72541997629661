import React from "react";

export default function Ad(props) {
  const { ad, className } = props;
  const {
    callToAction,
    clickUrl,
    copy,
    id,
    imageUrl,
    isCopyAd,
    removeAdsClickUrl,
    url
  } = ad;

  const isClozemasterHref = (href) => !!(href.match("https://www.clozemaster.com") || href.match(/^\//));

  const onRemoveAdsClick = (e) => {
    trackRemoveAdsClick(e);
    !!props.onRemoveAdsClick && props.onRemoveAdsClick();
  };

  const onCopyLinkClick = (e) => {
    trackClick(e);
    !!props.onCopyLinkClick && props.onCopyLinkClick();
  };

  const onCallToActionClick = (e) => {
    trackClick(e);
    !!props.onCallToActionClick && props.onCallToActionClick();
  };

  const onLinkClick = (e) => {
    trackClick(e);
    !!props.onLinkClick && props.onLinkClick();
  };

  const trackRemoveAdsClick = (e) => {
    e.preventDefault();

    const href = e.currentTarget.getAttribute("href");
    const go = () => window.location.href = href;

    $.ajax({
      data: { id: ad.id },
      method: "post",
      url: removeAdsClickUrl
    })
      .done(go)
      .fail(go);

    setTimeout(go, 300);
  };

  const trackClick = (e) => {
    let go = null;
    const href = e.currentTarget.getAttribute("href");

    // if not a clozemaster href the link should open in a new tab/window
    if(isClozemasterHref(href)) {
      e.preventDefault();
      go = () => window.location.href = href;
      setTimeout(go, 300);
    }

    $.ajax({
      data: { id: ad.id },
      method: "post",
      url: clickUrl
    })
      .done(go)
      .fail(go);
  }


  let content = null;
  if(isCopyAd) {
    content = (
      <span
        className="content"
        ref={(el) => {
          $(el)
            .off(".ad")
            .on("click.ad", "a", onCopyLinkClick);
          
          $(el).find("a").each((i, a) => {
            if(!isClozemasterHref(a.getAttribute("href"))) {
              $(a)
                .prop("target", "_blank")
                .prop("rel", "noopener");
            }
          });
        }}
      >
        <span className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
        {!!callToAction && (
          <a className="btn btn-success joystix" href={url} onClick={onCallToActionClick}>
            {callToAction}
          </a>
        )}
      </span>
    );
  }
  else {
    content = (
      <a href={url} onClick={onLinkClick} {...(isClozemasterHref(url) ? {} : { rel: "noopener", target: "_blank" })}>
        <img src={imageUrl} style={{ maxWidth: "100%" }} />
      </a>
    );
  }

  // vert as in advert as in advertisement
  return (
    <p className={["vert", className, (isCopyAd ? "copy-vert" : "")].join(" ")} style={{ textAlign: "center" }}>
      {content}
      <small style={{ display: "block", marginTop: 5 }}>
        <a href="/pro" onClick={onRemoveAdsClick}>
          🚀 Remove ads
        </a>
      </small>
    </p>
  );
};
