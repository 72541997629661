import React from "react";

import TextareaAutosize from "react-textarea-autosize";

import Block from "./Block";

export default class TextBlock extends React.Component {
  renderInput() {
    const { item, onChange, textarea } = this.props;

    if(textarea) {
      return (
        <TextareaAutosize
          className={`form-control ${item.type}`}
          onChange={onChange}
          value={item.text}
        />
      );
    }

    return (
      <input
        className={`form-control ${item.type}`}
        onChange={onChange}
        type="text"
        value={item.text}
      />
    );
  }

  render() {
    const { item, onDelete, renderErrorMessage } = this.props;
    const { error } = item;
    const wrapperClass = error ? "form-group has-error" : "form-group";

    return (
      <Block
        item={item}
        legend={item.type.charAt(0).toUpperCase() + item.type.slice(1)}
        onDelete={onDelete}
      >
        <div className={wrapperClass}>
          {this.renderInput()}
          {renderErrorMessage(error)}
        </div>
      </Block>
    );
  }
}
