import React from "react";

import Icon from "./Icon";

export default class PlaySystemTtsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ttsAvailable: false
    };
  }

  componentDidMount() {
    this.initTts();
  }

  componentWillUnmount() {
    clearTimeout(this.ttsTimeout);
  }

  initTts() {
    // run check to load voices
    this.ttsTimeoutCount = 0;
    this.checkForTts();
  }

  checkForTts() {
    const ttsAvailable = this.isSystemTtsAvailable();
    this.setState({ ttsAvailable });
    this.ttsTimeoutCount += 1;
    if(!ttsAvailable && this.ttsTimeoutCount < 5) {
      this.ttsTimeout = setTimeout(() => this.checkForTts(), 1000);
    }
  }

  isSystemTtsAvailable() {
    const { targetLanguageIso, targetLanguageCode } = this.props;
    return window.clozemaster.systemTtsAvailable(targetLanguageIso, targetLanguageCode);
  }

  getSystemTtsVoices() {
    const { targetLanguageIso, targetLanguageCode } = this.props;
    return window.clozemaster.getSystemTtsVoices(targetLanguageIso, targetLanguageCode);
  }

  playTts() {
    const { text } = this.props;
    const voices = this.getSystemTtsVoices();
    const voice = voices[0]; // voices[Math.floor(Math.random()*voices.length)];
    const u = new SpeechSynthesisUtterance(text);
    u.voice = voice;
    u.lang = voice.lang;
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(u);
  }

  render() {
    const { ttsAvailable } = this.state;

    if(!ttsAvailable) {
      return null;
    }

    const { className, style } = this.props;

    return (
      <button className={className || "btn btn-default btn-xs"} onClick={() => this.playTts()} style={style}><Icon name="volume-up" /></button>
    );
  }
}
