import React from "react";

import ClozeSentenceEntry from "../mods/ClozeSentenceEntry";
import CollectionClozeSentenceEditor from "../mods/CollectionClozeSentenceEditor";
import CollectionClozeSentenceTts from "../mods/CollectionClozeSentenceTts";
import Loading from "../Loading";
import Tokens from "./QuickMod/Tokens";
import TokensEditor from "../mods/TokensEditor";

export default class QuickMod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      loading: true,
      sentences: [],
      togglingModerated: false,
      total: 0,
      url: props.collectionClozeSentencesUrl
    };
  }

  componentDidMount() {
    this.loadSentences();

    $(window).on("keydown", (e) => {
      if($(".modal").is(":visible") || this.state.togglingModerated) {
        return true;
      }

      // right arrow, d = 68
      if(e.which === 39 || e.which === 68) {
        return this.nextSentence();
      }

      // left arrow, a = 65
      if(e.which === 37 || e.which === 65) {
        return this.prevSentence();
      }

      const { index, sentences } = this.state;

      if(!sentences[index]) {
        return false;
      }

      // e = 69
      if(e.which === 69) {
        return this.showSentenceEditor();
      }

      // s = 83
      if(e.which === 83) {
        return this.showTtsEditor();
      }

      // t = 84
      if(e.which === 84) {
        return this.showTokensEditor();
      }

      if(e.which >= 49 && e.which <= 57) {
        const audio = $("audio").get(e.which - 49);
        audio && audio.play();
        return true;
      }

      // r = 82
      if(e.which === 82) {
        return this.toggleModerated();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { index, loading, moreSentencesAvailable, sentences } = this.state;

    if(!loading && !sentences[index] && moreSentencesAvailable) {
      this.loadSentences();
    }
  }

  loadSentences() {
    const { url } = this.state;

    this.setState({ loading: true });

    $.ajax({
      data: {
        include_stats: true,
        include_tts: true,
        order: "num_incorrect_desc",
        per_page: 100,
        scope: "unmoderated"
      },
      url
    })
      .done((data) => {
        if(!data.collectionClozeSentences.length) {
          this.setState({
            moreSentencesAvailable: false,
            loading: false
          });
          return false;
        }

        this.setState({
          moreSentencesAvailable: !!data.relativeNextPageUrl,
          loading: false,
          sentences: this.state.sentences.concat(data.collectionClozeSentences),
          url: data.relativeNextPageUrl
        });
      })
      .fail(() => {
        alert("Oh no! Something's gone terribly wrong. Please refresh the page and let someone know if you see this message again.");
      });
  }

  nextSentence() {
    const { index, sentences } = this.state;
    this.setState({ index: Math.min(index + 1, sentences.length) });
  }

  prevSentence() {
    const { index, sentences } = this.state;
    if(index <= 0) {
      return false;
    }
    this.setState({ index: index - 1 });
  }

  renderTts() {
    const { index, sentences } = this.state;
    const currentSentence = sentences[index];
    return (
      <ul>
        {currentSentence.tts.map((t, i) => (
          <li key={t["voice_id"]}>
            <strong>{t["voice_id"]}</strong> <small>({t["service"]})</small> ({i + 1})
            <br />
            <audio controls preload="none" src={t["audioUrl"]} style={{ maxWidth: "100%" }} />
          </li>
        ))}
      </ul>
    );
  }

  renderTokens() {
    const { index, sentences } = this.state;
    const currentSentence = sentences[index];
    return (
      <Tokens key={currentSentence.id} url={currentSentence.tokensUrl} />
    );
  }

  showSentenceEditor() {
    this.setState({ sentenceEditorVisible: true });
  }

  showTtsEditor() {
    this.setState({ ttsEditorVisible: true });
  }

  showTokensEditor() {
    this.setState({ tokensEditorVisible: true });
  }

  toggleModerated() {
    this.setState({ togglingModerated: true });
    const { index, sentences } = this.state;
    const currentSentence = sentences[index];
    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        moderated: !currentSentence.moderated
      }),
      method: "patch",
      url: currentSentence.url
    })
      .done((data) => {
        this.setState({
          sentences: sentences.map((s) => s.id === currentSentence.id ? Object.assign({}, currentSentence, data.collectionClozeSentence) : s)
        });
      })
      .fail(() => alert("Oh no! There was an error updating the sentence. Sorry about that. Please try again and let us know if you see this message again."))
      .always(() => this.setState({ togglingModerated: false }));
  }

  renderControls() {
    const { index, sentences, togglingModerated } = this.state;
    const currentSentence = sentences[index];

    return (
      <ul className="list-inline">
        <li><button className="btn btn-default" disabled={togglingModerated} onClick={this.showSentenceEditor.bind(this)}>Edit</button> <span>(e)</span></li>
        <li><button className="btn btn-default" disabled={togglingModerated} onClick={this.showTtsEditor.bind(this)}>TTS</button> <span>(s)</span></li>
        <li><button className="btn btn-default" disabled={togglingModerated} onClick={this.showTokensEditor.bind(this)}>Tokens</button> <span>(t)</span></li>
        <li><button className="btn btn-default" disabled={!(index > 0) || togglingModerated} onClick={() => this.prevSentence()}>Back</button> <span>(⬅️ or a)</span></li>
        <li><button className="btn btn-default" disabled={togglingModerated} onClick={() => this.nextSentence()}>Next</button> <span>(➡️ or d)</span></li>
        <li><button className={`btn btn-${currentSentence.moderated ? "success" : "default"} btn-lg`} disabled={togglingModerated} onClick={() => this.toggleModerated()}>{currentSentence.moderated ? "✅ Moderated 🎉" : "➖ Moderated"}</button> <span>(r)</span></li>
      </ul>
    );
  }

  renderSentenceEditor() {
    if(!this.state.sentenceEditorVisible) {
      return false;
    }

    const { index, sentences } = this.state;
    const collectionClozeSentence = sentences[index];

    return (
      <CollectionClozeSentenceEditor
        collectionClozeSentence={collectionClozeSentence}
        collectionClozeSentenceUrl={collectionClozeSentence.url}
        clozeSentenceUrl={collectionClozeSentence.clozeSentenceUrl}
        onHidden={() => this.setState({ sentenceEditorVisible: false })}
        onUpdate={(attrs) => {
          this.setState({
            sentences: sentences.map((s) => s.id === collectionClozeSentence.id ? Object.assign(collectionClozeSentence, attrs) : s)
          });
        }}
      />
    );
  }

  renderTtsEditor() {
    if(!this.state.ttsEditorVisible) {
      return false;
    }

    const { index, sentences } = this.state;
    const collectionClozeSentence = sentences[index];

    return (
      <CollectionClozeSentenceTts
        clozeSentenceTtsJobUrl={collectionClozeSentence.clozeSentenceTtsJobUrl}
        clozeSentenceUrl={collectionClozeSentence.clozeSentenceUrl}
        collectionClozeSentenceTtsJobUrl={collectionClozeSentence.collectionClozeSentenceTtsJobUrl}
        collectionClozeSentenceUrl={collectionClozeSentence.url}
        onHidden={() => this.setState({ ttsEditorVisible: false })}
      />
    );
  }

  renderTokensEditor() {
    if(!this.state.tokensEditorVisible) {
      return null;
    }

    const { index, sentences } = this.state;
    const collectionClozeSentence = sentences[index];

    return (
      <TokensEditor
        clozeText={collectionClozeSentence.text}
        onHidden={({ tokensCount }) => this.setState({ tokensEditorVisible: false })}
        tokensUrl={collectionClozeSentence.tokensUrl}
      />
    );
  }

  render() {
    const { index, loading, sentences } = this.state;

    if(loading && !sentences[index]) {
      return <Loading />;
    }
    else if(!sentences[index]) {
      return (
        <>
          <p>No more sentences! 🎉</p>
          <button className="btn btn-default" onClick={() => this.prevSentence()}>Back</button>
        </>
      );
    }

    const currentSentence = sentences[index];
    console.log(currentSentence);
    const { languagePairing } = this.props;
    const { baseLanguageIso, targetLanguageIso } = (languagePairing || {});

    return (
      <>
        <hr />
        <ul className="list-inline">
          <li>Index: {index + 1}</li>
          <li>Moderated this session: {sentences.filter((s) => s.moderated).length}</li>
        </ul>
        {this.renderControls()}
        <div style={{ marginTop: 30 }}>
          <ClozeSentenceEntry
            baseLanguageIso={baseLanguageIso}
            clozeSentence={currentSentence}
            clozeSentenceStyle={{ fontSize: "1.5em" }}
            includeTranslationLinks={!!baseLanguageIso && !!targetLanguageIso}
            targetLanguageIso={targetLanguageIso}
          />
          <br />
          {this.renderTts()}
          <ul className="list-inline">
            <li>{currentSentence.numPlayed} 🎮</li>
            <li>{currentSentence.numIncorrect} ❌</li>
            <li>{currentSentence.numIgnored} 🚫</li>
            <li>Tokens: {currentSentence.tokensCount}</li>
          </ul>
          {/*
          <div className="col-xs-12 col-sm-4">
            <h2>Tokens</h2>
            {this.renderTokens()}
            <p>
              <button className="btn btn-default">Tokens</button> <span>(k)</span>
            </p>
          </div>
          */}
        </div>
        {this.renderSentenceEditor()}
        {this.renderTtsEditor()}
        {this.renderTokensEditor()}
      </>
    );
  }
}
