import React from "react";

import { ReactSortable } from "react-sortablejs";
import { v4 as uuidv4 } from "uuid";

import { shuffleArray } from "../../helpers";

import Block from "./Block";
import SentenceBlock from "./SentenceBlock";
import SentenceSearchModal from "./SentenceSearchModal";

export default class SentencesBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sentenceSearchModalVisible: false
    };
  }

  renderSentenceSearchModal() {
    if(!this.state.sentenceSearchModalVisible) {
      return null;
    }

    const { item, onSentenceAdd, onSentenceDelete, sentenceSearchUrl } = this.props;

    return (
      <SentenceSearchModal
        onHidden={() => this.setState({ sentenceSearchModalVisible: false })}
        onSentenceAdd={onSentenceAdd}
        onSentenceDelete={onSentenceDelete}
        sentences={item.sentences}
        url={sentenceSearchUrl}
      />
    );
  }

  render() {
    const { item, onSentenceAdd, onDelete, onSentenceChange, onSentenceDelete, onSentencesChange } = this.props;
    return (
      <Block
        item={item}
        legend="Sentences"
        onDelete={onDelete}
      >
        <ReactSortable
          handle="legend"
          list={item.sentences}
          setList={onSentencesChange}
        >
          {item.sentences.map((sentence, i) => {
            return (
              <SentenceBlock
                index={i}
                key={sentence.id}
                onChange={onSentenceChange}
                onDelete={onSentenceDelete}
                sentence={sentence}
              />
            );
          })}
        </ReactSortable>
        <div>
          <button
            className="btn btn-default sentence-search"
            onClick={() => this.setState({ sentenceSearchModalVisible: true })}
          >
            Sentence Search
          </button>
          <button
            className="btn btn-default"
            onClick={() => onSentenceAdd({ id: uuidv4(), text: "", translation: "" })}
            style={{ marginLeft: 10 }}
          >
            Add Sentence
          </button>
          <button
            className="btn btn-default"
            onClick={() => onSentencesChange(shuffleArray(item.sentences))}
            style={{ marginLeft: 10 }}
          >
            Shuffle Sentences
          </button>
        </div>
        {this.renderSentenceSearchModal()}
      </Block>
    );
  }
}
