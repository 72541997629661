import React from "react";

import AddSentenceToCollectionModal from "./AddSentenceToCollectionModal";

export default class AddSentenceToCollectionBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    };
  }
  
  hideModal() {
    this.addSentenceToCollectionModal.hide();
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    const { collectionClozeSentence, collectionsUrl, copyable, isPro, nextReviewByLevel } = this.props;

    return (
      <AddSentenceToCollectionModal
        collectionClozeSentence={collectionClozeSentence}
        collectionsUrl={collectionsUrl}
        copyable={copyable}
        isPro={isPro}
        nextReviewByLevel={nextReviewByLevel}
        onHidden={() => this.setState({ modalVisible: false })}
        onSentenceAdded={() => this.hideModal()}
        ref={(el) => this.addSentenceToCollectionModal = el}
      />
    );
  }

  render() {
    const { className, style, text, title } = this.props;

    return (
      <>
        <button className={className || "btn btn-success"} onClick={() => this.setState({ modalVisible: true })} style={style} title={title}>
          <span className="glyphicon glyphicon-plus"></span>{text === false ? "" : (text || " Add to Collection")}
        </button>
        {this.renderModal()}
      </>
    );
  }
}
