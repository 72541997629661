import React from "react";

import TextareaAutosize from "react-textarea-autosize";

export default class TTSVoicesTableRow extends React.Component {
  constructor(props) {
    super(props);
    
    const voice = props.voice || {};

    this.state = {
      languageId: voice.languageId || "",
      sampleAudioText: voice.sampleAudioText,
      updating: false
    };
  }

  componentDidMount() {
    const { voice } = this.props;

    if(this.shouldCheckSampleAudioStatus(voice.sampleAudioStatus)) {
      this.checkSampleAudioStatus();
    }
  }

  shouldCheckSampleAudioStatus(status) {
    return status === "sample_audio_queued" || status === "sample_audio_working";
  }

  checkSampleAudioStatus() {
    const { onUpdate, voice } = this.props;

    this.setState({ updating: true });

    setTimeout(() => {
      $.ajax({
        url: voice.url
      })
        .done((data) => {
          const { ttsVoice } = data;

          onUpdate && onUpdate(ttsVoice);

          if(this.shouldCheckSampleAudioStatus(ttsVoice.sampleAudioStatus)) {
            this.checkSampleAudioStatus();
          }
          else {
            this.setState({ updating: false });
          }
        })
        .fail(() => {
          alert("Error checking sample audio status.");
          this.setState({ updating: false });
        });
    }, 3000);
  }

  update(attrs) {
    this.setState({ updating: true });

    const { onUpdate, voice } = this.props;
    console.log(voice);

    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({ tts_voice: attrs }),
      dataType: "json",
      method: "put",
      url: voice.url
    })
      .done((data) => {
        if(data.errors) {
          alert(data.errors);
          this.setState({ updating: false });
        }
        else {
          const { ttsVoice } = data;

          onUpdate && onUpdate(ttsVoice);

          if(this.shouldCheckSampleAudioStatus(ttsVoice.sampleAudioStatus)) {
            this.checkSampleAudioStatus();
          }
          else {
            this.setState({ updating: false });
          }
        }
      })
      .fail(() => {
        alert("Something's gone awry! Failed to update.");
        this.setState({ updating: false });
      });
  }

  render() {
    const { languages, voice } = this.props;
    const { sampleAudioText, updating } = this.state;

    return (
      <tr className={"voice " + (voice.languageId ? (voice.active ? "success" : "warning") : "danger")}>
        <td><input disabled={updating} type="checkbox" onChange={(e) => this.update({ active: e.target.checked })} checked={voice.active} /></td>
        <td>{voice.ttsProvider}</td>
        <td>{voice.languageCode}</td>
        <td>{voice.name}</td>
        <td>
          {!!voice.sampleAudioUrl && <audio controls preload="none" src={voice.sampleAudioUrl} />}
          <div className="status"><small>Status: {voice.sampleAudioStatus}</small></div>
          {!!voice.sampleAudioErrorMessage && <div className="alert alert-danger">{voice.sampleAudioErrorMessage}</div>}
          {!!voice.sampleAudioErrorBacktrace && <pre style={{ maxHeight: 200, overflow: "scroll" }}>{JSON.stringify(voice.sampleAudioErrorBacktrace, null, 2)}</pre>}
          <div>
            <TextareaAutosize disabled={updating} name="sample_audio_text" onChange={(e) => this.setState({ sampleAudioText: e.target.value })} style={{ marginRight: 5 }} value={sampleAudioText} />
            <button className="btn btn-xs btn-default submit" disabled={updating} onClick={() => this.update({ sample_audio_text: sampleAudioText })} style={{ verticalAlign: "top" }}>Save</button>
          </div>
        </td>
        <td><select onChange={(e) => this.update({ language_id: e.target.value })} value={voice.languageId || ""}><option></option>{languages.map((l) => <option key={l.id} value={l.id}>{l.englishName}</option>)}</select></td>
        <td>{voice.displayName}</td>
        <td>{voice.gender}</td>
      </tr>
    );
  }
}
