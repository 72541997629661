import React from "react";

import { splitTextOnCloze } from "../helpers";

import CollectionEditorModal from "./Dashboard/CollectionEditorModal";
import Icon from "./Icon";
import Loading from "./Loading";
import MyTeachingCollection from "./TeachingDashboard/MyTeachingCollection";
import Pagination from "./Pagination";
import SharedCollectionsModal from "./Dashboard/SharedCollectionsModal";

export default class TeachingDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cloning: false,
      collections: [],
      collectionEditorCollection: null,
      collectionEditorModalVisible: false,
      loading: true,
      page: 1,
      sharedCollectionsModalVisible: false
    };
  }

  componentDidMount() {
    this.loadCollections();
  }

  loadCollections() {
    this.setState({ loading: true });

    $.ajax({
      data: { filter: 'mine' },
      url: this.props.collectionsUrl
    })
      .done((data) => {
        this.setState({
          collections: data.collections,
          loading: false,
          page: 1
        });
      })
      .fail(() => {
        alert("Oh no! There was an error loading the collections. Sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  renderCollections() {
    // Collection 1
    // Link / Embed / Results / Add / Manage / Delete
    // Play options with link/embed

    const { clozeSentencesUrl, isAdmin } = this.props;
    const { collections, loading, page } = this.state;
    const perPage = 20; // using just js for now
    
    if(loading) {
      return <Loading />;
    }

    if(!collections.length) {
      return (
        <h2 className="text-center" style={{ marginTop: 30 }}>No collections yet - check out shared collections or create your own!</h2>
      );
    }

    return (
      <div>
        {collections.slice((page - 1) * perPage, page * perPage).map((c) => (
          <MyTeachingCollection
            clozeSentencesUrl={clozeSentencesUrl}
            crosswordsUrl={this.props.crosswordsUrl}
            collection={c}
            isAdmin={isAdmin}
            key={c.id}
            onEdit={(collection) => this.setState({ collectionEditorCollection: collection, collectionEditorModalVisible: true })}
          />
        ))}
        <Pagination
          onNextClick={() => this.setState({ page: page + 1 })}
          onPrevClick={() => this.setState({ page: page - 1 })}
          page={page}
          perPage={perPage}
          total={collections.length}
        />
      </div>
    );
  }

  renderContent() {
    const { baseLanguageName, targetLanguageName } = this.props;

    return (
      <div>
        <h1>Teaching {targetLanguageName} from {baseLanguageName}</h1>
        <p>Clozemaster Teaching Collections are collections of cloze-sentences that you can share with your students. They are meant to be played anonymously, meaning students can play through the sentences <em>without</em> having to create an account, and at they end of the round they can enter their name so you can see the results. Teaching Collections are capped at 50 sentences, and are meant to be played through in a single round. You can share a link to your teaching collection, or embed a teaching collection on your site or blog. Questions? Feedback? Let us know <a href="/forum">on the forum</a>.</p>
        <p className="text-right">
          <button className="btn btn-success joystix" onClick={() => this.setState({ sharedCollectionsModalVisible: true })} style={{ marginRight: 10 }}><Icon name="users" type="fa" /> Shared</button>
          <button className="btn btn-success joystix" onClick={() => this.setState({ collectionEditorModalVisible: true })}><Icon name="plus" /> Create</button>
        </p>
        {this.renderCollections()}
      </div>
    );
  }

  renderCollectionEditorModal() {
    if(!this.state.collectionEditorModalVisible) {
      return null;
    }

    return (
      <CollectionEditorModal
        collection={this.state.collectionEditorCollection}
        collectionsUrl={this.props.collectionsUrl}
        isPro={this.props.isPro}
        onCreate={(collection) => this.setState({ collections: [collection].concat(this.state.collections) })}
        onDelete={(collection) => this.setState({ collections: this.state.collections.filter((c) => c.id !== collection.id) })}
        onUpdate={(collection) => this.setState({ collections: this.state.collections.map((c) => c.id === collection.id ? collection: c)})}
        onHidden={() => this.setState({ collectionEditorCollection: null, collectionEditorModalVisible: false })}
        proPromoCan={""}
        proPromoSecondary={""}
      />
    );
  }

  clone(collection) {
    this.setState({ cloning: true });
    $.ajax({
      method: 'post',
      url: collection.cloneUrl
    })
      .done((data) => {
        this.setState({ collections: [data.collection].concat(this.state.collections) }, () => this.sharedCollectionsModal.hide());
      })
      .fail(() => {
        alert("Oh no! There was an error cloning the collection. Please try again and let us know if you see this error message again.");
        this.setState({ cloning: false });
      });
  }

  renderSharedCollectionsModal() {
    if(!this.state.sharedCollectionsModalVisible) {
      return null;
    }

    return (
      <SharedCollectionsModal
        isPro={this.props.isPro}
        onHidden={() => this.setState({ sharedCollectionsModalVisible: false })}
        ref={(el) => this.sharedCollectionsModal = el}
        renderSharedCollection={(collection) => (
          <div className="row shared-collection" key={collection.id} style={{ borderBottom: '1px solid #efefef', marginBottom: 10, paddingBottom: 10 }}>
            <div className="col-xs-12 col-sm-6">
              <h3 style={{ margin: 0 }}>{collection.name}</h3>
              <div>{collection.sentencesCount} sentences / Created by <a href={collection.user.url} target="_blank">{collection.user.username}</a></div>
              <div>{collection.description}</div>
              <div>
                <button className="btn btn-success joystix" disabled={!this.props.isPro || this.state.cloning} onClick={() => this.clone(collection)}>Clone</button>
                {!this.props.isPro && <span className="joystix" style={{ marginLeft: 6 }}>Pro!</span>}
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <div className="scrollbar-always-visible" style={{ maxHeight: 100, overflowY: 'scroll' }}>
                <ol>
                  {collection.sentences.map((s) => {
                    const parts = splitTextOnCloze(s.text);
                    return (
                      <li key={s.id}>
                        {parts.preClozeStr}<strong><u>{parts.cloze}</u></strong>{parts.postClozeStr}
                        <br />
                        <small>{s.translation}</small>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
          </div>
        )}
        url={this.props.collectionsUrl}
      />
    );
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            {this.renderContent()}
            {this.renderCollectionEditorModal()}
            {this.renderSharedCollectionsModal()}
          </div>
        </div>
      </div>
    );
  }
}
