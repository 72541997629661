import React from "react";

import ClozeSentenceSearchModal from "../ClozeSentenceSearchModal";

export default class ClozeTextSentenceSearchBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    };
  }

  componentDidMount() {
    $('body')
      .on('click', '#translation-popover .sentence-search.btn', (e) => {
        e.preventDefault();

        const $sentence = $('.sentence.popovered');
        const selection = $('#translation-popover').find('.selection').text();

        $sentence.popover('destroy').removeClass('highlight');

        this.setState({
          query: selection,
          modalVisible: true,
        });
      })
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    return (
      <ClozeSentenceSearchModal
        collectionsUrl={this.props.collectionsUrl}
        isPro={this.props.isPro}
        onHidden={() => this.setState({ modalVisible: false, query: null })}
        query={this.state.query}
        url={this.props.clozeSentencesUrl}
      />
    );
  }

  render() {
    return (
      <>
        <button className="btn btn-success btn-block btn-sm joystix" onClick={() => this.setState({ modalVisible: true })}>
          <span className="glyphicon glyphicon-search"></span> Sentence Search
        </button>
        {this.renderModal()}
      </>
    );
  }
}

