import React from "react";

import { ReactSortable } from "react-sortablejs";
import { v4 as uuidv4 } from "uuid";

import SentencesBlock from "./SentencesBlock";
import TextBlock from "./TextBlock";

export default class WorksheetEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blocks: this.setupBlocks(props.blocks || []),
      editable: props.editable,
      title: props.title || ""
    };
  }

  setupBlocks(blocksArr) {
    blocksArr.forEach((block) => {
      block.id = uuidv4();

      if (block.type === "sentences_block") {
        block.sentences = block.sentences || [];
        if (block.sentences.length !== 0) {
          block.sentences = Object.values(block.sentences);
          block.sentences.forEach((sentence) => {
            sentence.id = uuidv4();
          });
        }
      }
    });

    return blocksArr;
  }

  addBlock(type) {
    const { blocks } = this.state;
    const element = {};

    if (type === "sentences_block") {
      element.sentences = [];
    } else {
      element.text = "";
    }

    element.type = type;
    element.id = uuidv4();
    blocks.push(element);

    this.setState({
      blocks
    }, () => window.scrollTo(0, document.body.scrollHeight)); // scroll to bottom
  }

  makeEditable(status) {
    $.ajax({
      data: { worksheet: { editable: status } },
      method: "put",
      url: this.props.worksheetUrl,

    })
      .done(() => {
        this.setState({ editable: status });
      })
      .fail(() => {
        alert("Something is wrong");
      });
  }

  validate() {
    const { blocks } = this.state;
    let valid = true;

    blocks.forEach((block) => {
      if (block.type === "sentences_block") {
        if (block.sentences) {
          block.sentences.forEach((sentence) => {
            if (sentence.text.length === 0) {
              sentence.textError = "Can't be empty.";
              valid = false;
            } else if (!sentence.text.match(/{{.*}}/)) {
              sentence.textError = "Should contain a cloze.";
              valid = false;
            } else {
              sentence.textError = null;
            }

            if (sentence.translation.length === 0) {
              sentence.translationError = "Can't be empty.";
              valid = false;
            } else {
              sentence.translationError = null;
            }
          });
        }
      } else {
        if (block.text.length === 0) {
          block.error = "Can't be empty.";
          valid = false;
        } else {
          block.error = null;
        }
      }
    });

    this.setState({ blocks });

    return valid;
  }

  save() {
    if (!this.validate()) {
      alert("Some fields have errors, please correct them and try again.");
      return false;
    }

    const { blocks: content, title } = this.state;
    const { admin, editable } = this.props;

    $.ajax({
      data: { worksheet: { content, editable, title } },
      method: "put",
      url: this.props.worksheetUrl,
    })
      .done(() => {
        if(!editable && !admin) {
          alert("Thank you!");
          window.location.href = "/";
        } else {
          alert("Saved!");
        }
      })
      .fail(() => {
        alert("Something is wrong. Please try again and please let someone know if you see this message again.");
      });
  }

  renderErrorMessage(message) {
    if(!message) {
      return null;
    }

    return (
      <span className="help-block">
        {message}
      </span>
    );
  }

  renderSentencesBlock(item) {
    const { blocks } = this.state;
    const { sentenceSearchUrl } = this.props;

    return (
      <SentencesBlock
        item={item}
        key={item.id}
        onDelete={() => this.setState({ blocks: blocks.filter((b) => b.id !== item.id) })}
        onSentenceAdd={(sentence) => {
          item.sentences.push(sentence);
          this.setState({ blocks });
        }}
        onSentenceChange={(sentence) => {
          item.sentences = item.sentences.map((s) => s.id === sentence.id ? sentence : s);
          this.setState({ blocks });
        }}
        onSentenceDelete={(sentence) => {
          item.sentences = item.sentences.filter((s) => s.id !== sentence.id);
          this.setState({ blocks });
        }}
        onSentencesChange={(sentences) => {
          item.sentences = sentences;
          this.setState({ blocks });
        }}
        sentenceSearchUrl={sentenceSearchUrl}
      />
    );
  }

  renderTextBlock(item) {
    const { blocks } = this.state;

    return (
      <TextBlock
        item={item}
        key={item.id}
        onChange={(e) => {
          item.text = e.target.value;
          this.setState({ blocks });
        }}
        onDelete={() => this.setState({ blocks: blocks.filter((el) => el.id !== item.id) })}
        renderErrorMessage={this.renderErrorMessage.bind(this)}
        textarea={item.type === "explainer"}
      />
    );
  }

  renderTitle() {
    const { title } = this.state;

    return (
      <input
        className="form-control"
        onChange={(e) => { this.setState({ title: e.target.value }); }}
        placeholder="Title"
        type="text"
        value={title}
      />
    );
  }

  renderBlocks() {
    const { blocks } = this.state;

    return (
      <ReactSortable
        handle="legend"
        list={blocks}
        setList={(blocks) => this.setState({ blocks })}
      >
        {blocks.map((item) => {
          if (item.type === "sentences_block") {
            return this.renderSentencesBlock(item);
          } else {
            return this.renderTextBlock(item);
          }
        })}
      </ReactSortable>
    );
  }

  renderFooter() {
    const { editable } = this.state;
    const { admin, previewUrl } = this.props;
    return (
      <div className="footer">
        <button
          className="btn btn-success btn-lg"
          onClick={() => this.save()}
          style={{ marginRight: 10 }}
        >
          Save
        </button>
        <button className="btn btn-default" onClick={() => this.addBlock("header")}>
          Add Header
        </button>
        <button className="btn btn-default" onClick={() => this.addBlock("explainer")}>
          Add Explainer
        </button>
        <button className="btn btn-default" onClick={() => this.addBlock("sentences_block")}>
          Add Sentences Block
        </button>
        {editable && !admin &&
          <button
            className="btn btn-warning btn-sm"
            onClick={() => { if (confirm("Are you sure?")) { this.setState({ editable: false }, () => this.save()); } }}
            type="button"
          >
            Submit
          </button>
        }
        {editable && admin &&
          <button className="btn btn-warning btn-sm" onClick={() => this.makeEditable(false)}>
            Lock
          </button>
        }
        {!editable && admin &&
          <button className="btn btn-warning btn-sm" onClick={() => this.makeEditable(true)}>
            Make Editable
          </button>
        }
        <a
          className="btn btn-default btn-sm"
          href={previewUrl}
          rel="noreferrer"
          target="_blank"
        >
          Preview
        </a>
      </div>
    );
  }

  render() {
    return (
      <div id="worksheet-editor">
        {this.renderTitle()}
        <hr />
        {this.renderBlocks()}
        <hr />
        {this.renderFooter()}
      </div>
    );
  }
}
