import React from "react";

import Loading from "../Loading";

export default class ResultsViewer extends React.Component {
  constructor(props) {
    super(props);

    this.sentencesMap = new Map(props.sentences.map((s) => [s.id, s.text]));

    this.state = {
      loading: true,
      results: []
    };
  }

  componentDidMount() {
    this.loadResults();
  }

  loadResults() {
    $.ajax({
      url: this.props.resultsUrl
    })
      .done((data) => {
        this.setState({ loading: false, results: data.teachingCollectionResults });
      })
      .fail(() => {
      });
  }

  renderResultsTable() {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Stats</th>
            <th>Incorrect</th>
          </tr>
        </thead>
        <tbody>
          {this.state.results.map((r) => (
            <tr className="result" key={r.createdAt}>
              <td>{r.name}</td>
              <td>{r.createdAt}</td>
              <td>
                <ul>
                  <li>Time: {Math.floor(r.stats.elapsedTime / 60) + ':' + (r.stats.elapsedTime % 60 > 9 ? '' : '0') + (r.stats.elapsedTime % 60)}</li>
                  <li>Correct: {r.stats.numCorrect}</li>
                  <li>Incorrect: {r.stats.numIncorrect}</li>
                </ul>
              </td>
              <td>
                <div className="scrollbar-always-visible" style={{ maxHeight: 80, overflowY: 'scroll' }}>
                  <ol>
                    {(r.stats.answerTape || []).filter((a) => !a.correct).map((a) => <li key={a.id}>{this.sentencesMap.get(a.id)}</li>)}
                  </ol>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderIncorrectSentences() {
    const incorrects = new Map();
    this.state.results.forEach((r) => {
      (r.stats.answerTape || []).forEach((a) => {
        if(!a.correct) {
          const text = this.sentencesMap.get(a.id);
          incorrects.set(text, (incorrects.get(text) || 0) + 1);
        }
      });
    });

    if(!incorrects.size) {
      return null;
    }

    return (
      <div>
        <h3>Sentences answered incorrectly one or more times ordered by count:</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Count</th>
              <th>Text</th>
            </tr>
          </thead>
          <tbody>
            {[...incorrects.entries()].sort((a, b) => a[1] > b[1]).map((e) => (
              <tr><td>{e[1]}</td><td>{e[0]}</td></tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    if(this.state.loading) {
      return <Loading />;
    }

    return (
      <div>
        {this.renderResultsTable()}
        {this.renderIncorrectSentences()}
      </div>
    );
  }
}
