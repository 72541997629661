import React from "react";

import { splitTextOnCloze } from "../../helpers";

import Loading from "../Loading";
import Pagination from "../Pagination";

export default class ClozeSentenceSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bulkAddVisible: false,
      clozeSentences: null,
      context: 'text',
      loading: true,
      page: 1,
      perPage: 20,
      query: props.query || '',
      total: 0,
    };
  }

  componentDidMount() {
    this.search();
  }

  search() {
    this.setState({ loading: true });
    const {
      context,
      page,
      perPage,
      query
    } = this.state;
    $.ajax({
      data: {
        context,
        page,
        per_page: perPage,
        query,
      },
      method: 'get',
      url: this.props.url
    })
      .done((data) => {
        console.log(data);
        this.setState({
          clozeSentences: data.clozeSentences,
          loading: false,
          total: data.total
        });
      })
      .fail(() => {
        this.setState({ loading: false });
        alert('Oh no! There was an error searching sentences. Please try again and let us know if you see this error message again.');
      });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ page: 1 }, () => this.search());
  }

  renderForm() {
    const { loading } = this.state;

    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        <div className="row">
          <div className="col-xs-8">
            <div className="form-group">
              <label htmlFor="cloze-sentence-search-query">Query</label>
              <input id="cloze-sentences-query" disabled={loading} className="form-control" type="text" value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} />
            </div>
          </div>
          <div className="col-xs-4">
            <div className="form-group">
              <label htmlFor="cloze-sentence-search-context">Context</label>
              <select id="cloze-sentence-search-context" disabled={loading} className="form-control" value={this.state.context} onChange={(e) => this.setState({ context: e.target.value })}>
                <option value="cloze">Cloze</option>
                <option value="text">Text</option>
                <option value="translation">Translation</option>
              </select>
            </div>
          </div>
        </div>
        <button disabled={loading} type="submit" className="btn btn-success btn-sm btn-block joystix">Search</button>
      </form>
    );
  }

  renderClozeSentences() {
    if(this.state.loading) {
      return <Loading />;
    }

    return (
      <div style={{ marginTop: 10 }}>
        {this.state.clozeSentences.map((cs) => {
          const parts = splitTextOnCloze(cs.text);
          return (
            <div className="cloze-sentence" key={cs.id} style={{ borderBottom: '1px solid #efefef', marginBottom: 10, paddingBottom: 10, position: 'relative' }}>
              <label style={{ fontWeight: 'normal', margin: 0 }}>
                <input checked={this.props.sentencesMap.has(cs.text)} onChange={(e) => e.target.checked ? this.props.add(cs) : this.props.remove(cs)} style={{ position: 'absolute', left: 0, top: 4 }} type="checkbox" />
                <div style={{ marginLeft: 20 }}>
                  <div className="text" style={{ fontSize: '2rem' }}>
                    <span>{parts.preClozeStr}<strong><u>{parts.cloze}</u></strong>{parts.postClozeStr}</span>
                  </div>
                  <div>{cs.translation}</div>
                </div>
              </label>
            </div>
          );
        })}
      </div>
    );
  }

  renderPagination() {
    if(this.state.loading) {
      return null;
    }

    const { page, perPage, total } = this.state;

    return (
      <Pagination
        onNextClick={() => this.setState({ page: this.state.page + 1 }, () => this.search())}
        onPrevClick={() => this.setState({ page: this.state.page - 1 }, () => this.search())}
        page={page}
        perPage={perPage}
        total={total}
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderForm()}
        {this.renderClozeSentences()}
        {this.renderPagination()}
      </div>
    );
  }
}
