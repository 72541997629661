import React from "react";

import Contributors from "./Contributors";
import CrosswordViewer from "./CrosswordViewer";
import EmbedBuilder from "./EmbedBuilder";
import Icon from "../Icon";
import LinkBuilder from "./LinkBuilder";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import Panel from "../Panel";
import ResultsViewer from "./ResultsViewer";
import SentencesEditor from "./SentencesEditor";

export default class MyTeachingCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contributorsEnabled: !!props.collection.contributorsEnabled,
      contributorsModalVisible: false,
      embedModalVisible: false,
      linkModalVisible: false,
      resultsModalVisible: false,
      sentences: props.collection.sentences,
      sentencesModalVisible: false
    };
  }

  renderResultsModal() {
    if(!this.state.resultsModalVisible) {
      return null;
    }

    const { collection } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={() => this.setState({ resultsModalVisible: false })}
        size="large"
        title={collection.name + " Results"}
      >
        <ResultsViewer
          resultsUrl={collection.resultsUrl}
          sentences={collection.sentences}
        />
      </Modal>
    );

  }

  renderSentencesModal() {
    if(!this.state.sentencesModalVisible) {
      return null;
    }

    const { clozeSentencesUrl, collection } = this.props;

    return (
      <Modal
        backdrop="static"
        dialogStyle={{ width: "90%" }}
        onHidden={() => this.setState({ sentencesModalVisible: false })}
        ref={(el) => this.sentencesModal = el}
        size="large"
        title={collection.name + " Sentences"}
      >
        <SentencesEditor
          clozeSentencesUrl={this.props.clozeSentencesUrl}
          collectionUrl={collection.url}
          onCancel={() => this.sentencesModal.hide()}
          onSave={(sentences) => this.setState({ sentences }, () => this.sentencesModal.hide())}
          sentences={this.state.sentences}
        />
      </Modal>
    );
  }

  renderLinkModal() {
    if(!this.state.linkModalVisible) {
      return null;
    }

    const { collection } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={() => this.setState({ linkModalVisible: false })}
        title={collection.name + " Link"}
      >
        <LinkBuilder
          url={collection.playWebUrl}
        />
      </Modal>
    );
  }

  renderEmbedModal() {
    if(!this.state.embedModalVisible) {
      return null;
    }

    const { collection } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={() => this.setState({ embedModalVisible: false })}
        title={"Embed " + collection.name}
      >
        <EmbedBuilder
          url={collection.embeddedPlayUrl}
        />
      </Modal>
    );
  }

  renderCrosswordModal() {
    if(!this.state.crosswordModalVisible) {
      return null;
    }

    const { sentences } = this.state;
    const { collection, crosswordsUrl } = this.props;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={() => this.setState({ crosswordModalVisible: false })}
        size="large"
        title={collection.name + " Crossword"}
      >
        <CrosswordViewer
          crosswordsUrl={crosswordsUrl}
          sentences={sentences}
        />
      </Modal>
    );

  }

  renderContributorsModal() {
    if(!this.state.contributorsModalVisible) {
      return null;
    }

    const { collection } = this.props;
    const { contributorsEnabled } = this.state; 

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={() => this.setState({ contributorsModalVisible: false })}
        title={collection.name + " Contributors"}
      >
        <Contributors
          collection={collection}
          enabled={contributorsEnabled}
          onChange={(enabled) => this.setState({ contributorsEnabled: enabled })}
        />
      </Modal>
    );
  }

  render() {
    const { collection, isAdmin } = this.props;
    const { contributorsEnabled } = this.state;

    return (
      <Panel className="my-teaching-collection">
        <div className="pull-right">
          <ul className="list-inline">
            <li><button className="btn btn-default" onClick={() => this.props.onEdit(collection)}>Edit</button></li>
            <li><button className="btn btn-default" onClick={() => this.setState({ sentencesModalVisible: true })}>Sentences</button></li>
            {!!isAdmin && <li><button className="btn btn-default" onClick={() => this.setState({ contributorsModalVisible: true })}>Contributors <Icon name={contributorsEnabled ? "check" : "lock"} type="fa" /></button></li>}
            <li><button className="btn btn-default" onClick={() => this.setState({ resultsModalVisible: true })}>Results</button></li>
            <li><button className="btn btn-default" onClick={() => this.setState({ linkModalVisible: true })}>Link</button></li>
            <li><button className="btn btn-default" onClick={() => this.setState({ embedModalVisible: true })}>Embed</button></li>
            <li><a className="btn btn-default" href={collection.playWebUrl} target="_blank">Preview</a></li>
            <li><button className="btn btn-default" onClick={() => this.setState({ crosswordModalVisible: true })}>Crossword</button></li>
          </ul>
        </div>
        <h3 style={{ marginTop: 0 }}>{collection.name}</h3>
        <div>Sentences: {this.state.sentences.length} Results: {collection.resultsCount}</div>
        {this.renderSentencesModal()}
        {this.renderContributorsModal()}
        {this.renderResultsModal()}
        {this.renderLinkModal()}
        {this.renderEmbedModal()}
        {this.renderCrosswordModal()}
      </Panel>
    );
  }
}
