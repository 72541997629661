import React from "react";
import Chart from "chart.js";

export default class StatsReportsLineChart extends React.Component {
  componentDidMount() {
    const colors = [
      "rgb(255, 99, 132)",
      "rgb(54, 162, 235)",
      // Array.from(Array(20)).map((x) => Math.floor(Math.random()*16777215).toString(16));
      '#560391', '#70e904', '#328bef', '#16800e', '#1f65e0', '#a3bfca', '#896ab7', '#495a40', '#8dae12', '#3495f', '#7c341d', '#2d3cb4', '#4fb679', '#853531', '#3db719', '#479575', '#ca1d4c', '#823dcf', '#1558e2', '#ba8313'
    ];

    const config = {
			type: 'line',
			data: {
				labels: this.props.dates,
				datasets: this.props.lines.map((l, i) => ({
					backgroundColor: colors[i],
					borderColor: colors[i],
					data: l.data,
					fill: false,
					label: l.label,
				}))
			},
			options: {
				responsive: true,
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
			}
		};

    const ctx = document.getElementById('canvas').getContext('2d');
    const myLine = new Chart(ctx, config);
  }

  render() {
    return (
      <canvas id="canvas"></canvas>
    );
  }
}
