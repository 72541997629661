import React, { useState, useEffect } from 'react';

const SaleCountdownTimer = ({ ends }) => {
  // Set the target date: Sunday, October 22 at midnight
  const targetDate = new Date(`${ends} 23:59:59`);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000); // Update every second

    // Clean up the timer when the component is unmounted
    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  if(timeLeft.days === undefined) {
    return null;
  }

  const unit = (n, label) => (
    <span style={{ display: "flex", flexDirection: "column", alignItems: "center", width: 50 }}> 
      <span className="lead" style={{ marginBottom: 0 }}><strong>{n}</strong></span>
      <span>{label}</span>
    </span>
  );

  return (
    <span style={{ display: "flex", flexDirection: "row", gap: 15, justifyContent: "center" }}>
      {unit(timeLeft.days, "Days")}
      {unit(timeLeft.hours, "Hours")}
      {unit(timeLeft.minutes, "Minutes")}
      {unit(timeLeft.seconds, "Seconds")}
    </span>
  );
};

export default SaleCountdownTimer;
