import React from "react";

import BulkSentenceCreationEditor from "./BulkSentenceCreationEditor";
import Loading from "../Loading";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";

export default class AddTextToCollectionModal extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      phase: 'form',
      text: ''
    };
  }

  hide() {
    this.modal.hide();
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ phase: 'processing' });
    $.ajax({
      data: { text: this.state.text },
      method: 'post',
      url: this.props.quickClozeUrl,
    })
      .done((createData) => {
        this.handleCreateData(createData);
      })
      .fail(() => {
        this.setState({ phase: 'form' });
        alert("Oh no! There was an error processing the text - sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  handleCreateData(createData) {
    console.log(createData);

    if(createData.errors) {
      alert("Oh no! There was an error processing the text: " + createData.errors + " Sorry about that. Please try again and let us know if you see this message again.");
      this.setState({ phase: 'form' });
      return false;
    }

    const statusInterval = setInterval(() => {
      $.ajax({
        url: createData.url
      })
        .done((statusData) => {
          const { error, result } = statusData;
          console.log(statusData);
          if(error) {
            clearInterval(statusInterval);
            this.setState({ phase: 'form' });
            alert("Oh no! There was an error processing the text: " + error + " - sorry about that. Please try again and let us know if you see this message again.");
          }
          if(result) {
            clearInterval(statusInterval);
            this.setState({ phase: 'edit', result: result.map((r, id) => ({ id, ...r })) });
          }
        })
        .fail(() => {
          this.setState({ phase: 'form' });
          alert("Oh no! There was an error processing the text - sorry about that. Please try again and let us know if you see this message again.");
        });
    }, 5000);
  }

  renderProcessing() {
    return (
      <Loading />
    );
  }

  renderForm() {
    const { text } = this.state;
    const max = 10000;
    const over = Math.max(text.length - max, 0);

    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <p>Paste in your text or list of sentences. Max {max.toLocaleString()} characters.</p>
        <div className={'form-group' + (over ? ' has-error' : '')}>
          <label htmlFor="text">Text</label>
          <textarea name="text" className="form-control" onChange={(e) => this.setState({ text: e.target.value })} value={text} />
        </div>
        {!!over && <p className="alert alert-danger">Too many characters! Over by {over}.</p>}
        <button className="btn btn-success joystix" disabled={!!over}>Submit</button>
      </form>
    );
  }

  renderEditor() {
    return (
      <BulkSentenceCreationEditor
        collection={this.props.collection}
        onSentencesAdded={this.props.onSentencesAdded}
        sentences={this.state.result}
      />
    );
  }

  renderContent() {
    const { phase } = this.state;

    switch(phase) {
      case 'form':
        return this.renderForm();
      case 'processing':
        return this.renderProcessing();
      case 'edit':
        return this.renderEditor();
      default:
        throw new Error("unrecognized phase!");
    }
  }

  render() {
    return (
      <Modal
        backdrop="static"
        dialogStyle={this.state.phase === 'edit' ? { width: '95%' } : null}
        footer={<ModalFooterCloseBtn />}
        onHidden={this.props.onHidden}
        ref={(el) => this.modal = el}
        size="large"
        title="Paste in Text"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
