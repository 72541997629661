import React from "react";

import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";
import ReviewSettings from "./ReviewSettings";

export default class ReviewSettingsModal extends React.Component {
  renderProPromo() {
    return (
      <ModalProPromo
        can="customize their review intervals and set the number of reviews per round."
        secondary="Take control of your learning. Get fluent faster."
      />
    );
  }

  renderContent() {
    if(!this.props.isPro) {
      return this.renderProPromo();
    }

    return (
      <ReviewSettings
        onUpdate={() => this.reviewSettingsModal.hide()}
        url={this.props.url}
      />
    );
  }

  render() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn text="Cancel" />}
        onHidden={this.props.onHidden}
        ref={(el) => this.reviewSettingsModal = el}
        title="Review Settings"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
