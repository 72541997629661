import React from "react";

import ClozeSentenceEditor from "./ClozeSentenceEditor";
import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";

export default class CollectionClozeSentenceEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clozeSentenceKey: 0,
      editBoth: true,
      loading: false
    };
  }

  componentDidMount() {
    setTimeout(() => $(".modal .collection-cloze-sentence [name=text]").focus(), 500);
  }

  renderCollectionClozeSentenceEditor() {
    const { clozeSentenceUrl, collectionClozeSentence, collectionClozeSentenceUrl, onDelete, onUpdate, textEditingDisabled, translationEditingDisabled } = this.props;
    const { clozeSentenceKey, editBoth, loading } = this.state;

    return (
      <ClozeSentenceEditor
        disabled={loading}
        onDelete={onDelete}
        onUpdate={({ attrs, data }) => {
          if(editBoth && !!clozeSentenceUrl) {
            this.setState({ loading: true });
            $.ajax({
              contentType: "application/json",
              data: JSON.stringify({ cloze_sentence: data }),
              method: "patch",
              url: clozeSentenceUrl
            })
              .done(() => {
                this.setState({
                  clozeSentenceKey: clozeSentenceKey + 1,
                  loading: false
                });
              })
              .fail(() => {
                alert("Failed to save parent sentence! Sorry about that. Please let someone know.");
                this.setState({ loading: false });
              });
          }
          this.modalRef && this.modalRef.$modal.focus();
          onUpdate && onUpdate(attrs);
        }}
        sentence={collectionClozeSentence}
        textEditingDisabled={textEditingDisabled}
        translationEditingDisabled={translationEditingDisabled}
        type="collection_cloze_sentence"
        url={collectionClozeSentenceUrl}
      />
    );
  }

  renderClozeSentenceEditor() {
    const { clozeSentenceUrl, translationEditingDisabled } = this.props;
    const { clozeSentenceKey, editBoth, loading } = this.state;

    if(!clozeSentenceUrl) {
      return null;
    }

    return (
      <ClozeSentenceEditor
        disabled={editBoth || loading}
        key={clozeSentenceKey}
        onUpdate={() => {
          this.modalRef && this.modalRef.$modal.focus();
        }}
        translationEditingDisabled={translationEditingDisabled}
        type="cloze_sentence"
        url={clozeSentenceUrl}
      />
    );
  }

  renderEditors() {
    const { clozeSentenceUrl } = this.props;

    if(!clozeSentenceUrl) {
      return this.renderCollectionClozeSentenceEditor();
    }

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <p><strong>Collection Cloze-Sentence</strong></p>
          <hr />
          {this.renderCollectionClozeSentenceEditor()}
        </div>
        <div className="col-xs-12 col-sm-6">
          <p><strong>Parent Cloze-Sentence</strong></p>
          <hr />
          {this.renderClozeSentenceEditor()}
        </div>
      </div>
    );
  }

  renderEditBothCheckbox() {
    const { clozeSentenceUrl } = this.props;

    if(!clozeSentenceUrl) {
      return false;
    }

    const { editBoth } = this.state;

    return (
      <>
        <div className="checkbox">
          <label>
            <input checked={editBoth} name="edit_both" onChange={(e) => this.setState({ editBoth: e.target.checked })} type="checkbox" />
            Apply Collection Cloze-Sentence changes to Parent Cloze-Sentence
          </label>
        </div>
        <hr />
      </>
    );
  }

  render() {
    const { clozeSentenceUrl, onHidden } = this.props;
    const { editBoth } = this.state;

    return (
      <Modal
        footer={<ModalFooterCloseBtn />}
        onHidden={onHidden}
        ref={(el) => this.modalRef = el}
        size={!!clozeSentenceUrl ? "large" : null}
        title="Edit Sentence"
      >
        {this.renderEditBothCheckbox()}
        {this.renderEditors()}
      </Modal>
    );
  }
}
