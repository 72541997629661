import React from "react";

import CollectionClozeSentenceEditor from "./CollectionClozeSentenceEditor";
import Modal from "./Modal";
import ModalFooterCloseBtn from "./ModalFooterCloseBtn";

export default class CollectionClozeSentenceEditorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  hide() {
    this.modal.hide();
  }

  onDeleteClick() {
    if(confirm("Are you sure? This action cannot be undone and will affect *everyone* playing this collection!")) {
      if(confirm("Just double checking - you're positive you want to delete this sentence?")) {
        this.deleteSentence();
      }
    }
  }

  deleteSentence() {
    this.setState({ loading: true });
    $.ajax({
      method: 'delete',
      url: this.props.deleteUrl
    })
      .done(() => {
        this.modal.hide({ onHidden: () => this.props.onDelete && this.props.onDelete(this.props.sentence) });
      })
      .fail(() => {
        this.setState({ loading: false });
        alert("Oh no! There was an error deleting the sentence. Sorry about that. Please try again and let us know if you see this message again.");
      });
  }

  renderFooter() {
    if(this.props.deleteUrl) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button className="btn btn-danger" onClick={() => this.onDeleteClick()}>Delete</button>
          <ModalFooterCloseBtn text="Cancel" />
        </div>
      );
    }

    return <ModalFooterCloseBtn text="Cancel" />;
  }

  render() {
    const { isTextEditable } = this.props;

    return (
      <Modal
        footer={this.renderFooter()}
        id="sentence-editor-modal"
        onHidden={this.props.onHidden}
        onShown={() => {
          const $modal = $("#sentence-editor-modal");

          const $collectionName = $modal.find("[name='collection[name]']");
          if($collectionName.length) {
            return $collectionName.focus();
          }

          $modal.find(`[name=${isTextEditable ? "text" : "translation"}]`).focus();
        }}
        ref={(el) => this.modal = el}
        title="Edit Sentence"
      >
        <CollectionClozeSentenceEditor
          alreadyMasteredNextReview={this.props.alreadyMasteredNextReview}
          answerQualityOptionsVisible={this.props.answerQualityOptionsVisible}
          collectionClozeSentence={this.props.sentence}
          disabled={this.state.loading}
          isPro={this.props.isPro}
          isTextEditable={isTextEditable}
          nextReviewByLevel={this.props.nextReviewByLevel}
          onUpdate={(sentence) => {
            this.modal.hide({ onHidden: () => this.props.onUpdate && this.props.onUpdate(sentence) });
          }}
          sentenceOnly={this.props.sentenceOnly}
          updateUrl={this.props.updateUrl}
          upsertUrl={this.props.upsertUrl}
        />
      </Modal>
    );
  }
}
