import React from "react";

export default class Block extends React.Component {
  render() {
    const { children, item, legend, onDelete } = this.props;
    return (
      <fieldset className={item.type} key={item.id}>
        <legend>
          {legend || item.type}
          <button
            className="btn btn-danger btn-xs"
            onClick={() => { if (confirm("Are you sure?")) { onDelete(item); } }}
            style={{ marginLeft: 10 }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </legend>
        {children}
      </fieldset>
    );
  }
}
