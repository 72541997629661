import React from "react";

import Pagination from "../Pagination";
import Word from "./Words/Word";

export default class Words extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      page: parseInt(props.page) || 1,
      query: props.query || "",
      total: 0,
      words: []
    };
  }

  componentDidMount() {
    this.loadWords();
  }

  updateUrlSearchParams() {
    const { page, query } = this.state;

    if('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search)
      searchParams.set("page", page);
      searchParams.set("query", query);
      var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      history.pushState(null, '', newRelativePathQuery);
    }
  }

  loadWords() {
    this.setState({ loading: true });

    const { page, query } = this.state;
    const { perPage, url } = this.props;

    $.ajax({
      data: {
        query,
        page,
        per_page: perPage
      },
      url 
    })
      .done((data) => {
        console.log(data);
        const { total, words } = data;
        this.setState({
          loading: false,
          total,
          words
        }, () => this.updateUrlSearchParams());
      })
      .fail(() => {
        alert("Something's gone terribly wrong! Please let someone know.");
      });
  }

  nextPage() {
    const { page } = this.state;
    this.setState({ page: page + 1 }, () => this.loadWords());
  }

  prevPage() {
    const { page } = this.state;
    this.setState({ page: page - 1 }, () => this.loadWords());
  }

  updateWord({ attrs, word }) {
    this.setState({ loading: true });

    $.ajax({
      data: { word: attrs },
      method: "put",
      url: word.url
    })
      .done((data) => {
        if(data.errors) {
          alert(data.errors);
        }
        this.setState({ loading: false });
      })
      .fail(() => {
        alert("Something's gone terribly wrong! Please let someone know.");
      });
  }

  deleteWord({ word }) {
    this.setState({ loading: true });

    const { page } = this.state;
    const { perPage } = this.props;

    $.ajax({
      data: { page, per_page: perPage },
      method: "delete",
      url: word.url
    })
      .done((data) => {
        console.log(data);
        const { total, words } = data;
        this.setState({
          loading: false,
          total,
          words
        });

      })
      .fail(() => {
        alert("Something's gone terribly wrong! Please let someone know.");
      });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ page: 1 }, () => this.loadWords());
  }

  render() {
    const { perPage } = this.props;
    const { loading, page, query, total, words } = this.state;

    return (
      <>
        <form onSubmit={(e) => this.onSubmit(e)}>
          <div className="form-group">
            <input className="form-control" disabled={loading} onChange={(e) => this.setState({ query: e.target.value })} placeholder="Search..." type="text" value={query} />
          </div>
        </form>
        {words.map((word) => (
          <Word
            destroy={() => this.deleteWord({ word })}
            key={word.text}
            loading={loading}
            update={(attrs) => this.updateWord({ attrs, word })}
            text={word.text}
          />
        ))}
        <hr />
        <div style={{ display: "inline-block", width: 500 }}>
          <Pagination
            onNextClick={() => this.nextPage()}
            onPrevClick={() => this.prevPage()}
            page={page}
            perPage={perPage}
            total={total}
            updating={loading}
          />
        </div>
      </>
    );
  }
}
