import React from "react";
import ReactDOM from "react-dom";

export default class Modal extends React.Component {
  componentDidMount() {
    this.$modal = $(this.modal);
    this.$modal.on("hidden.bs.modal", () => {
      $(".modal:visible").length && $(document.body).addClass("modal-open");
      this.props.onHidden && this.props.onHidden();
    });
    this.$modal.on("show.bs.modal", () => {
      const zIndex = 1040 + (10 * $(".modal:visible").length);
      this.$modal.css("z-index", zIndex);
      setTimeout(function() {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", zIndex - 1)
          .addClass("modal-stack");
      }, 0);
      this.props.onShow && this.props.onShow();
    });
    this.$modal.on("shown.bs.modal", () => this.props.onShown && this.props.onShown());
    if(this.props.show === false) {
      this.$modal.modal();
    }
    else {
      this.show();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.show !== prevProps.show) {
      this[this.props.show ? "show" : "hide"]();
    }
  }

  show() {
    this.$modal.modal("show");
  }

  renderFooter() {
    if(!this.props.footer) {
      return null;
    }
    // <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
    // <button type="button" className="btn btn-primary">Save changes</button>
    return (
      <div className="modal-footer">
        {this.props.footer}
      </div>
    );
  }

  getSizeClassName() {
    const { size } = this.props;
    if(size === "large") {
      return "modal-lg";
    }
    else if(size === "small") {
      return "modal-sm";
    }
    return "";
  }

  hide(args) {
    const { noFade, onHidden } = (args || {});

    if(noFade) {
      $(this.modal).removeClass("fade");
    }

    if(onHidden) {
      this.$modal.one("hidden.bs.modal", onHidden);
    }

    $(this.modal).modal("hide");
  }

  render() {
    const { noFade } = this.props;
    return ReactDOM.createPortal(
      <div className={`modal clozemaster text-left ${noFade ? "" : "fade"}`} id={this.props.id} data-show={this.props.show === false ? "false" : "true"} data-keyboard={this.props.noClose ? "false" : null} data-backdrop={this.props.backdrop ? this.props.backdrop : (this.props.noClose ? "static" : null)} tabIndex="-1" role="dialog" ref={(el) => this.modal = el}>
        <div className={"modal-dialog " + this.getSizeClassName()} role="document" style={this.props.dialogStyle}>
          <div className="modal-content">
            <div className="modal-header">
              {!this.props.noClose && !this.props.noCloseBtn && (
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
              <h5 className="modal-title joystix">{this.props.title}</h5>
            </div>
            <div className="modal-body">
              {this.props.children}
            </div>
            {this.renderFooter()}
            {this.props.modalContentExtra}
          </div>
        </div>
      </div>,
      document.querySelector("body")
    );
  }
}
