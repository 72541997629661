import React from "react";

import LeaderboardModal from "./LeaderboardModal";
import RankingsModal from "./RankingsModal";
import StatPanel from "./StatPanel";

import { ordinalSuffix } from "../../helpers";

export default class LeaderboardStatPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    };
  }

  renderControls() {
    return (
      <>
        <small>This week in points</small>
        <button className="btn btn-xs btn-default btn-block" disabled={!this.props.isSignedIn} onClick={() => this.setState({ modalVisible: true })}>View</button>
      </>
    );
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    const { baseLanguageFlagIso, leaderboardsUrl, rankingsUrl, targetLanguageFlagIso, useRankingsForLeaderboard } = this.props;

    if(useRankingsForLeaderboard) {
      return (
        <RankingsModal
          baseLanguageFlagIso={baseLanguageFlagIso}
          onHidden={() => this.setState({ modalVisible: false })}
          rankingsUrl={rankingsUrl}
          targetLanguageFlagIso={targetLanguageFlagIso}
        />
      );
    }
    else {
      return (
        <LeaderboardModal
          baseLanguageFlagIso={baseLanguageFlagIso}
          onHidden={() => this.setState({ modalVisible: false })}
          leaderboardsUrl={leaderboardsUrl}
          targetLanguageFlagIso={targetLanguageFlagIso}
        />
      );
    }
  }

  renderValue() {
    // const { prevDayRanking, ranking } = this.props;
    const { currentWeekLeaderboardRank } = this.props;

    return (
      <>
      <span className="rank">{currentWeekLeaderboardRank ? <span>{currentWeekLeaderboardRank}<sup>{ordinalSuffix(currentWeekLeaderboardRank)}</sup></span> : 'N/A'}</span>
        {/*ranking && prevDayRanking ?
          <span className={'glyphicon trend glyphicon-arrow-' + (ranking > prevDayRanking ? 'up uptrend' : 'down downtrend')} data-toggle="tooltip" title={'Ranked ' + prevDayRanking + ' yesterday UTC'}></span>
          : null
        */}
      </>
    );
  }

  render() {
    return (
      <StatPanel
        className="leaderboard"
        title="Leaderboard"
        value={this.renderValue()}
      >
        {this.renderControls()}
        {this.renderModal()}
      </StatPanel>
    );
  }
}
