import React from "react";

import { collectionPctStr, getLocalStorageBoolean, setLocalStorageBoolean } from "../../helpers";

import CollectionProgressBar from "./CollectionProgressBar";
import FastTrackStatsModal from "./FastTrackStatsModal";
import Icon from "../Icon";
import MasteredIcon from "../MasteredIcon";
import PlayingIcon from "../PlayingIcon";

export default class FastTrackComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statsModalVisible: false
    };
  }

  renderStatsModal() {
    if(!this.state.statsModalVisible) {
      return null;
    }

    return (
      <FastTrackStatsModal
        collection={this.props.fastTrackCollection}
        onHidden={() => this.setState({ statsModalVisible: false })}
      />
    );
  }

  render() {
    const { fastTrackCollection, isSignedIn, progressPerChunk, useProgressChunks } = this.props;

    return (
      <div className="panel panel-default fast-track">
        <div className="panel-body">
          <div>
            <div className="pull-right hidden-xs">
              <button disabled={!isSignedIn} onClick={() => this.props.play(fastTrackCollection)} className="btn btn-success btn-lg btn-block joystix">Play <span className="glyphicon glyphicon-chevron-right"></span></button>
              {!isSignedIn && <div className="joystix text-center">Sign up!</div>}
            </div>
            <h2>
              Fluency Fast Track
              <button
                className="btn btn-xs btn-link"
                data-content="Fast and efficient language learning. Each word in each sentence is matched against a frequency list (a list of words ordered by how often they're likely to occur). The most difficult (least common) word is then selected as the missing word. The Fast Track has one sentence for each missing word, and the sentences are played in order of difficulty, so you're always making progress."
                data-title="Fluency Fast Track"
                data-toggle="popover"
                data-trigger="focus"
                style={{ fontSize: '65%', marginLeft: 8 }}
              >
                <span className="glyphicon glyphicon-question-sign"></span>
              </button>
              <button data-test-id="manage-fast-track-btn" data-toggle="tooltip" data-title="Manage Fast Track" disabled={!isSignedIn} onClick={() => this.props.manage(fastTrackCollection)} className="btn btn-default btn-sm" style={{ marginLeft: 8 }}><Icon name="list" /></button>
              <button id="fast-track-stats-btn" data-toggle="tooltip" data-title="Fast Track Stats" disabled={!isSignedIn} onClick={() => this.setState({ statsModalVisible: true })} className="btn btn-default btn-sm" style={{ marginLeft: 8 }}><Icon name="stats" /></button>
                <small style={{ /*color: "inherit", */ display: 'block', /* fontSize: "17.5px", fontWeight: "bold", */ marginTop: 8 }}>Play {fastTrackCollection.numSentences.toLocaleString()} sentences with {fastTrackCollection.numSentences.toLocaleString()} unique cloze-words in order of difficulty.</small>
            </h2>
          </div>
          <CollectionProgressBar
            collection={fastTrackCollection}
            perChunk={progressPerChunk}
            useChunks={useProgressChunks}
          />
          <div>
            <span style={{ marginLeft: 8, fontSize: '1.75rem' }}>
              <strong><PlayingIcon /> Playing {fastTrackCollection.numPlaying.toLocaleString()} <span className="hidden-xs"> / {fastTrackCollection.numSentences.toLocaleString()} sentences </span>({collectionPctStr(fastTrackCollection, 'playing')})</strong>
            </span>
            <span style={{ marginLeft: 8, fontSize: '1.75rem' }}>
              <strong><MasteredIcon /> Mastered {fastTrackCollection.numMastered.toLocaleString()} {/*<span className="hidden-xs">sentences </span>*/}({collectionPctStr(fastTrackCollection, 'mastered')})</strong>
            </span>
          </div>
          <button disabled={!isSignedIn} onClick={() => this.props.play(fastTrackCollection)} className="btn btn-success btn-lg btn-block joystix visible-xs" style={{ marginTop: 10 }}>Play <span className="glyphicon glyphicon-chevron-right"></span></button>
          {!isSignedIn && <div className="joystix text-center visible-xs" style={{ marginTop: 4 }}>Sign up!</div>}
        </div>
        {this.renderStatsModal()}
      </div>
    );
  }
}
