import React from "react";

import Modal from "../Modal";
import ModalFooterCloseBtn from "../ModalFooterCloseBtn";
import ModalProPromo from "../ModalProPromo";
import ReviewSettings from "./V3ReviewSettings";

export default class ReviewSettingsModal extends React.Component {
  renderContent() {
    return (
      <ReviewSettings
        isPro={this.props.isPro}
        onUpdate={() => this.reviewSettingsModal.hide()}
        url={this.props.url}
      />
    );
  }

  render() {
    return (
      <Modal
        footer={<ModalFooterCloseBtn text="Cancel" />}
        onHidden={this.props.onHidden}
        ref={(el) => this.reviewSettingsModal = el}
        title="Default Review Settings"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}
