import React, { useEffect, useRef, useState } from "react";

import { trackEvent } from "../analytics";

import DailyReminderModal from "./DailyReminderModal";
import Icon from "./Icon";
import Modal from "./Modal";
import Strike from "./Strike";

const DailyReminder = ({ selected, updateUrl, timeZone }) => {
  const [dailyReminderEmail, setDailyReminderEmail] = useState(selected);
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  let dailyReminder = null;
  if(dailyReminderEmail) {
    dailyReminder = (
      <p>
        Your daily reminder email is set for {dailyReminderEmail} {timeZone}.
      </p>
    );
  }

  return <>
    {dailyReminder}

    <button
      className={`btn btn-default btn-block ${dailyReminderEmail ? "" : "btn-lg"} joystix`}
      onClick={() => setShowModal(true)}
      style={{textWrap: "wrap"}}
    >
      <Icon name="bell" /> {
        dailyReminderEmail ?
          <>Update<span className="hidden-xs"> Daily Reminder</span></> :
          <>Set <span className="hidden-xs">a </span>Daily Reminder</>
      }
    </button>

    {
      showModal &&
        <DailyReminderModal
          dailyReminderEmail={dailyReminderEmail}
          onHidden={() => setShowModal(false)}
          onUpdate={(selected) => {
            setDailyReminderEmail(selected);
            modalRef.current.hide();
            setShowModal(false);
          }}
          ref={modalRef}
          updateUrl={updateUrl}
        />
    }
  </>;
};

const MaxPlayedTodayModal = ({ dailyReminder, languagePairingWebUrl, sentenceLimit }) => {
  useEffect(() => {
    trackEvent("max played today modal shown", { dailyReminder: !!dailyReminder });
  }, []);

  return (
    <Modal
      footer={
        <div className="text-left">
          <a className="btn btn-default btn-sm joystix" href={languagePairingWebUrl || "/dashboard"}><Icon name="chevron-left" />Dashboard</a>
        </div>
      }
      noClose={true}
      title="Daily Limit Reached"
    >
      <div className="alert alert-success" style={{ fontSize: "1.5em", textAlign: "center" }}>
        <p>You've reached today's limit of { sentenceLimit } sentences! 🙌 </p>
        <p>Come back tomorrow or <strong>get Clozemaster Pro for unlimited access to everything on Clozemaster!</strong></p>
      </div>

      {
        dailyReminder?.show && (
          <>
            <DailyReminder {...dailyReminder} />
            <Strike style={{ fontSize: "1.3em", marginBottom: 10, marginTop: 10 }}>or</Strike>
          </>
        )
      }

      <a
        className="btn btn-attention btn-block btn-lg joystix"
        href="/pro#subscription-plans"
      >
        Get Pro Today!
      </a>
    </Modal>
  );
};

export default MaxPlayedTodayModal;
