import React from "react";

export default class Tokens extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tokens: null
    };
  }

  componentDidMount() {
    const { url } = this.props;

    $.ajax({
      data: { include: "morphological_features,pos_tags" },
      url
    })
      .done((data) => {
        this.setState({
          loading: false,
          morphologicalFeatures: data.morphologicalFeatures,
          posTags: data.posTags,
          tokens: data.tokens
        });
      })
      .fail(() => {
        alert("Failed to get tokens! Please refresh the page to try again and let someone know if you see this message again.");
      });
  }

  render() {
    const { loading, tokens } = this.state;

    if(loading) {
      return "Loading...";
    }

    if(!tokens || !tokens.length) {
      return null;
    }

    return (
      <div>
        {tokens.map((t) => (
          <div style={{ display: "inline-block", marginBottom: 10, marginRight: 15, textAlign: "center", verticalAlign: "top" }}>
            {t.text}
            {t.posTag && <><br />{t.posTag.key}</>}
            {t.lemma && t.lemma.text && t.lemma.text !== t.text && <><br />{t.lemma.text}</>}
          </div>
        ))}
      </div>
    );
  }
}
